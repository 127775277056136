import { React, useState, useEffect } from "react";
import Axios, {
  BookingAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance";
import { Rnd } from "react-rnd";
// Internal module import
import "./DraggableBookingTable.css";
// Chakara ui modules
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import {
  useDisclosure,
  IconButton,
  useToast,
  Stack,
  Button,
  Tooltip,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Select,
  Input,
  Textarea,
  Badge,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import TextArea from "antd/es/input/TextArea";

const DraggableBookingTable = ({
  isLoading,
  columns,
  data,
  bookingData,
  getData,
  setBookingData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsBooking,
  pageNumbers,
}) => {
  const toast = useToast();
  // State Variables
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState({});
  const [selectedBookingContainer, setSelectedBookingContainer] = useState({});
  const [editedBooking, setEditedBooking] = useState({});
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [viewConatiner, setViewConatiner] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [size, setSize] = useState("xl"); // Set the initial size to 'full'
  const [salesPersonData, setSalesPersonData] = useState([]);
  const {
    isOpen: isOpenContainer,
    onOpen: onOpenContainer,
    onClose: onCloseContainer,
  } = useDisclosure();

  const [isContainerOpen, setIsContainerOpen] = useState(false);
  const [sizeContainer, setSizeContainer] = useState("xl");

  const {
    isOpen: isOpenVesselDetails,
    onOpen: onOpenVesselDetails,
    onClose: onCloseVesselDetails,
  } = useDisclosure();

  const [isVesseldetailsOpen, setIsVesselDetailsOpen] = useState(false);
  const [sizeVesseldetails, setSizeVesselDetails] = useState("xl");

  const [formData, setFormData] = useState({});
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  // Grade Map
  const [productData, setProductData] = useState([]);
  const [incotermData, setIncotermData] = useState([]);
  const [scopeData, setScopeData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [portData, setPortData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [filteredPIC, setFilteredPIC] = useState("");
  const [filteredAddress, setFilteredAddress] = useState([]);

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Equipment API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        console.log(error);
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Incoterm API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_incoterm");
        setIncotermData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_region");
        setRegionData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Scope API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Port API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //Sales Person API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_salesperson");
        setSalesPersonData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  const [equipmentData, setEquipmentData] = useState([]);
  // UseEffects
  useEffect(() => {
    setEditedBooking(selectedBooking);
  }, [selectedBooking]);

  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );

      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);

  const sortTable = (key) => {
    let newSortOrder = "asc";

    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...bookingData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortKey(key);
    setSortOrder(newSortOrder);

    if (typeof setBookingData === "function") {
      setBookingData(sortedData);
    }
  };

  function handleContainerChange(name, value, str) {
    // Parse the string into an array of objects
    let containers = str?.split(";").map((item) => {
      let obj = {};
      item
        .trim()
        ?.split(",")
        .forEach((prop) => {
          let [key, val] = prop?.split(":").map((p) => p.trim());
          // obj[key] = isNaN(val) ? val : parseFloat(val);
          obj[key] = val;
        });
      return obj;
    });

    // Update the specified object
    let [propertyName, index] = name?.split("-");
    index = parseInt(index);
    containers[index][propertyName] = value;

    // Convert the array back into a string
    let updatedStr = containers
      .map((obj) => {
        return Object.entries(obj)
          .map(([key, val]) => `${key}: ${val}`)
          .join(", ");
      })
      .join("; ");

    return updatedStr;
  }

  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;

      // // Update editedBooking state
      // setEditedBooking((prev) => ({
      //   ...prev,
      //   [name]: value,
      // }));
      // Update editedBooking state for the Company Name selection
      if (name === "CompanyName") {
        const selectedCompany = customerData.find(
          (data) => data.Company === value
        );

        if (selectedCompany) {
          setEditedBooking((prev) => ({
            ...prev,
            CompanyName: value,
            PIC: selectedCompany.Name, // Update PIC
            Address: `${selectedCompany.Address_Line_1 || ""}, ${
              selectedCompany.Address_Line_2 || ""
            }, ${selectedCompany.Address_Line_3 || ""}, ${
              selectedCompany.City || ""
            }, ${selectedCompany.State || ""}, ${
              selectedCompany.Country || ""
            } - ${selectedCompany.Pincode || ""}`, // Update Address (formatted)
          }));
        }
      } else {
        // Update editedBooking state for other fields
        setEditedBooking((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } catch (error) {
      // Handle errors if necessary
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const handleInnerContainerChange = async (e) => {
    try {
      const { name, value } = e.target;

      const ans = handleContainerChange(name, value, editedBooking.Containers);

      // Update editedBooking state
      setEditedBooking((prev) => ({
        ...prev,
        Containers: ans,
      }));
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const addContainer = () => {
    setEditedBooking((prev) => ({
      ...prev,
      Containers:
        (prev.Containers || "") +
        ";ContainerType: , Quantity: , Weight: , Dimensions: ",
    }));
  };

  // function removeRowByIndex(data, indexToRemove) {
  //   let rows = data?.split(";").filter((row) => row.trim() !== ""); // Split the data into rows
  //   const removedContainerIds = []; // Array to hold the removed container IDs

  //   if (indexToRemove >= 0 && indexToRemove < rows.length) {
  //     const removedRow = rows[indexToRemove]; // Get the row being removed
  //     const containerId = removedRow
  //       .split(", ")
  //       .find((part) => part.startsWith("Container_ID:"))
  //       .split(": ")[1]
  //       .trim(); // Extract Container_ID
  //     removedContainerIds.push({ id: containerId, flag: 1 }); // Push the object into the array
  //     rows.splice(indexToRemove, 1); // Remove row at specified index
  //   }

  //   return { updatedRows: rows.join("; "), removedContainerIds }; // Return updated rows and removed container IDs
  // }

  // const removeContainer = (indexToRemove) => {
  //   const { updatedRows, removedContainerIds } = removeRowByIndex(
  //     editedBooking.Containers,
  //     indexToRemove
  //   );
  //   setEditedBooking((prev) => ({
  //     ...prev,
  //     Containers: updatedRows,
  //     DeletionFlag: [...(prev.DeletionFlag || []), ...removedContainerIds], // Append new deletion flags
  //   }));
  // };

  function removeRowByIndex(data, indexToRemove) {
    let rows = (data || "").split(";").filter((row) => row.trim() !== ""); // Ensure data is a string
    const removedContainerIds = []; // Array to hold the removed container IDs

    if (indexToRemove >= 0 && indexToRemove < rows.length) {
      const removedRow = rows[indexToRemove]; // Get the row being removed
      const containerId = removedRow
        .split(", ")
        .find((part) => part.startsWith("Container_ID:"))
        ?.split(": ")[1]
        ?.trim(); // Safely extract Container_ID
      if (containerId) {
        removedContainerIds.push({ id: containerId, flag: 1 }); // Push the object into the array
      }
      rows.splice(indexToRemove, 1); // Remove row at specified index
    }

    return { updatedRows: rows.join("; "), removedContainerIds }; // Return updated rows and removed container IDs
  }

  const removeContainer = (indexToRemove) => {
    const { updatedRows, removedContainerIds } = removeRowByIndex(
      editedBooking.Containers,
      indexToRemove
    );
    setEditedBooking((prev) => ({
      ...prev,
      Containers: updatedRows || "", // Ensure Containers is updated as a string
      DeletionFlag: [...(prev.DeletionFlag || []), ...removedContainerIds], // Append new deletion flags
    }));
  };

  // Sailing Vessel Details
  const addVesselDetails = () => {
    setEditedBooking((prev) => ({
      ...prev,
      VesselDetails:
        (prev.VesselDetails || "") +
        ";VesselType: , Vessel: , Voyage: , PolEta: , PortOfLoading: , ETD: , PortOfDischarge: , ETA: ",
    }));
  };

  function removeRowByVesselIndex(data, indexToRemove) {
    let rows = (data || "").split(";").filter((row) => row.trim() !== ""); // Ensure data is a string
    const removedVesselIds = []; // Array to hold the removed Vessel_ID

    if (indexToRemove >= 0 && indexToRemove < rows.length) {
      const removedRow = rows[indexToRemove]; // Get the row being removed
      const vendorId = removedRow
        .split(", ")
        .find((part) => part.startsWith("Vessel_ID:"))
        ?.split(": ")[1]
        ?.trim(); // Safely extract Vessel_ID
      if (vendorId) {
        removedVesselIds.push({ id: vendorId, flag: 1 }); // Push the object into the array
      }
      rows.splice(indexToRemove, 1); // Remove row at specified index
    }

    return { updatedRows: rows.join("; "), removedVesselIds }; // Return updated rows and removed Vessel_ID
  }

  const removeVessel = (indexToRemove) => {
    const { updatedRows, removedVesselIds } = removeRowByVesselIndex(
      editedBooking.VesselDetails,
      indexToRemove
    );
    setEditedBooking((prev) => ({
      ...prev,
      VesselDetails: updatedRows || "", // Ensure Vessel is updated as a string
      VesselDeletionFlag: [
        ...(prev.VesselDeletionFlag || []),
        ...removedVesselIds,
      ], // Append new deletion flags
    }));
  };

  const handleInnerVesselDetailsChange = async (e) => {
    try {
      const { name, value } = e.target;
      const ans = handleContainerChange(
        name,
        value,
        editedBooking.VesselDetails
      );
      // Update editedBooking state
      setEditedBooking((prev) => ({
        ...prev,
        VesselDetails: ans,
      }));
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  // TermsAndCondition

  const addtermsandcondition = () => {
    setEditedBooking((prev) => ({
      ...prev,
      TermsConditions: (prev.TermsConditions || "") + ";TermsAndConditions: ",
    }));
  };

  function removeRowByTermsConditionIndex(data, indexToRemove) {
    let rows = (data || "").split(";").filter((row) => row.trim() !== ""); // Ensure data is a string
    const removedTermsConditionIds = []; // Array to hold the removed TermsCondition_ID

    if (indexToRemove >= 0 && indexToRemove < rows.length) {
      const removedRow = rows[indexToRemove]; // Get the row being removed
      const termsandconditionId = removedRow
        .split(", ")
        .find((part) => part.startsWith("TermsCondition_ID:"))
        ?.split(": ")[1]
        ?.trim(); // Safely extract TermsCondition_ID
      if (termsandconditionId) {
        removedTermsConditionIds.push({ id: termsandconditionId, flag: 1 }); // Push the object into the array
      }
      rows.splice(indexToRemove, 1); // Remove row at specified index
    }

    return { updatedRows: rows.join("; "), removedTermsConditionIds }; // Return updated rows and removed TermsCondition_ID
  }

  const removeTermsandconditions = (indexToRemove) => {
    const { updatedRows, removedTermsConditionIds } =
      removeRowByTermsConditionIndex(
        editedBooking.TermsConditions,
        indexToRemove
      );
    setEditedBooking((prev) => ({
      ...prev,
      TermsConditions: updatedRows || "", // Ensure termscondition is updated as a string
      TermsConditionDeletionFlag: [
        ...(prev.TermsConditionDeletionFlag || []),
        ...removedTermsConditionIds,
      ], // Append new deletion flags
    }));
  };

  const handleInnerTermsandConditionChange = async (e) => {
    try {
      const { name, value } = e.target;
      const ans = handleContainerChange(
        name,
        value,
        editedBooking.TermsConditions
      );
      // Update editedBooking state
      setEditedBooking((prev) => ({
        ...prev,
        TermsConditions: ans,
      }));
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  // Single Update
  const handleSubmitUpdate = async () => {
    const updateData = {
      ...editedBooking,
      //Containers: operations
    };
    console.log(editedBooking);
    try {
      const { data } = await BookingAPIInstance.put(
        `singleupdate_booking?userId=?${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          ...updateData,
          id: selectedBooking.id,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Booking Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        // Optionally, you may want to update the selectedBooking state here
        setSelectedBooking((prevSelectedBooking) => ({
          ...prevSelectedBooking,
          ...editedBooking,
        }));
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };
  //  Function to open the modal and fetch booking data for a particular ID
  const handleOpenModal = async (id) => {
    try {
      const selectedBookingData = bookingData.find(
        (booking) => booking.id === id
      );
      setSelectedBooking(selectedBookingData);
      handleFullSizeClick();
      setSelectedRowId(id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenContainer = async (BookingNumber) => {
    try {
      const selectedBookingData = bookingData.find(
        (booking) => booking.BookingNumber === BookingNumber
      );
      setSelectedBooking(selectedBookingData);
      handleFullSizeContainer();
      setSelectedRowId(BookingNumber);
      console.log(selectedBookingData, "Booking Data Id");
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenVesselDetails = async (BookingNumber) => {
    try {
      const selectedBookingData = bookingData.find(
        (booking) => booking.BookingNumber === BookingNumber
      );
      setSelectedBooking(selectedBookingData);
      handleFullSizeVesselDetails();
      setSelectedRowId(BookingNumber);
      console.log(selectedBookingData, "Booking Data Id");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setCustomerData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  const handleCloseModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
    onClose(true);
  };

  const handleCloseConatiner = () => {
    setSelectedRowId(null);
    setIsContainerOpen(false);
    onCloseContainer(true);
  };

  const handleCloseVesselDetails = () => {
    setSelectedRowId(null);
    setIsVesselDetailsOpen(false);
    onCloseVesselDetails(true);
  };

  // Single Edit Module
  const handleFullSizeClick = () => {
    setSize("xl");
    onOpen();
  };

  const handleFullSizeContainer = () => {
    setSizeContainer("xl");
    onOpenContainer();
  };

  const handleFullSizeVesselDetails = () => {
    setSizeVesselDetails("xl");
    onOpenVesselDetails();
  };

  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    // overflowY: "scroll",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "9999",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };
  return (
    <div>
      {!isLoading ? (
        tableColumnsBooking?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {true ? (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={checkBoxAll}
                        onChange={() => {
                          handleCheckbox(data.map((item) => item.id));
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              ); // Return the filtered array
                            } else {
                              return [...prev, currentPage]; // Return a new array with the added item
                            }
                          });

                          setCheckBoxAll((prev) => {
                            if (prev === true) {
                              setCheckBoxSelected([]);
                            }
                            return !prev;
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={
                          checkBoxSelected.length === 0
                            ? false
                            : activePageCheckBoxSelect.includes(currentPage)
                        }
                        onChange={() => {
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          const idsToAdd = data.map((item) => item.id);
                          setCheckBoxSelected((prev) => {
                            const currentData = new Set(prev);
                            if (
                              activePageCheckBoxSelect.includes(currentPage)
                            ) {
                              // If the page is selected, remove its IDs
                              idsToAdd.forEach((item) => {
                                currentData.delete(item);
                              });
                            } else {
                              // If the page is not selected, add its IDs
                              idsToAdd.forEach((item) => {
                                currentData.add(item);
                              });
                            }
                            return Array.from(currentData);
                          });
                        }}
                      />
                    )}
                  </div>
                </th>
                <th className="border border-slate-300 whitespace-nowrap text-center">
                  Edit
                </th>

                {tableColumnsBooking.map(
                  (column, index) =>
                    column.label !== "Id" &&
                    column.label !== "TermsConditions" &&
                    // column.label !== "Containers" &&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        {column.label === "EmptyPickupLocation" ? (
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              {column.label}
                            </span>
                            <BiSort
                              className="whitespace-nowrap text-left"
                              style={{
                                marginLeft: "190px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        ) : (
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              {column.label}
                            </span>
                            <BiSort
                              className="whitespace-nowrap text-left"
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        )}
                      </th>
                    )
                )}
                {/* <table className="table-auto border-collapse border w-full">
                  <tr>
                    <th colspan="4" >Container Details</th>
                  </tr>
                  <tr>
                    <th className="whitespace-nowrap container-table-head">Container Type</th>
                    <th>Quantity</th>
                    <th>Weight</th>
                    <th>Dimensions</th>
                  </tr>
                </table> */}
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                        {false ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(row?.id, row?.BookingNumber);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(row?.id, row?.BookingNumber);
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected?.includes(row.id)
                            }
                          />
                        )}
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        label="Edit Row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="right-start"
                      >
                        <IconButton
                          icon={<MdEdit />}
                          onClick={() => handleOpenModal(row?.id)}
                          boxSize={6}
                          size={"20px"}
                        />
                      </Tooltip>
                    </td>

                    {tableColumnsBooking.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        // column.label !== "Containers" &&
                        column.label !== "TermsConditions" &&
                        column.isVisible === true && (
                          <td
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {column.key === "SICutOff" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "ETD" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "ETA" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "ETDDestination" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "CYCutOff" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "VGMCutOff" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "ConversionFlag" ? (
                              row[column.key] === 1 ? (
                                <Badge colorScheme="green">Converted</Badge>
                              ) : (
                                <Badge colorScheme="red">Not Converted</Badge>
                              )
                            ) : column.key === "DeletionFlag" ? (
                              row[column.key] === 1 ? (
                                <Badge colorScheme="red">Deleted</Badge>
                              ) : (
                                <Badge colorScheme="green">Not Deleted</Badge>
                              )
                            ) : column.key === "Containers" ? (
                              row[column.key] ? (
                                <>
                                  <Button
                                    colorScheme="teal"
                                    size="sm"
                                    onClick={() => {
                                      //if(columnIndex === )
                                      console.log("Column Index", columnIndex);
                                      handleOpenContainer(row?.BookingNumber);
                                    }}
                                  >
                                    View Container
                                  </Button>
                                </>
                              ) : (
                                ""
                              )
                            ) : column.key === "VesselDetails" ? (
                              row[column.key] ? (
                                <>
                                  <Button
                                    colorScheme="teal"
                                    size="sm"
                                    onClick={() => {
                                      //if(columnIndex === )
                                      console.log("Column Index", columnIndex);
                                      handleOpenVesselDetails(
                                        row?.BookingNumber
                                      );
                                    }}
                                  >
                                    View Vessel Details
                                  </Button>
                                </>
                              ) : (
                                ""
                              )
                            ) : (
                              row[column.key]
                            )}
                          </td>
                        )
                    )}

                    {/* {tableColumnsBooking.map((column, columnIndex) => {
                      if (column.label === "Id" || !column.isVisible)
                        return null;

                      const formatDate = (date) =>
                        date ? new Date(date).toLocaleDateString() : "";

                      return (
                        <td
                          key={`${rowIndex}-${columnIndex}`}
                          style={{
                            textAlign: "left",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {[
                            "SICutOff",
                            "ETD",
                            "ETA",
                            "CYCutOff",
                            "VGMCutOff",
                          ].includes(column.key) ? (
                            formatDate(row[column.key])
                          ) : column.key === "Containers" ? (
                            row[column.key] ? (
                              <Button
                                colorScheme="teal"
                                size="sm"
                                onClick={() => handleOpenContainer(row?.id)}
                              >
                                View Container
                              </Button>
                            ) : (
                              ""
                            )
                          ) : (
                            row[column.key]
                          )}
                        </td>
                      );
                    })} */}

                    {/* {tableColumnsBooking.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        column.isVisible === true && (
                          <td
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {(() => {
                              const value = row[column.key];
                              if (!value) return "";

                              if (
                                column.key === "SICutOff" ||
                                column.key === "ETD" ||
                                column.key === "ETA" ||
                                column.key === "CYCutOff" ||
                                column.key === "VGMCutOff"
                              ) {
                                return new Date(value).toLocaleDateString();
                              }

                              if (column.key === "Containers") {
                                return (
                                  <Button
                                    colorScheme="teal"
                                    size="sm"
                                    onClick={() => {
                                      //if(columnIndex === )
                                      console.log("Column Index", columnIndex);
                                      handleOpenContainer(row?.BookingNumber);
                                    }}
                                  >
                                    View Container
                                  </Button>
                                );
                              }

                              return value;
                            })()}
                          </td>
                        )
                    )} */}

                    {/* {row.Containers?.split(';').map((containerInfo, index) => {
                        const containerDetails = containerInfo.trim()?.split(', ');
                        return (

                          <tr key={index}>
                            {containerDetails.map((detail, detailIndex) => {
                              const [key, value] = detail?.split(': ');
                              if (key.trim() === 'Container_ID') {
                                // setContainerId(value.trim());

                                return null;
                              }
                              return <td className="whitespace-nowrap" style={{
                                textAlign: "left",
                                paddingLeft: "50px",
                                justifyContent: "space-between",
                              }} key={detailIndex}>{value}</td>;
                            })}
                          </tr>

                        );
                      })} */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsBooking.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}
      <>
        {isOpenContainer && (
          <>
            <div style={backdropStyle}></div>
            <div className={`MassUploadModal ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 1000,
                  // height: 200
                }}
                cancel=".cancelDragAndDrop"
                allowAnyClick={true}
              >
                <br />
                {/* <header
                              style={{
                                float: "left",
                                fontSize: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              Conatiner Information{" "}
                              <Badge
                                marginLeft="70px"
                                variant="solid"
                                colorScheme="red"
                                fontSize="12pt"
                              >
                                Booking Number :{" "}
                                {selectedBooking["BookingNumber"]}
                              </Badge>
                            </header> */}
                <header>Container Information</header>
                <div
                  className="closemodule cancelDragAndDrop"
                  onClick={() => {
                    handleCloseConatiner(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <br />
                {/* <table className="table-auto border-collapse border border-slate-400 w-full dark:border-gray-600 dark:bg-slate-950">
                              <thead className="flex-nowrap text-center">
                                <tr>
                                  <th className="border border-slate-300 whitespace-nowrap text-left">
                                    Container Type
                                  </th>
                                  <th className="border border-slate-300 whitespace-nowrap text-left">
                                    Quantity
                                  </th>
                                  <th className="border border-slate-300 whitespace-nowrap text-left">
                                    Weight
                                  </th>
                                  <th className="border border-slate-300 whitespace-nowrap text-left">
                                    Dimensions
                                  </th>
                                </tr>
                              </thead>
                              {selectedBooking?.Containers?.split(";").map(
                                (containerInfo, index) => {
                                  const containerDetails = containerInfo
                                    .trim()
                                    ?.split(", ");
                                  return (
                                    <tr key={index}>
                                      {containerDetails.map(
                                        (detail, detailIndex) => {
                                          const [key, value] =
                                            detail?.split(": ");
                                          if (key.trim() === "Container_ID") {
                                            // setContainerId(value.trim());
                                            return null;
                                          }
                                          return (
                                            <td
                                              className="whitespace-nowrap"
                                              style={{
                                                textAlign: "left",
                                                // paddingLeft: "50px",
                                                justifyContent: "space-between",
                                              }}
                                              key={detailIndex}
                                            >
                                              {value}
                                            </td>
                                          );
                                        }
                                      )}
                                    </tr>
                                  );
                                }
                              )}
                            </table> */}
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Container Type</Th>
                        <Th>Quantity</Th>
                        <Th>Weight</Th>
                        <Th>Dimensions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {selectedBooking?.Containers?.split(";").map(
                        (containerInfo, index) => {
                          const containerDetails = containerInfo
                            .trim()
                            ?.split(", ");
                          return (
                            <Tr key={index}>
                              {containerDetails.map((detail, detailIndex) => {
                                const [key, value] = detail?.split(": ");
                                if (
                                  key.trim() === "Container_ID" ||
                                  key.trim() === "DeletionFlag"
                                ) {
                                  // setContainerId(value.trim());
                                  return null;
                                }
                                return (
                                  <Td
                                    style={{
                                      whiteSpace: "nowrap",
                                      // paddingLeft: "50px",
                                    }}
                                    key={detailIndex}
                                  >
                                    {value}
                                  </Td>
                                );
                              })}
                            </Tr>
                          );
                        }
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Rnd>
            </div>
          </>
        )}
      </>
      <>
        {isOpenVesselDetails && (
          <>
            <div style={backdropStyle}></div>
            <div className={`MassUploadModal ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 1000,
                  // height: 200
                }}
                cancel=".cancelDragAndDrop"
                allowAnyClick={true}
              >
                <br />
                <header>Vessel Information</header>
                <div
                  className="closemodule cancelDragAndDrop"
                  onClick={() => {
                    handleCloseVesselDetails(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <br />
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Type</Th>
                        <Th>Vessel</Th>
                        <Th>Voyage</Th>
                        <Th>POL ETA</Th>
                        <Th>Port Of Loading</Th>
                        <Th>ETD</Th>
                        <Th>Port Of Discharge</Th>
                        <Th>ETA</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {selectedBooking?.VesselDetails?.split(";").map(
                        (vesselInfo, index) => {
                          const vesselDetails = vesselInfo.trim()?.split(", ");
                          return (
                            <Tr key={index}>
                              {vesselDetails.map((detail, detailIndex) => {
                                const [key, value] = detail?.split(": ");
                                if (
                                  key.trim() === "Vessel_ID" ||
                                  key.trim() === "DeletionFlag"
                                ) {
                                  return null;
                                }
                                return (
                                  <Td
                                    style={{
                                      whiteSpace: "nowrap",
                                      // paddingLeft: "50px",
                                    }}
                                    key={detailIndex}
                                  >
                                    {value}
                                  </Td>
                                );
                              })}
                            </Tr>
                          );
                        }
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Rnd>
            </div>
          </>
        )}
      </>
      <>
        {isOpen && (
          <>
            <div style={backdropStyle}></div>
            <div className={`singleEditModal`}>
              {/* ${scrollBehavior} */}
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  // width: 500,
                  height: 500,
                }}
                cancel=".cancelDragAndDrop"
                allowAnyClick={true}
              >
                <header
                  style={{
                    float: "left",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Edit Booking Information
                </header>
                <div
                  className="closemodule cancelDragAndDrop"
                  onClick={() => {
                    handleCloseModal(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <form>
                  <Tabs>
                    <Badge variant="solid" colorScheme="red" fontSize="12pt">
                      Booking Number : {selectedBooking["BookingNumber"]}
                    </Badge>
                  </Tabs>
                  <Tabs w="100%" variant="enclosed">
                    &nbsp;
                    <TabList className="cancelDragAndDrop">
                      <Tab _selected={{ color: "white", bg: "#172554" }}>
                        Booking Information
                      </Tab>
                      <Tab _selected={{ color: "white", bg: "#172554" }}>
                        Customer Details
                      </Tab>
                      <Tab _selected={{ color: "white", bg: "#172554" }}>
                        Sailing Details
                      </Tab>
                      <Tab _selected={{ color: "white", bg: "#172554" }}>
                        Vessel Details
                      </Tab>
                      <Tab _selected={{ color: "white", bg: "#172554" }}>
                        Container
                      </Tab>
                      <Tab _selected={{ color: "white", bg: "#172554" }}>
                        Terms & Conditions
                      </Tab>
                    </TabList>
                    {selectedBooking &&
                      Object.keys(selectedBooking).length > 0 && (
                        <TabPanels>
                          {/* Booking Info */}
                          <TabPanel>
                            <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                              <GridItem colSpan={2}>
                                <FormControl>
                                  <FormLabel>Booking Type</FormLabel>
                                  <Select
                                    name="BookingType"
                                    defaultValue={
                                      formData["BookingType"] ||
                                      selectedBooking["BookingType"]
                                    }
                                    value={editedBooking["BookingType"] || ""}
                                    onChange={handleChange}
                                  >
                                    <option value="Original">Original</option>
                                    <option value="Dummy">Dummy</option>
                                  </Select>

                                  <FormLabel>Place Of Receipt</FormLabel>
                                  <Input
                                    type="text"
                                    name="PlaceOfReceipt"
                                    defaultValue={
                                      formData["PlaceOfReceipt"] ||
                                      selectedBooking["PlaceOfReceipt"]
                                    }
                                    value={
                                      editedBooking["PlaceOfReceipt"] || ""
                                    }
                                    onChange={handleChange}
                                  />
                                  <FormLabel>POD/Discharge Airport</FormLabel>
                                  <Select
                                    name="POD"
                                    defaultValue={
                                      formData["POD"] || selectedBooking["POD"]
                                    }
                                    value={editedBooking["POD"] || ""}
                                    onChange={handleChange}
                                  >
                                    {portData.map((data) => (
                                      <option value={data.Port}>
                                        {data.Port}
                                      </option>
                                    ))}
                                  </Select>

                                  <FormLabel>Cargo</FormLabel>
                                  <Input
                                    type="text"
                                    name="Cargo"
                                    defaultValue={
                                      formData["Cargo"] ||
                                      selectedBooking["Cargo"]
                                    }
                                    value={editedBooking["Cargo"] || ""}
                                    onChange={handleChange}
                                  />

                                  <FormLabel>Cargo Nature</FormLabel>
                                  <Input
                                    type="text"
                                    name="CargoNature"
                                    defaultValue={
                                      formData["CargoNature"] ||
                                      selectedBooking["CargoNature"]
                                    }
                                    value={editedBooking["CargoNature"] || ""}
                                    onChange={handleChange}
                                  />

                                  <FormLabel>Scope</FormLabel>
                                  <Select
                                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                    type="text"
                                    name="Scope"
                                    defaultValue={
                                      formData["Scope"] ||
                                      selectedBooking["Scope"]
                                    }
                                    value={editedBooking["Scope"] || ""}
                                    onChange={handleChange}
                                  >
                                    <option>Select Scope</option>
                                    {scopeData.map((item) => (
                                      <option value={item.Scope}>
                                        {item.Scope}
                                      </option>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem colStart={4} colEnd={6}>
                                <FormControl>
                                  <FormLabel>Product</FormLabel>
                                  <Select
                                    name="Product"
                                    defaultValue={
                                      formData["Product"] ||
                                      selectedBooking["Product"]
                                    }
                                    value={editedBooking["Product"] || ""}
                                    onChange={handleChange}
                                  >
                                    {productData.map((data) => (
                                      <option value={data.Product}>
                                        {data.Product}
                                      </option>
                                    ))}
                                  </Select>
                                </FormControl>
                                <FormLabel>POL/Loading Airport</FormLabel>
                                <Select
                                  name="POL"
                                  defaultValue={
                                    formData["POL"] || selectedBooking["POL"]
                                  }
                                  value={editedBooking["POL"] || ""}
                                  onChange={handleChange}
                                >
                                  {portData.map((data) => (
                                    <option value={data.Port}>
                                      {data.Port}
                                    </option>
                                  ))}
                                </Select>
                                <FormLabel>Place Of Delivery</FormLabel>
                                <Input
                                  type="text"
                                  name="PlaceOfDelivery"
                                  defaultValue={
                                    formData["PlaceOfDelivery"] ||
                                    selectedBooking["PlaceOfDelivery"]
                                  }
                                  value={editedBooking["PlaceOfDelivery"] || ""}
                                  onChange={handleChange}
                                />
                                <FormLabel>Incoterm</FormLabel>
                                <Select
                                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                  type="text"
                                  name="Incoterm"
                                  defaultValue={
                                    formData["Incoterm"] ||
                                    selectedBooking["Incoterm"]
                                  }
                                  value={editedBooking["Incoterm"] || ""}
                                  onChange={handleChange}
                                >
                                  <option>Select Incoterm</option>
                                  {incotermData.map((item) => (
                                    <option value={item.Incoterm}>
                                      {item.Incoterm}
                                    </option>
                                  ))}
                                </Select>
                                <FormLabel>Sales</FormLabel>
                                {/* <Input
                                type="text"
                                name="Sales"
                                defaultValue={
                                  formData["Sales"] || selectedBooking["Sales"]
                                }
                                value={editedBooking["Sales"] || ""}
                                onChange={handleChange}
                              /> */}
                                <Select
                                  name="Sales"
                                  defaultValue={
                                    formData["Sales"] ||
                                    selectedBooking["Sales"]
                                  }
                                  value={editedBooking["Sales"] || ""}
                                  onChange={handleChange}
                                >
                                  {salesPersonData.map((data) => (
                                    <option value={data.fullname}>
                                      {data.fullname}
                                    </option>
                                  ))}
                                </Select>
                                <FormLabel>Type Of Shipment</FormLabel>
                                  <Select
                                    name="TypeOfShipment"
                                    defaultValue={
                                      formData["TypeOfShipment"] ||
                                      selectedBooking["TypeOfShipment"]
                                    }
                                    value={editedBooking["TypeOfShipment"] || ""}
                                    onChange={handleChange}
                                  >
                                   
                        <option value="LCL">LCL - Less than container load</option>
                        <option value="FCL">FCL - Full container load</option>
                                  </Select>
                              </GridItem>
                            </Grid>
                          </TabPanel>
                          {/* Customer details */}
                          <TabPanel>
                            <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                              <GridItem colSpan={2}>
                                <FormControl>
                                  <FormLabel>Company Name</FormLabel>
                                  <Select
                                    name="CompanyName"
                                    value={editedBooking["CompanyName"] || ""}
                                    onChange={handleChange}
                                  >
                                    <option value="">
                                      Select Company Name
                                    </option>
                                    {customerData.map((data) => (
                                      <option
                                        key={data.Company}
                                        value={data.Company}
                                      >
                                        {data.Company}
                                      </option>
                                    ))}
                                  </Select>

                                  <FormLabel>Person In Charge</FormLabel>
                                  <Select
                                    name="PIC"
                                    value={editedBooking["PIC"] || ""}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select PIC</option>
                                    {customerData
                                      .filter(
                                        (data) =>
                                          data.Company ===
                                          editedBooking.CompanyName
                                      )
                                      .map((data) => (
                                        <option
                                          key={data.Name}
                                          value={data.Name}
                                        >
                                          {data.Name}
                                        </option>
                                      ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem colStart={4} colEnd={6}>
                                <FormControl>
                                  <FormLabel>Address</FormLabel>
                                  <Select
                                    name="Address"
                                    value={editedBooking["Address"] || ""}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Address</option>
                                    {editedBooking.Address && (
                                      <option value={editedBooking.Address}>
                                        {editedBooking.Address}
                                      </option>
                                    )}
                                  </Select>
                                </FormControl>
                              </GridItem>
                            </Grid>
                          </TabPanel>

                          {/* Sailing Details */}
                          <TabPanel>
                            <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                              <GridItem colSpan={2}>
                                <FormControl>
                                  <FormLabel>Liner Ref Number</FormLabel>
                                  <Input
                                    type="text"
                                    name="LinerRefNumber"
                                    defaultValue={
                                      formData["LinerRefNumber"] ||
                                      selectedBooking["LinerRefNumber"]
                                    }
                                    value={
                                      editedBooking["LinerRefNumber"] || ""
                                    }
                                    onChange={handleChange}
                                  />
                                  <FormLabel>Region</FormLabel>
                                  <Select
                                    name="Region"
                                    defaultValue={
                                      formData["Region"] ||
                                      selectedBooking["Region"]
                                    }
                                    value={editedBooking["Region"] || ""}
                                    onChange={handleChange}
                                  >
                                    <option>Select Internal Region</option>
                                    {regionData.map((data) => (
                                      <option value={data.Region}>
                                        {data.Region}
                                      </option>
                                    ))}
                                  </Select>
                                  <FormLabel>ETD</FormLabel>
                                  <Input
                                    className="cursor-pointer"
                                    type="date"
                                    name="ETD"
                                    defaultValue={
                                      (
                                        editedBooking["ETD"] ||
                                        formData["ETD"] ||
                                        selectedBooking["ETD"] ||
                                        ""
                                      )?.split("T")[0] || ""
                                    }
                                    onChange={handleChange}
                                  />

                                  {/* <FormLabel>ETD Origin</FormLabel>
                                <Input
                                  className="cursor-pointer"
                                  type="date"
                                  name="ETDOrigin"
                                  defaultValue={
                                    (
                                      editedBooking["ETDOrigin"] ||
                                      formData["ETDOrigin"] ||
                                      selectedBooking["ETDOrigin"] ||
                                      ""
                                    )?.split("T")[0] || ""
                                  }
                                /> */}
                                  <FormLabel>Empty Pickup Location</FormLabel>
                                  <Textarea
                                    type="text"
                                    name="EmptyPickupLocation"
                                    defaultValue={
                                      formData["EmptyPickupLocation"] ||
                                      selectedBooking["EmptyPickupLocation"]
                                    }
                                    value={
                                      editedBooking["EmptyPickupLocation"] || ""
                                    }
                                    onChange={handleChange}
                                  />
                                  <FormLabel>VGM Cut Off</FormLabel>
                                  <Input
                                    className="cursor-pointer"
                                    type="date"
                                    name="VGMCutOff"
                                    defaultValue={
                                      (
                                        editedBooking["VGMCutOff"] ||
                                        formData["VGMCutOff"] ||
                                        selectedBooking["VGMCutOff"] ||
                                        ""
                                      )?.split("T")[0] || ""
                                    }
                                    onChange={handleChange}
                                  />

                                  <FormLabel>Remarks</FormLabel>
                                  <Textarea
                                    type="text"
                                    defaultValue={
                                      formData["Remarks"] ||
                                      selectedBooking["Remarks"]
                                    }
                                    value={editedBooking["Remarks"] || ""}
                                    onChange={handleChange}
                                    name="Remarks"
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem colStart={4} colEnd={6}>
                                <FormControl>
                                  <FormLabel>Vessel Name/Voyage</FormLabel>
                                  <Input
                                    type="text"
                                    name="VesselNameVoyage"
                                    defaultValue={
                                      formData["VesselNameVoyage"] ||
                                      selectedBooking["VesselNameVoyage"]
                                    }
                                    value={
                                      editedBooking["VesselNameVoyage"] || ""
                                    }
                                    onChange={handleChange}
                                  />
                                  <FormLabel>ETA</FormLabel>
                                  <Input
                                    className="cursor-pointer"
                                    type="date"
                                    name="ETA"
                                    defaultValue={
                                      (
                                        editedBooking["ETA"] ||
                                        formData["ETA"] ||
                                        selectedBooking["ETA"] ||
                                        ""
                                      )?.split("T")[0] || ""
                                    }
                                    onChange={handleChange}
                                  />

                                  {/* <FormLabel>ETA POL</FormLabel>
                                <Input
                                  className="cursor-pointer"
                                  type="date"
                                  name="ETAPOL"
                                  defaultValue={
                                    (
                                      editedBooking["ETAPOL"] ||
                                      formData["ETAPOL"] ||
                                      selectedBooking["ETAPOL"] ||
                                      ""
                                    )?.split("T")[0] || ""
                                  }
                                /> */}

                                  <FormLabel>ETD Designation</FormLabel>
                                  <Input
                                    className="cursor-pointer"
                                    type="date"
                                    name="ETDDestination"
                                    defaultValue={
                                      (
                                        editedBooking["ETDDestination"] ||
                                        formData["ETDDestination"] ||
                                        selectedBooking["ETDDestination"] ||
                                        ""
                                      )?.split("T")[0] || ""
                                    }
                                    onChange={handleChange}
                                  />
                                  <FormLabel>SI Cut Off</FormLabel>
                                  <Input
                                    className="cursor-pointer"
                                    type="date"
                                    name="SICutOff"
                                    defaultValue={
                                      (
                                        editedBooking["SICutOff"] ||
                                        formData["SICutOff"] ||
                                        selectedBooking["SICutOff"] ||
                                        ""
                                      )?.split("T")[0] || ""
                                    }
                                    onChange={handleChange}
                                  />

                                  <FormLabel>CY Cut Off</FormLabel>
                                  <Input
                                    className="cursor-pointer"
                                    type="date"
                                    name="CYCutOff"
                                    defaultValue={
                                      (
                                        editedBooking["CYCutOff"] ||
                                        formData["CYCutOff"] ||
                                        selectedBooking["CYCutOff"] ||
                                        ""
                                      )?.split("T")[0] || ""
                                    }
                                    onChange={handleChange}
                                  />
                                </FormControl>
                              </GridItem>
                            </Grid>
                            <br />
                            <br />
                          </TabPanel>
                          {/* Vessel Details */}
                          <TabPanel>
                            {selectedBooking.VesselDetails && (
                              <div className="table-container">
                                <table className="table-auto border-collapse border border-slate-400 w-full dark:border-gray-600 dark:bg-slate-950">
                                  <thead className="flex-nowrap text-center">
                                    <tr>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        Type
                                      </th>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        Vessel
                                      </th>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        Voyage
                                      </th>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        POL ETA
                                      </th>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        Port Of Loading
                                      </th>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        ETD
                                      </th>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        Port Of Discharge
                                      </th>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        ETA
                                      </th>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {editedBooking?.VesselDetails?.split(
                                      ";"
                                    )?.map((vessel, index) => {
                                      const vesselAttributes =
                                        vessel?.split(", ");

                                      console.log(editedBooking);

                                      return (
                                        <tr key={index}>
                                          <td>
                                            <Select
                                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                              style={{ width: "120px" }}
                                              type="text"
                                              name={`VesselType-${index}`}
                                              value={
                                                editedBooking[
                                                  `VesselType-${index}`
                                                ] ||
                                                vesselAttributes[0]?.split(
                                                  ": "
                                                )[1]
                                              }
                                              onChange={(e) =>
                                                handleInnerVesselDetailsChange(
                                                  e,
                                                  index,
                                                  "VesselType"
                                                )
                                              }
                                            >
                                              <option>Select Type</option>
                                              <option value="Feeder">
                                                Feeder
                                              </option>
                                              <option value="Mother">
                                                Mother
                                              </option>
                                            </Select>
                                          </td>
                                          <td>
                                            <Input
                                              style={{ width: "150px" }}
                                              name={`Vessel-${index}`}
                                              type="text"
                                              value={
                                                editedBooking[
                                                  `Vessel-${index}`
                                                ] ||
                                                vesselAttributes[1]?.split(
                                                  ": "
                                                )[1]
                                              }
                                              onChange={(e) =>
                                                handleInnerVesselDetailsChange(
                                                  e,
                                                  index,
                                                  "Vessel"
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              style={{ width: "150px" }}
                                              name={`Voyage-${index}`}
                                              type="text"
                                              value={
                                                editedBooking[
                                                  `Voyage-${index}`
                                                ] ||
                                                vesselAttributes[2]?.split(
                                                  ": "
                                                )[1]
                                              }
                                              onChange={(e) =>
                                                handleInnerVesselDetailsChange(
                                                  e,
                                                  index,
                                                  "Voyage"
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              name={`PolEta-${index}`}
                                              type="date"
                                              value={
                                                editedBooking[
                                                  `PolEta-${index}`
                                                ] ||
                                                vesselAttributes[3]?.split(
                                                  ": "
                                                )[1]
                                              }
                                              onChange={(e) =>
                                                handleInnerVesselDetailsChange(
                                                  e,
                                                  index,
                                                  "PolEta"
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              name={`PortOfLoading-${index}`}
                                              type="text"
                                              value={
                                                editedBooking[
                                                  `PortOfLoading-${index}`
                                                ] ||
                                                vesselAttributes[4]?.split(
                                                  ": "
                                                )[1]
                                              }
                                              onChange={(e) =>
                                                handleInnerVesselDetailsChange(
                                                  e,
                                                  index,
                                                  "PortOfLoading"
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              name={`ETD-${index}`}
                                              type="date"
                                              value={
                                                editedBooking[`ETD-${index}`] ||
                                                vesselAttributes[5]?.split(
                                                  ": "
                                                )[1]
                                              }
                                              onChange={(e) =>
                                                handleInnerVesselDetailsChange(
                                                  e,
                                                  index,
                                                  "ETD"
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              name={`PortOfDischarge-${index}`}
                                              type="text"
                                              value={
                                                editedBooking[
                                                  `PortOfDischarge-${index}`
                                                ] ||
                                                vesselAttributes[6]?.split(
                                                  ": "
                                                )[1]
                                              }
                                              onChange={(e) =>
                                                handleInnerVesselDetailsChange(
                                                  e,
                                                  index,
                                                  "PortOfDischarge"
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              name={`ETA-${index}`}
                                              type="date"
                                              value={
                                                editedBooking[`ETA-${index}`] ||
                                                vesselAttributes[7]?.split(
                                                  ": "
                                                )[1]
                                              }
                                              onChange={(e) =>
                                                handleInnerVesselDetailsChange(
                                                  e,
                                                  index,
                                                  "ETA"
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            {editedBooking?.VesselDetails?.split(
                                              ";"
                                            )?.length > 1 && (
                                              <BiSolidMinusCircle
                                                className="cursor-pointer"
                                                size={30}
                                                style={{
                                                  marginLeft: "5px",
                                                  color: "red",
                                                }}
                                                onClick={() =>
                                                  removeVessel(index)
                                                }
                                              />
                                            )}

                                            <IoMdAddCircle
                                              className="cursor-pointer"
                                              size={30}
                                              style={{
                                                marginLeft: "5px",
                                                color: "green",
                                              }}
                                              onClick={addVesselDetails}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </TabPanel>
                          <TabPanel>
                            {selectedBooking.Containers && (
                              <div className="table-container">
                                <table className="table-auto border-collapse border border-slate-400 w-full dark:border-gray-600 dark:bg-slate-950">
                                  <thead className="flex-nowrap text-center">
                                    <tr>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        Container Type
                                      </th>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        Quantity
                                      </th>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        Weight
                                      </th>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        Dimensions
                                      </th>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {editedBooking?.Containers?.split(";")?.map(
                                      (container, index) => {
                                        const containerAttributes =
                                          container?.split(", ");

                                        console.log(editedBooking);

                                        return (
                                          <tr key={index}>
                                            <td>
                                              <Select
                                                w="190px"
                                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                type="text"
                                                name={`ContainerType-${index}`}
                                                value={
                                                  editedBooking[
                                                    `ContainerType-${index}`
                                                  ] ||
                                                  containerAttributes[0]?.split(
                                                    ": "
                                                  )[1]
                                                }
                                                onChange={(e) =>
                                                  handleInnerContainerChange(
                                                    e,
                                                    index,
                                                    "ContainerType"
                                                  )
                                                }
                                              >
                                                <option>
                                                  Select Equipment
                                                </option>
                                                {equipmentData.map((item) => (
                                                  <option
                                                    value={item.ContainerType}
                                                  >
                                                    {item.ContainerType}
                                                  </option>
                                                ))}
                                              </Select>
                                            </td>
                                            <td>
                                              <Input
                                                name={`Quantity-${index}`}
                                                type="text"
                                                value={
                                                  editedBooking[
                                                    `Quantity-${index}`
                                                  ] ||
                                                  containerAttributes[1]?.split(
                                                    ": "
                                                  )[1]
                                                }
                                                onChange={(e) =>
                                                  handleInnerContainerChange(
                                                    e,
                                                    index,
                                                    "Quantity"
                                                  )
                                                }
                                              />
                                            </td>
                                            <td>
                                              <Input
                                                name={`Weight-${index}`}
                                                type="text"
                                                value={
                                                  editedBooking[
                                                    `Weight-${index}`
                                                  ] ||
                                                  containerAttributes[2]?.split(
                                                    ": "
                                                  )[1]
                                                }
                                                onChange={(e) =>
                                                  handleInnerContainerChange(
                                                    e,
                                                    index,
                                                    "Weight"
                                                  )
                                                }
                                              />
                                            </td>
                                            <td>
                                              <Input
                                                name={`Dimensions-${index}`}
                                                type="text"
                                                value={
                                                  editedBooking[
                                                    `Dimensions-${index}`
                                                  ] ||
                                                  containerAttributes[3]?.split(
                                                    ": "
                                                  )[1]
                                                }
                                                onChange={(e) =>
                                                  handleInnerContainerChange(
                                                    e,
                                                    index,
                                                    "Dimensions"
                                                  )
                                                }
                                              />
                                            </td>
                                            <td>
                                              {editedBooking?.Containers?.split(
                                                ";"
                                              )?.length > 1 && (
                                                <BiSolidMinusCircle
                                                  className="cursor-pointer"
                                                  size={30}
                                                  style={{
                                                    marginLeft: "5px",
                                                    color: "red",
                                                  }}
                                                  onClick={() =>
                                                    removeContainer(index)
                                                  }
                                                />
                                              )}

                                              <IoMdAddCircle
                                                className="cursor-pointer"
                                                size={30}
                                                style={{
                                                  marginLeft: "5px",
                                                  color: "green",
                                                }}
                                                onClick={addContainer}
                                              />
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </TabPanel>
                          <TabPanel>
                            {selectedBooking.TermsConditions && (
                              <div className="table-container">
                                <table className="table-auto border-collapse border border-slate-400 w-full dark:border-gray-600 dark:bg-slate-950">
                                  <thead className="flex-nowrap text-center">
                                    <tr>
                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        Terms & Conditions
                                      </th>

                                      <th className="border border-slate-300 whitespace-nowrap text-left">
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {editedBooking?.TermsConditions?.split(
                                      ";"
                                    )?.map((termscondition, index) => {
                                      const termsConditionAttributes =
                                        termscondition?.split(", ");

                                      console.log(editedBooking);

                                      return (
                                        <tr key={index}>
                                          <td>
                                            <TextArea
                                              name={`TermsAndConditions-${index}`}
                                              type="text"
                                              value={
                                                editedBooking[
                                                  `TermsAndConditions-${index}`
                                                ] ||
                                                termsConditionAttributes[0]?.split(
                                                  ": "
                                                )[1]
                                              }
                                              onChange={(e) =>
                                                handleInnerTermsandConditionChange(
                                                  e,
                                                  index,
                                                  "TermsAndConditions"
                                                )
                                              }
                                            />
                                          </td>

                                          <td>
                                            {editedBooking?.TermsConditions?.split(
                                              ";"
                                            )?.length > 1 && (
                                              <BiSolidMinusCircle
                                                className="cursor-pointer"
                                                size={30}
                                                style={{
                                                  marginLeft: "5px",
                                                  color: "red",
                                                }}
                                                onClick={() =>
                                                  removeTermsandconditions(
                                                    index
                                                  )
                                                }
                                              />
                                            )}

                                            <IoMdAddCircle
                                              className="cursor-pointer"
                                              size={30}
                                              style={{
                                                marginLeft: "5px",
                                                color: "green",
                                              }}
                                              onClick={addtermsandcondition}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </TabPanel>
                          {!selectedBooking && (
                            <p>Data not available for the selected booking.</p>
                          )}
                        </TabPanels>
                      )}
                  </Tabs>
                  <Stack
                    className="cancelDragAndDrop"
                    spacing={4}
                    direction="row"
                    align="center"
                    float="right"
                    marginTop={5}
                  >
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        handleCloseModal();
                        getData();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      colorScheme="green"
                      onClick={() => {
                        handleSubmitUpdate();
                        handleCloseModal();
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </form>
              </Rnd>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default DraggableBookingTable;
