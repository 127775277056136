import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {Header}  from "../../components";
import { Input, Button, Flex, Box, Text } from "@chakra-ui/react";
import  {
  HrmAPIInstance,
} from "../../api-instance";
import Select from "react-select";
// import image from "../../assets/img/image.png";
// import "./EmployeeProfiles.css";
import Sidebar from "../HrmDashboard/sidebar.js";

const EmployeeProfiles = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [columns2, setColumns2] = useState([]);
  const [isdelete, setIsdelete] = useState(false);
  const [employeeData, setEmployeeData] = useState(null);
  const [employeeNumber, setEmployeeNumber] = useState("");

  const [columns, setColumns] = useState({
    EmployeeNumber: true,
    First_Name: true,
  });

  const [options, setOptions] = useState([]);

  // Search API 
  const handleSearch = async () => {
    const isdelete = false; // Assume 'isdelete' value is provided as needed

    const { data } = await HrmAPIInstance.get(
      `search_employeeprofile?userId=${JSON.parse(localStorage.getItem("user")).userId}&isDeleted=${isdelete}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    if (data?.data?.length === 0) {
      setEmployeeData([]);
      setOptions([]); // Clear dropdown options if no data
      return;
    }

    const array1 = Object.keys(columns).filter((key) => columns[key]);

    const header = Object.keys(data?.data[0])?.map((item) => ({
      key: item,
      label: item.charAt(0).toUpperCase() + item.slice(1),
    }));

    const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));
    setColumns2(filteredDataHeader);

    const filteredData = data?.data?.map((item) => {
      const filteredItem = {};
      Object.keys(item).forEach((key) => {
        if (array1.includes(key)) {
          filteredItem[key] = item[key];
        }
      });
      return filteredItem;
    });

    setEmployeeData(filteredData);

    // Prepare options for react-select
    const newOptions = data?.data?.map((item) => ({
      value: item.EmployeeNumber,
      label: `${item.First_Name} ${item.Last_Name} (${item.EmployeeNumber})`,
    }));

    setOptions(newOptions);
  };

  useEffect(() => {
    // Call handleSearch once to fetch initial data
    handleSearch();
  }, []);

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setEmployeeNumber(selectedOption.value); // Set selected employee number
      navigate(`/employeeprofile/${selectedOption.value}`); // Navigate to the specific employee profile page
    } else {
      setEmployeeNumber("");
    }
  };

  return (
    <>
   <Header />
   <Sidebar  
  // onClick={handleOpenHrmDocs}
  size={25}
  style={{
    position: "absolute",
    cursor: "pointer",
    marginTop: "15px",  // Ensure the unit is specified
    marginLeft: "10px"  // Ensure the unit is specified
  }}
/>
      <Flex align="center" bg="blue.900" p={4} color="white"></Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        height="85vh"
        bg="gray.50"
      >
        <Box bg="#93c5fd" w="90%" p={5} color="Black" boxShadow="2xl" borderRadius="md">
          <Flex direction="row" alignItems="center" justifyContent="space-between">
            <Flex direction="column" alignItems="center" w="100%">
              <Text fontWeight="bold" fontSize="xl" textAlign="center" mb={4}>
                Search Employee Profile
              </Text>
              <Select
                options={options}
                isClearable
                onChange={handleSelectChange}
                placeholder="Select an Employee"
                className="Select"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "1px solid #d1d5db",
                    borderRadius: "5px",
                    boxShadow: "none",
                    minHeight: "36px", // Adjust height if needed
                    height: "auto",
                    width: "100%", // Adjust width to fit the box
                    "&:hover": { borderColor: "#d1d5db" },
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999, // Ensure dropdown is visible above other elements
                  }),
                }}
              />
            </Flex>
            <Box ml={4}>
              {/* <img src={image} alt="Employee Profile" style={{ maxWidth: '150px', height: 'auto' }} /> */}
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default EmployeeProfiles;
