import React from "react";
import { useState, useEffect } from "react";
import Axios, {
  HrmAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Rnd } from "react-rnd";
import moment, { utc } from "moment/moment";
import { AiOutlineUser } from 'react-icons/ai'; 


import { useNavigate } from "react-router-dom";

// Internal module import
import "./DraggableEmploymentTable.css";

// Chakara ui modules
import { Badge,Avatar,AvatarBadge } from "@chakra-ui/react";
import {
  useDisclosure,
  Button,
  Modal,
  Stack,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

import {
  IconButton,
  Tooltip,
  useToast,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Select,
  Box,
  FormErrorMessage,
  FormHelperText,
  Input,
} from "@chakra-ui/react";

// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";
import { MdOutlineFactCheck } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { CgClose } from "react-icons/cg";

const DraggableEmployeeTable = ({
  isLoading,
  columns,
  data,
  employeeData,
  getData,
  setEmployeeData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsEmployee,
  pageNumbers,
}) => {
  const toast = useToast();
  // State Variables
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  // const [CountryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [editedEmployee, setEditedEmployee] = useState({});
  const [tagData, setTagData] = useState([]);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  const [size, setSize] = React.useState("xl"); // Set the initial size to 'full'
  const [formData, setFormData] = useState({
    PhoneWithCode: "",
    CountryData: "",
  });

  // UseEffects
  useEffect(() => {
    setEditedEmployee(selectedEmployee);
  }, [selectedEmployee]);


  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;
 
      // Update editedEmployee state
      setEditedEmployee((prev) => ({
        ...prev,
        [name]: value,
      }));
  
      if (name === "Country") {
        // Update selectedEmployee state with new Country value and reset City to empty string
        setSelectedEmployee((prev) => ({
          ...prev,
          Country: value,
          City: "",
        }));
  
        // Fetch city data based on the selected Country
        const { data } = await MasterDataAPIInstance.get(
          `get_city?countryData=${value}`
        );
  
        // Update cityData state with fetched city data
        setCityData(data.data);
      } else if (name === "City") {
        // Update selectedEmployee state with new City value
        setSelectedEmployee((prev) => ({
          ...prev,
          City: value,
        }));
      } 
  
      // Update editedEmployee state with updated flags
      setEditedEmployee((prev) => ({
        ...prev,
      }));
    } catch (error) {
      // Handle errors if necessary
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  

  const getOriginCountry = async (country) => {
    try {
      const { data } = await MasterDataAPIInstance.get
        ("get_city")
      
      setCityData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
console.log("SL:",selectedEmployee);
  // Single Update
  const handleSubmitUpdate = async () => {
    const updateData = {
      ...editedEmployee,
      DialCode: formData?.CountryData
      ? formData.CountryData.dialCode
      : editedEmployee.DialCode, // Use the updated dial code
      Phone: formData?.Phone ? formData.Phone : editedEmployee.Phone, // Use the updated phone number
    }; 
    console.log("Updated data",updateData);
    try {
      const { data } = await HrmAPIInstance.put(
        `singleupdate_employee?userId=?${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          ...updateData,
          id: selectedEmployee.id,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Employee Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        // Optionally, you may want to update the selectedEmployee state here
        setSelectedEmployee((prevSelectedEmployee) => ({
          ...prevSelectedEmployee,
          ...editedEmployee,
        }));
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  //  Function to open the modal and fetch Employee data for a particular ID
  const handleOpenModal = async (id, EmployeeNumber) => {
    try {
      const selectedEmployeeData = employeeData.find((employee) => employee.id === id);
      //const EmployeeNumber = EmployeeData((Employee) => employee.id === id);
      setSelectedEmployee(selectedEmployeeData);
      handleFullSizeClick();
      setSelectedRowId(id);

      const { data } = await MasterDataAPIInstance.get
        ("get_city")
      
      setCityData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
    
  };
  const handleCloseModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
    onClose(true);
  };

  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );

      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);

  const sortTable = (key) => {
    let newSortOrder = "asc";

    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...employeeData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortKey(key);
    setSortOrder(newSortOrder);

    if (typeof setEmployeeData === "function") {
      setEmployeeData(sortedData);
    }
  };

  // Single Edit Module
  const handleFullSizeClick = () => {
    setSize("xl");
    onOpen();
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "scroll",
    overflowX: "hidden",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "9999",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };
  
  const handleEmployeeProfile=(data)=>{
    console.log(data);
    navigate(`/employeeprofile/${data.EmployeeNumber}`);
  };
  //console.log(citiesDataSet);
  // const [showFactCheck, setShowFactCheck] = useState(false);
  // console.log(checkBoxSelected, currentPage, activePageCheckBoxSelect);
  return (
    <div>
      {!isLoading ? (
        tableColumnsEmployee?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {false ? (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={checkBoxAll}
                        onChange={() => {
                          handleCheckbox(data.map((item) => item.id));
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              ); // Return the filtered array
                            } else {
                              return [...prev, currentPage]; // Return a new array with the added item
                            }
                          });

                          setCheckBoxAll((prev) => {
                            if (prev === true) {
                              setCheckBoxSelected([]);
                            }
                            return !prev;
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={
                          checkBoxSelected.length === 0
                            ? false
                            : activePageCheckBoxSelect.includes(currentPage)
                        }
                        onChange={() => {
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          const idsToAdd = data.map((item) => item.id);
                          setCheckBoxSelected((prev) => {
                            const currentData = new Set(prev);
                            if (
                              activePageCheckBoxSelect.includes(currentPage)
                            ) {
                              // If the page is selected, remove its IDs
                              idsToAdd.forEach((item) => {
                                currentData.delete(item);
                              });
                            } else {
                              // If the page is not selected, add its IDs
                              idsToAdd.forEach((item) => {
                                currentData.add(item);
                              });
                            }
                            return Array.from(currentData);
                          });
                        }}
                      />
                    )}
                  </div>
                </th>
                <th className="border border-slate-300 whitespace-nowrap text-center">
                  Edit
                </th>

                {tableColumnsEmployee.map(
                  (column, index) =>
                    column.label !== "Id" &&
                    column.label !== "DialCode" &&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        { (
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              {column.label}
                            </span>
                            <BiSort
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        )}
                      </th>
                    )
                )}
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                        {false ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected?.includes(row.id)
                            }
                          />
                        )}
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        label="Edit Row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="right-start"
                      >
                        <IconButton
                          icon={<MdEdit />}
                          onClick={() =>
                            handleOpenModal(row?.id, row?.EmployeeNumber)
                          }
                          boxSize={6}
                          size={"20px"}
                        />
                      </Tooltip>
                    </td>
                    {tableColumnsEmployee.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        column.label !== "DialCode" &&
                        column.isVisible === true && (
                          <td
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {column.key === "EmployeeNumber" ? (
                              <a style={{cursor: "pointer"}} onClick={()=>handleEmployeeProfile(row)}
                                
                              >
                                {row[column.key]}
                              </a>
                            ) :column.key === "Email" ? (
                              <a href={`mailto:${row[column.key]}`}>
                                {row[column.key]}
                              </a>
                            )  :column.key === "DeletionFlag" ? (
                              row[column.key]===1?(
                                <Badge colorScheme="red">Deleted</Badge>
                              ):(
                              <Badge colorScheme="green">Not Deleted</Badge>)
                            )  : column.key === "URL_Object" ? (
                              <Avatar
      src={row[column.key]}
      size="md"
     // style={{ marginRight: '8px' }}
    />
                            ) 
                             :
                            
                            (
                              row[column.key]
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsEmployee.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}
      <>
        {isOpen && (
          <div className={`singleEditModal ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                // width: 500,
                height: 500,
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header
                style={{ float: "left", fontSize: "20px", fontWeight: "bold" }}
              >
                Edit Employee Information
              </header>
              <div
                onClick={() => {
                  handleCloseModal(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              {selectedEmployee && Object.keys(selectedEmployee).length > 0 && (
                <form>
                  <Badge variant="solid" colorScheme="green" fontSize="12pt" ml={5}>
                  Employee Number : {selectedEmployee["EmployeeNumber"]}
                  </Badge>
                  <br/>
                  <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                    <GridItem colSpan={2}>
                      <FormControl>
                       
                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
  {selectedEmployee.URL_Object ? (
    <Avatar
      size="2xl"
      src={selectedEmployee.URL_Object}
    >
      <AvatarBadge boxSize='1.25em' bg='green.500' />
    </Avatar>
  ) : (
    <Avatar
      size="2xl"
      bg="red.500"
      icon={<AiOutlineUser fontSize="1.5rem" />}
    >
      <AvatarBadge borderColor='papayawhip' bg='tomato' boxSize='1.25em' />
    </Avatar>
  )}

  {/* <label style={{ display: 'block', marginTop: '16px' }}>
    Employee Photo<span style={{ color: "red" }}>*</span>
  </label> */}
  
    {/* <Avatar
      src={selectedEmployee.URL_Object}
      size="md"
      style={{ marginRight: '8px' }}
    /> */}
     <FormLabel>
                        EmployeeNumber<span style={{ color: "red" }}>*</span>
                        </FormLabel>
    <Input
      type="text"
      name="EmployeeNumber"
      defaultValue={formData["EmployeeNumber"] || selectedEmployee["EmployeeNumber"]}
      value={editedEmployee["EmployeeNumber"] || ""}
      onChange={handleChange}
      disabled
      style={{ padding: '8px', width: '100%' }}
    />

</div>

      
                        <FormLabel>
                        First_Name<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          type="text"
                          name="First_Name"
                          defaultValue={
                            formData["First_Name"] || selectedEmployee["First_Name"]
                          }
                          value={editedEmployee["First_Name"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                       
                         <FormLabel>Last_Name</FormLabel>
                        <Input
                          type="text"
                          name="Last_Name"
                          defaultValue={
                            formData["Last_Name"] || selectedEmployee["Last_Name"]
                          }
                          value={editedEmployee["Last_Name"] || ""}
                          onChange={handleChange}
                          disabled
                        
                        />
                         <FormLabel>DateOfBirth</FormLabel>
                        <Input
                          type="date"
                          name="DateOfBirth"
                          defaultValue={
                            formData["DateOfBirth"] || selectedEmployee["DateOfBirth"]
                          }
                          value={editedEmployee["DateOfBirth"] || ""}
                          onChange={handleChange}
                        
                        />
                         <FormLabel>PlaceOfBirth</FormLabel>
                        <Input
                          type="text"
                          name="PlaceOfBirth"
                          defaultValue={
                            formData["PlaceOfBirth"] || selectedEmployee["PlaceOfBirth"]
                          }
                          value={editedEmployee["PlaceOfBirth"] || ""}
                          onChange={handleChange}
                        
                        />
                         <FormLabel>Aadhaar_Number</FormLabel>
                        <Input
                          type="text"
                          name="Aadhaar_Number"
                          defaultValue={
                            formData["Aadhaar_Number"] || selectedEmployee["Aadhaar_Number"]
                          }
                          value={editedEmployee["Aadhaar_Number"] || ""}
                          onChange={handleChange}
                        
                        />
                         <FormLabel>Bloodgroup</FormLabel>
                         <Input
                          type="text"
                          name="Bloodgroup"
                          defaultValue={
                            formData["Bloodgroup"] || selectedEmployee["Bloodgroup"]
                          }
                          value={editedEmployee["Bloodgroup"] || ""}
                          onChange={handleChange}
                        
                        />
                        <FormLabel>Gender</FormLabel>
                        <Select className="cancelDragAndDrop"
                          defaultValue={formData["Gender"] || selectedEmployee["Gender"]}
                          value={editedEmployee["Gender"] || ""}
                          onChange={handleChange}
                          name="Gender"
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Others">Others</option>
                        </Select>
                        <FormLabel>Nationality</FormLabel>
                         <Input
                          type="text"
                          name="Nationality"
                          defaultValue={
                            formData["Nationality"] || selectedEmployee["Nationality"]
                          }
                          value={editedEmployee["Nationality"] || ""}
                          onChange={handleChange}
                        
                        />
                          <FormLabel>Religion</FormLabel>
                         <Input
                          type="text"
                          name="Religion"
                          defaultValue={
                            formData["Religion"] || selectedEmployee["Religion"]
                          }
                          value={editedEmployee["Religion"] || ""}
                          onChange={handleChange}
                        
                        />
                      
                     
                      <FormLabel>Reporting_Manager</FormLabel>
                      <Input
                          type="text"
                          name="Reporting_Manager"
                          defaultValue={
                            formData["Reporting_Manager"] || selectedEmployee["Reporting_Manager"]
                          }
                          value={editedEmployee["Reporting_Manager"] || ""}
                          onChange={handleChange}
                        
                        />
                         <FormLabel>
                         Employee_Type<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          type="text"
                          name="Employee_Type"
                          defaultValue={
                            formData["Employee_Type"] || selectedEmployee["Employee_Type"]
                          }
                          value={editedEmployee["Employee_Type"] || ""}
                          onChange={handleChange}
                        
                        />
                        <FormLabel>
                        Employee_Status<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          type="text"
                          name="Employee_Status"
                          defaultValue={
                            formData["Employee_Status"] || selectedEmployee["Employee_Status"]
                          }
                          value={editedEmployee["Employee_Status"] || ""}
                          onChange={handleChange}
                        
                        />
                       <FormLabel>
                       <FormLabel>DateOfJoining</FormLabel>
                       <Input
                          type="date"
                          name="DateOfJoining"
                          defaultValue={
                            formData["DateOfJoining"] || selectedEmployee["DateOfJoining"]
                          }
                          value={editedEmployee["DateOfJoining"] || ""}
                          onChange={handleChange}
                        
                        />
                        {/* probationPeriod<span style={{ color: "red" }}>*</span> */}
                        </FormLabel>
                        
                        </FormControl>
                      
                      </GridItem>
                      <GridItem colStart={4} colEnd={6}>
                        <FormControl>
                        <FormLabel> Mobile_Number</FormLabel>
                        <Input
                          type="text"
                          name="Mobile_Number"
                          defaultValue={
                            formData["Mobile_Number"] || selectedEmployee["Mobile_Number"]
                          }
                          value={editedEmployee["Mobile_Number"] || ""}
                          onChange={handleChange}
                          />
                        <FormLabel>Designation</FormLabel>
                        <Input
                          type="text"
                          name="Designation"
                          defaultValue={
                            formData["Designation"] || selectedEmployee["Designation"]
                          }
                          value={editedEmployee["Designation"] || ""}
                          onChange={handleChange}
                        />
                         <FormLabel>Department</FormLabel>
                        <Input
                          type="text"
                          name="Department"
                          defaultValue={
                            formData["Department"] || selectedEmployee["Department"]
                          }
                          value={editedEmployee["Department"] || ""}
                          onChange={handleChange}
                        />
                        <FormLabel>Location</FormLabel>
                        <Input
                          type="text"
                          name="Location"
                          defaultValue={
                            formData["Location"] || selectedEmployee["Location"]
                          }
                          value={editedEmployee["Location"] || ""}
                          onChange={handleChange}

                        />
                         <FormLabel>Cost_Center</FormLabel>
                        <Input
                          type="text"
                          name="Cost_Center"
                          defaultValue={
                            formData["Cost_Center"] || selectedEmployee["Cost_Center"]
                          }
                          value={editedEmployee["Cost_Center"] || ""}
                          onChange={handleChange}
                          
                        />
                        
                         <FormLabel>Pan_Number</FormLabel>
                        <Input
                          type="text"
                          name="Pan_Number"
                          defaultValue={
                            formData["Pan_Number"] || selectedEmployee["Pan_Number"]
                          }
                          value={editedEmployee["Pan_Number"] || ""}
                          onChange={handleChange}
                          
                        />
                        <FormLabel>Pf_Number</FormLabel>
                        <Input
                          type="text"
                          name="Pf_Number"
                          defaultValue={
                            formData["Pf_Number"] || selectedEmployee["Pf_Number"]
                          }
                          value={editedEmployee["Pf_Number"] || ""}
                          onChange={handleChange}
                          multiple
                        />
                        
                        {/* <FormLabel>department</FormLabel>
                        <Select className="cancelDragAndDrop"
                          name="department"
                          defaultValue={
                            formData["department"] || selectedEmployee["department"]
                          }
                          value={editedEmployee["department"] || ""}
                          onChange={handleChange}
                        >
                          {PackingTypeData.map((item) => (
                            <option value={item.packingtypeId}>{item.packingtypeId}</option>
                          ))}
                        </Select> */}

                       <FormLabel>Uan_Number</FormLabel>
                        <Input
                          type="text"
                          name="Uan_Number"
                          defaultValue={
                            formData["Uan_Number"] || selectedEmployee["Uan_Number"]
                          }
                          value={editedEmployee["Uan_Number"] || ""}
                          onChange={handleChange}
                          multiple
                        />
                        <FormLabel>Payment_Type</FormLabel>
                        <Input
                          type="text"
                          name="Payment_Type"
                          defaultValue={
                            formData["Payment_Type"] || selectedEmployee["Payment_Type"]
                          }
                          value={editedEmployee["Payment_Type"] || ""}
                          onChange={handleChange}
                          multiple
                        />


                        <FormLabel>Bank_Name</FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["Bank_Name"] || selectedEmployee["Bank_Name"]
                          }
                          value={editedEmployee["Bank_Name"] || ""}
                          onChange={handleChange}
                          name="Bank_Name"
                        />
                         <FormLabel>Bank_Branch</FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["Bank_Branch"] || selectedEmployee["Bank_Branch"]
                          }
                          value={editedEmployee["Bank_Branch"] || ""}
                          onChange={handleChange}
                          name="Bank_Branch"
                        />
                         <FormLabel>Account_Number</FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["Account_Number"] || selectedEmployee["Account_Number"]
                          }
                          value={editedEmployee["Account_Number"] || ""}
                          onChange={handleChange}
                          name="Account_Number"
                        />
                        <FormLabel>ReferredBy</FormLabel>
                        <Input
                          type="text"
                          name="ReferredBy"
                          defaultValue={
                            formData["ReferredBy"] || selectedEmployee["ReferredBy"]
                          }
                          value={editedEmployee["ReferredBy"] || ""}
                          onChange={handleChange}
                        
                        />
                        <FormLabel>Probation_Period</FormLabel>
                        <Input
                          type="text"
                          name="Probation_Period"
                          defaultValue={
                            formData["Probation_Period"] || selectedEmployee["Probation_Period"]
                          }
                          value={editedEmployee["Probation_Period"] || ""}
                          onChange={handleChange}
                        />
                        
                        <FormLabel>Confirmation_Date</FormLabel>
                        <Input
                          type="date"
                          name="Confirmation_Date"
                          defaultValue={
                            formData["Confirmation_Date"] || selectedEmployee["Confirmation_Date"]
                          }
                          value={editedEmployee["Confirmation_Date"] || ""}
                          onChange={handleChange}
                        />
                         <FormLabel>Email</FormLabel>
                         <Input
                          type="text"
                          name="Email"
                          defaultValue={
                            formData["Email"] || selectedEmployee["Email"]
                          }
                          value={editedEmployee["Email"] || ""}
                          onChange={handleChange}
                          />
                      </FormControl>
                      
                    </GridItem>
                  </Grid>
                  {!selectedEmployee && (
                    <p>Data not available for the selected Employee.</p>
                  )}
                </form>
              )}
              <Stack
                spacing={4}
                direction="row"
                align="center"
                float="right"
                marginTop={5}
              >
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button
                  colorScheme="messenger"
                  onClick={() => {
                    handleSubmitUpdate();
                    handleCloseModal();
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Rnd>
          </div>
        )}
      </>
    </div>
  );
};
export default DraggableEmployeeTable;
