export class Checkvalidation {
    constructor(
      checkBoxSelected,
      toast,
      onOpenMassupdate,
      handleOpenEditor,
      onOpenReportDelete,
    ) {
      this.checkBoxSelected = checkBoxSelected;
      this.toast = toast;
      this.onOpenMassupdate = onOpenMassupdate;
      this.handleOpenEditor = handleOpenEditor;
      this.onOpenReportDelete = onOpenReportDelete;
    }
    handleEditClickMassdelete() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for deletion.");
        this.toast({
          title: "Please select a row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
      }
      this.onOpenReportDelete();
    }

    handleEditClickMassmail() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for Email.");
        this.toast({
          title: "Please select a row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
      }
      // If checkBoxSelected is true, you can continue with the  action.
      this.handleOpenEditor();
    }
  
    handleEditClickMassupdate() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for deletion.");
        this.toast({
          title: "Please select a row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
            bottom:'0px'
          },
        });
        return;
      }
      // If checkBoxSelected has at least one item selected continue with
      this.onOpenMassupdate();
    }
  
   
  }
  