import React from "react";
import { useState, useEffect } from "react";
import { Header } from "../../components";
import { v4 as uuidv4 } from "uuid";
import Axios, {
  MasterDataAPIInstance,
  ProfomaInvoiceAPIInstance,
} from "../../api-instance";
import { useToast, useDisclosure } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "./AddDebitNote.css";
import Select from "react-select";
import { GiCancel, GiConsoleController } from "react-icons/gi";
import {
  Button,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  FormControl,
  Textarea,
} from "@chakra-ui/react";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import { Rnd } from "react-rnd";
import { CgClose } from "react-icons/cg";
const AddDebitNote = () => {
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [Phone, setPhone] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const [proinvId, setProinvId] = useState(uuidv4());
  const location = useLocation();
  const [jobData, setJobData] = useState([]);
  const [selectedJobNumber, setSelectedJobNumber] = useState("");
  const [uniqueJobNumbers, setUniqueJobNumbers] = useState([]);
  const [bookingNumbers, setBookingNumbers] = useState([]);
  const [selectedBookingNumber, setSelectedBookingNumber] = useState("");
  const [taxdesData, setTaxdesData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [billToStateData, setBillToStateData] = useState([]);
  const [billToCityData, setBillToCityData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [initialContainers, setInitialContainers] = useState([]);
  const [portData, setPortData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [showOtherDescriptionInput, setShowOtherDescriptionInput] = useState(
    []
  );
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  const [otherDescriptions, setOtherDescriptions] = useState({});
  const [descriptionOptions, setDescriptionOption] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [tiUser_Region, setTiUser_Region] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  // Generated Date Auto
  const current = new Date();

  const [tiRegionOptions, setTiRegionOptions] = useState([]);
  const [jobNumberOptions, setJobNumberOptions] = useState([]);
  const [bookingNumberOptions, setBookingNumberOptions] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [addressOptions, setAddressOptions] = useState([]);
  const {
    isOpen: isOpenCancelDebit,
    onOpen: onOpenCancelDebit,
    onClose: onCloseCancelDebit,
  } = useDisclosure();

  const currentDate = `${current.getFullYear()}-${(current.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${current.getDate().toString().padStart(2, "0")}`;
  const [formData, setFormData] = useState({
    ProinvId: proinvId,
    ProinvNumber: "",
    TypeOfShipment: "",
    JobNumber: "",
    BookingNumber: "",
    MBL: "",
    HBL: "",
    POL: "",
    POD: "",
    Invoice_Date: "",
    Due_Date: "",
    TiOwner: JSON.parse(localStorage.getItem("user")).userId,
    ETA: "",
    ETD: "",
    ContainerType: "",
    VesselNameVoyage: "",
    CompanySelection: "",
    CompanyId: "",
    BankDetails: "",
    BankId: "",
    TiContainers: [],
    TotalInvValue: "",
    Bill_to: "",
    BillAddress: "",
    BillAddressCountry: "",
    BillAddressState: "",
    BillAddressCity: "",
    BillAddressPincode: "",
    BillGst: "",
    ExchangeRate: "",
    Remark: "",
    TiCurrency: "",
    TiRegion: "",
    TiState: "",
    Container_No: "",
    Shipper: "",
    Consignee: "",
    TiTax: "",
    Volume:"",
  });
  const [TiContainers, setTiContainers] = useState([
    {
      OtherDescription: "",
      Description: "",
      Currency: "",
      UnitPrice: "",
      Base: "",
      Qty: "",
      Ex_Rate: "",
      TaxableAmount: "",
      Gst: "",
      SacCode: "",
      AmtInclGst: "",
    },
  ]);

  const [selectedJob, setSelectedJob] = useState(null);
  const [containers, setContainers] = useState([{}]);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  console.log("forms", formData);

  const customStyles = {
    indicatorSeparator: () => ({ display: "none" }),
    control: (provided) => ({
      ...provided,
      minWidth: "200px",
    }),
  };

  useEffect(() => {
    // Calculate and set the initial invoice date and due date if not empty
    const calculatedInvoiceDate = calculateInvoiceDate();
    if (calculatedInvoiceDate) {
      const calculatedDueDate = calculateDueDate(calculatedInvoiceDate);
      setFormData((prevData) => ({
        ...prevData,
        Invoice_Date: calculatedInvoiceDate,
        Due_Date: calculatedDueDate,
      }));
    }
  }, []);

  useEffect(() => {
    // Update due date whenever invoice date changes
    if (formData.Invoice_Date) {
      const calculatedDueDate = calculateDueDate(formData.Invoice_Date);
      setFormData((prevData) => ({
        ...prevData,
        Due_Date: calculatedDueDate,
      }));
    }
  }, [formData.Invoice_Date]);

  const calculateInvoiceDate = () => {
    const currentDate = new Date();
    const invoiceDate = new Date(currentDate);
    return invoiceDate.toISOString().split("T")[0];
  };

  const calculateDueDate = (invoiceDate) => {
    const date = new Date(invoiceDate);
    date.setDate(date.getDate() + 3); // Add 3 days
    return date.toISOString().split("T")[0];
  };

  const handlePolChange = (selectedOption) => {
    const pol = selectedOption ? selectedOption.value : "";
    setFormData({ ...formData, POL: pol });
  };
  const handlePodChange = (selectedOption) => {
    const pod = selectedOption ? selectedOption.value : "";
    setFormData({ ...formData, POD: pod });
  };

  console.log("Updated formData:", formData);
  console.log("Is address selected:", isAddressSelected);
  useEffect(() => {
    // Only proceed if both TiRegion and BillAddressCountry are selected
    if (formData.TiRegion && formData.BillAddressCountry) {
      const selectedCountry = formData.BillAddressCountry;
      const selectedTiRegion = formData.TiRegion;

      // Set the TiTax query based on the comparison between TiRegion and BillAddressCountry
      const tiTaxQuery =
        selectedTiRegion === selectedCountry ? formData.TiTax : "No_Tax";

      // Fetch tax details if TiRegion and BillAddressCountry are both set
      const fetchTaxData = async () => {
        try {
          // Fetch the tax details based on the tiTaxQuery
          const { data: fetchedTaxData } = await ProfomaInvoiceAPIInstance.get(
            `get_tax?tax=${tiTaxQuery}`
          );

          // Map the fetched tax data to description options
          const descriptionOptions = Array.isArray(fetchedTaxData.data)
            ? fetchedTaxData.data.map((item) => ({
                Description: item.Description,
                value: item.Description,
                label: item.Description,
                Tax: item.Tax,
                SacCode: item.SacCode,
              }))
            : [];

          // Set the description options and tax data
          setDescriptionOption(descriptionOptions);
          setTaxData(descriptionOptions);
        } catch (error) {
          // Handle any errors (e.g., network issues) with a toast notification
          toast({
            title: "Error fetching tax details",
            description: error.message,
            status: "error",
            position: "bottom-center",
            isClosable: true,
          });
        }
      };

      // Call the function to fetch the tax data
      fetchTaxData();
    }
  }, [formData.TiRegion, formData.BillAddressCountry]); // Add both fields as dependencies

  const handleChange = async (e, index) => {
    const { name, value } = e.target;
    if (name === "CompanySelection") {
      const selectedCompany = bankData.find(
        (bank) => bank.OfficeAddress === value
      );
      const selectedCompanyId = selectedCompany ? selectedCompany.id : "";
      setFormData((prevData) => ({
        ...prevData,
        CompanySelection: value,
        CompanyId: selectedCompanyId,
      }));
    } else if (name === "BankDetails") {
      const selectedBank = bankData.find(
        (bank) => `${bank.AccNumber}-${bank.BankName}` === value
      );
      const selectedBankId = selectedBank ? selectedBank.id : ""; // Assuming BankId is the ID field
      setFormData((prevData) => ({
        ...prevData,
        BankDetails: value,
        BankId: selectedBankId,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const validateContainers = () => {
    const newErrors = containers.map((container) => {
      const containerErrors = {};

      if (!container.Description || container.Description.trim() === "") {
        containerErrors.Description = "Description is required.";
      }
      if (!container.Base || container.Base.trim() === "") {
        containerErrors.Base = "Base is required.";
      }

      if (!container.Qty || isNaN(container.Qty) || container.Qty <= 0) {
        containerErrors.Qty = "Quantity is required.";
      }

      if (!container.Currency || container.Currency.trim() === "") {
        containerErrors.Currency = "Currency is required.";
      }

      if (
        !container.UnitPrice ||
        isNaN(container.UnitPrice) ||
        container.UnitPrice <= 0
      ) {
        containerErrors.UnitPrice = "Unit price is required.";
      }

      if (
        !container.Ex_Rate ||
        isNaN(container.Ex_Rate) ||
        container.Ex_Rate <= 0
      ) {
        containerErrors.Ex_Rate = "Exchange rate is required.";
      }

      return containerErrors;
    });

    // Update the errors state
    setErrors(newErrors);

    // Return true if there are no errors
    return newErrors.every((error) => Object.keys(error).length === 0);
  };
  // End

  const [showPopup, setShowPopup] = useState(false);
  const [currencyOption, setCurrencyOption] = useState({});

  const handlePrint = async (id) => {
    try {
      const userId = JSON.parse(localStorage.getItem("user")).userId;
      const token = JSON.parse(localStorage.getItem("token"));

      const ids = id;

      const pdfResponse = await ProfomaInvoiceAPIInstance.get(
        `get_note_pdfdata?userId=${userId}&type=DBN&id=${ids}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      const TIData = pdfResponse.data.data[0]; // Fetch TIData from the response
      console.log(TIData);

      // Extract operations from the Containers field
      if (TIData && TIData.Containers && TIData.Containers !== "") {
        const operations = JSON.parse(TIData.Containers).map((container) => {
          const containerObj = {};
          Object.keys(container).forEach((key) => {
            containerObj[key] = container[key] === "" ? null : container[key];
          });
          return containerObj;
        });
        TIData.Operations = operations;
      }

      let currency = "";
      let TiCurrency = TIData.NoteCurrency; // Assign TiCurrency from TIData
      let TiCountry = TIData.TiRegion;
      if (TIData.Operations && Array.isArray(TIData.Operations)) {
        const matchingOperation = TIData.Operations.find(
          (operation) => parseFloat(operation.Ex_Rate) === 1
        );

        if (matchingOperation) {
          currency = matchingOperation.Currency;
        }
        console.log("Currency with Ex_Rate = 1:", currency);
      }

      console.log("TICurrency:", TiCurrency);

      // Check if currency matches TiCurrency
      if (currency === TiCurrency) {
        await processPDF(TiCurrency, TIData, TiCountry); // Pass TiCurrency and TIData to processPDF
      } else {
        // Show popup for user to select between TiCurrency and Currency
        setShowPopup(true);
        setCurrencyOption({ TiCurrency, currency, TIData });
      }
    } catch (error) {
      console.error("Error in handlePrint:", error);
    }
  };
console.log("currencyOption",currencyOption)
  const processPDF = async (type, TIData) => {
    try {
      const userId = JSON.parse(localStorage.getItem("user")).userId;
      const token = JSON.parse(localStorage.getItem("token"));
      const tiCountry = TIData.TiRegion;
      const pdfConfigResponse = await ProfomaInvoiceAPIInstance.get(
        `get_pdf?userId=${userId}&country=${tiCountry}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      const pdfConfigData = pdfConfigResponse.data.data[0];
      console.log("PDF Config Data:", pdfConfigData);

      if (pdfConfigData && pdfConfigData.taxApi) {
        const proformaApiEndpoint = `/${pdfConfigData.taxApi}?currtype=${type}`;

        const postResponse = await ProfomaInvoiceAPIInstance.post(
          proformaApiEndpoint,
          TIData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob",
          }
        );

        const pdfBlob = new Blob([postResponse.data], {
          type: "application/pdf",
        });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        const timestamp = new Date()
          .toISOString()
          .replace(/[-T:]/g, "")
          .split(".")[0];

        // Set the desired filename with timestamp
        link.download = `${TIData.DbnNumber}_${timestamp}.pdf`;

        document.body.appendChild(link);
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up the link element
        URL.revokeObjectURL(pdfUrl); // Release the object URL
        navigate("/debitnote");
      }
    } catch (error) {
      console.error("Error in processPDF:", error);
    }
  };

  const handlePopupSelection = (type) => {
    console.log("currencyOption", currencyOption);
    setShowPopup(false); // Close the popup
    processPDF(type, currencyOption.TIData); // Proceed with the selected type
  };
  const Pagerefresh = () => window.location.reload(true);
  //handle submit
  const handleSubmit = async (event) => {
    setHasSubmitted(true);
    const isValid = validateContainers();
    if (!isValid) {
      // If validation fails, do not proceed
      return;
    }
    formData.TiContainers = TiContainers;
    event.preventDefault();
    console.log(formData, "form");

    try {
      const { data } = await ProfomaInvoiceAPIInstance.post(
        `add_debit_notax?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      console.log("====================================");
      console.log(data);
      console.log("====================================");
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        navigate("/debitnote");
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      console.error("Submission error:", error);
      toast({
        title: "An error occurred. Please try again.",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    } finally {
      setIsSubmitting(false); // Re-enable the button regardless of success or failure
    }
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //handle submit and refresh the same page
  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();
      formData.TiContainers = TiContainers;
      event.preventDefault();
      console.log(formData, "formnext");
      const { data } = await ProfomaInvoiceAPIInstance.post(
        `add_debit_notax?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        const tinumber = data.data.id; // If the ID is the second word // Extract second character
        handlePrint(tinumber);

        // Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Currency Data
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_currency");
        setCurrencyData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await ProfomaInvoiceAPIInstance.get(
          `userregion_pi?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }`
        );
        console.log("Fetched TI Region Data:", data.data); // Debugging line

        // Filter out duplicates based on TiRegion
        const uniqueRegions = Array.from(
          new Set(data.data.map((region) => region.user_region))
        ).map((uniqueRegion) => {
          return data.data.find(
            (region) => region.user_region === uniqueRegion
          );
        });

        setTiRegionOptions(
          uniqueRegions.map((region) => ({
            value: region.user_region,
            label: region.user_region,
            off_add: region.OfficeAddress,
            ti_state: region.State,
            companyId: region.id,
            region_code: region.regionCode,
            country: region.Country,
            ti_tax: region.Tax,
            CurrencyType: region.CurrencyType,
          }))
        );
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //fetch client data
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setClientData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  console.log("client", clientData);
  const portOptions = portData.map((port) => ({
    value: port.Port,
    label: port.Port,
  }));
  const uniqueClients = [
    ...new Set(clientData.map((client) => client.Company)),
  ];

  const clientOptions = uniqueClients.map((company) => ({
    value: company,
    label: company,
    addresses: clientData
      .filter((client) => client.Company === company)
      .map((client) => ({
        label: `${client.Address_Line_1}, ${client.City}, ${client.State}, ${client.Country}, ${client.Pincode}`,
        value: client.id, // Unique ID for the address
        billTo_Address: client.Address_Line_1,
        billTo_City: client.City,
        billTo_State: client.State,
        billTo_Country: client.Country,
        billTo_Country: client.Country,
        billTo_State: client.State,
        billTo_City: client.City,
        billTo_Country: client.Country,
        billTo_State: client.State,
        billTo_City: client.City,
        billTo_Pincode: client.Pincode,
        billTo_Gst: client.GST,
      })),
  }));

  const uniqueCountries = Array.from(
    new Set(bankData.map((company) => company.Country))
  );

  const handleBankDetailsChange = (selectedOption) => {
    const bankDetails = selectedOption ? selectedOption.value : "";
    const bank_id = selectedOption ? selectedOption.id : "";
    setFormData((prevData) => ({
      ...prevData,
      BankDetails: bankDetails,
      BankId: bank_id,
    }));
  };

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_country");
        setCountryData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    fetchCountryData();
  }, []);

  const handleBillCountryChange = async (selectedOption) => {
    const country = selectedOption ? selectedOption.value : "";
    setFormData((prevData) => ({
      ...prevData,
      BillAddressCountry: country,
      BillAddressState: "",
      BillAddressCity: "",
    }));

    try {
      const { data } = await MasterDataAPIInstance.get(
        `get_country_state?countryData=${country}`
      );
      setBillToStateData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const handleBillStateChange = async (selectedOption) => {
    const state = selectedOption ? selectedOption.value : "";
    setFormData((prevData) => ({
      ...prevData,
      BillAddressState: state,
      BillAddressCity: "",
    }));

    try {
      const { data } = await MasterDataAPIInstance.get(
        `get_state_city?stateData=${state}`
      );
      setBillToCityData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const countryOptions = countryData.map((country) => ({
    value: country.Country_name,
    label: country.Country_name,
  }));
  const stateOptions = billToStateData.map((state) => ({
    value: state.State_name,
    label: state.State_name,
  }));
  const cityOptions = billToCityData.map((city) => ({
    value: city.City_name,
    label: city.City_name,
  }));

  const handleClientChange = async (selectedOption) => {
    if (!selectedOption) {
      // Reset form data and clear options if no client is selected
      setFormData({
        ...formData,
        Bill_to: "",
        BillAddress: "",
        BillAddressCity: "",
        BillAddressCountry: "",
        BillAddressState: "",
        BillAddressPincode: "",
        BillGst: "",
      });
      setAddressOptions([]);
      setBillToStateData([]);
      setBillToCityData([]);
      setIsAddressSelected(false);
      return;
    }

    const client = selectedOption.value;
    const addresses = selectedOption.addresses || [];

    if (addresses.length === 1) {
      // Automatically map the single address
      const singleAddress = addresses[0];
      let selectedCountry = singleAddress.billTo_Country;
      let selectedState = singleAddress.billTo_State;
      let selectedCity = singleAddress.billTo_City;

      setFormData({
        ...formData,
        Bill_to: client,
        BillAddressId: 0,
        BillAddress: singleAddress.billTo_Address,
        BillAddressCity: selectedCity,
        BillAddressCountry: selectedCountry,
        BillAddressState: selectedState,
        BillAddressPincode: singleAddress.billTo_Pincode,
        BillGst: singleAddress.billTo_Gst,
      });
      setAddressOptions([]); // No dropdown needed for a single address
      setIsAddressSelected(true);
      try {
        console.log("Countrysss", formData.TiRegion, "fff", selectedCountry);

        // Fetch state options for the selected country
        if (selectedCountry) {
          const { data: stateData } = await MasterDataAPIInstance.get(
            `get_country_state?countryData=${selectedCountry}`
          );
          setBillToStateData(stateData.data);

          // Automatically select the state if it's predefined
          if (selectedState) {
            const stateExists = stateData.data.find(
              (state) => state.State === selectedState
            );
            if (stateExists) {
              setFormData((prevData) => ({
                ...prevData,
                BillAddressState: selectedState,
              }));
            }
          }
        }

        // Fetch city options for the selected state
        if (selectedState) {
          const { data: cityData } = await MasterDataAPIInstance.get(
            `get_state_city?stateData=${selectedState}`
          );
          setBillToCityData(cityData.data);

          // Automatically select the city if it's predefined
          if (selectedCity) {
            const cityExists = cityData.data.find(
              (city) => city.City === selectedCity
            );
            if (cityExists) {
              setFormData((prevData) => ({
                ...prevData,
                BillAddressCity: selectedCity,
              }));
            }
          }
        }
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } else if (addresses.length > 1) {
      // Populate address options for the dropdown
      setAddressOptions(
        addresses.map((address, index) => ({
          value: index, // Use the index as the unique value for the dropdown
          label: `${address.billTo_Address}, ${address.billTo_City}, ${address.billTo_State}`,
          ...address,
        }))
      );

      // Reset address-related fields for manual selection
      setFormData({
        ...formData,
        Bill_to: client,
        BillAddress: "",
        BillAddressCity: "",
        BillAddressCountry: "",
        BillAddressState: "",
        BillAddressPincode: "",
        BillGst: "",
      });
      setIsAddressSelected(false);
    }
  };

  const handleAddressChange = async (selectedAddress) => {
    if (selectedAddress) {
      // Update selected values based on the chosen address
      let selectedCountry = selectedAddress.billTo_Country;
      let selectedState = selectedAddress.billTo_State;
      let selectedCity = selectedAddress.billTo_City;

      // Update form data with the selected address details
      setFormData((prevData) => ({
        ...prevData,
        BillAddressId: selectedAddress.value,
        BillAddress: selectedAddress.billTo_Address,
        BillAddressCity: selectedAddress.billTo_City,
        BillAddressCountry: selectedAddress.billTo_Country,
        BillAddressState: selectedAddress.billTo_State,
        BillAddressPincode: selectedAddress.billTo_Pincode,
        BillGst: selectedAddress.billTo_Gst,
      }));
      setIsAddressSelected(true); // Switch to Textarea mode

      try {
        // Log the selected country and TiRegion for debugging
        console.log("Countrysss", formData.TiRegion, "fff", selectedCountry);
        // Fetch state options for the selected country
        if (selectedCountry) {
          const { data: stateData } = await MasterDataAPIInstance.get(
            `get_country_state?countryData=${selectedCountry}`
          );
          setBillToStateData(stateData.data);

          // Auto-select the state if it exists
          if (selectedState) {
            const stateExists = stateData.data.find(
              (state) => state.State === selectedState
            );
            if (stateExists) {
              setFormData((prevData) => ({
                ...prevData,
                BillAddressState: selectedState,
              }));
            }
          }
        }

        // Fetch city options for the selected state
        if (selectedState) {
          const { data: cityData } = await MasterDataAPIInstance.get(
            `get_state_city?stateData=${selectedState}`
          );
          setBillToCityData(cityData.data);

          // Auto-select the city if it exists
          if (selectedCity) {
            const cityExists = cityData.data.find(
              (city) => city.City === selectedCity
            );
            if (cityExists) {
              setFormData((prevData) => ({
                ...prevData,
                BillAddressCity: selectedCity,
              }));
            }
          }
        }
      } catch (error) {
        // Handle errors and show toast notification
        toast({
          title: "An error occurred",
          description: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } else {
      // Clear address fields and reset when no address is selected
      setFormData((prevData) => ({
        ...prevData,
        BillAddressId: "",
        BillAddress: "",
        BillAddressCity: "",
        BillAddressCountry: "",
        BillAddressState: "",
        BillAddressPincode: "",
        BillGst: "",
      }));
      setIsAddressSelected(false); // Keep dropdown visible
    }
  };
  const handleRegionChange = async (selectedOption) => {
    const tiregion = selectedOption ? selectedOption.country : "";
    const ticurrency = selectedOption ? selectedOption.CurrencyType : "";
    const tistate = selectedOption ? selectedOption.ti_state : "";
    const companyId = selectedOption ? selectedOption.companyId : "";
    const picode = selectedOption ? selectedOption.region_code : "";
    const ti_tax = selectedOption ? selectedOption.ti_tax : "";

    // Update formData with the selected region and company information
    setFormData({
      ...formData,
      TiRegion: tiregion,
      TiCurrency: ticurrency,
      CompanySelection: selectedOption ? selectedOption.off_add : "",
      CompanyId: companyId,
      TiState: tistate,
      TiTax: ti_tax,
    });

    try {
      // Fetch bank data based on selected region
      const bankResponse = await ProfomaInvoiceAPIInstance.get(
        `get_bank?country=${tiregion}`
      );
      const banks = bankResponse.data.data;

      // Create bank options, avoiding duplicates
      const seenBankIds = new Set();
      const bankOptions = banks.reduce((acc, bank) => {
        if (!seenBankIds.has(bank.id)) {
          seenBankIds.add(bank.id);
          acc.push({
            value: `${bank.AccNumber}-${bank.BankName}`,
            label: `${bank.AccNumber} - ${bank.BankName} - ${bank.CurrencyType}`,
            id: bank.id,
            state: bank.State,
          });
        }
        return acc;
      }, []);

      setBankOptions(bankOptions);

      // Set default bank for India based on state
      if (tiregion === "India") {
        const defaultBank = bankOptions.find((bank) => bank.state === tistate);
        if (defaultBank) {
          setFormData((prevData) => ({
            ...prevData,
            BankDetails: defaultBank.value,
            BankId: defaultBank.id,
          }));
        }
      } else {
        // Reset any previous bank selection when region is not India
        setFormData((prevData) => ({
          ...prevData,
          BankDetails: "",
          BankId: "",
        }));
      }

      // Fetch and set job data
      const jobResponse = await ProfomaInvoiceAPIInstance.get(
        `get_jobs?code=${picode}`
      );
      const jobs = jobResponse.data.data;
      const uniqueJobNumbers = [...new Set(jobs.map((job) => job.JobNumber))];
      setJobNumberOptions(
        uniqueJobNumbers.map((jobNumber) => ({
          value: jobNumber,
          label: jobNumber,
        }))
      );
      setJobData(jobs);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const handleJobNumberChange = (selectedOption) => {
    const jobNumber = selectedOption ? selectedOption.value : "";
    setSelectedJobNumber(jobNumber);
    setFormData((prevData) => ({
      ...prevData,
      JobNumber: jobNumber,
      BookingNumber: "",
    }));

    // Filter jobs based on the selected job number
    const filteredJobs = jobData.filter((job) => job.JobNumber === jobNumber);

    // Extract unique booking numbers
    const uniqueBookingNumbers = [
      ...new Set(filteredJobs.map((job) => job.BookingNumber)),
    ];
    setBookingNumberOptions(
      uniqueBookingNumbers.map((number) => ({
        value: number,
        label: number,
      }))
    );
  };

  // Function to handle booking number change
  const handleBookingNumberChange = (selectedOption) => {
    const bookingNumber = selectedOption ? selectedOption.value : "";
    setSelectedBookingNumber(bookingNumber);
    setFormData((prevFormData) => ({
      ...prevFormData,
      BookingNumber: bookingNumber,
    }));

    const selectedJob = jobData.find(
      (job) =>
        job.JobNumber === selectedJobNumber &&
        job.BookingNumber === bookingNumber
    );
    if (selectedJob) {
      let containerTypeString = "";
      let containerNoString = "";
      if (selectedJob.Containers) {
        const initialContainers = selectedJob.Containers.split(";").map(
          (containerStr) => {
            const containerAttributes = containerStr
              .split(", ")
              .reduce((acc, attr) => {
                const [key, value] = attr
                  .split(": ")
                  .map((item) => item.trim());
                if (key === "ContainerType") acc.Base = value;
                if (key === "Quantity") acc.Qty = value;
                if (key === "Container_Id") acc.Container_Id = value;
                return acc;
              }, {});
            return containerAttributes;
          }
        );

        containerTypeString = initialContainers
          .map((container) => `${container.Base}-${container.Qty}`)
          .join(", ");
        containerNoString = initialContainers
          .map((container) => `${container.Container_Id}`)
          .join(", ");
        setInitialContainers(initialContainers);
        setContainers(initialContainers);
        setTiContainers(
          initialContainers.map((container) => ({
            ...container,
            Description: "",
            Currency: "",
            UnitPrice: "",
            Ex_Rate: "",
            TaxableAmount: "",
            Gst: "",
            SacCode: "",
            AmtInclGst: "",
          }))
        );
      }
      const parseDate = (dateString) => dateString.split("T")[0];
      setFormData((prevFormData) => ({
        ...prevFormData,
        JobNumber: selectedJob.JobNumber,
        BookingNumber: selectedJob.BookingNumber,
        TypeOfShipment: selectedJob.Product,
        MBL: selectedJob.MBLNo,
        HBL: selectedJob.HBLNo,
        POL: selectedJob.POL,
        POD: selectedJob.POD,
        ETA: parseDate(selectedJob.ETA),
        ETD: parseDate(selectedJob.ETD),
        VesselNameVoyage: selectedJob.VesselNameVoyage,
        ContainerType: containerTypeString,
        Container_No: containerNoString,
        Shipper: selectedJob.Shipper,
        Consignee: selectedJob.Consignee,
      }));
    }
  };

  const addContainer = () => {
    setContainers([...containers, {}]);
    setTiContainers([
      ...TiContainers,
      {
        OtherDescription: "",
        Description: "",
        Currency: "",
        UnitPrice: "",
        Base: "",
        Qty: "",
        Ex_Rate: "",
        TaxableAmount: "",
        Gst: "",
        SacCode: "",
        AmtInclGst: "",
      },
    ]);
    setShowOtherDescriptionInput([...showOtherDescriptionInput, false]);
  };

  const removeContainer = (indexToRemove) => {
    setContainers(containers.filter((_, index) => index !== indexToRemove));
    setTiContainers(TiContainers.filter((_, index) => index !== indexToRemove));
    setShowOtherDescriptionInput(
      showOtherDescriptionInput.filter((_, index) => index !== indexToRemove)
    );
    setOtherDescriptions((prev) => {
      const updated = { ...prev };
      delete updated[indexToRemove];
      return updated;
    });
  };
  console.log(formData);

  const handleContainerChange = (index, fieldName, value) => {
    const updatedContainers = [...containers];
    const updatedTiContainers = [...TiContainers];
    const updatedErrors = [...errors];

    // Update the value for both containers and TiContainers
    updatedContainers[index] = {
      ...updatedContainers[index],
      [fieldName]: value,
    };

    updatedTiContainers[index] = {
      ...updatedTiContainers[index],
      [fieldName]: value,
    };

    // Update GST and SacCode based on the selected Description
    if (fieldName === "Description") {
      const selectedDescription = taxData.find(
        (item) => item.Description === value
      );
      console.log(
        "sad",
        taxData.find((item) => item.Description === value)
      );
      if (selectedDescription) {
        // Convert the tax string to a number
        //const taxValue = parseFloat(selectedDescription.Tax) || 0; // Ensure it's a number
        updatedContainers[index].Gst = selectedDescription.Tax; // Store as number
        updatedContainers[index].SacCode = selectedDescription.SacCode;
        updatedTiContainers[index].Gst = selectedDescription.Tax; // Store as number
        updatedTiContainers[index].SacCode = selectedDescription.SacCode;
      }
      console.log("sad", value, taxData);
    }

    // Update Quantity when Base changes
    if (fieldName === "Base") {
      const selectedContainer = initialContainers.find((c) => c.Base === value);
      const updatedQty = selectedContainer ? selectedContainer.Qty : "";

      updatedContainers[index].Qty = updatedQty;
      updatedTiContainers[index].Qty = updatedQty;
    }

    // Calculate and update TaxableAmount and AmtInclGst
    const { Qty, UnitPrice, Ex_Rate, Gst } = updatedContainers[index];
    const qty = parseFloat(Qty) || 0;
    const unitPrice = parseFloat(UnitPrice) || 0;
    const exRate = parseFloat(Ex_Rate) || 0;

    // Calculate Taxable Amount
    updatedContainers[index].TaxableAmount = (qty * unitPrice * exRate).toFixed(
      2
    );
    updatedTiContainers[index].TaxableAmount =
      updatedContainers[index].TaxableAmount;

    const taxableAmount =
      parseFloat(updatedContainers[index].TaxableAmount) || 0;
    const gst = parseFloat(Gst) || 0;

    // Calculate Amount Including GST
    updatedContainers[index].AmtInclGst = (
      taxableAmount *
      (1 + gst / 100)
    ).toFixed(2);
    updatedTiContainers[index].AmtInclGst = updatedContainers[index].AmtInclGst;

    // Clear the error for the current field
    if (hasSubmitted && updatedErrors[index]?.[fieldName]) {
      updatedErrors[index][fieldName] = ""; // Clear the specific error
    }

    // Update state
    setContainers(updatedContainers);
    setTiContainers(updatedTiContainers);
  };

  const baseOptions = equipmentData.map((item) => ({
    value: item.ContainerType,
    label: item.ContainerType,
  }));

  const currencyOptions = currencyData.map((item) => ({
    value: item.Currency,
    label: `${item.Currency}`,
  }));

  // Add "Others" option only in the frontend
  const extendedDescriptionOptions = [
    ...(descriptionOptions || []), // Ensure descriptionOptions is an array
    { value: "others", label: "Others" },
  ];

  const handleDescriptionChange = (selectedOption, index) => {
    const selectedValue = selectedOption ? selectedOption.value : "";
    handleContainerChange(index, "Description", selectedValue);

    // Check if "Others" is selected
    const updatedShowOtherDescriptionInput = [...showOtherDescriptionInput];
    updatedShowOtherDescriptionInput[index] = selectedValue === "others";
    setShowOtherDescriptionInput(updatedShowOtherDescriptionInput);
  };

  const handleOtherDescriptionChange = (e, index) => {
    const { value } = e.target;
    setOtherDescriptions({ ...otherDescriptions, [index]: value });
    handleContainerChange(index, "OtherDescription", value);
  };

  const handleBackToDropdown = (index) => {
    const updatedShowOtherDescriptionInput = [...showOtherDescriptionInput];
    updatedShowOtherDescriptionInput[index] = false;
    setShowOtherDescriptionInput(updatedShowOtherDescriptionInput);
    handleContainerChange(index, "Description", "");
  };

  const containerIndex = 0; // Change this to the index of the container you want to display

  // Check if the containers array is not empty and the specified index is valid
  if (
    containers.length > containerIndex &&
    containers[containerIndex].AmtInclGst !== undefined
  ) {
    // Display the AmtInclGst value of the specified container
    console.log("AmtInclGst Value:", containers[containerIndex].AmtInclGst);
  } else {
    console.log("Container or AmtInclGst value not available.");
  }
  const totalAmtInclGst = containers.reduce((sum, container) => {
    // Parse the AmtInclGst value to ensure it's a valid number
    const amtInclGst = parseFloat(container.AmtInclGst);
    return sum + (isNaN(amtInclGst) ? 0 : amtInclGst);
  }, 0);

  // Display the totalAmtInclGst
  const roundedTotalAmtInclGst = totalAmtInclGst.toFixed(2);

  // Display the totalAmtInclGst
  console.log("Total AmtInclGst for all containers:", roundedTotalAmtInclGst);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      TotalInvValue: roundedTotalAmtInclGst,
    }));
  }, [roundedTotalAmtInclGst]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  console.log("BD", bankOptions);

  const style = {
    border: "1px solid #ddd",
    backgroundColor: "white",
    zIndex: 20,
  };

  const closeModal = {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
  };

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimming effect
    zIndex: "100",
  };

  const handleConfirmCancel = () => {
    navigate("/debitnote");
    onCloseCancelDebit();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Header />
      <div
      // onKeyDown={handleKeyDown}
      >
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">
            Create Debit Note
          </p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Save"}
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-3 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={handleSubmitNext}
          >
            Save & Preview
          </button>

          <div
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950 cursor-pointer"
            // onClick={() => {
            //   navigate("/booking");
            // }}
            onClick={onOpenCancelDebit}
            disabled={!submitAllowed}
          >
            Cancel
          </div>

          {/* <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/proforma_invoice");
            }}
            disabled={!submitAllowed}
          >
            {" "}
            Cancel
          </button> */}
        </div>

        <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
          <h2
            style={{
              float: "left",
              color: "#172554",
              fontSize: "20px",
              borderRadius: "10px",
              fontFamily: "cursive",
              paddingBottom: "10px", // space for the border
              position: "relative",
            }}
          >
            Region Details<span style={{ color: "red" }}>*</span>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "4px", // height of the border
                borderRadius: "10px",
                background: "linear-gradient(135deg, #71b7e6, #9b59b6)",
              }}
            />
          </h2>

          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-debinvs">
                <div className="input-box">
                  <span className="debinvs">Region</span>
                  <Select
                    isClearable
                    options={tiRegionOptions}
                    value={tiRegionOptions.find(
                      (option) => option.value === formData.TiRegion
                    )}
                    onChange={handleRegionChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                </div>
                <div className="input-box">
                  <span className="debinvs">Bank Details</span>
                  <Select
                    isClearable
                    options={bankOptions}
                    value={bankOptions.find(
                      (option) => option.value === formData.BankDetails
                    )}
                    onChange={handleBankDetailsChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    isDisabled={!formData.CompanySelection} // Disable if no CompanySelection is chosen
                    styles={customStyles}
                  />
                </div>
                <div className="input-box">
                  <span className="debinvs">Company Selection</span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="CompanySelection"
                    value={formData.CompanySelection} // Initially set based on fetched data
                    onChange={handleChange} // Update formData.CompanySelection on change
                    placeholder="Enter Company Name"
                  />
                </div>
              </div>
            </div>
          </div>
          <h2
            style={{
              float: "left",
              color: "#172554",
              fontSize: "20px",
              borderRadius: "10px",
              fontFamily: "cursive",
              paddingBottom: "10px", // space for the border
              position: "relative",
            }}
          >
            Job Details<span style={{ color: "red" }}>*</span>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "4px", // height of the border
                borderRadius: "10px",
                background: "linear-gradient(135deg, #71b7e6, #9b59b6)",
              }}
            />
          </h2>

          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-debinvs">
                <div className="input-box">
                  <span className="debinvs">Job Number</span>
                  <Select
                    isClearable
                    options={jobNumberOptions}
                    value={jobNumberOptions.find(
                      (option) => option.value === formData.JobNumber
                    )}
                    onChange={handleJobNumberChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                </div>

                <div className="input-box">
                  <span className="debinvs">Booking Number</span>
                  <Select
                    isClearable
                    options={bookingNumberOptions}
                    value={bookingNumberOptions.find(
                      (option) => option.value === formData.BookingNumber
                    )}
                    onChange={handleBookingNumberChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                </div>
                <div className="input-box">
                  <span className="debinvs">Product</span>

                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="TypeOfShipment"
                    value={formData.TypeOfShipment}
                    onChange={handleChange}
                    placeholder="Enter your Product"
                  >
                    <option>Select Product</option>
                    {productData.map((item) => (
                      <option value={item.Product}>{item.Product}</option>
                    ))}
                  </select>
                </div>

                <div className="input-box">
                  <span className="debinvs">MBL</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="MBL"
                    value={formData.MBL}
                    onChange={handleChange}
                    placeholder="Enter your MBL"
                  />
                </div>

                <div className="input-box">
                  <span className="debinvs">HBL</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="HBL"
                    value={formData.HBL}
                    onChange={handleChange}
                    placeholder="Enter your HBL"
                  />
                </div>

                <div className="input-box">
                  <span className="debinvs">POL</span>
                  <Select
                    isClearable
                    options={portOptions}
                    value={portOptions.find(
                      (option) => option.value === formData.POL
                    )}
                    onChange={handlePolChange}
                    classNamePrefix="react-select"
                    styles={customStyles}
                    noOptionsMessage={() => "No options"}
                  />
                </div>

                <div className="input-box">
                  <span className="debinvs">POD</span>
                  <Select
                    isClearable
                    options={portOptions}
                    value={portOptions.find(
                      (option) => option.value === formData.POD
                    )}
                    onChange={handlePodChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                </div>
                <div className="input-box">
                  <span className="debinvs">Invoice Date</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="Invoice_Date"
                    value={formData.Invoice_Date}
                    onChange={handleChange}
                    placeholder="Enter your Invoice_Date"
                  />
                </div>
                <div className="input-box">
                  <span className="debinvs">Due Date</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="Due_Date"
                    value={formData.Due_Date}
                    onChange={handleChange}
                    placeholder="Enter your Due_Date"
                  />
                </div>
                <div className="input-box">
                  <span className="debinvs">ETA</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="ETA"
                    value={formData.ETA}
                    onChange={handleChange}
                    placeholder="Enter your ETA"
                  />
                </div>
                <div className="input-box">
                  <span className="debinvs">ETD</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="ETD"
                    type="date"
                    value={formData.ETD}
                    onChange={handleChange}
                    placeholder="Enter your ETD"
                  />
                </div>
                <div className="input-box">
                  <span className="debinvs">ContainerType</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="ContainerType"
                    value={formData.ContainerType}
                    onChange={handleChange}
                    placeholder="Enter your ContainerType"
                  />
                </div>
                <div className="input-box">
                  <span className="debinvs">VesselNameVoyage </span>

                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="VesselNameVoyage"
                    value={formData.VesselNameVoyage}
                    onChange={handleChange}
                    placeholder="Enter your VesselNameVoyage  "
                  />
                </div>
              </div>
            </div>
          </div>
          <h2
            style={{
              float: "left",
              color: "#172554",
              fontSize: "20px",
              borderRadius: "10px",
              fontFamily: "cursive",
              paddingBottom: "10px", // space for the border
              position: "relative",
            }}
          >
            Client Details<span style={{ color: "red" }}>*</span>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "4px", // height of the border
                borderRadius: "10px",
                background: "linear-gradient(135deg, #71b7e6, #9b59b6)",
              }}
            />
          </h2>

          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-debinvs">
                <div className="input-box">
                  <span className="debinvs">Client</span>
                  <Select
                    isClearable
                    options={clientOptions}
                    value={clientOptions.find(
                      (option) => option.value === formData.Bill_to
                    )}
                    onChange={handleClientChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                </div>
                {!isAddressSelected && addressOptions.length > 1 ? (
                  <div className="input-box">
                    <span className="debinvs">Client Address</span>
                    <Select
                      isClearable
                      options={addressOptions}
                      value={addressOptions.find(
                        (option) => option.value === formData.BillAddressId
                      )}
                      onChange={(selectedOption) => {
                        handleAddressChange(selectedOption); // Update formData with selected address or clear it
                      }}
                      classNamePrefix="react-select"
                      noOptionsMessage={() => "No options"}
                      styles={customStyles}
                    />
                  </div>
                ) : (
                  <div className="input-box">
                    <span className="debinvs">Client Address</span>
                    <Textarea
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="BillAddress"
                      value={formData.BillAddress}
                      onChange={handleChange}
                      placeholder="Enter Client Address"
                    />
                  </div>
                )}
                <div className="input-box">
                  <span className="debinvs">Client Country</span>
                  <Select
                    isClearable
                    options={countryOptions}
                    value={countryOptions.find(
                      (option) => option.value === formData.BillAddressCountry
                    )}
                    onChange={handleBillCountryChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                </div>
                <div className="input-box">
                  <span className="debinvs">Client State</span>
                  <Select
                    isClearable
                    options={stateOptions}
                    value={stateOptions.find(
                      (option) => option.value === formData.BillAddressState
                    )}
                    onChange={handleBillStateChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                </div>
                <div className="input-box">
                  <span className="debinvs">Client City</span>
                  <Select
                    isClearable
                    options={cityOptions}
                    value={cityOptions.find(
                      (option) => option.value === formData.BillAddressCity
                    )}
                    onChange={(selectedOption) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        BillAddressCity: selectedOption
                          ? selectedOption.value
                          : "",
                      }))
                    }
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                </div>
                <div className="input-box">
                  <span className="debinvs">Client Pincode</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="BillAddressPincode"
                    value={formData.BillAddressPincode}
                    onChange={handleChange}
                    placeholder="Enter  Bill To tincode"
                  />
                </div>
                <div className="input-box">
                  <span className="debinvs">Client Tax</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="BillGst"
                    value={formData.BillGst}
                    onChange={handleChange}
                    placeholder="Enter  Bill To  Gst"
                  />
                </div>
                <div className="input-box"></div>
                <div className="input-box"></div>
                <div className="input-box">
                  <span className="debinvs">
                    ExChange Rate({formData.TiCurrency || ""})
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="ExchangeRate"
                    value={formData.ExchangeRate}
                    onChange={handleChange}
                    placeholder="Enter exchange rate"
                  />
                </div>

                <div className="input-box">
                  <span className="debinvs">Remark</span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Remark"
                    value={formData.Remark} // Initially set based on fetched data
                    onChange={handleChange} // Update formData.CompanySelection on change
                    placeholder="Enter Remark"
                  />
                </div>
                <div className="input-box"></div>
                <div className="table-container">
                  <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                    <thead className="flex-nowrap text-center bg-blue-950 text-white">
                      <tr>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Description
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Base
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Qty
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Currency
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          UnitPrice
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Ex_Rate
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          TaxableAmount
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Tax
                        </th>

                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Amt Incl Tax
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {containers.map((container, index) => (
                        <tr key={index}>
                          <td>
                            {showOtherDescriptionInput[index] ? (
                              <div className="custom-input-container">
                                <input
                                  type="text"
                                  name={`OtherDescription-${index}`}
                                  value={otherDescriptions[index] || ""}
                                  onChange={(e) =>
                                    handleOtherDescriptionChange(e, index)
                                  }
                                  placeholder="Enter other description"
                                  className={`custom-input ${
                                    hasSubmitted &&
                                    errors[index]?.OtherDescription
                                      ? "input-error"
                                      : ""
                                  }`}
                                />
                                <GiCancel
                                  onClick={() => handleBackToDropdown(index)}
                                  style={{ cursor: "pointer", color: "red" }}
                                  size={20}
                                  className="cancel-icon"
                                />
                              </div>
                            ) : (
                              <Select
                                name={`Description-${index}`}
                                value={extendedDescriptionOptions.find(
                                  (option) =>
                                    option.value === container.Description
                                )}
                                onChange={(selectedOption) =>
                                  handleDescriptionChange(selectedOption, index)
                                }
                                options={extendedDescriptionOptions}
                                classNamePrefix="react-select"
                                noOptionsMessage={() => "No options"}
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                menuPosition="fixed"
                              />
                            )}
                            {hasSubmitted && (
                              <>
                                {errors[index]?.Description && (
                                  <span className="error-message">
                                    {errors[index].Description}
                                  </span>
                                )}
                                {errors[index]?.OtherDescription && (
                                  <span className="error-message">
                                    {errors[index].OtherDescription}
                                  </span>
                                )}
                              </>
                            )}
                          </td>

                          <td>
                            <Select
                              name={`Base-${index}`}
                              value={baseOptions.find(
                                (option) => option.value === container.Base
                              )}
                              onChange={(selectedOption) =>
                                handleContainerChange(
                                  index,
                                  "Base",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              options={baseOptions}
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "No options"}
                              styles={customStyles}
                              menuPortalTarget={document.body} // Render the dropdown menu in the body
                              menuPosition="fixed"
                            />
                            {hasSubmitted && errors[index]?.Base && (
                              <span className="error-message">
                                {errors[index].Base}
                              </span>
                            )}
                          </td>
                          <td>
                            <Input
                              w="80px"
                              type="number"
                              name={`Qty-${index}`}
                              value={container.Qty || ""}
                              onChange={(e) =>
                                handleContainerChange(
                                  index,
                                  "Qty",
                                  e.target.value
                                )
                              }
                            />
                            {hasSubmitted && errors[index]?.Qty && (
                              <span className="error-message">
                                {errors[index].Qty}
                              </span>
                            )}
                          </td>
                          <td>
                            <Select
                              name={`Currency-${index}`}
                              value={currencyOptions.find(
                                (option) => option.value === container.Currency
                              )}
                              onChange={(selectedOption) =>
                                handleContainerChange(
                                  index,
                                  "Currency",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              options={currencyOptions}
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "No options"}
                              styles={customStyles}
                              menuPortalTarget={document.body}
                              menuPosition="fixed"
                            />
                            {hasSubmitted && errors[index]?.Currency && (
                              <span className="error-message">
                                {errors[index].Currency}
                              </span>
                            )}
                          </td>
                          <td>
                            <Input
                              w="80px"
                              type="number"
                              name={`UnitPrice-${index}`}
                              value={container.UnitPrice || ""}
                              onChange={(e) =>
                                handleContainerChange(
                                  index,
                                  "UnitPrice",
                                  e.target.value
                                )
                              }
                            />
                            {hasSubmitted && errors[index]?.UnitPrice && (
                              <span className="error-message">
                                {errors[index].UnitPrice}
                              </span>
                            )}
                          </td>
                          <td>
                            <Input
                              w="80px"
                              type="number"
                              name={`Ex_Rate-${index}`}
                              value={container.Ex_Rate || ""}
                              onChange={(e) =>
                                handleContainerChange(
                                  index,
                                  "Ex_Rate",
                                  e.target.value
                                )
                              }
                            />
                            {hasSubmitted && errors[index]?.Ex_Rate && (
                              <span className="error-message">
                                {errors[index].Ex_Rate}
                              </span>
                            )}
                          </td>
                          <td>
                            <Input
                              w="150px"
                              type="number"
                              name={`TaxableAmount-${index}`}
                              value={container.TaxableAmount || ""}
                              readOnly
                            />
                            {hasSubmitted && errors[index]?.TaxableAmount && (
                              <span className="error-message">
                                {errors[index].TaxableAmount}
                              </span>
                            )}
                          </td>
                          <td>
                            <Input
                              w="150px"
                              type="text"
                              name={`Gst-${index}`}
                              value={container.Gst}
                              onChange={(e) =>
                                handleContainerChange(
                                  index,
                                  "Gst",
                                  e.target.value
                                )
                              }
                              readOnly={container.Description !== "others"}
                            />
                            {hasSubmitted && errors[index]?.Gst && (
                              <span className="error-message">
                                {errors[index].Gst}
                              </span>
                            )}
                          </td>
                          <td>
                            <Input
                              w="120px"
                              type="number"
                              name={`AmtInclGst-${index}`}
                              value={container.AmtInclGst || ""}
                              readOnly
                            />
                            {hasSubmitted && errors[index]?.AmtInclGst && (
                              <span className="error-message">
                                {errors[index].AmtInclGst}
                              </span>
                            )}
                          </td>
                          <td>
                            {containers.length > 1 && (
                              <BiSolidMinusCircle
                                className="cursor-pointer"
                                size={30}
                                style={{ marginTop: "15px", color: "red" }}
                                onClick={() => removeContainer(index)}
                              />
                            )}
                            <IoMdAddCircle
                              className="cursor-pointer"
                              size={30}
                              style={{ marginTop: "15px", color: "green" }}
                              onClick={addContainer}
                            />
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={7}></td>
                        <td className="font-medium"> Total Incl Value :</td>
                        <td>
                          <Input
                            w="120px"
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            type="number"
                            name="TotalInvValue"
                            value={formData.TotalInvValue}
                            onChange={handleChange}
                            placeholder="Enter your TotalInvValue"
                            readOnly
                          />
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="input-box"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenCancelDebit && (
        <>
          <div style={backdropStyle}></div>
          <div className="ConfirmCancelDebit">
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
              }}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Create Debit Note Cancel
              </header>
              <div onClick={onCloseCancelDebit} style={closeModal}>
                <CgClose />
              </div>
              <br />
              <FormControl>
                Are you sure you want to cancel the debit note?
              </FormControl>
              <br />
              <Button colorScheme="green" onClick={handleConfirmCancel}>
                Yes
              </Button>
              <Button colorScheme="red" onClick={onCloseCancelDebit} ml={3}>
                No
              </Button>
            </Rnd>
          </div>
        </>
      )}

      <>
        {showPopup && (
          <>
            <div style={backdropStyle}></div>
            <div className={`MassDeleteModule ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 600,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header>Show the Debit Note in</header>
                <div
                  onClick={() => {
                    setShowPopup(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <Button
                  colorScheme="blue"
                  onClick={() =>
                    handlePopupSelection(currencyOption.TiCurrency)
                  }
                >
                  {currencyOption.TiCurrency}
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  colorScheme="blue"
                  onClick={() => handlePopupSelection(currencyOption.currency)}
                >
                  {currencyOption.currency}
                </Button>
              </Rnd>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default AddDebitNote;
