import { React, useState, useEffect, useMemo } from "react";
import Axios, {
  MasterDataAPIInstance,
  ProfomaInvoiceAPIInstance,
} from "../../api-instance";
// Internal module import
import "./DraggableCreditnoteTable.css";
import { Rnd } from "react-rnd";
// Chakara ui modules
import { useNavigate } from "react-router-dom";

import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import {
  useDisclosure,
  IconButton,
  useToast,
  Stack,
  Button,
  Tooltip,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Badge,
} from "@chakra-ui/react";

// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";

const DraggableCnTable = ({
  isLoading,
  columns,
  data,
  crnData,
  getData,
  setCrnData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsCrn,
  pageNumbers,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  // State Variables
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedCrn, setSelectedCrn] = useState({});
  const [editedCrn, setEditedCrn] = useState({});
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = useState("xl"); // Set the initial size to 'full'
  const [formData, setFormData] = useState({});
  const [selectedRow, setSelectedRow] = useState([]);

  //console.log(crnData)
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  // const userRegion = JSON.parse(localStorage.getItem("user")).region;

  useEffect(() => {
    if (selectedCrn) {
      let containers = [];
      try {
        containers = JSON.parse(selectedCrn.Containers);
      } catch (e) {
        containers = selectedCrn.Containers || [];
      }
      setEditedCrn({ ...selectedCrn, Containers: containers });
    }
  }, [selectedCrn]);
  useEffect(() => {
    if (selectedCrn) {
      let containers = [];
      try {
        containers = JSON.parse(selectedCrn.Containers);
      } catch (e) {
        containers = selectedCrn.Containers || [];
      }
      setEditedCrn({ ...selectedCrn, Containers: containers });
    }
  }, [selectedCrn]);

  useEffect(() => {
    if (editedCrn.Containers) {
      const totalAmtInclGst = editedCrn.Containers.reduce(
        (sum, container) => {
          const amtInclGst = parseFloat(container.AmtInclGst);
          return sum + (isNaN(amtInclGst) ? 0 : amtInclGst);
        },
        0
      );

      const roundedTotalAmtInclGst = totalAmtInclGst.toFixed(2);

      setEditedCrn((prev) => ({
        ...prev,
        TotalInvValue: roundedTotalAmtInclGst,
      }));
    }
  }, [editedCrn.Containers]);

  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );

      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);

  const sortTable = (key) => {
    let newSortOrder = "asc";

    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...crnData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortKey(key);
    setSortOrder(newSortOrder);

    if (typeof setCrnData === "function") {
      setCrnData(sortedData);
    }
  };

  // Function to handle changes in container fields

  useEffect(() => {
    if (editedCrn?.ContainerType) {
      const parsedContainers = editedCrn?.ContainerType.split(", ").map(
        (item) => {
          const [base, qty] = item.split("-");
          return { base, qty };
        }
      );

      const updatedContainers = (editedCrn.Containers || []).map(
        (container, index) => {
          if (parsedContainers[index]) {
            return {
              ...container,
              Base: parsedContainers[index].base,
              Qty: parsedContainers[index].qty,
            };
          }
          return container;
        }
      );

      setEditedCrn({ ...editedCrn, Containers: updatedContainers });
    }
  }, [editedCrn?.ContainerType]);

  useEffect(() => {
    const totalAmtInclGst = editedCrn.Containers
      ? editedCrn.Containers.reduce((sum, container) => {
          if (container.DeletionFlag === "0") {
            const amtInclGst = parseFloat(container.AmtInclGst);
            return sum + (isNaN(amtInclGst) ? 0 : amtInclGst);
          }
          return sum;
        }, 0)
      : 0;

    const roundedTotalAmtInclGst = totalAmtInclGst.toFixed(2);

    setEditedCrn((prevFormData) => ({
      ...prevFormData,
      TotalInvValue: roundedTotalAmtInclGst,
    }));
  }, [editedCrn.Containers]);



  // Single Edit Module
  const handleFullSizeClick = () => {
    setSize("xl");
    onOpen();
  };
  console.log(selectedRow);
  console.log(data);
  return (
    <div>
      {!isLoading ? (
        tableColumnsCrn?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {false ? (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={checkBoxAll}
                        onChange={() => {
                          handleCheckbox(data.map((item) => item.id));
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              ); // Return the filtered array
                            } else {
                              return [...prev, currentPage]; // Return a new array with the added item
                            }
                          });

                          setCheckBoxAll((prev) => {
                            if (prev === true) {
                              setCheckBoxSelected([]);
                            }
                            return !prev;
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={
                          checkBoxSelected.length === 0
                            ? false
                            : activePageCheckBoxSelect.includes(currentPage)
                        }
                        onChange={() => {
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          const idsToAdd = data?.map((item) => item.id);
                          setCheckBoxSelected((prev) => {
                            const currentData = new Set(prev);
                            if (
                              activePageCheckBoxSelect.includes(currentPage)
                            ) {
                              // If the page is selected, remove its IDs
                              idsToAdd.forEach((item) => {
                                currentData.delete(item);
                              });
                            } else {
                              // If the page is not selected, add its IDs
                              idsToAdd.forEach((item) => {
                                currentData.add(item);
                              });
                            }
                            return Array.from(currentData);
                          });
                        }}
                      />
                    )}
                  </div>
                </th>

                {tableColumnsCrn?.map(
                  (column, index) =>
                    column.label !== "Id" &&
                  column.label !== "status" &&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        <>
                          <span style={{ cursor: "all-scroll" }}>
                            {column.label}
                          </span>
                          <BiSort
                            className="whitespace-nowrap text-left"
                            style={{
                              marginLeft: "160px",
                              marginTop: "-22px",
                              cursor: "pointer",
                            }}
                            onClick={() => sortTable(column.key)}
                          />
                        </>
                        {/* )} */}
                      </th>
                    )
                )}
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                        {false ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(
                                row?.id,
                                row?.CrnNumber,
                                row?.IrnStatus,
                                row?.TiRegion,
                              );
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(
                                row?.id,
                                row?.CrnNumber,
                                row?.IrnStatus,
                                row?.TiRegion,
                              );
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected?.includes(
                                row?.id,
                                row?.CrnNumber,
                                row?.IrnStatus,
                                row?.TiRegion,
                              )
                            }
                          />
                        )}
                      </Tooltip>
                    </td>
                    {tableColumnsCrn.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                      column.label !== "status" &&
                        column.isVisible === true && (
                          <td
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {column.key === "IrnStatus" ? (
                              row["IrnStatus"] === "CAN" ? (
                                <Badge colorScheme="red">CANCELED</Badge>
                              ) : row["IrnStatus"] === "NON" ? (
                                <Badge variant="outline" colorScheme="red">
                                  NOT GENERATED
                                </Badge>
                              ) : row["IrnStatus"] === "GEN" ? (
                                <Badge colorScheme="green">GENERATED</Badge>
                              ) : (
                                row[column.key]
                              )
                            ) : column.key === "InvoiceStatus" ? (
                              row["InvoiceStatus"] === "CNL" ? (
                                <Badge colorScheme="red">CANCELED</Badge>
                              ) : row["InvoiceStatus"] === "ACT" ? (
                                <Badge colorScheme="green">ACTIVE</Badge>
                              ) : (
                                row[column.key]
                              )
                            ) : ["AckDt"].includes(column.key) ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : (
                              row[column.key]
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsCrn.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default DraggableCnTable;
