import React, { useState, useEffect } from "react";
import { 
  Flex, Box, Grid, VStack, Stat, StatLabel, StatNumber, 
  Text, IconButton, Button, Alert, AlertIcon, Input 
} from "@chakra-ui/react";
import { FaUsers, FaClock, FaClipboardList, FaPlaneDeparture, FaPaperPlane, FaComment } from 'react-icons/fa';
import Sidebar from "./sidebar";
import {Header} from "../../components";
import { Pie, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './HrmDashboard.css';

export default function Dashboard() {
  const [date, setDate] = useState(new Date());
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isChatOpen, setIsChatOpen] = useState(false); // Toggle state for chatbox

  // To handle live time update
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const pieData = {
    labels: ['Employee', 'Attendance', 'Onboarding', 'Leave'],
    datasets: [
      {
        data: [10, 20, 15, 35],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ], 
  };

  const barData = {
    labels: ['1', '2', '3', '4'],
    datasets: [
      {
        label: 'Dataset 1',
        backgroundColor: '#36A2EB',
        data: [1, 3, 2, 4],
      },
      {
        label: 'Dataset 2',
        backgroundColor: '#4BC0C0',
        data: [2, 2, 3, 1],
      },
    ],
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== "") {
      // Add admin message to the chat
      setMessages([...messages, { sender: "Admin", text: newMessage }]);
      setNewMessage("");

      // Simulate a response from the employee after a delay
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "Employee", text: "This is an automated reply from the employee." }
        ]);
      }, 1000);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <>
      <Header />
      <Flex>
        <Sidebar />
        <Box flex="1" p="4" bg="gray.100">
          <Flex justify="space-between" align="center" mb={4}>
            <Box>
              <Text fontSize="2xl" fontWeight="bold">Good Morning</Text>
              <Text fontSize="lg">Admin</Text>
            </Box>
            <Box>
              <Text fontSize="6xl">{time.toLocaleTimeString()}</Text>
            </Box>
          </Flex>

          <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
            <StatBox label="Employee" count={5} icon={FaUsers} />
            <StatBox label="Last Day Working Hours" count="00:41:21 Hours" icon={FaClock} />
            <StatBox label="Total Attendance" count="0 Days" icon={FaClipboardList} />
            <StatBox label="Total Absent" count="0 Days" icon={FaPlaneDeparture} />
          </Grid>

          <Box mb={6}>
            <ButtonBox />
          </Box>

          <Alert status="warning" mb={6}>
            <AlertIcon />
            You are late today! Your Office In Time is 09:30 AM
          </Alert>

          <Grid templateColumns="repeat(3, 1fr)" gap={6} mb={6}>
            <NoticeBox />
            <EventBox />
            <HolidayBox />
          </Grid>

          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <ChartBox label="Employee" chart={<Pie data={pieData} />} />
            <ChartBox label="Payroll Statistic" chart={<Bar data={barData} />} />
          </Grid>

          <Box mt={6}>
            <Calendar value={date} onChange={setDate} />
          </Box>
        </Box>
      </Flex>

      <IconButton
        icon={<FaComment />}
        position="fixed"
        bottom="20px"
        right="20px"
        zIndex={10}
        colorScheme="teal"
        onClick={() => setIsChatOpen(!isChatOpen)}
      />

      {isChatOpen && (
        <ChatBox 
          messages={messages} 
          newMessage={newMessage} 
          setNewMessage={setNewMessage} 
          handleSendMessage={handleSendMessage}
          handleKeyPress={handleKeyPress} 
        />
      )}
    </>
  );
}

const ChatBox = ({ messages, newMessage, setNewMessage, handleSendMessage, handleKeyPress }) => (
  <Box
    position="fixed"
    bottom="70px"
    right="20px"
    width="300px"
    bg="white"
    borderRadius="md"
    boxShadow="md"
    p={4}
    zIndex={10}
  >
    <Text fontSize="lg" fontWeight="bold" mb={2}>Chat with Employee</Text>
    <Box maxH="200px" overflowY="auto" mb={4}>
      {messages.length === 0 ? (
        <Text>No messages yet</Text>
      ) : (
        messages.map((msg, index) => (
          <Text key={index} mb={2} color={msg.sender === "Admin" ? "blue.500" : "green.500"}>
            <strong>{msg.sender}:</strong> {msg.text}
          </Text>
        ))
      )}
    </Box>
    <Flex>
      <Input
        placeholder="Type a message..."
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyPress={handleKeyPress}
        mr={2}
      />
      <IconButton
        icon={<FaPaperPlane />}
        colorScheme="blue"
        onClick={handleSendMessage}
      />
    </Flex>
  </Box>
);

const StatBox = ({ label, count, icon }) => (
  <VStack
    p={4}
    bgGradient="linear(to-r, pink.300, blue.500)"
    borderRadius="md"
    boxShadow="sm"
    align="start"
    spacing={2}
  >
    <IconButton
      aria-label={label}
      icon={<Box as={icon} />}
      size="lg"
      isRound
      colorScheme="white"
    />
    <Stat>
      <StatLabel>{label}</StatLabel>
      <StatNumber>{count}</StatNumber>
    </Stat>
  </VStack>
);

const ButtonBox = () => (
  <Flex justify="center" gap={4}>
    <Button colorScheme="red">Office Out</Button>
    <Button colorScheme="green">Lunch In</Button>
    <Button colorScheme="orange">Late Reason</Button>
    <Button colorScheme="purple">Daily Report</Button>
  </Flex>
);

const NoticeBox = () => (
  <Box p={4} bg="white" borderRadius="md" boxShadow="sm">
    <Text fontSize="lg" fontWeight="bold" mb={4}>Notice's</Text>
    <Text>No Notice Found.!</Text>
  </Box>
);

const EventBox = () => (
  <Box p={4} bg="white" borderRadius="md" boxShadow="sm">
    <Text fontSize="lg" fontWeight="bold" mb={4}>Upcoming Event's</Text>
    <Text>No Events Found.!</Text>
  </Box>
);

const HolidayBox = () => (
  <Box p={4} bg="white" borderRadius="md" boxShadow="sm">
    <Text fontSize="lg" fontWeight="bold" mb={4}>Upcoming Holiday's</Text>
    <Grid templateColumns="repeat(3, 1fr)" gap={4}>
      <Box>
        <Text>Diwali</Text>
        <Text fontSize="sm">From Sep 4 to Sep 5</Text>
      </Box>
      <Box>
        <Text>Dashera</Text>
        <Text fontSize="sm">From Sep 10 to Sep 12</Text>
      </Box>
      <Box>
        <Text>Test Holiday</Text>
        <Text fontSize="sm">From Oct 18 to Oct 21</Text>
      </Box>
    </Grid>
  </Box>
);

const ChartBox = ({ label, chart }) => (
  <Box p={4} bg="white" borderRadius="sm" boxShadow="sm">
    <Text fontSize="md" mb={4}>{label}</Text>
    {chart}
  </Box>
);


// import React, { useState } from "react";
// import { Flex, Box, Grid, VStack, Stat, StatLabel, StatNumber, Text, IconButton } from "@chakra-ui/react";
// import Sidebar from "./sidebar";
// import HrmHeader from "../../components/Hrm/HrmHeader";
// import { FaUsers, FaClock, FaClipboardList, FaPlaneDeparture } from 'react-icons/fa';
// import { Pie, Bar } from 'react-chartjs-2';
// import 'chart.js/auto';
// import Calendar from 'react-calendar'; // Import the Calendar component
// import 'react-calendar/dist/Calendar.css'; // Import calendar styles

// export default function Dashboard() {
//   const [date, setDate] = useState(new Date()); // State for calendar

//   const pieData = {
//     labels: ['Employee', 'Attendance ', 'Onboarding', 'Leave'],
//     datasets: [
//       {
//         data: [10, 20, 15, 35],
//         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
//       },
//     ],
//   };

//   const barData = {
//     labels: ['1', '2', '3', '4'],
//     datasets: [
//       {
//         label: 'Dataset 1',
//         backgroundColor: '#36A2EB',
//         data: [1, 3, 2, 4],
//       },
//       {
//         label: 'Dataset 2',
//         backgroundColor: '#4BC0C0',
//         data: [2, 2, 3, 1],
//       },
//     ],
//   };

//   return (
//     <>
//       <HrmHeader />
//       <Flex>
//         <Sidebar />
//         <Box flex="1" p="4" bg="sky.600">
//           <Flex>
//             <Box flex="4" p={4}>
//               <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
//                 <StatBox label="Employee" count={50} icon={FaUsers} />
//                 <StatBox label="Attendance" count={45} icon={FaClock} />
//                 <StatBox label="Onboarding" count={5} icon={FaClipboardList} />
//                 <StatBox label="Leave" count={2} icon={FaPlaneDeparture} />
//               </Grid>

//               <Grid templateColumns="repeat(3, 1fr)" gap={6} mb={6}>
//                 <InfoBox label="Birthday Mate" />
//                 <InfoBox label="Holiday" />
//                 <InfoBox label="Event" />
//               </Grid>

//               <Grid templateColumns="repeat(2, 1fr)" gap={6}>
//                 <ChartBox label="Employee" chart={<Pie data={pieData} />} />
//                 <ChartBox label="Payroll Statistic" chart={<Bar data={barData} />} />
//               </Grid>
//             </Box>

//             <Box flex="1" p={4} ml={6}>
//               <LatestAnnouncement />
//               <Box mt={6} p={4} bg="white" borderRadius="md" boxShadow="md">
//                 <Text fontSize="lg" fontWeight="bold" mb={4}>
//                   Calendar
//                 </Text>
//                 <StyledCalendar date={date} onDateChange={setDate} />
//               </Box>
//             </Box>
//           </Flex>
//         </Box>
//       </Flex>
//     </>
//   );
// }

// const StatBox = ({ label, count, icon }) => (
//   <VStack
//     p={4}
//     bg="slate-200"
//     borderRadius="md"
//     boxShadow="sm"
//     align="start"
//     spacing={2}
//   >
//     <IconButton
//       aria-label={label}
//       icon={<Box as={icon} />}
//       size="lg"
//       isRound
//       colorScheme="blue"
//     />
//     <Stat>
//       <StatLabel>{label}</StatLabel>
//       <StatNumber>{count}</StatNumber>
//     </Stat>
//   </VStack>
// );

// const InfoBox = ({ label }) => (
//   <Box p={4} bg="white" borderRadius="md" boxShadow="md" textAlign="center">
//     <Text fontSize="md">{label}</Text>
//   </Box>
// );

// const ChartBox = ({ label, chart }) => (
//   <Box p={4} bg="white" borderRadius="md" boxShadow="sm">
//     <Text fontSize="md" mb={4}>
//       {label}
//     </Text>
//     {chart}
//   </Box>
// );

// const LatestAnnouncement = () => (
//   <Box
//     bg="pink"
//     borderRadius="md"
//     boxShadow="sm"
//     p={4}
//     height="full"
//     maxHeight="400px"
//     overflowY="auto"
//   >
//     <Text fontSize="xl" fontWeight="bold" mb={4}>
//       Latest Announcement
//     </Text>
//     <Text>No announcements available.</Text>
//   </Box>
// );

// const StyledCalendar = ({ date, onDateChange }) => (
//   <Box>
//     <Calendar
//       value={date}
//       onChange={onDateChange}
//       tileClassName={({ date }) => {
//         const today = new Date();
//         if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth()) {
//           return 'highlight-today'; // Add a custom class for today's date
//         }
//       }}
//     />
//     <style jsx global>{`
//       .react-calendar {
//         border-radius: 10px;
//         overflow: hidden;
//         border: 1px solid #e2e8f0;
//         font-family: Arial, sans-serif;
//         background-color: white;
//       }
//       .react-calendar__tile {
//         height: 50px;
//         font-size: 16px;
//         transition: background-color 0.3s ease;
//         border-radius: 8px;
//       }
//       .react-calendar__tile:hover {
//         background-color: #e2e8f0;
//       }
//       .react-calendar__tile--now {
//         background-color: #f0e68c;
//       }
//       .react-calendar__tile--active {
//         background-color: #36A2EB;
//         color: white;
//       }
//       .highlight-today {
//         background-color: #FFCE56 !important;
//         color: white;
//       }
//       .react-calendar__month-view__days__day--weekend {
//         color: #d9534f;
//       }
//     `}</style>
//   </Box>
// );
