import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Axios, { OperationDocumentAPIInstance,MasterDataAPIInstance } from "../../../api-instance";
import { Textarea, useToast } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CFSNomination.css";
import { GiCancel } from "react-icons/gi";
import { Button } from "antd";
import {
  Select,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
} from "@chakra-ui/react";
import { Header } from "../../../components";

const AddCfsCreation = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [formDataIndex, setFormDataIndex] = useState(0);
  const [descriptionType, setDescriptionType] = useState("Single");
  const [descriptionOfGoods, setDescriptionOfGoods] = useState("");
  const [editedCfsData, setEditedCfsData] = useState({});
  const [PortData, setportData] = useState([]);
  const location = useLocation();
  const { selectedJob } = location.state || {};
  console.log("hdosss:", selectedJob);

  const [freightData, setfreightData] = useState({});
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await OperationDocumentAPIInstance.get(
          `get_hdofreight?BookingNumber=${selectedJob.BookingNumber}&JobNumber=${selectedJob.JobNumber}`
        );
        if (data.data.length === 0) {
          toast({
            title: "No freight data found.",
            position: "bottom-center",
            isClosable: true,
            status: "warning",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        } else {
          setfreightData(data.data[0]);
        }
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, [selectedJob]);
  const intial_MBLNumber = freightData.MBLNumber;
  const intial_POL = freightData.POL;
  const intial_POD = freightData.POD;
  const inital_ContainerNumber = freightData.ContainerNumber;
  const [selectedCfsData, setSelectedCfsData] = useState({});

  useEffect(() => {
    if (selectedJob) {
      setSelectedCfsData({
        BookingNumber: selectedJob.BookingNumber || "",
        JobNumber: selectedJob.JobNumber || "",
        CfsNumber: "",
        to_: "",
        POL: intial_POL || "",
        POD: intial_POD || "",
        CfsName: "",
        CfsCode: "",
        MBLNumber: intial_MBLNumber || "",
        ContainerNumber: inital_ContainerNumber || "",
        JobInfoNo: selectedJob.JobInfono || "",
        CFSOwner: JSON.parse(localStorage.getItem("user")).userId,
      });
    }
  }, [
    selectedJob,
    intial_MBLNumber,
    intial_POL,
    intial_POD,
    inital_ContainerNumber,
  ]);

  const [formData, setFormData] = useState(selectedCfsData);

  useEffect(() => {
    // Update formData with editedCfsData
    setFormData(editedCfsData);
  }, [editedCfsData, descriptionType]);

  useEffect(() => {
    setEditedCfsData(selectedCfsData);
  }, [selectedCfsData]);

  const handlePolChange = (e) => {
    setEditedCfsData((prevData) => ({
      ...prevData,
      POL: e.target.value,
    }));
  };
  const handlePodChange = (e) => {
    setEditedCfsData((prevData) => ({
      ...prevData,
      POD: e.target.value,
    }));
  };
  const handleChange = async (e) => {
    const { name, value } = e.target;

    setEditedCfsData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setportData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // End
  const Pagerefresh = () => window.location.reload(true);
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData, "form");
    const { data } = await OperationDocumentAPIInstance.post(
      `add_cfs?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    console.log("====================================");
    console.log(data);
    console.log("====================================");
    if (data.success) {
      toast({
        title: data.data.message,
        position: "bottom-center",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
    } else {
      toast({
        title: data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const handleOperationChange = (index, key, value) => {
    const updatedOperations = [...editedCfsData.Operations];
    updatedOperations[index][key] = value;
    setEditedCfsData((prevState) => ({
      ...prevState,
      Operations: updatedOperations,
    }));
    // if (descriptionType === "Single" && key === "DescriptionOfGoods") {
    //   console.log("descriptionType",descriptionType);
    //   const updatedOperationsWithDescription = updatedOperations.map((operation) => ({
    //     ...operation,
    //     DescriptionOfGoods: value,
    //   }));
    //   setEditedCfsData((prevState) => ({
    //     ...prevState,
    //     Operations: updatedOperationsWithDescription,
    //   }));
    // }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  return (
    <>
      <Header />
      <div>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">
            CFS Nomination Creation
          </p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/opertion_docs", {
                state: { selectedJob: selectedJob },
              });
            }}
            disabled={!submitAllowed}
          >
            {" "}
            Cancel
          </button>
        </div>

        <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
          <div className="title">CFS Nomination Information</div>
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-cfscreations">
                <div className="input-box" hidden>
                  <span className="cfscreations">
                    CfsNumber
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="CfsNumber"
                    defaultValue={
                      formData["CfsNumber"] || selectedCfsData["CfsNumber"]
                    }
                    value={editedCfsData["CfsNumber"] || ""}
                    placeholder="Enter your CfsNumber"
                    // disabled
                  />
                </div>
                <div className="input-box">
                  <span className="cfscreations">
                    To
                  </span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="to_"
                    defaultValue={formData["to_"] || selectedCfsData["to_"]}
                    value={editedCfsData["to_"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your To"
                  />
                </div>
                {/* <div className="input-box">
                  <span className="cfscreations">
                    POL
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POL"
                    defaultValue={formData["POL"] || selectedCfsData["POL"]}
                    value={editedCfsData["POL"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your POL"
                  />
                </div> */}
                <div className="input-box">
                  <span className="cfscreations">
                    POL
                  </span>
                 <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POL"
                    defaultValue={formData["POL"] || selectedCfsData["POL"]}
                    value={editedCfsData["POL"] || ""}
                    onChange={handlePolChange}
                  >
                    placeholder="Enter your POL"
                    <option value={formData["POL"] || selectedCfsData["POL"]}>
                      {formData["POL"] || selectedCfsData["POL"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {PortData.map((item, idx) => (
                      <option key={idx} value={item.Port}>
                        {item.Port}
                      </option>
                    ))}
                  </select>
                  </div>
                <div className="input-box">
                  <span className="cfscreations">
                    CfsName
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="CfsName"
                    defaultValue={
                      formData["CfsName"] || selectedCfsData["CfsName"]
                    }
                    value={editedCfsData["CfsName"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your CfsName"
                  />
                </div>
                {/* <div className="input-box">
                  <span className="cfscreations">
                    POD
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POD"
                    defaultValue={formData["POD"] || selectedCfsData["POD"]}
                    value={editedCfsData["POD"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your POD"
                  />
                </div> */}
                <div className="input-box">
                  <span className="freightcertificate">
                    POD
                  </span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POD"
                    defaultValue={formData["POD"] || selectedCfsData["POD"]}
                    value={editedCfsData["POD"] || ""}
                    onChange={handlePodChange}
                    placeholder="Enter your POD"
                  >
                    <option value={formData["POD"] || selectedCfsData["POD"]}>
                      {formData["POD"] || selectedCfsData["POD"]}
                    </option>

                    {/* Render options from equipmentData */}
                    {PortData.map((item, idx) => (
                      <option key={idx} value={item.Port}>
                        {item.Port}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="cfscreations">
                    CfsCode
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="CfsCode"
                    defaultValue={
                      formData["CfsCode"] || selectedCfsData["CfsCode"]
                    }
                    value={editedCfsData["CfsCode"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your CfsCode"
                  />
                </div>
                <div className="input-box">
                  <span className="cfscreations">ContainerNumber</span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="ContainerNumber"
                    defaultValue={
                      formData["ContainerNumber"] ||
                      selectedCfsData["ContainerNumber"]
                    }
                    value={editedCfsData["ContainerNumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your ContainerNumber"
                  />
                </div>
                <div className="input-box">
                  <span className="cfscreations">
                    MBLNumber
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="MBLNumber"
                    defaultValue={
                      formData["MBLNumber"] || selectedCfsData["MBLNumber"]
                    }
                    value={editedCfsData["MBLNumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your MBLNumber"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCfsCreation;
