import { React, useState, useEffect } from "react";
import { Header, OperationSidebar } from "../../components";
import "./Addjob.css";
import { v4 as uuidv4 } from "uuid";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import {
  Select,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
  Textarea,
  Badge,
  FormControl,
  Button,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { Rnd } from "react-rnd";
import { CgClose } from "react-icons/cg";
import Axios, {
  JobAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance";
import UnallocatedJob from "./UnallocatedJob";
import { Table } from "jspdf-autotable";
import Documents from "../Opertion/Documents/Documents";
import OperationVendors from "../Opertion/OperationVendors/OperationVendors";
import Checklist from "../Opertion/Checklist/Checklist";
import Activitylog from "../Opertion/Activitylog/Activitylog";
import AddUnallocatedJob from "./AddUnallocatedJob";
import SearchSelect from "../../components/Select/select";
const Addjob = () => {
  const location = useLocation();
  const unBooking = location.state.selectedData || {};

  console.log("Add Job Selected data:", unBooking);
  // const [jobInfoNo, setJobInfoNo] = useState(uuidv4([]));
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [incotermData, setIncotermData] = useState([]);
  const [scopeData, setScopeData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [productData, setProductData] = useState([]);
  const [shipmentLineData, setShipmentLineData] = useState([]);
  const [packageTypeData, setPackageTypeData] = useState([]);
  const [portData, setPortData] = useState([]);
  const [salesPersonData, setSalesPersonData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();
  const [unallocatedBookingState, setUnallocatedBookingState] = useState([]);
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const fullName = `${storedUser.firstname} ${storedUser.lastname}`;
  const regionCode = storedUser.regions[0].regionCode;
  const [formDataIndex, setFormDataIndex] = useState(0);
  const [initialRegionCode, setInitialRegionCode] = useState(
    storedUser.regions.length > 0 ? storedUser.regions[0].regionCode : ""
  );
  const [jobStatusOptions, setJobStatusOptions] = useState([
    { value: "Inprogress", label: "Inprogress" },
    { value: "Completed", label: "Completed" },
    { value: "Hold", label: "Hold" },
    { value: "Cancelled", label: "Cancelled" },
  ]);
  const [errors, setErrors] = useState({});
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeBookingTabIndex, setActiveBookingTabIndex] = useState(0); // Track active booking tab

  // Define required fields for each tab
  const requiredFields = {
    0: ["Product", "POL", "POD", "CompanyName", "Client_email_id"], // Required fields for Job Information tab
    1: ["Cargo"], // Example fields for Cargo Details tab
    2: [], // Example fields for Liner Details tab
  };
  const [formData, setFormData] = useState([
    {
      Product: "",
      ShipmentHandledBy: "",
      Job_Status: "",
      JobNumber: "",
      JobInfono: "",
      DateOfBusinessConfirmed: "",
      PlaceOfReceipt: "",
      PlaceOfDelivery: "",
      POL: "",
      POD: "",
      Shipper: "",
      Consignee: "",
      CompanyName: "", //Client name replace company name
      Client_email_id: "",
      Agent: "",
      QuotationNumber: "",
      Scope: "",
      Region: "",
      Sales: "",
      Incoterm: "",
      Cargo: "",
      CargoNature: "",
      HsCode: "",
      ShippingLine: "",
      VesselNameVoyage: "",
      CyOpen: "",
      SICutOff: "",
      VGMCutOff: "",
      CYCutOff: "",
      CustomsCuttOff: "",
      OOGCuttOff: "",
      ETD: "",
      ETA: "",
      FreeDaysAtOrigin: "",
      FreeDaysAtDestination: "",
      Freight: "",
      MBLType: "",
      HBLType: "",
      LinerBookingNumber: "",
      LinerRefNumber: "",
      MBLNumber: "",
      HBLNumber: "",
      CargoPickUpDate: "",
      EmptyPickUpDate: "",
      Stuffingdate: "",
      LadenGateIndate: "",
      VesselArrivalDate: "",
      OperationTranferDeadline: "",
      Vendor: "",
      Detail: "",
      Amount: "",
      Currency: "",
      ContainerNumber: "",
      SealNumber: "",
      No_Of_Pkge: "",
      Type_Of_Pkge: "",
      NtWeight: "",
      GrWeight: "",
      Volume: "",
      TareWeight: "",
      VGM: "",
      VGMMethod: "",
      // Origin
      Detention: "",
      Detention_By_Liner: "",
      DetentionNo: "",
      Demurrage: "",
      Demurrage_By_Liner: "",
      DemurrageNo: "",
      Port_Storage: "",
      PortStorage: "",
      Port_Storage_Days: "",
      CombinedDetentionDemurrage: "",
      CombinedDetentionDemurrageLiner: "",
      NoOfDaysDetentionDemurrageOccuring: "",
      // Destination
      ContainerDischargeDate: "",
      PortOutDate: "",
      EmptyReturnDate: "",
      DestinationDetention: "",
      DestinationDemurrage: "",
      DestinationDetentionByLiner: "",
      DestinationDemurrageByLiner: "",
      DestinationNoOfDetentionOccuring: "",
      DestinationNoOfDemurrageOccuring: "",
      DestinationPortStorage: "",
      DestinationPortStorageGivenByPort: "",
      DestinationNoOfDaysPortStorageOccuring: "",
      DestinationCombinedDetentionDemurrage: "",
      DestinationCombinedDetentionDemurrageByLiner: "",
      DestinationNoOfdaysDetentionDemurrageOccuring: "",
      Current_Status: "",
      Notes: "",
      Containers: "ContainerType: , Quantity: , Weight: , Dimensions: ;",
      JobOwner: "",
      CreatedBy: fullName,
      regionCode: initialRegionCode || regionCode,
      Operations: [
        {
          ContainerNumber: "",
          SealNumber: "",
          No_Of_Pkge: "",
          Type_Of_Pkge: "",
          NtWeight: "",
          GrWeight: "",
          Volume: "",
          TareWeight: "",
          VGM: "",
          VGMMethod: "",
        },
      ],
      vendors: [
        {
          Vendor: "",
          Detail: "",
          Amount: "",
          Currency: "",
        },
      ],
    },
  ]);

  // useEffect(() => {
  //   // Update formData when storedUser changes
  //   setFormData((prevFormData) => {
  //     return prevFormData.map((item) => ({
  //       ...item,
  //       CreatedBy: fullName,
  //       regionCode: storedUser.regions[0].regionCode,
  //       BookingOwner: storedUser.userId,
  //     }));
  //   });
  // }, [storedUser, fullName]);

  // useEffect(() => {
  //   setFormData((prevFormData) => {
  //     return prevFormData.map((item) => ({
  //       ...item,
  //       CreatedBy: fullName,
  //       BookingOwner: storedUser.userId,
  //       regionCode: initialRegionCode,
  //     }));
  //   });
  // }, [storedUser, fullName, initialRegionCode]);

  // const handleChangeRegionCode = (e) => {
  //   setInitialRegionCode(e.target.value);
  //   setFormData((prevFormData) => {
  //     const updatedFormData = [...prevFormData];
  //     updatedFormData[formDataIndex].regionCode = e.target.value;
  //     return updatedFormData;
  //   });
  // };
  useEffect(() => {
    setFormData((prevFormData) =>
      selectedData.map((item, index) => ({
        ...prevFormData[index],
        CreatedBy: fullName,
        BookingOwner: storedUser.userId,
        regionCode: initialRegionCode,
      }))
    );
  }, [storedUser, fullName, initialRegionCode, selectedData]);

  const handleChangeRegionCode = (e) => {
    setInitialRegionCode(e.target.value);
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[formDataIndex].regionCode = e.target.value;
      return updatedFormData;
    });
  };

  const [jobVendor, setJobVendor] = useState([
    {
      Vendor: "",
      Detail: "",
      Amount: "",
      Currency: "",
    },
  ]);
  console.log(formData);
  useEffect(() => {
    setFormData(unBooking);
    setSelectedData(unBooking);
  }, []);

  useEffect(() => {}, [setSelectedData, setSelectedRows]);
  // Product API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Equipment API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Incoterm API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_incoterm");
        setIncotermData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Scope API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Region API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_region");
        setRegionData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Shipment API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_shipment");
        setShipmentLineData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Package Type API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_packagetype");
        setPackageTypeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Port API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //Sales Person API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_salesperson");
        setSalesPersonData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Currency Data
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_currency");
        setCurrencyData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Customer API
  const [filteredPIC, setFilteredPIC] = useState([]);
  const [filteredAddress, setFilteredAddress] = useState([]);
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [filteredCompanyName, setFilteredCompanyName] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setCustomerData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (formData[formDataIndex]?.CompanyName) {
      const filteredAddress = customerData.find(
        (item) => item.Company === formData[formDataIndex].CompanyName
      );
      setFilteredAddress(
        filteredAddress
          ? [
              {
                value: `${filteredAddress.Address_Line_1}, ${filteredAddress.Address_Line_2}, ${filteredAddress.Address_Line_3}, ${filteredAddress.City}, ${filteredAddress.State}, ${filteredAddress.Country} - ${filteredAddress.Pincode}`,
                label: `${filteredAddress.Address_Line_1}, ${filteredAddress.Address_Line_2}, ${filteredAddress.Address_Line_3}, ${filteredAddress.City}, ${filteredAddress.State}, ${filteredAddress.Country} - ${filteredAddress.Pincode}`,
                name: "Address",
              },
            ]
          : []
      );
    }
  }, [formData[formDataIndex]?.CompanyName, customerData]);

  useEffect(() => {
    if (formData[formDataIndex]?.CompanyName) {
      const filteredEmails = customerData
        .filter((item) => item.Company === formData[formDataIndex].CompanyName)
        .map((item) => ({
          value: item.Client_email_id,
          label: item.Client_email_id,
          name: "Client_email_id",
        }));
      setFilteredEmails(filteredEmails);
    } else {
      setFilteredEmails([]);
    }
  }, [formData[formDataIndex]?.CompanyName, customerData]);

  const addContainer = () => {
    setFormData((prevData) => {
      const newData = [...prevData];
      const updatedOperations = [...newData[formDataIndex]?.Operations];

      updatedOperations?.push({
        ContainerNumber: "",
        SealNumber: "",
        No_Of_Pkge: "",
        Type_Of_Pkge: "",
        NtWeight: "",
        GrWeight: "",
        Volume: "",
        TareWeight: "",
        VGM: "",
        VGMMethod: "",
      });

      newData[formDataIndex] = {
        ...newData[formDataIndex],
        Containers:
          newData[formDataIndex].Containers +
          "ContainerType: , Quantity: , Weight: , Dimensions: ;",
        Operations: updatedOperations,
      };
      return newData;
    });
  };

  console.log(formData);

  function removeRowByIndex(data, indexToRemove) {
    let rows = data.split(";").filter((row) => row.trim() !== ""); // Split the data into rows
    if (indexToRemove >= 0 && indexToRemove < rows.length) {
      // Check if index is within bounds
      rows.splice(indexToRemove, 1); // Remove row at specified index
    }
    return rows.join("; "); // Join the rows back into a single string
  }

  const removeContainer = (indexToRemove) => {
    console.log(indexToRemove);
    // setFormData(prevData => {
    //   const newData = [...prevData];
    //   newData[formDataIndex] = {
    //     ...newData[formDataIndex],
    //     "Containers": removeRowByIndex(newData[formDataIndex].Containers, indexToRemove),
    //     "Operations": newData[formDataIndex]?.Operations.filter((_, index) => index !== indexToRemove)

    //   };
    //   return newData;
    // });
    setFormData((prevData) => {
      const newData = [...prevData];
      const containers = newData[formDataIndex]?.Containers || "";

      // Split the containers into rows
      let rows = containers.split(";").filter((row) => row.trim() !== "");

      if (indexToRemove >= 0 && indexToRemove < rows.length) {
        // Remove row at specified index
        rows.splice(indexToRemove, 1);
      }

      // Join the rows back into a single string with semicolons
      newData[formDataIndex] = {
        ...newData[formDataIndex],
        Containers:
          rows.join("; ").trim() + (containers.endsWith(";") ? ";" : ""), // Ensure ending semicolon if necessary
        Operations: newData[formDataIndex]?.Operations.filter(
          (_, index) => index !== indexToRemove
        ),
      };
      return newData;
    });
  };


  

  // const handleContainerChange = (index, field, value) => {
  //   const updatedContainers = [
  //     ...formData[formDataIndex]?.Containers?.split(";"),
  //   ];
  //   const containerString = updatedContainers[index];

  //   if (containerString) {
  //     const container = {};
  //     containerString.split(",").forEach((pair) => {
  //       const [key, val] = pair.split(":");
  //       container[key?.trim()] = val?.trim();
  //     });

  //     // Update the value for the specified field
  //     container[field] = value;

  //     // Reconstruct the container string
  //     updatedContainers[index] = Object.entries(container)
  //       .map(([key, val]) => `${key}:${val}`)
  //       .join(", ");

  //     // Update the state with the new containers string
  //     setFormData((prevData) => {
  //       const newData = [...prevData];
  //       newData[formDataIndex].Containers = updatedContainers.join(";");
  //       return newData;
  //     });
  //   }
  // };

  // const handleOperationChange = (index, field, value) => {
  //   setFormData((prevData) => {
  //     const newData = [...prevData];
  //     const updatedContainer = { ...newData[formDataIndex].Operations[index] };
  //     updatedContainer[field] = value;
  //     newData[formDataIndex].Operations[index] = updatedContainer;
  //     return newData;
  //   });
  // };

  
  // Quantity based
  // const handleContainerChange = (index, field, value) => {
  //   const updatedContainers = [
  //     ...(formData[formDataIndex]?.Containers?.split(";") || []),
  //   ];
  //   const containerString = updatedContainers[index];

  //   if (containerString) {
  //     const container = {};
  //     containerString.split(",").forEach((pair) => {
  //       const [key, val] = pair.split(":");
  //       container[key?.trim()] = val?.trim();
  //     });

  //     // Update the field value
  //     container[field] = value;

  //     // Reconstruct the container string
  //     updatedContainers[index] = Object.entries(container)
  //       .map(([key, val]) => `${key}:${val}`)
  //       .join(", ");

  //     // Update the state
  //     setFormData((prevData) => {
  //       const newData = [...prevData];
  //       newData[formDataIndex].Containers = updatedContainers.join(";");
  //       return newData;
  //     });
  //   }
  // };

  // const handleOperationChange = (containerIndex, rowIndex, field, value) => {
  //   setFormData((prevData) => {
  //     const newData = [...prevData];

  //     // Ensure Operations array exists
  //     if (!newData[formDataIndex].Operations) {
  //       newData[formDataIndex].Operations = [];
  //     }

  //     // Ensure the specific container's Operations array exists
  //     if (!Array.isArray(newData[formDataIndex].Operations[containerIndex])) {
  //       newData[formDataIndex].Operations[containerIndex] = [];
  //     }

  //     // Ensure the specific row exists and initialize it if undefined
  //     if (!newData[formDataIndex].Operations[containerIndex][rowIndex]) {
  //       newData[formDataIndex].Operations[containerIndex][rowIndex] = {};
  //     }

  //     // Update the specific field for the given row
  //     newData[formDataIndex].Operations[containerIndex][rowIndex][field] =
  //       value;

  //     return newData;
  //   });
  // };

   // End Quantity Based

  // Add row based
  const handleContainerChange = (index, field, value) => {
    const updatedContainers = [
      ...formData[formDataIndex]?.Containers?.split(";"),
    ];
    const containerString = updatedContainers[index];

    if (containerString) {
      const container = {};
      containerString.split(",").forEach((pair) => {
        const [key, val] = pair.split(":");
        container[key?.trim()] = val?.trim();
      });

      // Update the value for the specified field
      container[field] = value;

      // Reconstruct the container string
      updatedContainers[index] = Object.entries(container)
        .map(([key, val]) => `${key}:${val}`)
        .join(", ");

      // Update the state with the new containers string
      setFormData((prevData) => {
        const newData = [...prevData];
        newData[formDataIndex].Containers = updatedContainers.join(";");
        return newData;
      });
    }
  };

  const handleOperationChange = (index, field, value) => {
    setFormData((prevData) => {
      const newData = [...prevData];
      const updatedContainer = { ...newData[formDataIndex].Operations[index] };
      updatedContainer[field] = value;
      newData[formDataIndex].Operations[index] = updatedContainer;
      return newData;
    });
  };

  // End Add Row Based

  const [vendors, setVendors] = useState([{}]);

  const addVendor = () => {
    setVendors([...vendors, {}]);
    setVendors([
      ...vendors,
      {
        Vendor: "",
        Detail: "",
        Amount: "",
        Currency: "",
      },
    ]);
  };

  const removeVendor = (indexToRemove) => {
    setVendors(vendors.filter((_, index) => index !== indexToRemove));
    setVendors(vendors.filter((_, index) => index !== indexToRemove));
  };

  // const handleVendorChange = (index, fieldName, value) => {
  //   const updatedVendors = [...vendors];
  //   updatedVendors[index] = {
  //     ...updatedVendors[index],
  //     [fieldName]: value,
  //   };
  //   setVendors(updatedVendors);

  //   const updatedJobVendor = [...jobVendor];
  //   updatedJobVendor[index] = {
  //     ...updatedJobVendor[index],
  //     [fieldName]: value,
  //   };
  //   setJobVendor(updatedJobVendor);
  //   setFormData((prevData) => {
  //     const newData = [...prevData];
  //     newData[formDataIndex] = { ...newData[formDataIndex], [name]: value };
  //     return newData;
  //   });
  // };

  const handleVendorChange = (index, fieldName, value, name) => {
    const updatedVendors = [...vendors];
    updatedVendors[index] = {
      ...updatedVendors[index],
      [fieldName]: value,
    };
    setVendors(updatedVendors);

    const updatedJobVendor = [...jobVendor];
    updatedJobVendor[index] = {
      ...updatedJobVendor[index],
      [fieldName]: value,
    };
    setJobVendor(updatedJobVendor);

    setFormData((prevData) => {
      const newData = [...prevData];
      newData[formDataIndex].vendors[index] = {
        ...newData[formDataIndex].vendors[index],
        [fieldName]: value,
      };
      return newData;
    });
  };
  console.log("Vendor Form Data Update", setFormData);

  const handleChange = (e, index) => {
    if (
      e?.name === "POL" ||
      e?.name === "POD" ||
      e?.name === "Sales" ||
      e?.name === "Shipper" ||
      e?.name === "Consignee" ||
      e?.name === "CompanyName" || //Client replace CompanyName
      e?.name === "Agent" ||
      e?.name === "PIC" ||
      e?.name === "Address" ||
      e?.name === "Client_email_id"
    ) {
      const { name, value } = e;
      console.log(name, value);
      setFormData((prevData) => {
        const newData = [...prevData];
        newData[formDataIndex] = { ...newData[formDataIndex], [name]: value };
        return newData;
      });

      // Remove error for field if it's been corrected
      if (errors[`${formDataIndex}-${name}`]) {
        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[`${formDataIndex}-${name}`];
          return updatedErrors;
        });
      }

      return;
    }

    const { name, value } = e.target;
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[formDataIndex] = { ...newData[formDataIndex], [name]: value };

      // Update Detention based on date difference
      const detention = calculateDetention();
      newData[formDataIndex].Detention = detention;

      // Recalculate DetentionNo if either Detention or Detention_By_Liner changes
      const detentionByLiner = newData[formDataIndex]?.Detention_By_Liner;
      newData[formDataIndex].DetentionNo = calculateDetentionNo(
        detention,
        detentionByLiner
      );

      const demurrage = calculateDemurrage();
      newData[formDataIndex].Demurrage = demurrage;

      const demurrageByLiner = newData[formDataIndex]?.Demurrage_By_Liner;
      newData[formDataIndex].DemurrageNo = calculateDemurrageNo(
        demurrage,
        demurrageByLiner
      );
      const portstorage = calculatePortStorageDate();
      newData[formDataIndex].Port_Storage = portstorage;

      const PortStorage = newData[formDataIndex]?.PortStorage;
      newData[formDataIndex].Port_Storage_Days = calculatePortStorageDays(
        portstorage,
        PortStorage
      );
      const combinedDD = calculateCombinedDetentionDemurrage();
      newData[formDataIndex].CombinedDetentionDemurrage = combinedDD;

      const combinedDDLiner =
        newData[formDataIndex]?.CombinedDetentionDemurrageLiner;
      newData[formDataIndex].NoOfDaysDetentionDemurrageOccuring =
        calculateDetentionDemurrageDays(combinedDD, combinedDDLiner);

      const destinationdetention = calculateDestinationDetention();
      newData[formDataIndex].DestinationDetention = destinationdetention;

      const destinationdetentionbyliner =
        newData[formDataIndex]?.DestinationDetentionByLiner;
      newData[formDataIndex].DestinationNoOfDetentionOccuring =
        calculateDestinationNoOfOccuring(
          destinationdetention,
          destinationdetentionbyliner
        );

      const destinationdemurrage = calculateDestinationDemurrage();
      newData[formDataIndex].DestinationDemurrage = destinationdemurrage;

      const destinationdemurragebyliner =
        newData[formDataIndex]?.DestinationDemurrageByLiner;
      newData[formDataIndex].DestinationNoOfDemurrageOccuring =
        calculateDestinationDemurrageOccuring(
          destinationdemurrage,
          destinationdemurragebyliner
        );

      const destinationportstorage = calculateDestinationPortStorage();
      newData[formDataIndex].DestinationPortStorage = destinationportstorage;

      const destinationportstoragegivenbyport =
        newData[formDataIndex]?.DestinationPortStorageGivenByPort;
      newData[formDataIndex].DestinationNoOfDaysPortStorageOccuring =
        calculateDestinationPortStorageOccuring(
          destinationportstorage,
          destinationportstoragegivenbyport
        );

      const destinationCombinedDD = calculateDestinationCombinedDD();
      newData[formDataIndex].DestinationCombinedDetentionDemurrage =
        destinationCombinedDD;

      const destinationCombinedDDbyliner =
        newData[formDataIndex]?.DestinationCombinedDetentionDemurrageByLiner;
      newData[formDataIndex].DestinationNoOfdaysDetentionDemurrageOccuring =
        calculateDestinationDDOccuring(
          destinationCombinedDD,
          destinationCombinedDDbyliner
        );

      return newData;
    });
  };

  // Validate all fields across all booking and main tabs
  const validateAllTabs = () => {
    let tabErrors = {};

    selectedData.forEach((_, bookingIndex) => {
      Object.keys(requiredFields).forEach((tabIndex) => {
        requiredFields[tabIndex].forEach((field) => {
          if (!formData[bookingIndex][field]) {
            tabErrors[`${bookingIndex}-${field}`] = `${field} is required`;
          }
        });
      });
    });

    return tabErrors;
  };

  // Find the first main tab and booking tab with an error
  const getFirstErrorTabIndex = (tabErrors) => {
    for (
      let bookingIndex = 0;
      bookingIndex < selectedData.length;
      bookingIndex++
    ) {
      for (let tabIndex in requiredFields) {
        if (
          requiredFields[tabIndex].some(
            (field) => tabErrors[`${bookingIndex}-${field}`]
          )
        ) {
          return { mainTab: parseInt(tabIndex), bookingTab: bookingIndex };
        }
      }
    }
    return { mainTab: 0, bookingTab: 0 };
  };

  const Pagerefresh = () => window.location.reload(true);
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData, "form");

    const tabErrors = validateAllTabs();

    if (Object.keys(tabErrors).length) {
      setErrors(tabErrors);

      // Find the first tab with an error
      const { mainTab, bookingTab } = getFirstErrorTabIndex(tabErrors);

      // Set active tabs and update formDataIndex
      setActiveTabIndex(mainTab);
      setActiveBookingTabIndex(bookingTab);
      setFormDataIndex(bookingTab);

      // Update region code based on booking tab in error
      handleGetUnallocatedBooking(bookingTab);

      return;
    }

    const { data } = await JobAPIInstance.post(
      `add_job?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    console.log("====================================");
    console.log(data);
    console.log("====================================");
    if (data.success) {
      toast({
        title: data.data.message,
        position: "bottom-center",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/operation_job");
    } else {
      toast({
        title: data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();
      const tabErrors = validateAllTabs();

      if (Object.keys(tabErrors).length) {
        setErrors(tabErrors);
        setActiveTabIndex(getFirstErrorTabIndex(tabErrors)); // Activate the first tab with errors

        // toast({
        //   title: "Please fill in the required fields",
        //   position: "bottom-center",
        //   status: "error",
        //   isClosable: true,
        //   containerStyle: {
        //     width: "98.5vw",
        //     maxWidth: "98.5vw",
        //     alignContent: "center",
        //   },
        // });
        return;
      }

      const { data } = await JobAPIInstance.post(
        `add_job?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const handleApply = () => {
    console.log("Selected Data:", selectedData);
  };
  // const handleGetUnallocatedBooking = (index) => {
  //   setFormDataIndex(index);
  //   setActiveBookingTabIndex(index); // Set the active booking tab index when clicked
  // };

  const handleGetUnallocatedBooking = (index) => {
    setFormDataIndex(index);
    setActiveBookingTabIndex(index);

    const bookingNumber = selectedData[index].BookingNumber;
    const regionCodeFromBooking = bookingNumber.slice(3, 5); // Extracts "DL" from "BHTDLDHIMAA6240002"

    const matchingRegion = storedUser.regions.find(
      (region) => region.regionCode === regionCodeFromBooking
    );

    if (matchingRegion) {
      setInitialRegionCode(matchingRegion.regionCode);
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          regionCode: matchingRegion.regionCode,
        };
        return updatedFormData;
      });
    }
  };

  console.log(formData, "formData");

  const calculateEtaminus = () => {
    const etaDateString = formData[formDataIndex]?.ETA;

    // Return an empty string if etaDateString is not provided or empty
    if (!etaDateString) return "0";

    const etaDate = new Date(etaDateString);

    // Check if etaDate is a valid date
    if (isNaN(etaDate.getTime())) return "0";

    const days = 12;
    const millisecondsInADay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const etaminusDate = new Date(
      etaDate.getTime() - days * millisecondsInADay
    );

    // Format the date as "YYYY-MM-DD"
    const formattedEtaminusDate = etaminusDate.toISOString().split("T")[0];
    return formattedEtaminusDate;
  };
  useEffect(() => {
    const etaminusDate = calculateEtaminus();

    setFormData((prevData) => {
      const newData = [...prevData];
      newData[formDataIndex] = {
        ...newData[formDataIndex],
        OperationTranferDeadline: etaminusDate,
      };
      return newData;
    });
  }, [formData, formDataIndex]);

  useEffect(() => {
    const defaultJobStatus = "Inprogress"; // Define the default Job_Status

    setFormData((prevData) => {
      const newData = [...prevData];
      newData[formDataIndex] = {
        ...newData[formDataIndex],
        Job_Status: defaultJobStatus,
      };
      return newData;
    });
  }, [formDataIndex]);

  const calculateDetention = () => {
    const ladenGateInDate = new Date(formData[formDataIndex]?.LadenGateIndate);
    const emptyPickupDate = new Date(formData[formDataIndex]?.EmptyPickUpDate);

    if (
      !ladenGateInDate ||
      !emptyPickupDate ||
      isNaN(ladenGateInDate) ||
      isNaN(emptyPickupDate)
    ) {
      return 0;
    }

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      Math.abs((ladenGateInDate - emptyPickupDate) / oneDay)
    );
    return diffDays;
  };

  const calculateDemurrage = () => {
    const VesselArrivalDate = new Date(
      formData[formDataIndex]?.VesselArrivalDate
    );
    const ladenGateInDate = new Date(formData[formDataIndex]?.LadenGateIndate);

    if (
      !ladenGateInDate ||
      !VesselArrivalDate ||
      isNaN(ladenGateInDate) ||
      isNaN(VesselArrivalDate)
    ) {
      return 0;
    }

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      Math.abs((VesselArrivalDate - ladenGateInDate) / oneDay)
    );
    return diffDays;
  };

  const calculateDetentionNo = (detention, detentionByLiner) => {
    return (Number(detention) || 0) - (Number(detentionByLiner) || 0);
  };

  const calculateDemurrageNo = (demurrage, demurrageByLiner) => {
    return (Number(demurrage) || 0) - (Number(demurrageByLiner) || 0);
  };
  const calculateDetentionDemurrageDays = (combinedDD, combinedDDLiner) => {
    return (Number(combinedDD) || 0) - (Number(combinedDDLiner) || 0);
  };

  const calculatePortStorageDate = () => {
    const vesselarrivalDate = new Date(
      formData[formDataIndex]?.VesselArrivalDate
    );
    const ladenGateInDate = new Date(formData[formDataIndex]?.LadenGateIndate);

    if (
      !ladenGateInDate ||
      !vesselarrivalDate ||
      isNaN(ladenGateInDate) ||
      isNaN(vesselarrivalDate)
    ) {
      return 0;
    }

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      Math.abs((vesselarrivalDate - ladenGateInDate) / oneDay)
    );
    return diffDays;
  };

  const calculatePortStorageDays = (portstorage, PortStorage) => {
    return (Number(portstorage) || 0) - (Number(PortStorage) || 0);
  };

  const calculateCombinedDetentionDemurrage = () => {
    const vesselArrivalDate = new Date(
      formData[formDataIndex]?.VesselArrivalDate
    );
    const emptyPickUpDate = new Date(formData[formDataIndex]?.EmptyPickUpDate);

    if (
      !emptyPickUpDate ||
      !vesselArrivalDate ||
      isNaN(emptyPickUpDate) ||
      isNaN(vesselArrivalDate)
    ) {
      return 0;
    }

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const diffDays = Math.round(
      Math.abs((vesselArrivalDate - emptyPickUpDate) / oneDay)
    );
    return diffDays;
  };

  // Destination

  const calculateDestinationDetention = () => {
    const emptyReturnDate = new Date(formData[formDataIndex]?.EmptyReturnDate);
    const portoutdate = new Date(formData[formDataIndex]?.PortOutDate);

    if (
      !emptyReturnDate ||
      !portoutdate ||
      isNaN(emptyReturnDate) ||
      isNaN(portoutdate)
    ) {
      return 0;
    }
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round(
      Math.abs((portoutdate - emptyReturnDate) / oneDay)
    );
    return diffDays;
  };

  const calculateDestinationDemurrage = () => {
    const portoutdate = new Date(formData[formDataIndex]?.PortOutDate);
    const vesselArrivalDate = new Date(
      formData[formDataIndex]?.VesselArrivalDate
    );
    if (
      !portoutdate ||
      !vesselArrivalDate ||
      isNaN(portoutdate) ||
      isNaN(vesselArrivalDate)
    ) {
      return 0;
    }
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round(
      Math.abs((portoutdate - vesselArrivalDate) / oneDay)
    );
    return diffDays;
  };

  const calculateDestinationNoOfOccuring = (
    destinationdetention,
    destinationdetentionbyliner
  ) => {
    return (
      (Number(destinationdetention) || 0) -
      (Number(destinationdetentionbyliner) || 0)
    );
  };

  const calculateDestinationDemurrageOccuring = (
    destinationdemurrage,
    destinationdemurragebyliner
  ) => {
    return (
      (Number(destinationdemurrage) || 0) -
      (Number(destinationdemurragebyliner) || 0)
    );
  };

  const calculateDestinationPortStorage = () => {
    const portoutdate = new Date(formData[formDataIndex]?.PortOutDate);
    const vesselArrivalDate = new Date(
      formData[formDataIndex]?.VesselArrivalDate
    );
    if (
      !portoutdate ||
      !vesselArrivalDate ||
      isNaN(portoutdate) ||
      isNaN(vesselArrivalDate)
    ) {
      return 0;
    }
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round(
      Math.abs((portoutdate - vesselArrivalDate) / oneDay)
    );
    return diffDays;
  };

  const calculateDestinationPortStorageOccuring = (
    destinationportstorage,
    destinationportstoragegivenbyport
  ) => {
    return (
      (Number(destinationportstorage) || 0) -
      (Number(destinationportstoragegivenbyport) || 0)
    );
  };

  const calculateDestinationCombinedDD = () => {
    const emptyReturnDate = new Date(formData[formDataIndex]?.EmptyReturnDate);
    const vesselArrivalDate = new Date(
      formData[formDataIndex]?.VesselArrivalDate
    );
    if (
      !emptyReturnDate ||
      !vesselArrivalDate ||
      isNaN(emptyReturnDate) ||
      isNaN(vesselArrivalDate)
    ) {
      return 0;
    }
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round(
      Math.abs((emptyReturnDate - vesselArrivalDate) / oneDay)
    );
    return diffDays;
  };

  const calculateDestinationDDOccuring = (
    destinationCombinedDD,
    destinationCombinedDDbyliner
  ) => {
    return (
      (Number(destinationCombinedDD) || 0) -
      (Number(destinationCombinedDDbyliner) || 0)
    );
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const {
    isOpen: isOpenCancelBooking,
    onOpen: onOpenCancelBooking,
    onClose: onCloseCancelBooking,
  } = useDisclosure();

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 10,
  };

  const style = {
    border: "1px solid #ddd",
    backgroundColor: "white",
    zIndex: 20,
  };

  const closeModal = {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
  };

  const handleConfirmCancel = () => {
    navigate("/operation_job");
    onCloseCancelBooking();
  };

  return (
    <>
      <Header />
      <form onKeyDown={handleKeyDown} w={100} size="md">
        <div
          className="top-bar bg-blue-950  text-white  dark:bg-indigo-900"
          style={{
            position: "sticky",
            top: "0",
            zIndex: "100",
          }}
        >
          {/* <OperationSidebar
          size={20}
        /> */}
          <p className="float-left py-1 px-2 rounded my-2">Create Jobs</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-3 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={handleSubmitNext}
          >
            Save & Next
          </button>
          {/* <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/operation_job");
            }}
            disabled={!submitAllowed}
          >
            Cancel
          </button> */}
          <div
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950 cursor-pointer"
            // onClick={() => {
            //   navigate("/booking");
            // }}
            onClick={onOpenCancelBooking}
            disabled={!submitAllowed}
          >
            Cancel
          </div>
          <AddUnallocatedJob
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            onApply={handleApply}
            setUnallocatedBookingState={setUnallocatedBookingState}
            setFormData={setFormData}
            formData={formData}
          />
        </div>
        &nbsp;
        {/* <Tabs variant='soft-rounded' style={{ backgroundColor: "bg-indigo-900" }} > */}
        <Tabs
          index={activeBookingTabIndex}
          onChange={setActiveBookingTabIndex}
          w="100%"
          variant="enclosed"
        >
          <TabList>
            {selectedData.map((unallocatedbooking, index) => (
              <Tab
                key={index}
                onClick={() => handleGetUnallocatedBooking(index)}
                _selected={{ color: "white", bg: "#EA7600" }}
              >
                {unallocatedbooking.BookingNumber}
              </Tab>
            ))}
          </TabList>
        </Tabs>
        <Tabs
          index={activeTabIndex}
          onChange={setActiveTabIndex}
          w="100%"
          variant="enclosed"
        >
          &nbsp;
          <TabList>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Job Information
            </Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Cargo Details
            </Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Liner Details
            </Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Operation Details
            </Tab>
            {/* <Tab _selected={{ color: 'white', bg: '#172554' }}>Additional Charges</Tab> */}
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Detention & Demurrage
            </Tab>
            {/* <Tab _selected={{ color: "white", bg: "#172554" }}>
              Current Status & Notes
            </Tab> */}
            {/* <Tab _selected={{ color: 'white', bg: '#172554' }}></Tab> */}
            {/* <Tab _selected={{ color: 'white', bg: '#172554' }}>Documents</Tab>
          <Tab _selected={{ color: 'white', bg: '#172554' }}>Vendor</Tab>
          <Tab _selected={{ color: 'white', bg: '#172554' }}>Checklist</Tab>
          <Tab _selected={{ color: 'white', bg: '#172554' }}>Activitylog</Tab> */}
          </TabList>
          <TabPanels>
            <TabPanel>
              {/* Shippment  */}
              <div className="content">
                <div className="form-user">
                  <div className="user-job">
                    <div className="input-box">
                      <span className="job">Select Region</span>
                      {storedUser.regions.length > 1 ? (
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="regionCode"
                          value={
                            formData[formDataIndex]?.regionCode ||
                            initialRegionCode
                          }
                          onChange={handleChangeRegionCode}
                        >
                          {storedUser.regions.map((region, index) => (
                            <option key={index} value={region.regionCode}>
                              {region.user_region} - {region.regionCode}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="regionCode"
                          value={
                            formData[formDataIndex]?.regionCode ||
                            initialRegionCode
                          }
                          readOnly
                        />
                      )}
                    </div>
                    <div className="input-box">
                      <span className="job">
                        Product<span style={{ color: "red" }}>*</span>
                      </span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Product"
                        value={formData[formDataIndex]?.Product} //[formDataIndex]
                        onChange={handleChange}
                        placeholder="Enter your Product"
                      >
                        <option>Select Product</option>
                        {productData.map((item) => (
                          <option value={item.Product}>{item.Product}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">Place of Receipt</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="PlaceOfReceipt"
                        value={formData[formDataIndex]?.PlaceOfReceipt} //[formDataIndex]
                        onChange={handleChange}
                        placeholder="Enter your place of receipt"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Place Of Delivery</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="PlaceOfDelivery"
                        value={formData[formDataIndex]?.PlaceOfDelivery} //[formDataIndex]
                        onChange={handleChange}
                        placeholder="Enter your place of delivery"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Shipment Handled By</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="ShipmentHandledBy"
                        value={formData[formDataIndex]?.ShipmentHandledBy} //[formDataIndex]
                        onChange={handleChange}
                        placeholder="Enter your shipment handled by"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Job Status</span>
                      {/* <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="JobStatus"
                      value={formData[formDataIndex]?.JobStatus}
                      onChange={handleChange}
                      placeholder="Enter your job status"
                    /> */}
                      {/* <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Job_Status"
                        value={formData[formDataIndex]?.Job_Status}
                        onChange={handleChange}
                      >
                        <option value="Inprogress">Inprogress</option>
                        <option value="Completed">Completed</option>
                        <option value="Hold">Hold</option>
                        <option value="Cancelled">Cancelled</option>
                      </select> */}
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        name="Job_Status"
                        value={formData[formDataIndex]?.Job_Status}
                        onChange={handleChange}
                      >
                        {jobStatusOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* <div className="input-box">
                    <span className="job">
                      Job Number
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="JobNumber"
                      value={formData[formDataIndex]?.JobNumber}
                      onChange={handleChange}
                      placeholder="Enter your job number"
                    />
                  </div> */}

                    <div className="input-box">
                      <span className="job">Date Of Business Confirmed</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="DateOfBusinessConfirmed"
                        value={formData[formDataIndex]?.DateOfBusinessConfirmed}
                        onChange={handleChange}
                        placeholder="Enter your date of business confirmed"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        POL<span style={{ color: "red" }}>*</span>
                      </span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="POL"
                        value={formData[formDataIndex]?.POL}
                        onChange={handleChange}
                        placeholder="Enter your pol"
                      /> */}
                      {/* <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="POL"
                        value={formData[formDataIndex]?.POL}
                        onChange={handleChange}
                        placeholder="Enter your pol"
                      >
                        <option>Select Pol</option>
                        {portData.map((item) => (
                          <option value={item.Port}>{item.Port}</option>
                        ))}
                      </select> */}
                      <SearchSelect
                        name="POL"
                        value={formData[formDataIndex]?.POL}
                        handleChange={handleChange}
                        placeholder="Select Pol"
                        colourOptions={portData.map((item) => {
                          return {
                            value: item.Port,
                            label: item.Port,
                            name: "POL",
                          };
                        })}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        POD<span style={{ color: "red" }}>*</span>
                      </span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="POD"
                        value={formData[formDataIndex]?.POD}
                        onChange={handleChange}
                        placeholder="Enter your pod"
                      /> */}
                      {/* <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="POD"
                        value={formData[formDataIndex]?.POD}
                        onChange={handleChange}
                        placeholder="Enter your pod"
                      >
                        <option>Select Pod</option>
                        {portData.map((item) => (
                          <option value={item.Port}>{item.Port}</option>
                        ))}
                      </select> */}
                      <SearchSelect
                        name="POD"
                        value={formData[formDataIndex]?.POD}
                        handleChange={handleChange}
                        placeholder="Select Pod"
                        colourOptions={portData.map((item) => {
                          return {
                            value: item.Port,
                            label: item.Port,
                            name: "POD",
                          };
                        })}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Shipper</span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Shipper"
                        value={formData[formDataIndex]?.Shipper}
                        onChange={handleChange}
                        placeholder="Enter your shipper"
                      /> */}
                      <SearchSelect
                        name="Shipper"
                        value={formData[formDataIndex]?.Shipper}
                        handleChange={handleChange}
                        placeholder="Select Shipper"
                        colourOptions={customerData.map((item) => {
                          return {
                            value: item.Company,
                            label: item.Company,
                            name: "Shipper",
                          };
                        })}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Consignee</span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Consignee"
                        value={formData[formDataIndex]?.Consignee}
                        onChange={handleChange}
                        placeholder="Enter your consignee"
                      /> */}
                      <SearchSelect
                        name="Consignee"
                        value={formData[formDataIndex]?.Consignee}
                        handleChange={handleChange}
                        placeholder="Select Consignee"
                        colourOptions={customerData.map((item) => {
                          return {
                            value: item.Company,
                            label: item.Company,
                            name: "Consignee",
                          };
                        })}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        Client<span style={{ color: "red" }}>*</span>
                      </span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Client"
                        value={formData[formDataIndex]?.Client}
                        onChange={handleChange}
                        placeholder="Enter your client"
                      /> */}
                      <SearchSelect
                        name="CompanyName"
                        value={formData[formDataIndex]?.CompanyName}
                        handleChange={handleChange}
                        placeholder="Select Client"
                        colourOptions={customerData.map((item) => {
                          return {
                            value: item.Company,
                            label: item.Company,
                            name: "CompanyName",
                          };
                        })}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        Client E-Mail Id<span style={{ color: "red" }}>*</span>
                      </span>
                      {/* <SearchSelect
                        name="client_email_id"
                        value={formData[formDataIndex]?.client_email_id}
                        handleChange={handleChange}
                        placeholder="Select client mail id"
                        colourOptions={customerData.map((item) => {
                          return {
                            value: item.client_email_id,
                            label: item.client_email_id,
                            name: "client_email_id",
                          };
                        })}
                      /> */}
                      <SearchSelect
                        name="Client_email_id"
                        value={formData[formDataIndex]?.Client_email_id}
                        handleChange={handleChange}
                        placeholder="Select client mail id"
                        colourOptions={filteredEmails}
                      />
                      {errors[`${formDataIndex}-Client_email_id`] && (
                        <p style={{ color: "red" }}>
                          {errors[`${formDataIndex}-Client_email_id`]}
                        </p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="job">Agent</span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Agent"
                        value={formData[formDataIndex]?.Agent}
                        onChange={handleChange}
                        placeholder="Enter your agent"
                      /> */}
                      <SearchSelect
                        name="Agent"
                        value={formData[formDataIndex]?.Agent}
                        handleChange={handleChange}
                        placeholder="Select Agent"
                        colourOptions={customerData.map((item) => {
                          return {
                            value: item.Company,
                            label: item.Company,
                            name: "Agent",
                          };
                        })}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Quote Number</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="QuotationNumber"
                        value={formData[formDataIndex]?.QuotationNumber}
                        onChange={handleChange}
                        placeholder="Enter your quote number"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Update E-Mail Id</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Client_email_id"
                        value={formData[formDataIndex]?.Client_email_id}
                        onChange={handleChange}
                        placeholder="Enter your update email"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Scope</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Scope"
                        value={formData[formDataIndex]?.Scope}
                        onChange={handleChange}
                        placeholder="Enter your Scope"
                      >
                        <option>Select Scope</option>
                        {scopeData.map((item) => (
                          <option value={item.Scope}>{item.Scope}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">Region</span>
                      {/* {console.log(formData[formDataIndex])} */}
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Region"
                        value={formData[formDataIndex]?.Region}
                        onChange={handleChange}
                        placeholder="Enter your Region"
                      >
                        <option>Select Internal Region</option>
                        {regionData.map((item) => (
                          <option key={item.Region} value={item.Region}>
                            {item.Region}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="input-box">
                      <span className="job">Sales</span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Sales"
                        value={formData[formDataIndex]?.Sales}
                        onChange={handleChange}
                        placeholder="Enter your sales"
                      /> */}
                      <SearchSelect
                        name="Sales"
                        value={formData[formDataIndex]?.Sales}
                        handleChange={handleChange}
                        placeholder="Select Sales"
                        colourOptions={salesPersonData.map((item) => {
                          return {
                            value: item.fullname,
                            label: item.fullname,
                            name: "Sales",
                          };
                        })}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Incoterm</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Incoterm"
                        value={formData[formDataIndex]?.Incoterm}
                        onChange={handleChange}
                        placeholder="Enter your Product"
                      >
                        <option>Select Incoterm</option>
                        {incotermData.map((item) => (
                          <option value={item.Incoterm}>{item.Incoterm}</option>
                        ))}
                      </select>
                    </div>
                    <div className="input-box">
                      <span className="job">Notes</span>
                      <textarea
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Notes"
                        value={formData[formDataIndex]?.Notes}
                        onChange={handleChange}
                        placeholder="Enter your notes"
                      />
                    </div>
                    <div className="input-box">
                      <span className="job">Current Status</span>
                      <textarea
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Current_Status"
                        value={formData[formDataIndex]?.Current_Status}
                        onChange={handleChange}
                        placeholder="Enter your current status"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End  Shippment Info*/}
            </TabPanel>
            <TabPanel>
              {/* Cargo */}
              <div className="content">
                <div className="form-user">
                  <div className="user-job">
                    <div className="input-box">
                      <span className="job">
                        Cargo<span style={{ color: "red" }}>*</span>
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Cargo"
                        value={formData[formDataIndex]?.Cargo}
                        onChange={handleChange}
                        placeholder="Enter your cargo"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Cargo Nature</span>
                      <textarea
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="CargoNature"
                        value={formData[formDataIndex]?.CargoNature}
                        onChange={handleChange}
                        placeholder="Enter your cargo nature"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">HS Code</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="HsCode"
                        value={formData[formDataIndex]?.HsCode}
                        onChange={handleChange}
                        placeholder="Enter your hs code"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End Cargo */}
              {/* Table Conatiner */}
              <div className="table-container">
                <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                  <thead className="flex-nowrap text-center bg-blue-950 text-white">
                    <tr>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Container Type
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        QTY
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Weight Per container(KG)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Dimension(LXWXH IN M)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData[formDataIndex]?.Containers?.split(";")
                      ?.join("")
                      ?.trim() !== "" &&
                      formData[formDataIndex]?.Containers?.split(";").map(
                        (containerString, index) => {
                          // Convert container string into an object
                          if (!containerString.trim()) return null;
                          const container = {};
                          containerString.split(",").forEach((pair) => {
                            const [key, value] = pair.split(":");
                            container[key?.trim()] = value?.trim();
                          });
                          return (
                            <tr key={index}>
                              <td>
                                <Select
                                  w="190px"
                                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                  type="text"
                                  name="ContainerType"
                                  value={container.ContainerType || ""}
                                  onChange={(e) =>
                                    handleContainerChange(
                                      index,
                                      "ContainerType",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option>Select Equipment</option>
                                  {equipmentData.map((item, idx) => (
                                    <option
                                      key={idx}
                                      value={item.ContainerType}
                                    >
                                      {item.ContainerType}
                                    </option>
                                  ))}
                                </Select>
                              </td>
                              <td>
                                <Input
                                  w="150px"
                                  type="text"
                                  name="Quantity"
                                  value={container.Quantity || ""}
                                  onChange={(e) =>
                                    handleContainerChange(
                                      index,
                                      "Quantity",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  w="150px"
                                  type="text"
                                  name="Weight"
                                  value={container.Weight || ""}
                                  onChange={(e) =>
                                    handleContainerChange(
                                      index,
                                      "Weight",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  w="150px"
                                  type="text"
                                  name="Dimensions"
                                  value={container.Dimensions || ""}
                                  onChange={(e) =>
                                    handleContainerChange(
                                      index,
                                      "Dimensions",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <IoMdAddCircle
                                  className="cursor-pointer"
                                  size={30}
                                  style={{ marginLeft: "5px", color: "green" }}
                                  onClick={addContainer}
                                />
                                {formData[formDataIndex]?.Containers?.split(";")
                                  .length > 2 && (
                                  <BiSolidMinusCircle
                                    className="cursor-pointer"
                                    size={30}
                                    style={{ marginLeft: "5px", color: "red" }}
                                    onClick={() => removeContainer(index)}
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
              <br />
            </TabPanel>

            <TabPanel>
              {/* Liner start */}
              <div className="content">
                <div className="form-user">
                  <div className="user-job">
                    <div className="input-box">
                      <span className="job">Shipping Line / NVOCC / LCL</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="ShippingLine"
                        value={formData[formDataIndex]?.ShippingLine}
                        onChange={handleChange}
                        placeholder="Enter your Shipping Line"
                      >
                        <option>Select Shipping Line</option>
                        {shipmentLineData.map((item) => (
                          <option value={item.ShippingLine}>
                            {item.ShippingLine}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">Vessel Name & Voy</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="VesselNameVoyage"
                        value={formData[formDataIndex]?.VesselNameVoyage}
                        onChange={handleChange}
                        placeholder="Enter your vessel name"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">CY Open</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="CyOpen"
                        value={formData[formDataIndex]?.CyOpen}
                        onChange={handleChange}
                        placeholder="Enter your cy open"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">SI Cutt Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="SICutOff"
                        value={
                          formData[formDataIndex]?.SICutOff
                            ? formData[formDataIndex]?.SICutOff.split("T")[0]
                            : ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your si cut off"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">VGM Cut Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="VGMCutOff"
                        value={
                          formData[formDataIndex]?.VGMCutOff
                            ? formData[formDataIndex]?.VGMCutOff.split("T")[0]
                            : ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your vgm cutt off"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">CY Cut Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="CYCutOff"
                        value={
                          formData[formDataIndex]?.CYCutOff
                            ? formData[formDataIndex]?.CYCutOff.split("T")[0]
                            : ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your cy cut off"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Customs Cut Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="CustomsCuttOff"
                        value={formData[formDataIndex]?.CustomsCuttOff}
                        onChange={handleChange}
                        placeholder="Enter your customs cutt off"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">OOG Cut Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="OOGCuttOff"
                        value={formData[formDataIndex]?.OOGCuttOff}
                        onChange={handleChange}
                        placeholder="Enter your oog cutt off"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">ETD</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ETD"
                        value={
                          formData[formDataIndex]?.ETD
                            ? formData[formDataIndex]?.ETD.split("T")[0]
                            : ""
                        }
                        min={new Date().toISOString().split("T")[0]}
                        onChange={handleChange}
                        placeholder="Enter your ETD"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">ETA</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ETA"
                        value={
                          formData[formDataIndex]?.ETA
                            ? formData[formDataIndex]?.ETA.split("T")[0]
                            : ""
                        }
                        min={new Date().toISOString().split("T")[0]}
                        onChange={handleChange}
                        placeholder="Enter your ETA"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Free Days At Origin</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="FreeDaysAtOrigin"
                        value={formData[formDataIndex]?.FreeDaysAtOrigin}
                        onChange={handleChange}
                        placeholder="Enter your free days at origin"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Free Days At Destination</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="FreeDaysAtDestination"
                        value={formData[formDataIndex]?.FreeDaysAtDestination}
                        onChange={handleChange}
                        placeholder="Enter your free days at destination"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Freight</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Freight"
                        value={formData[formDataIndex]?.Freight}
                        onChange={handleChange}
                      >
                        <option>Select Freight Type</option>
                        <option value="Prepaid">Prepaid</option>
                        <option value="Collect">Collect</option>
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">MBL Type</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="MBLType"
                        value={formData[formDataIndex]?.MBLType}
                        onChange={handleChange}
                      >
                        <option>Select MBL Type</option>
                        <option value="Original">Original</option>
                        <option value="Surrender">Surrender</option>
                        <option value="Seaway">Seaway</option>
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">HBL Type</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="HBLType"
                        value={formData[formDataIndex]?.HBLType}
                        onChange={handleChange}
                      >
                        <option>Select HBL Type</option>
                        <option value="Not Involved">Not Involved</option>
                        <option value="Original">Original</option>
                        <option value="Surrender">Surrender</option>
                        <option value="Seaway">Seaway</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Liner */}
              <br />
            </TabPanel>

            <TabPanel>
              {/* Operation start */}
              <div className="content">
                <div className="form-user">
                  <div className="user-job">
                    <div className="input-box">
                      <span className="job">Liner Booking Number</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="LinerBookingNumber"
                        value={formData[formDataIndex]?.LinerRefNumber}
                        onChange={handleChange}
                        placeholder="Enter your liner booking number"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">MBL Number</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="MBLNumber"
                        value={formData[formDataIndex]?.MBLNumber}
                        onChange={handleChange}
                        placeholder="Enter your MBL number"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">HBL Number</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="HBLNumber"
                        value={formData[formDataIndex]?.BookingNumber}
                        onChange={handleChange}
                        placeholder="Enter your HBL number"
                        disabled
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Cargo PickUp Date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="CargoPickUpDate"
                        value={formData[formDataIndex]?.CargoPickUpDate}
                        onChange={handleChange}
                        placeholder="Enter your cargo pick up date"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Empty PickUp date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="EmptyPickUpDate"
                        value={formData[formDataIndex]?.EmptyPickUpDate}
                        onChange={handleChange}
                        placeholder="Enter your empty pickup date"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Stuffing Date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="Stuffingdate"
                        value={formData[formDataIndex]?.Stuffingdate}
                        onChange={handleChange}
                        placeholder="Enter your stuffing date"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Laden Gate In Date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="LadenGateIndate"
                        value={formData[formDataIndex]?.LadenGateIndate}
                        onChange={handleChange}
                        placeholder="Enter your laden gate in date"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Opertion Tranfer Deadline</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="OperationTranferDeadline"
                        value={
                          formData[formDataIndex]?.OperationTranferDeadline ||
                          calculateEtaminus()
                        }
                        onChange={handleChange}
                        placeholder="Enter your operation transfer deadline"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <TabPanel>
                {/* Additional Charges */}

                <div className="table-container">
                  <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                    <thead className="flex-nowrap text-center bg-blue-950 text-white">
                      <tr>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Vendor
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Additional Charges Details
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Currency / Amount
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {vendors?.map((vendoritem, index) => (
                        <tr key={index}>
                          <td>
                            {/* <Input
                              type="text"
                              name="Vendor"
                              value={vendoritem.Vendor || ""}
                              onChange={(e) =>
                                handleVendorChange(
                                  index,
                                  "Vendor",
                                  e.target.value,
                                  "vendors"
                                )
                              }
                              placeholder="Enter your vendor"
                            /> */}
                            <Select
                              width="190px"
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                              type="text"
                              name="Vendor"
                              value={vendoritem.Vendor || ""}
                              onChange={(e) =>
                                handleVendorChange(
                                  index,
                                  "Vendor",
                                  e.target.value,
                                  "vendors"
                                )
                              }
                            >
                              <option>Select Vendor</option>
                              {customerData.map((item) => (
                                <option value={item.Company}>
                                  {item.Company}
                                </option>
                              ))}
                            </Select>
                          </td>
                          <td>
                            <Textarea
                              type="text"
                              name="Detail"
                              value={vendoritem.Detail || ""}
                              onChange={(e) =>
                                handleVendorChange(
                                  index,
                                  "Detail",
                                  e.target.value,
                                  "vendors"
                                )
                              }
                            />
                          </td>
                          <td>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Select
                                width="300px"
                                name="Currency"
                                value={vendoritem.Currency || ""}
                                onChange={(e) =>
                                  handleVendorChange(
                                    index,
                                    "Currency",
                                    e.target.value,
                                    "Currency"
                                  )
                                }
                              >
                                <option>Select Currency</option>
                                {currencyData.map((item) => (
                                  <option
                                    key={`${item.Currency}`}
                                    value={`${item.Currency}`}
                                  >
                                    {item.Currency}
                                  </option>
                                ))}
                              </Select>

                              <Input
                                width="150px"
                                type="text"
                                name="Amount"
                                value={vendoritem.Amount || ""}
                                onChange={(e) =>
                                  handleVendorChange(
                                    index,
                                    "Amount",
                                    e.target.value,
                                    "vendors"
                                  )
                                }
                                style={{ marginLeft: "10px" }}
                              />
                            </div>
                          </td>

                          <td>
                            {vendors.length > 1 && (
                              // <Button colorScheme='red' size='sm' float="left" marginLeft="5px" >
                              //   Remove
                              // </Button>
                              <BiSolidMinusCircle
                                className="cursor-pointer"
                                size={30}
                                style={{ marginTop: "15px", color: "red" }}
                                onClick={() => removeVendor(index)}
                              />
                            )}
                            {/* <Button colorScheme='teal' size='sm' float="left" marginLeft="5px" >
                              Add More
                            </Button> */}
                            <IoMdAddCircle
                              className="cursor-pointer"
                              size={30}
                              style={{ marginTop: "15px", color: "green" }}
                              onClick={addVendor}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <br />
              </TabPanel>

              {/* Table Container */}
              <div className="table-container">
                <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                  <thead className="flex-nowrap text-center bg-blue-950 text-white">
                    <tr>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Conatiner Type
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Container Number
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Seal Number
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Number of Packages
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Type Of Package
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Net Weight (kgs)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Gross Weight (kgs)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Volume (m3)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Tare Weight (kgs)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        VGM Weight (kgs)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        VGM Type
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ overflow: "auto" }}>
                    {/* Quantity based */}
                    {/* {formData[formDataIndex]?.Containers?.split(";")?.map(
                      (containerString, containerIndex) => {
                        if (!containerString.trim()) return null;

                        const container = {};
                        containerString.split(",").forEach((pair) => {
                          const [key, value] = pair.split(":");
                          container[key?.trim()] = value?.trim();
                        });

                        // Create rows based on the Quantity value
                        const quantity = parseInt(container.Quantity || 1, 10);
                        const rows = Array.from({ length: quantity });

                        return rows.map((_, rowIndex) => (
                          <tr key={`${containerIndex}-${rowIndex}`}>
                            <td>
                              <Select
                                width="190px"
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                type="text"
                                name="ContainerType"
                                value={container.ContainerType || ""}
                                disabled
                              >
                                <option>Select Equipment</option>
                                {equipmentData.map((item, idx) => (
                                  <option key={idx} value={item.ContainerType}>
                                    {item.ContainerType}
                                  </option>
                                ))}
                              </Select>
                            </td>
                            <td>
                              <Input
                                w="150px"
                                type="text"
                                name={`ContainerNumber-${rowIndex}`}
                                value={
                                  formData[formDataIndex]?.Operations[
                                    containerIndex
                                  ]?.[rowIndex]?.ContainerNumber || ""
                                }
                                onChange={(e) =>
                                  handleOperationChange(
                                    containerIndex,
                                    rowIndex,
                                    "ContainerNumber",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter container number"
                              />
                            </td>
                            <td>
                              <Input
                                w="150px"
                                type="text"
                                name={`SealNumber-${rowIndex}`}
                                value={
                                  formData[formDataIndex]?.Operations[
                                    containerIndex
                                  ]?.[rowIndex]?.SealNumber || ""
                                }
                                onChange={(e) =>
                                  handleOperationChange(
                                    containerIndex,
                                    rowIndex,
                                    "SealNumber",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter seal number"
                              />
                            </td>

                            <td>
                              <Input
                                w="270px"
                                type="text"
                                name={`No_Of_Pkge-${rowIndex}`}
                                value={
                                  formData[formDataIndex]?.Operations[
                                    containerIndex
                                  ]?.[rowIndex]?.No_Of_Pkge || ""
                                }
                                onChange={(e) =>
                                  handleOperationChange(
                                    containerIndex,
                                    rowIndex,
                                    "No_Of_Pkge",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter your number of packages"
                              />
                            </td>

                            <td>
                              <Select
                                width="190px"
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                name={`Type_Of_Pkge-${rowIndex}`}
                                value={
                                  formData[formDataIndex]?.Operations[
                                    containerIndex
                                  ]?.[rowIndex]?.Type_Of_Pkge || ""
                                }
                                onChange={(e) =>
                                  handleOperationChange(
                                    containerIndex,
                                    rowIndex,
                                    "Type_Of_Pkge",
                                    e.target.value
                                  )
                                }
                              >
                                <option>Select Package Type</option>
                                {packageTypeData.map((item, idx) => (
                                  <option key={idx} value={item.packingType}>
                                    {item.packingType}
                                  </option>
                                ))}
                              </Select>
                            </td>

                            <td>
                              <Input
                                w="150px"
                                type="text"
                                name={`NtWeight-${rowIndex}`}
                                value={
                                  formData[formDataIndex]?.Operations[
                                    containerIndex
                                  ]?.[rowIndex]?.NtWeight || ""
                                }
                                onChange={(e) =>
                                  handleOperationChange(
                                    containerIndex,
                                    rowIndex,
                                    "NtWeight",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter net weight"
                              />
                            </td>
                            <td>
                              <Input
                                w="150px"
                                type="text"
                                name={`GrWeight-${rowIndex}`}
                                value={
                                  formData[formDataIndex]?.Operations[
                                    containerIndex
                                  ]?.[rowIndex]?.GrWeight || ""
                                }
                                onChange={(e) =>
                                  handleOperationChange(
                                    containerIndex,
                                    rowIndex,
                                    "GrWeight",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter gross weight"
                              />
                            </td>

                            <td>
                              <Input
                                w="270px"
                                type="text"
                                name={`Volume-${rowIndex}`}
                                value={
                                  formData[formDataIndex]?.Operations[
                                    containerIndex
                                  ]?.[rowIndex]?.Volume || ""
                                }
                                onChange={(e) =>
                                  handleOperationChange(
                                    containerIndex,
                                    rowIndex,
                                    "Volume",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter your volume"
                              />
                            </td>
                            <td>
                              <Input
                                w="270px"
                                type="text"
                                name={`TareWeight-${rowIndex}`}
                                value={
                                  formData[formDataIndex]?.Operations[
                                    containerIndex
                                  ]?.[rowIndex]?.TareWeight || ""
                                }
                                onChange={(e) =>
                                  handleOperationChange(
                                    containerIndex,
                                    rowIndex,
                                    "TareWeight",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter your tare weight"
                              />
                            </td>
                            <td>
                              <Input
                                w="270px"
                                type="text"
                                name={`VGM-${rowIndex}`}
                                value={
                                  formData[formDataIndex]?.Operations[
                                    containerIndex
                                  ]?.[rowIndex]?.VGM || ""
                                }
                                onChange={(e) =>
                                  handleOperationChange(
                                    containerIndex,
                                    rowIndex,
                                    "VGM",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter your VGM weight"
                              />
                            </td>
                            <td>
                              <Select
                                width="190px"
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                name={`VGMMethod-${rowIndex}`}
                                value={
                                  formData[formDataIndex]?.Operations[
                                    containerIndex
                                  ]?.[rowIndex]?.VGMMethod || ""
                                }
                                onChange={(e) =>
                                  handleOperationChange(
                                    containerIndex,
                                    rowIndex,
                                    "VGMMethod",
                                    e.target.value
                                  )
                                }
                              >
                                <option>Select option</option>
                                <option value="Method I">Method I</option>
                                <option value="Method II">Method II</option>
                              </Select>
                            </td>
                          </tr>
                        ));
                      }
                    )} */}
                    {/* Add row based */}
                    {formData[formDataIndex]?.Containers?.split(";")
                      ?.join("")
                      ?.trim() !== "" &&
                      formData[formDataIndex]?.Containers?.split(";").map(
                        (containerString, index) => {
                          // Convert container string into an object
                          if (!containerString.trim()) return null;
                          const container = {};
                          containerString.split(",").forEach((pair) => {
                            const [key, value] = pair.split(":");
                            container[key?.trim()] = value?.trim();
                          });
                          console.log(container.ContainerType);
                          return (
                            <tr key={index}>
                              <td>
                                <Select
                                  width="190px"
                                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                  type="text"
                                  name="ContainerType"
                                  value={container.ContainerType || ""}
                                  onChange={(e) =>
                                    handleContainerChange(
                                      index,
                                      "ContainerType",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option>Select Equipment</option>
                                  {equipmentData.map((item, idx) => (
                                    <option
                                      key={idx}
                                      value={item.ContainerType}
                                    >
                                      {item.ContainerType}
                                    </option>
                                  ))}
                                </Select>
                              </td>
                              <td>
                                <Input
                                  w="270px"
                                  type="text"
                                  name="ContainerNumber"
                                  value={
                                    formData[formDataIndex]?.Operations[index]
                                      ?.ContainerNumber
                                  }
                                  onChange={(e) => {
                                    handleOperationChange(
                                      index,
                                      "ContainerNumber",
                                      e.target.value
                                    );
                                  }}
                                  placeholder="Enter your container number"
                                />
                              </td>
                              <td>
                                <Input
                                  w="270px"
                                  type="text"
                                  name="SealNumber"
                                  value={
                                    formData[formDataIndex]?.Operations[index]
                                      ?.SealNumber
                                  }
                                  onChange={(e) => {
                                    handleOperationChange(
                                      index,
                                      "SealNumber",
                                      e.target.value
                                    );
                                  }}
                                  placeholder="Enter your seal number"
                                />
                              </td>

                              <td>
                                <Input
                                  w="270px"
                                  type="text"
                                  name="No_Of_Pkge"
                                  value={
                                    formData[formDataIndex]?.Operations[index]
                                      ?.No_Of_Pkge
                                  }
                                  onChange={(e) => {
                                    handleOperationChange(
                                      index,
                                      "No_Of_Pkge",
                                      e.target.value
                                    );
                                  }}
                                  placeholder="Enter your number of packages"
                                />
                              </td>
                              <td>
                          
                                <Select
                                  width="190px"
                                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                  name="Type_Of_Pkge"
                                  value={
                                    formData[formDataIndex]?.Operations[index]
                                      ?.Type_Of_Pkge
                                  }
                                  onChange={(e) => {
                                    handleOperationChange(
                                      index,
                                      "Type_Of_Pkge",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option>Select Package Type</option>
                                  {packageTypeData.map((item, idx) => (
                                    <option key={idx} value={item.packingType}>
                                      {item.packingType}
                                    </option>
                                  ))}
                                </Select>
                              </td>
                              <td>
                                <Input
                                  w="270px"
                                  type="text"
                                  name="NtWeight"
                                  value={
                                    formData[formDataIndex]?.Operations[index]
                                      ?.NtWeight
                                  }
                                  onChange={(e) => {
                                    handleOperationChange(
                                      index,
                                      "NtWeight",
                                      e.target.value
                                    );
                                  }}
                                  placeholder="Enter your net weight"
                                />
                              </td>

                              <td>
                                <Input
                                  w="270px"
                                  type="text"
                                  name="GrWeight"
                                  value={
                                    formData[formDataIndex]?.Operations[index]
                                      ?.GrWeight
                                  }
                                  onChange={(e) => {
                                    handleOperationChange(
                                      index,
                                      "GrWeight",
                                      e.target.value
                                    );
                                  }}
                                  placeholder="Enter your gross weight"
                                />
                              </td>

                              <td>
                                <Input
                                  w="270px"
                                  type="text"
                                  name="Volume"
                                  value={
                                    formData[formDataIndex]?.Operations[index]
                                      ?.Volume
                                  }
                                  onChange={(e) => {
                                    handleOperationChange(
                                      index,
                                      "Volume",
                                      e.target.value
                                    );
                                  }}
                                  placeholder="Enter your volume"
                                />
                              </td>
                              <td>
                                <Input
                                  w="270px"
                                  type="text"
                                  name="TareWeight"
                                  value={
                                    formData[formDataIndex]?.Operations[index]
                                      ?.TareWeight
                                  }
                                  onChange={(e) => {
                                    handleOperationChange(
                                      index,
                                      "TareWeight",
                                      e.target.value
                                    );
                                  }}
                                  placeholder="Enter your tare weight"
                                />
                              </td>
                              <td>
                                <Input
                                  w="270px"
                                  type="text"
                                  name="VGM"
                                  value={
                                    formData[formDataIndex]?.Operations[index]
                                      ?.VGM
                                  }
                                  onChange={(e) => {
                                    handleOperationChange(
                                      index,
                                      "VGM",
                                      e.target.value
                                    );
                                  }}
                                  placeholder="Enter your vgm weight"
                                />
                              </td>
                              <td>

                                <Select
                                  width="190px"
                                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                  type="text"
                                  name="VGMMethod"
                                  value={
                                    formData[formDataIndex]?.Operations[index]
                                      ?.VGMMethod
                                  }
                                  onChange={(e) => {
                                    handleOperationChange(
                                      index,
                                      "VGMMethod",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option>Select option</option>
                                  <option value="Method I">Method I</option>
                                  <option value="Method II">Method II</option>
                                </Select>
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
              <br />
              {/* End operation */}
            </TabPanel>

            <TabPanel>
              {/* <TabPanel>
                <Badge
                  float="left"
                  fontSize="10px"
                  fontFamily="sans-serif"
                  colorScheme="red"
                >
                  Origin
                </Badge>
              </TabPanel> */}
              <h2
                style={{
                  float: "left",
                  color: "#fff",
                  fontSize: "20px",
                  backgroundColor: "#172554",
                  borderRadius: "10px",
                  fontFamily: "cursive",
                }}
              >
                Origin
              </h2>
              <br />

              {/* Detention and Demurrage */}
              <div className="content">
                <div className="form-user">
                  <div className="user-job">
                    <div className="input-box">
                      <span className="job">Empty PickUp date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="EmptyPickUpDate"
                        value={formData[formDataIndex]?.EmptyPickUpDate}
                        onChange={handleChange}
                        placeholder="Enter your empty pickup date"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Laden Gate In Date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="LadenGateIndate"
                        value={formData[formDataIndex]?.LadenGateIndate}
                        onChange={handleChange}
                        placeholder="Enter your laden gate in date"
                      />
                    </div>
                    <div className="input-box">
                      <span className="job whitespace-nowrap">
                        Vessel Arrival Date / Vessel Sailing Date
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="VesselArrivalDate"
                        value={formData[formDataIndex]?.VesselArrivalDate}
                        onChange={handleChange}
                        placeholder="Enter your vessel arrival date"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Detention</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Detention"
                        value={
                          formData[formDataIndex]?.Detention ||
                          calculateDetention()
                        }
                        readOnly // So that users cannot directly edit the detention value
                        onChange={handleChange}
                        placeholder="Enter your detention"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Liner Free Time / Days</span>
                      {/* Detention By Liner */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Detention_By_Liner"
                        value={formData[formDataIndex]?.Detention_By_Liner}
                        onChange={handleChange}
                        placeholder="Enter your detention by liner"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Detention Days</span>
                      {/* No Of Detention Occuring */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="DetentionNo	"
                        value={
                          formData[formDataIndex]?.DetentionNo ||
                          calculateDetentionNo()
                        }
                        onChange={handleChange}
                        placeholder="Enter your detention occuring"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Demurrage</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Demurrage"
                        value={
                          formData[formDataIndex]?.Demurrage ||
                          calculateDemurrage()
                        }
                        onChange={handleChange}
                        placeholder="Enter your demurrage"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Port Demurrage</span>
                      {/* Demurrage By Liner */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Demurrage_By_Liner"
                        value={
                          formData[formDataIndex]
                            ?.Demurrage_By_Liner_Destination
                        }
                        onChange={handleChange}
                        placeholder="Enter your demurrage by liner"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Demurrage Days</span>
                      {/* No Of Demurrage Occuring */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="DemurrageNo"
                        value={
                          formData[formDataIndex]?.DemurrageNo ||
                          calculateDemurrageNo()
                        }
                        onChange={handleChange}
                        placeholder="Enter your demurrage occuring"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Port Storage</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Port_Storage"
                        value={
                          formData[formDataIndex]?.Port_Storage ||
                          calculatePortStorageDate()
                        }
                        onChange={handleChange}
                        placeholder="Enter your port storage"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Port Storage Free Time</span>
                      {/* Port Storage Given By Port */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="PortStorage"
                        value={formData[formDataIndex]?.PortStorage}
                        onChange={handleChange}
                        placeholder="Enter your storage"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        No Of Days Port Storage Occuring
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Port_Storage_Days"
                        value={
                          formData[formDataIndex]?.Port_Storage_Days ||
                          calculatePortStorageDays()
                        }
                        onChange={handleChange}
                        placeholder="Enter your no of days port storage occuring"
                      />
                    </div>
                    <div className="input-box">
                      <span className="job">
                        Combined Detention & Demurrage
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="CombinedDetentionDemurrage"
                        value={
                          formData[formDataIndex]?.CombinedDetentionDemurrage ||
                          calculateCombinedDetentionDemurrage()
                        }
                        onChange={handleChange}
                        placeholder="Enter your combined detention demurrage"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        Combined Detention & Demurrage By Liner
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="CombinedDetentionDemurrageLiner"
                        value={
                          formData[formDataIndex]
                            ?.CombinedDetentionDemurrageLiner
                        }
                        onChange={handleChange}
                        placeholder="Enter your combined detention demurrage liner"
                      />
                    </div>
                    <div className="input-box">
                      <span className="job">
                        No Of Days Detention & Demurrage Occuring
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="NoOfDaysDetentionDemurrageOccuring"
                        value={
                          formData[formDataIndex]
                            ?.NoOfDaysDetentionDemurrageOccuring ||
                          calculateDetentionDemurrageDays()
                        }
                        onChange={handleChange}
                        placeholder="Enter your no of days detention demurrage occuring"
                      />
                    </div>
                  </div>
                </div>
                <h2
                  style={{
                    float: "left",
                    color: "#fff",
                    fontSize: "20px",
                    backgroundColor: "#172554",
                    borderRadius: "10px",
                    fontFamily: "cursive",
                  }}
                >
                  Destination
                </h2>
                <br />
                <div className="form-user">
                  <div className="user-job">
                    <div className="input-box">
                      <span className="job">Discharge Date</span>
                      {/* Container Discharge Date */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ContainerDischargeDate"
                        value={
                          formData[formDataIndex]?.ContainerDischargeDate || ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your container discharge date"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Port Out Date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="PortOutDate"
                        value={formData[formDataIndex]?.PortOutDate || ""}
                        onChange={handleChange}
                        placeholder="Enter your port out date"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Empty Return Date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="EmptyReturnDate"
                        value={formData[formDataIndex]?.EmptyReturnDate || ""}
                        onChange={handleChange}
                        placeholder="Enter your empty return date"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Detention</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="DestinationDetention"
                        value={
                          formData[formDataIndex]?.DestinationDetention ||
                          calculateDestinationDetention()
                        }
                        onChange={handleChange}
                        placeholder="Enter your detention"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Liner Free Time / Days</span>
                      {/* Detention By Liner */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="DestinationDetentionByLiner"
                        value={
                          formData[formDataIndex]?.DestinationDetentionByLiner
                        }
                        onChange={handleChange}
                        placeholder="Enter your detention by liner"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Detention Days</span>
                      {/* No Of Detention Occuring */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="DestinationNoOfDetentionOccuring"
                        value={
                          formData[formDataIndex]
                            ?.DestinationNoOfDetentionOccuring ||
                          calculateDestinationNoOfOccuring()
                        }
                        onChange={handleChange}
                        placeholder="Enter your no of detention occuring"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Demurrage</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="DestinationDemurrage"
                        value={
                          formData[formDataIndex]?.DestinationDemurrage ||
                          calculateDestinationDemurrage()
                        }
                        onChange={handleChange}
                        placeholder="Enter your demurrage"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Port Demurrage</span>
                      {/* Demurrage By Liner */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="DestinationDemurrageByLiner"
                        value={
                          formData[formDataIndex]?.DestinationDemurrageByLiner
                        }
                        onChange={handleChange}
                        placeholder="Enter your demurrage by liner"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Demurrage Days</span>
                      {/* No Of Demurrage Occuring */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="DestinationNoOfDemurrageOccuring"
                        value={
                          formData[formDataIndex]
                            ?.DestinationNoOfDemurrageOccuring ||
                          calculateDestinationDemurrageOccuring()
                        }
                        onChange={handleChange}
                        placeholder="Enter your no of demurrage occuring"
                        readOnly
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Port Storage</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="DestinationPortStorage"
                        value={
                          formData[formDataIndex]?.DestinationPortStorage ||
                          calculateDestinationPortStorage()
                        }
                        onChange={handleChange}
                        placeholder="Enter your port storage destination"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Port Storage Free Time</span>
                      {/* Port Storage Given By Port */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="DestinationPortStorageGivenByPort"
                        value={
                          formData[formDataIndex]
                            ?.DestinationPortStorageGivenByPort
                        }
                        onChange={handleChange}
                        placeholder="Enter your port storage given by port"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        No Of Days Port Storage Occuring
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="DestinationNoOfDaysPortStorageOccuring"
                        value={
                          formData[formDataIndex]
                            ?.DestinationNoOfDaysPortStorageOccuring ||
                          calculateDestinationPortStorageOccuring()
                        }
                        onChange={handleChange}
                        placeholder="Enter your no of days port storage occuring"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        Combined Detention & Demurrage
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="DestinationCombinedDetentionDemurrage"
                        value={
                          formData[formDataIndex]
                            ?.DestinationCombinedDetentionDemurrage ||
                          calculateDestinationCombinedDD()
                        }
                        onChange={handleChange}
                        placeholder="Enter your combined detention & demurrage"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        Combined Detention & Demurrage By Liner
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="DestinationCombinedDetentionDemurrageByLiner"
                        value={
                          formData[formDataIndex]
                            ?.DestinationCombinedDetentionDemurrageByLiner
                        }
                        onChange={handleChange}
                        placeholder="Enter your combined detention & demurrage by liner"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        No Of days Detention & Demurrage Occuring
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="DestinationNoOfdaysDetentionDemurrageOccuring"
                        value={
                          formData[formDataIndex]
                            ?.DestinationNoOfdaysDetentionDemurrageOccuring ||
                          calculateDestinationDDOccuring()
                        }
                        onChange={handleChange}
                        readOnly
                        placeholder="No of days detention & demurrage occurring"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End De & D */}
            </TabPanel>

            <TabPanel>
              {/* Current Status */}
              {/* <div className="table-container">
                <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                  <thead className="flex-nowrap text-center bg-blue-950 text-white">
                    <tr>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Current Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Input
                          type="text"
                          marginLeft="40%"
                          w="250px"
                          name="Current_Status"
                          value={formData[formDataIndex]?.Current_Status}
                          onChange={handleChange}
                          placeholder="Enter your current status"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
              {/* Notes */}
              {/* <div className="table-container">
                <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                  <thead className="flex-nowrap text-center bg-blue-950 text-white">
                    <tr>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Notes
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Input
                          type="text"
                          marginLeft="40%"
                          w="200px"
                          name="Notes"
                          value={formData[formDataIndex]?.Notes}
                          onChange={handleChange}
                          placeholder="Enter your notes"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
              {/* End Notes */}

              {/* End Current Status */}
            </TabPanel>

            {/* Document */}
            <TabPanel>
              <Documents />
            </TabPanel>

            {/* Vendor */}
            <TabPanel>
              <OperationVendors />
            </TabPanel>

            {/* Checklist */}
            <TabPanel>
              <Checklist />
            </TabPanel>

            {/* Activity Log */}
            <TabPanel>
              <Activitylog />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </form>
      {isOpenCancelBooking && (
        <>
          <div style={backdropStyle}></div>
          <div className="ConfirmCancelBooking">
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
              }}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Create Job Cancel
              </header>
              <div onClick={onCloseCancelBooking} style={closeModal}>
                <CgClose />
              </div>
              <br />
              <FormControl>
                Are you sure you want to cancel the job?
              </FormControl>
              <br />
              <Button colorScheme="green" onClick={handleConfirmCancel}>
                Yes
              </Button>
              <Button colorScheme="red" onClick={onCloseCancelBooking} ml={3}>
                No
              </Button>
            </Rnd>
          </div>
        </>
      )}
    </>
  );
};

export default Addjob;
