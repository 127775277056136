import { React, useState, useEffect } from "react";
import "./Addbooking.css";

import {
  Button,
  Input,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  FormControl,
} from "@chakra-ui/react";
import { useToast, useDisclosure } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import { Rnd } from "react-rnd";
import { CgClose } from "react-icons/cg";
import Axios, {
  BookingAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance";
import SearchSelect from "../../components/Select/select";
import { Header } from "../../components";
import TextArea from "antd/es/input/TextArea";
const Addbooking = () => {
  const location = useLocation();
  const duplicateBooking = location.state?.selectedData || {};

  console.log("Add Dupilcate Booking Selected data:", duplicateBooking);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [submitAllowed, setSubmitAllowed] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [incotermData, setIncotermData] = useState([]);
  const [scopeData, setScopeData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [portData, setPortData] = useState([]);
  const [salesPersonData, setSalesPersonData] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const fullName = user.firstname + " " + user.lastname;
  const regionCode = user.regions[0].regionCode;
  const storedUser = JSON.parse(localStorage.getItem("user"));
  // const fullName = `${storedUser.firstname} ${storedUser.lastname}`;
  // const regionCode = storedUser.regions[0].regionCode;
  // const [initialRegionCode, setInitialRegionCode] = useState("");
  const initialRegionCode =
  user.regions.length > 0 ? user.regions[0].regionCode : "";
  const [errors, setErrors] = useState({});
  const [activeTabIndex, setActiveTabIndex] = useState(0); // Track the active tab index

  // Define required fields for each tab
  const requiredFields = {
    0: ["BookingType", "Product", "Cargo", "Sales", "POL", "POD"], // Required fields for Job Information tab
    1: ["CompanyName"], // Example fields for Cargo Details tab
    2: ["VesselNameVoyage", "ETA", "ETD"], // Example fields for Liner Details tab
  };

  const [formDataIndex, setFormDataIndex] = useState(0);
  const [formData, setFormData] = useState([
    {
      BookingType: "",
      BookingNumber: "",
      Product: "",
      PlaceOfReceipt: "",
      POL: "",
      POD: "",
      PlaceOfDelivery: "",
      Cargo: "",
      CargoNature: "",
      TypeOfShipment: "",
      Incoterm: "",
      Sales: "",
      Scope: "",
      CompanyName: "",
      Address: "",
      PIC: "",
      LinerRefNumber: "",
      SICutOff: "",
      VesselNameVoyage: "",
      VGMCutOff: "",
      ETD: "",
      ETA: "",
      ETAPOL: "",
      ETDOrigin: "",
      ETDDestination: "",
      CYCutOff: "",
      EmptyPickupLocation: "",
      Region: "",
      Remarks: "",
      Containers: "ContainerType: , Quantity: , Weight: , Dimensions: ;",
      TermsConditions: "TermsAndConditions: ;",
      VesselDetails:
        "VesselType: , Vessel: , Voyage: , PolEta: , PortOfLoading: , ETD: , PortOfDischarge: , ETA: ;",
      CreatedBy: fullName,
      regionCode: initialRegionCode,
      BookingOwner: storedUser.userId,
      bookingContainers: [],
      termsandconditions: [],
      sailingVesselDetails: [],
    },
  ]);
  // useEffect(() => {
  //   setFormData((prevFormData) => {
  //     return prevFormData.map((item) => ({
  //       ...item,
  //       CreatedBy: fullName,
  //       BookingOwner: storedUser.userId,
  //       regionCode: initialRegionCode,
  //     }));
  //   });
  // }, [storedUser, fullName, initialRegionCode]);

  // useEffect(() => {
  //   // Check if any selected booking number contains "BHT"
  //   const bhtBooking = selectedData.find((booking) =>
  //     booking.BookingNumber.includes("BHT")
  //   );
  //   if (bhtBooking) {
  //     // Extract the region code from the booking number after "BHT"
  //     const regionCode = bhtBooking.BookingNumber.substring(3, 5); // Extracts "DL" from "BHTDLDHIMAA6240003"

  //     // Find the matching region code in storedUser.regions
  //     const matchedRegion = storedUser.regions.find(
  //       (region) => region.regionCode === regionCode
  //     );
  //     if (matchedRegion) {
  //       setInitialRegionCode(matchedRegion.regionCode);
  //     }
  //   }

  //   // Set form data with default values and initialRegionCode
  //   setFormData((prevFormData) => {
  //     return prevFormData.map((item) => ({
  //       ...item,
  //       CreatedBy: fullName,
  //       BookingOwner: storedUser.userId,
  //       regionCode: initialRegionCode,
  //     }));
  //   });
  // }, [storedUser, fullName, selectedData]);

  // const handleChangeRegionCode = (e) => {
  //   setInitialRegionCode(e.target.value);
  //   setFormData((prevFormData) => {
  //     const updatedFormData = [...prevFormData];
  //     updatedFormData[formDataIndex].regionCode = e.target.value;
  //     return updatedFormData;
  //   });
  // };

  // const handleChangeRegionCode = (e) => {
  //   setInitialRegionCode(e.target.value);
  //   setFormData((prevFormData) => {
  //     const updatedFormData = [...prevFormData];
  //     updatedFormData[formDataIndex].regionCode = e.target.value;
  //     return updatedFormData;
  //   });
  // };

  useEffect(() => {
    setFormData(duplicateBooking);
    setSelectedData(duplicateBooking);
  }, []);

  useEffect(() => {}, [setSelectedData, setSelectedRows]);

  const [bookingContainers, setBookingContainers] = useState([
    {
      ContainerType: "",
      Quantity: "",
      Weight: "",
      Dimensions: "",
    },
  ]);

  const [containers, setContainers] = useState([{}]);

  const addContainer = () => {
    setFormData((prevData) => {
      const newData = [...prevData];

      newData[formDataIndex] = {
        ...newData[formDataIndex],
        Containers:
          newData[formDataIndex].Containers +
          "ContainerType: , Quantity: , Weight: , Dimensions: ;",
      };
      return newData;
    });
  };

  // function removeRowByIndex(data, indexToRemove) {
  //   let rows = data.split(";").filter((row) => row.trim() !== ""); // Split the data into rows
  //   if (indexToRemove >= 0 && indexToRemove < rows.length) {
  //     // Check if index is within bounds
  //     rows.splice(indexToRemove, 1); // Remove row at specified index
  //   }
  //   return rows.join("; "); // Join the rows back into a single string
  // }

  const removeContainer = (indexToRemove) => {
    console.log(indexToRemove);
    setFormData((prevData) => {
      const newData = [...prevData];
      const containers = newData[formDataIndex]?.Containers || "";

      // Split the containers into rows
      let rows = containers.split(";").filter((row) => row.trim() !== "");

      if (indexToRemove >= 0 && indexToRemove < rows.length) {
        // Remove row at specified index
        rows.splice(indexToRemove, 1);
      }

      // Join the rows back into a single string with semicolons
      newData[formDataIndex] = {
        ...newData[formDataIndex],
        Containers:
          rows.join("; ").trim() + (containers.endsWith(";") ? ";" : ""), // Ensure ending semicolon if necessary
      };
      return newData;
    });
  };

  // Vessel Details

  const [sailingVesselDetails, setSailingVesselDetails] = useState([
    {
      VesselType: "",
      Vessel: "",
      Voyage: "",
      PolEta: "",
      PortOfLoading: "",
      ETD: "",
      PortOfDischarge: "",
      ETA: "",
    },
  ]);

  const addSailingVesselDetails = () => {
    setFormData((prevData) => {
      const newData = [...prevData];

      newData[formDataIndex] = {
        ...newData[formDataIndex],
        VesselDetails:
          newData[formDataIndex].VesselDetails +
          "VesselType: , Vessel: , Voyage: , PolEta: , PortOfLoading: , ETD: , PortOfDischarge: , ETA: ;",
      };
      return newData;
    });
  };

  const removeSailingVesselDetails = (indexToRemove) => {
    console.log(indexToRemove);
    setFormData((prevData) => {
      const newData = [...prevData];
      const vesselDetails = newData[formDataIndex]?.VesselDetails || "";

      // Split the containers into rows
      let rows = vesselDetails.split(";").filter((row) => row.trim() !== "");

      if (indexToRemove >= 0 && indexToRemove < rows.length) {
        // Remove row at specified index
        rows.splice(indexToRemove, 1);
      }

      // Join the rows back into a single string with semicolons
      newData[formDataIndex] = {
        ...newData[formDataIndex],
        VesselDetails:
          rows.join("; ").trim() + (vesselDetails.endsWith(";") ? ";" : ""), // Ensure ending semicolon if necessary
      };
      return newData;
    });
  };

  // Terms and Conditions

  const [termsandconditions, setTermsandconditions] = useState([
    { TermsAndConditions: "" },
  ]);

  const addTermsandcondition = () => {
    setFormData((prevData) => {
      const newData = [...prevData];

      newData[formDataIndex] = {
        ...newData[formDataIndex],
        TermsConditions:
          newData[formDataIndex].TermsConditions + "TermsAndConditions: ;",
      };
      return newData;
    });
  };

  function removeRowByIndex(data, indexToRemove) {
    let rows = data.split(";").filter((row) => row.trim() !== ""); // Split the data into rows
    if (indexToRemove >= 0 && indexToRemove < rows.length) {
      // Check if index is within bounds
      rows.splice(indexToRemove, 1); // Remove row at specified index
    }
    return rows.join("; "); // Join the rows back into a single string
  }

  const removeTermsandconditions = (indexToRemove) => {
    console.log(indexToRemove);
    setFormData((prevData) => {
      const newData = [...prevData];
      const termsConditions = newData[formDataIndex]?.TermsConditions || "";

      // Split the containers into rows
      let rows = termsConditions.split(";").filter((row) => row.trim() !== "");

      if (indexToRemove >= 0 && indexToRemove < rows.length) {
        // Remove row at specified index
        rows.splice(indexToRemove, 1);
      }

      // Join the rows back into a single string with semicolons
      newData[formDataIndex] = {
        ...newData[formDataIndex],
        TermsConditions:
          rows.join("; ").trim() + (termsConditions.endsWith(";") ? ";" : ""), // Ensure ending semicolon if necessary
      };
      return newData;
    });
  };

  const handleContainerChange = (index, field, value) => {
    const updatedContainers = [
      ...formData[formDataIndex]?.Containers?.split(";"),
    ];
    const containerString = updatedContainers[index];

    if (containerString) {
      const container = {};
      containerString.split(",").forEach((pair) => {
        const [key, val] = pair.split(":");
        container[key?.trim()] = val?.trim();
      });

      // Update the value for the specified field
      container[field] = value;

      // Reconstruct the container string
      updatedContainers[index] = Object.entries(container)
        .map(([key, val]) => `${key}:${val}`)
        .join(", ");

      // Update the state with the new containers string
      setFormData((prevData) => {
        const newData = [...prevData];
        newData[formDataIndex].Containers = updatedContainers.join(";");
        return newData;
      });
    }
  };

  const handleVesselDetailsChange = (index, field, value) => {
    const updatedVessel = [
      ...formData[formDataIndex]?.VesselDetails?.split(";"),
    ];
    const vesselString = updatedVessel[index];

    if (vesselString) {
      const vessel = {};
      vesselString.split(",").forEach((pair) => {
        const [key, val] = pair.split(":");
        vessel[key?.trim()] = val?.trim();
      });

      // Update the value for the specified field
      vessel[field] = value;

      // Reconstruct the container string
      updatedVessel[index] = Object.entries(vessel)
        .map(([key, val]) => `${key}:${val}`)
        .join(", ");

      // Update the state with the new containers string
      setFormData((prevData) => {
        const newData = [...prevData];
        newData[formDataIndex].VesselDetails = updatedVessel.join(";");
        return newData;
      });
    }
  };

  const handleTermsandConditionChange = (index, field, value) => {
    const updatedTermsConditions = [
      ...formData[formDataIndex]?.TermsConditions?.split(";"),
    ];
    const termsandconditionString = updatedTermsConditions[index];

    if (termsandconditionString) {
      const termsandcondition = {};
      termsandconditionString.split(",").forEach((pair) => {
        const [key, val] = pair.split(":");
        termsandcondition[key?.trim()] = val?.trim();
      });

      // Update the value for the specified field
      termsandcondition[field] = value;

      // Reconstruct the container string
      updatedTermsConditions[index] = Object.entries(termsandcondition)
        .map(([key, val]) => `${key}:${val}`)
        .join(", ");

      // Update the state with the new containers string
      setFormData((prevData) => {
        const newData = [...prevData];
        newData[formDataIndex].TermsConditions = updatedTermsConditions.join(";");
        return newData;
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Equipment API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Incoterm API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_incoterm");
        setIncotermData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Scope API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Region API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_region");
        setRegionData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Port API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //Sales Person API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_salesperson");
        setSalesPersonData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Customer API
  const [customerData, setCustomerData] = useState([]);
  const [filteredPIC, setFilteredPIC] = useState([]);
  const [filteredAddress, setFilteredAddress] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setCustomerData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (formData[formDataIndex]?.CompanyName) {
      const filteredPIC = customerData.filter(
        (item) => item.Company === formData[formDataIndex].CompanyName
      );
      setFilteredPIC(filteredPIC);
    }
  }, [formData[formDataIndex]?.CompanyName, customerData]);

  useEffect(() => {
    if (formData[formDataIndex]?.PIC) {
      const selectedCustomer = filteredPIC.find(
        (item) => item.Name === formData[formDataIndex].PIC
      );
      setFilteredAddress(
        selectedCustomer
          ? [
              {
                value: `${selectedCustomer.Address_Line_1}, ${selectedCustomer.Address_Line_2}, ${selectedCustomer.Address_Line_3}, ${selectedCustomer.City}, ${selectedCustomer.State}, ${selectedCustomer.Country} - ${selectedCustomer.Pincode}`,
                label: `${selectedCustomer.Address_Line_1}, ${selectedCustomer.Address_Line_2}, ${selectedCustomer.Address_Line_3}, ${selectedCustomer.City}, ${selectedCustomer.State}, ${selectedCustomer.Country} - ${selectedCustomer.Pincode}`,
                name: "Address",
              },
            ]
          : []
      );
    }
  }, [formData[formDataIndex]?.PIC, filteredPIC]);

  const handleChange = (e, index) => {
    if (
      e?.name === "POL" ||
      e?.name === "POD" ||
      e?.name === "Sales" ||
      e?.name === "CompanyName" ||
      e?.name === "PIC" ||
      e?.name === "Address"
    ) {
      const { name, value } = e;
      console.log(name, value);
      setFormData((prevData) => {
        const newData = [...prevData];
        newData[formDataIndex] = { ...newData[formDataIndex], [name]: value };
        return newData;
      });

      return;
    }

    const { name, value } = e.target;
    console.log(name, value);
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [name]: value,
    // }));
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[formDataIndex] = { ...newData[formDataIndex], [name]: value };
      // newData[formDataIndex].regionCode = regionCode;
      return newData;
    });

    // Remove error for the field if it's been corrected
    if (errors[name]) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name]; // Delete the error for the specific field
        return updatedErrors;
      });
    }

    console.log("Pol Data", setFormData);
  };

  // const handleChange = (e, index) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => {
  //     const newData = [...prevData];
  //     newData[formDataIndex] = { ...newData[formDataIndex], [name]: value };
  //     return newData;
  //   });
  // };

  const validateAllTabs = () => {
    let tabErrors = {};
    Object.keys(requiredFields).forEach((tabIndex) => {
      requiredFields[tabIndex].forEach((field) => {
        if (!formData[0][field]) {
          tabErrors[field] = `${field} is required`;
        }
      });
    });
    return tabErrors;
  };

  const getFirstErrorTabIndex = (tabErrors) => {
    // Find the first tab with a required field missing
    for (let tabIndex in requiredFields) {
      if (requiredFields[tabIndex].some((field) => tabErrors[field])) {
        return parseInt(tabIndex);
      }
    }
    return 0; // Default to the first tab if no errors are found
  };

  const Pagerefresh = () => window.location.reload(true);
  const handleSubmit = async (event) => {
    formData.bookingContainers = bookingContainers;
    formData.termsandconditions = termsandconditions;
    formData.sailingVesselDetails = sailingVesselDetails;
    event.preventDefault();
    console.log(bookingContainers, "Conatiner");
    console.log("Selected Form Data", formData);

    const tabErrors = validateAllTabs();

    if (Object.keys(tabErrors).length) {
      setErrors(tabErrors);
      setActiveTabIndex(getFirstErrorTabIndex(tabErrors)); // Activate the first tab with errors

      // toast({
      //   title: "Please fill in the required fields",
      //   position: "bottom-center",
      //   status: "error",
      //   isClosable: true,
      //   containerStyle: {
      //     width: "98.5vw",
      //     maxWidth: "98.5vw",
      //     alignContent: "center",
      //   },
      // });
      return;
    }

    const { data } = await BookingAPIInstance.post(
      `add_booking?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    console.log("====================================");
    console.log(data);
    console.log("====================================");
    if (data.success) {
      toast({
        title: data.data.message,
        position: "bottom-center",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/booking");
    } else {
      toast({
        title: data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();
      const tabErrors = validateAllTabs();

      if (Object.keys(tabErrors).length) {
        setErrors(tabErrors);
        setActiveTabIndex(getFirstErrorTabIndex(tabErrors)); // Activate the first tab with errors

        // toast({
        //   title: "Please fill in the required fields",
        //   position: "bottom-center",
        //   status: "error",
        //   isClosable: true,
        //   containerStyle: {
        //     width: "98.5vw",
        //     maxWidth: "98.5vw",
        //     alignContent: "center",
        //   },
        // });
        return;
      }

      console.log(bookingContainers, "conatiner");
      formData.bookingContainers = bookingContainers;
      formData.termsandconditions = termsandconditions;
      formData.sailingVesselDetails = sailingVesselDetails;
      const { data } = await BookingAPIInstance.post(
        `add_booking?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const handleApply = () => {
    console.log("Selected Data:", selectedData);
  };
  // const handleGetDuplicateBooking = (index) => {
  //   setFormDataIndex(index);
  // };
  const handleGetDuplicateBooking = (index) => {
    setFormDataIndex(index);
    //setActiveBookingTabIndex(index); // Set the active booking tab index when clicked
  };
  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter") {
  //     e.preventDefault();
  //   }
  // };
  const {
    isOpen: isOpenCancelBooking,
    onOpen: onOpenCancelBooking,
    onClose: onCloseCancelBooking,
  } = useDisclosure();

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 10,
  };

  const style = {
    border: "1px solid #ddd",
    backgroundColor: "white",
    zIndex: 20,
  };

  const closeModal = {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
  };

  const handleConfirmCancel = () => {
    navigate("/booking");
    onCloseCancelBooking(); // Close the modal after navigating
  };
  return (
    <div>
      <Header />
      <div>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">Create Booking</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>

          <button
            className="flexbox-container flexbox-item flexbox-item-3 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={handleSubmitNext}
          >
            Save & Next
          </button>

          {/* <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/booking");
            }}
            disabled={!submitAllowed}
          >
            Cancel
          </button> */}

          <div
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950 cursor-pointer"
            // onClick={() => {
            //   navigate("/booking");
            // }}
            onClick={onOpenCancelBooking}
            disabled={!submitAllowed}
          >
            Cancel
          </div>
        </div>
        {/* <Tabs>
          <TabList>
            {selectedData.map((duplicateBooking, index) => {
              return (
                <Tab
                  key={index}
                  onClick={() => {
                    handleGetDuplicateBooking(index);
                  }}
                >
                  {duplicateBooking.BookingNumber}
                </Tab>
              );
            })}
          </TabList>
        </Tabs> */}
        <Tabs
          index={activeTabIndex}
          onChange={setActiveTabIndex}
          w="100%"
          variant="enclosed"
        >
          &nbsp;
          <TabList>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Booking Information
            </Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Customer Details
            </Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Sailing Details
            </Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>Containers</Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Terms & Conditions
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {/* Booking Infromation */}
              <div className="content">
                <div className="form-user">
                  <div className="user-booking">
                    <div className="input-box">
                      <span className="job">
                        Select Region<span style={{ color: "red" }}>*</span>
                      </span>
                      {/* {storedUser.regions.length > 1 ? (
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="regionCode"
                          value={
                            formData[formDataIndex]?.regionCode ||
                            initialRegionCode
                          }
                          onChange={handleChangeRegionCode}
                        >
                          {storedUser.regions.map((region, index) => (
                            <option key={index} value={region.regionCode}>
                              {region.user_region} - {region.regionCode}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="regionCode"
                          value={
                            formData[formDataIndex]?.regionCode ||
                            initialRegionCode
                          }
                          readOnly
                        />
                      )} */}
                      {user.regions.length > 1 ? (
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="regionCode"
                          value={formData[formDataIndex]?.regionCode}
                          onChange={handleChange}
                        >
                          {user.regions.map((region, index) => (
                            <option key={index} value={region.regionCode}>
                              {region.user_region} - {region.regionCode}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="regionCode"
                          value={formData[formDataIndex]?.regionCode}
                          readOnly
                        />
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">Booking Type</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="BookingType"
                        // value={formData.BookingType}
                        value={formData[formDataIndex]?.BookingType}
                        onChange={handleChange}
                      >
                        <option>Select Booking Type</option>
                        <option value="Original">Original</option>
                        <option value="Dummy">Dummy</option>
                      </select>
                      {errors.BookingType && (
                        <p style={{ color: "red" }}>{errors.BookingType}</p>
                      )}
                    </div>

                    <div className="input-box" hidden>
                      <span className="booking">Booking Number</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="BookingNumber"
                        // value={formData.BookingNumber}
                        // value={formData[formDataIndex]?.BookingType}
                        onChange={handleChange}
                        placeholder="Enter your booking number"
                        hidden
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        Product<span style={{ color: "red" }}>*</span>
                      </span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Product"
                        // value={formData.Product}
                        value={formData[formDataIndex]?.Product}
                        onChange={handleChange}
                        placeholder="Enter your Product"
                      >
                        <option>Select Product</option>
                        {productData.map((item) => (
                          <option value={item.Product}>{item.Product}</option>
                        ))}
                      </select>
                      {errors.Product && (
                        <p style={{ color: "red" }}>{errors.Product}</p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">Place of Receipt</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="PlaceOfReceipt"
                        value={formData[formDataIndex]?.PlaceOfReceipt}
                        onChange={handleChange}
                        placeholder="Enter your place of receipt"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        Port of Loading/RAMP
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Pol"
                        value={formData.Pol}
                        onChange={handleChange}
                        placeholder="Enter your pol"
                      /> */}
                      {/* <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="POL"
                        value={formData[formDataIndex]?.POL}
                        defaultValue={"MUMBAI"}
                        onChange={handleChange}
                        placeholder="Enter your pol"
                      >
                        <option>Select Pol</option>
                        {portData.map((item) => (
                          <option value={item.Port}>{item.Port}</option>
                        ))}
                      </select>
                      <br /> */}
                      {/* <SearchSelect
                        name="POL"
                        value={formData[formDataIndex]?.POL}
                        handleChange={handleChange}
                        placeholder="Select Pol"
                        colourOptions={portData.map((item) => {
                          return {
                            value: item.Port,
                            label: item.Port,
                            name: "POL",
                          };
                        })}
                      /> */}
                      <SearchSelect
                        name="POL"
                        value={formData[formDataIndex]?.POL}
                        handleChange={handleChange}
                        placeholder="POL"
                        colourOptions={portData.map((item) => {
                          return {
                            value: item.Port,
                            label: item.Port,
                            name: "POL",
                          };
                        })}
                        // onChange={(value) => console.log(value)}
                      />
                      {/* <Select
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        colourOptions={portData.map((item) => {
          return {
            value: item.Port, label: item.Port
          }
        })}
      /> */}
                      {errors.POL && (
                        <p style={{ color: "red" }}>{errors.POL}</p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        Port of Destination/RAMP
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Pod"
                        value={formData.Pod}
                        onChange={handleChange}
                        placeholder="Enter your pod"
                      /> */}
                      {/* <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Pod"
                        value={formData.Pod}
                        onChange={handleChange}
                        placeholder="Enter your pod"
                      >
                        <option>Select Pod</option>
                        {portData.map((item) => (
                          <option value={item.Port}>{item.Port}</option>
                        ))}
                      </select> */}
                      <SearchSelect
                        name="POD"
                        value={formData[formDataIndex]?.POD}
                        handleChange={handleChange}
                        placeholder="Select Pod"
                        colourOptions={portData.map((item) => {
                          return {
                            value: item.Port,
                            label: item.Port,
                            name: "POD",
                          };
                        })}
                      />
                      {errors.POD && (
                        <p style={{ color: "red" }}>{errors.POD}</p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">Place Of Delivery</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="PlaceOfDelivery"
                        value={formData[formDataIndex]?.PlaceOfDelivery}
                        onChange={handleChange}
                        placeholder="Enter your place of delivery"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        Cargo<span style={{ color: "red" }}>*</span>
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Cargo"
                        value={formData[formDataIndex]?.Cargo}
                        onChange={handleChange}
                        placeholder="Enter your cargo"
                      />
                      {errors.Cargo && (
                        <p style={{ color: "red" }}>{errors.Cargo}</p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">Incoterm</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Incoterm"
                        value={formData[formDataIndex]?.Incoterm}
                        onChange={handleChange}
                        placeholder="Enter your Product"
                      >
                        <option>Select Incoterm</option>
                        {incotermData.map((item) => (
                          <option value={item.Incoterm}>{item.Incoterm}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="booking">Scope</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Scope"
                        value={formData[formDataIndex]?.Scope}
                        onChange={handleChange}
                        placeholder="Enter your Scope"
                      >
                        <option>Select Scope</option>
                        {scopeData.map((item) => (
                          <option value={item.Scope}>{item.Scope}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        Sales<span style={{ color: "red" }}>*</span>
                      </span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Sales"
                        value={formData.Sales}
                        onChange={handleChange}
                        placeholder="Enter your sales"
                      /> */}
                      <SearchSelect
                        name="Sales"
                        value={formData[formDataIndex]?.Sales}
                        handleChange={handleChange}
                        placeholder="Select Sales"
                        colourOptions={salesPersonData.map((item) => {
                          return {
                            value: item.fullname,
                            label: item.fullname,
                            name: "Sales",
                          };
                        })}
                      />
                      {errors.Sales && (
                        <p style={{ color: "red" }}>{errors.Sales}</p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">Cargo Nature</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="CargoNature"
                        value={formData.CargoNature}
                        onChange={handleChange}
                        placeholder="Enter your cargo nature"
                      />
                    </div>
                    <div className="input-box">
                      <span className="booking">Type Of Shipment</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="TypeOfShipment"
                        value={formData[formDataIndex]?.TypeOfShipment}
                        onChange={handleChange}
                      >
                        <option>Select Type Of Shipment</option>
                        <option value="LCL">LCL - Less than container load</option>
                        <option value="FCL">FCL - Full container load</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Booking info */}
            </TabPanel>

            <TabPanel>
              {/* Customer details */}
              <div className="content">
                <div className="form-user">
                  <div className="user-booking">
                    <div className="input-box">
                      <span className="booking">
                        Company Name<span style={{ color: "red" }}>*</span>
                      </span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="CompanyName"
                        value={formData.CompanyName}
                        onChange={handleChange}
                        placeholder="Enter your company"
                      /> */}
                      <SearchSelect
                        name="CompanyName"
                        value={formData[formDataIndex]?.CompanyName}
                        handleChange={handleChange}
                        placeholder="Select CompanyName"
                        colourOptions={customerData.map((item) => {
                          return {
                            value: item.Company,
                            label: item.Company,
                            name: "CompanyName",
                          };
                        })}
                      />
                      {errors.CompanyName && (
                        <p style={{ color: "red" }}>{errors.CompanyName}</p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">Person Incharge</span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="PIC"
                        value={formData.PIC}
                        onChange={handleChange}
                        placeholder="Enter your pic"
                      /> */}
                      <SearchSelect
                        name="PIC"
                        value={formData[formDataIndex]?.PIC}
                        handleChange={handleChange}
                        placeholder="Select PIC"
                        colourOptions={filteredPIC.map((item) => {
                          return {
                            value: item.Name,
                            label: item.Name,
                            name: "PIC",
                          };
                        })}
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">Address</span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Address"
                        value={formData.Address}
                        onChange={handleChange}
                        placeholder="Enter your address"
                      /> */}
                      <SearchSelect
                        name="Address"
                        value={formData[formDataIndex]?.Address}
                        handleChange={handleChange}
                        placeholder="Select Address"
                        colourOptions={filteredAddress}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End Customer */}
            </TabPanel>

            <TabPanel>
              {/* Sailing details */}
              <div className="content">
                <div className="form-user">
                  <div className="user-booking">
                    <div className="input-box">
                      <span className="booking">Liner REF No</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="LinerRefNumber"
                        value={formData[formDataIndex]?.LinerRefNumber}
                        onChange={handleChange}
                        placeholder="Enter your liner ref no"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        Vessel Name/Voyage
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="VesselNameVoyage"
                        value={formData[formDataIndex]?.VesselNameVoyage}
                        onChange={handleChange}
                        placeholder="Enter your vessel voy"
                      />
                      {errors.VesselNameVoyage && (
                        <p style={{ color: "red" }}>
                          {errors.VesselNameVoyage}
                        </p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">Region</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Region"
                        value={formData[formDataIndex]?.Region}
                        onChange={handleChange}
                        placeholder="Enter your Region"
                      >
                        <option>Select Internal Region</option>
                        {regionData.map((item) => (
                          <option key={item.Region} value={item.Region}>
                            {item.Region}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        Estimated time of arrival(ETA)
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ETA"
                        value={
                          formData[formDataIndex]?.ETA
                            ? formData[formDataIndex]?.ETA.split("T")[0]
                            : ""
                        }
                        min={new Date().toISOString().split("T")[0]}
                        onChange={handleChange}
                        placeholder="Enter your eta"
                      />
                      {errors.ETA && (
                        <p style={{ color: "red" }}>{errors.ETA}</p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        Estimated time of departure(ETD)
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ETD"
                        value={
                          formData[formDataIndex]?.ETD
                            ? formData[formDataIndex]?.ETD.split("T")[0]
                            : ""
                        }
                        min={new Date().toISOString().split("T")[0]}
                        onChange={handleChange}
                        placeholder="Enter your etd"
                      />
                      {errors.ETD && (
                        <p style={{ color: "red" }}>{errors.ETD}</p>
                      )}
                    </div>

                    {/* <div className="input-box">
                      <span className="booking">ETA POL</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ETAPOL"
                        value={formData.ETAPOL}
                        onChange={handleChange}
                        placeholder="Enter your eta pol"
                      />
                    </div> */}

                    {/* <div className="input-box">
                      <span className="booking">ETD Origin</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ETDOrigin"
                        value={
                          formData[formDataIndex]?.ETDOrigin
                            ? formData[formDataIndex]?.ETDOrigin.split("T")[0]
                            : ""d
                        }
                        onChange={handleChange}
                        placeholder="Enter your etd origin"
                      />
                    </div> */}

                    <div className="input-box">
                      <span className="booking">ETA Destination</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ETDDestination"
                        value={
                          formData[formDataIndex]?.ETDDestination
                            ? formData[formDataIndex]?.ETDDestination.split(
                                "T"
                              )[0]
                            : ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your eta designation"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">Empty Pick Up Location</span>
                      <textarea
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="EmptyPickupLocation"
                        value={formData[formDataIndex]?.EmptyPickupLocation}
                        onChange={handleChange}
                        placeholder="Enter your empty pickup location"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">SI Cut Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="SICutOff"
                        value={
                          formData[formDataIndex]?.SICutOff
                            ? formData[formDataIndex]?.SICutOff.split("T")[0]
                            : ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your si cut off"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">VGM Cut Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="VGMCutOff"
                        value={
                          formData[formDataIndex]?.VGMCutOff
                            ? formData[formDataIndex]?.VGMCutOff.split("T")[0]
                            : ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your vgm cut off"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">CY Cut Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="CYCutOff"
                        value={
                          formData[formDataIndex]?.CYCutOff
                            ? formData[formDataIndex]?.CYCutOff.split("T")[0]
                            : ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your cy cut off"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">Remarks</span>
                      <textarea
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Remarks"
                        value={formData[formDataIndex]?.Remarks}
                        onChange={handleChange}
                        placeholder="Enter your remarks"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End Sailing */}
              {/* Vessel Details */}
              <div className="table-container">
                <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                  <thead className="flex-nowrap text-center bg-blue-950 text-white">
                    <tr>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Type
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Vessel
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Voyage
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        POL ETA
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Port of Loading
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        ETD
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Port of Discharge
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        ETA
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData[formDataIndex]?.VesselDetails?.split(";")
                      ?.join("")
                      ?.trim() !== "" &&
                      formData[formDataIndex]?.VesselDetails?.split(";").map(
                        (vesselString, index) => {
                          // Convert vessel string into an object
                          if (!vesselString.trim()) return null;
                          const vessel = {};
                          vesselString.split(",").forEach((pair) => {
                            const [key, value] = pair.split(":");
                            vessel[key?.trim()] = value?.trim();
                          });
                          console.log(vessel.VesselType);
                          return (
                            <tr key={index}>
                              <td>
                                <Select
                                  w="190px"
                                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                  type="text"
                                  name="VesselType"
                                  value={vessel.VesselType || ""}
                                  onChange={(e) =>
                                    handleVesselDetailsChange(
                                      index,
                                      "VesselType",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option>Select Type</option>
                                  <option value="Feeder">Feeder</option>
                                  <option value="Mother">Mother</option>
                                </Select>
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  name="Vessel"
                                  value={vessel.Vessel || ""}
                                  onChange={(e) =>
                                    handleVesselDetailsChange(
                                      index,
                                      "Vessel",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  name="Voyage"
                                  value={vessel.Voyage || ""}
                                  onChange={(e) =>
                                    handleVesselDetailsChange(
                                      index,
                                      "Voyage",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="date"
                                  name="PolEta"
                                  value={vessel.PolEta || ""}
                                  onChange={(e) =>
                                    handleVesselDetailsChange(
                                      index,
                                      "PolEta",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  name="PortOfLoading"
                                  value={vessel.PortOfLoading || ""}
                                  onChange={(e) =>
                                    handleVesselDetailsChange(
                                      index,
                                      "PortOfLoading",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="date"
                                  name="ETD"
                                  value={vessel.ETD || ""}
                                  onChange={(e) =>
                                    handleVesselDetailsChange(
                                      index,
                                      "ETD",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  name="PortOfDischarge"
                                  value={vessel.PortOfDischarge || ""}
                                  onChange={(e) =>
                                    handleVesselDetailsChange(
                                      index,
                                      "PortOfDischarge",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="date"
                                  name="ETA"
                                  value={vessel.ETA || ""}
                                  onChange={(e) =>
                                    handleVesselDetailsChange(
                                      index,
                                      "ETA",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <IoMdAddCircle
                                  className="cursor-pointer"
                                  size={30}
                                  style={{ marginLeft: "5px", color: "green" }}
                                  onClick={addSailingVesselDetails}
                                />
                                {formData[formDataIndex]?.VesselDetails?.split(
                                  ";"
                                ).length > 2 && (
                                  <BiSolidMinusCircle
                                    className="cursor-pointer"
                                    size={30}
                                    style={{ marginLeft: "5px", color: "red" }}
                                    onClick={() =>
                                      removeSailingVesselDetails(index)
                                    }
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </TabPanel>

            <TabPanel>
              {/* Table Conatiner */}

              <div className="table-container">
                <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                  <thead className="flex-nowrap text-center bg-blue-950 text-white">
                    <tr>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Container Type
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        QTY
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Weight Per container(KG)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Dimension(LXWXH IN M)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData[formDataIndex]?.Containers?.split(";")
                      ?.join("")
                      ?.trim() !== "" &&
                      formData[formDataIndex]?.Containers?.split(";").map(
                        (containerString, index) => {
                          // Convert container string into an object
                          if (!containerString.trim()) return null;
                          const container = {};
                          containerString.split(",").forEach((pair) => {
                            const [key, value] = pair.split(":");
                            container[key?.trim()] = value?.trim();
                          });
                          console.log(container.ContainerType);
                          return (
                            <tr key={index}>
                              <td>
                                <Select
                                  w="190px"
                                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                  type="text"
                                  name="ContainerType"
                                  value={container.ContainerType || ""}
                                  onChange={(e) =>
                                    handleContainerChange(
                                      index,
                                      "ContainerType",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option>Select Equipment</option>
                                  {equipmentData.map((item, idx) => (
                                    <option
                                      key={idx}
                                      value={item.ContainerType}
                                    >
                                      {item.ContainerType}
                                    </option>
                                  ))}
                                </Select>
                              </td>
                              <td>
                                <Input
                                  w="150px"
                                  type="text"
                                  name="Quantity"
                                  value={container.Quantity || ""}
                                  onChange={(e) =>
                                    handleContainerChange(
                                      index,
                                      "Quantity",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  w="150px"
                                  type="text"
                                  name="Weight"
                                  value={container.Weight || ""}
                                  onChange={(e) =>
                                    handleContainerChange(
                                      index,
                                      "Weight",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  w="150px"
                                  type="text"
                                  name="Dimensions"
                                  value={container.Dimensions || ""}
                                  onChange={(e) =>
                                    handleContainerChange(
                                      index,
                                      "Dimensions",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <IoMdAddCircle
                                  className="cursor-pointer"
                                  size={30}
                                  style={{ marginLeft: "5px", color: "green" }}
                                  onClick={addContainer}
                                />
                                {formData[formDataIndex]?.Containers?.split(";")
                                  .length > 2 && (
                                  <BiSolidMinusCircle
                                    className="cursor-pointer"
                                    size={30}
                                    style={{ marginLeft: "5px", color: "red" }}
                                    onClick={() => removeContainer(index)}
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </TabPanel>
            {/* Terms and Conditions */}
            <TabPanel>
              <div className="table-container">
                <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                  <thead className="flex-nowrap text-center bg-blue-950 text-white">
                    <tr>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Terms & Conditions
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData[formDataIndex]?.TermsConditions?.split(";")
                      ?.join("")
                      ?.trim() !== "" &&
                      formData[formDataIndex]?.TermsConditions?.split(";").map(
                        (termsConditionString, index) => {
                          // Convert TermsAndConditions string into an object
                          if (!termsConditionString.trim()) return null;
                          const termsandcondition = {};
                          termsConditionString.split(",").forEach((pair) => {
                            const [key, value] = pair.split(":");
                            termsandcondition[key?.trim()] = value?.trim();
                          });
                          console.log(termsandcondition.TermsAndConditions);
                          return (
                            <tr key={index}>
                              <td>
                                <TextArea
                                  w="190px"
                                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                  type="text"
                                  name="TermsAndConditions"
                                  value={
                                    termsandcondition.TermsAndConditions || ""
                                  }
                                  onChange={(e) =>
                                    handleTermsandConditionChange(
                                      index,
                                      "TermsAndConditions",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <IoMdAddCircle
                                  className="cursor-pointer"
                                  size={30}
                                  style={{ marginLeft: "5px", color: "green" }}
                                  onClick={addTermsandcondition}
                                />
                                {formData[
                                  formDataIndex
                                ]?.TermsConditions?.split(";").length > 2 && (
                                  <BiSolidMinusCircle
                                    className="cursor-pointer"
                                    size={30}
                                    style={{ marginLeft: "5px", color: "red" }}
                                    onClick={() =>
                                      removeTermsandconditions(index)
                                    }
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
      {isOpenCancelBooking && (
        <>
          <div style={backdropStyle}></div>
          <div className="ConfirmCancelBooking">
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
              }}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Create Booking Cancel
              </header>
              <div onClick={onCloseCancelBooking} style={closeModal}>
                <CgClose />
              </div>
              <br />
              <FormControl>
                Are you sure you want to cancel the booking?
              </FormControl>
              <br />
              <Button colorScheme="green" onClick={handleConfirmCancel}>
                Yes
              </Button>
              <Button colorScheme="red" onClick={onCloseCancelBooking} ml={3}>
                No
              </Button>
            </Rnd>
          </div>
        </>
      )}
    </div>
  );
};

export default Addbooking;
