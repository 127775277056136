const constant = {
  // MasterDatabaseURL: "http://localhost:5012",
  // AuthbaseURL: "http://localhost:5000",
  // DashboardbaseURL: "http://localhost:5003",
  // LeadbaseURL: "http://localhost:5006",
  // ProspectbaseURL: "http://localhost:5009",
  // ReportbaseURL: "http://localhost:5014",
  // OperationDashboardbaseURL: "http://localhost:5017",
  // BookingbaseURL: "http://localhost:5020",
  // JobbaseURL: "http://localhost:5023",
  // OperationDocumentbaseURL: "http://localhost:5026",
  // ProfomaInvoicebaseURL: "http://localhost:5029",
  // RatebaseURL: "http://localhost:5015",
  // QuotationbaseURL: "http://localhost:5035",
  // EnquirybaseURL: "http://localhost:5038",
  // CustomerbaseURL: "http://localhost:5038",
  // HrmbaseURL: "http://localhost:5032",

  // Testing server API

  MasterDatabaseURL: "http://13.235.108.43/services/master_data",
  AuthbaseURL: "http://13.235.108.43/services/auth",
  DashboardbaseURL: "http://13.235.108.43/services/dashboard",
  LeadbaseURL: "http://13.235.108.43/services/leads",
  ProspectbaseURL: "http://13.235.108.43/services/prospects",
  EnquirybaseURL: "http://13.235.108.43/services/enquiry",
  RatebaseURL: "http://13.235.108.43/services/rate",
  QuotationbaseURL: "http://13.235.108.43/services/quotation",
  ReportbaseURL: "http://13.235.108.43/services/report",
  OperationDashboardbaseURL:
    "http://13.235.108.43/services/operation_dashboard",
  BookingbaseURL: "http://13.235.108.43/services/booking",
  JobbaseURL: "http://13.235.108.43/services/job",
  OperationDocumentbaseURL: "http://13.235.108.43/services/operation_document",
  ProfomaInvoicebaseURL: "http://13.235.108.43/services/profoma_invoice",
  HrmbaseURL : "http://13.235.108.43/services/hrm"
};

export default constant;
