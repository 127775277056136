import React, { useState } from 'react';
import {
    Box, VStack, Button, Collapse, Flex, Tooltip
} from '@chakra-ui/react';
import { FaHome, FaUser, FaClock, FaSuitcase, FaCogs, FaBook, FaDollarSign, FaToolbox, FaChartLine, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [isHovered, setIsHovered] = useState(false);
    const [showEmployeeSubmenu, setShowEmployeeSubmenu] = useState(false); 
    const [showCoreHrSubmenu, setshowCoreHrSubmenu] = useState(false); 

    const navigate = useNavigate();

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleEmployeeClick = () => {
        setShowEmployeeSubmenu((prev) => !prev); // Toggle submenu
    };
    const handleCoreHrClick = () => {
        setshowCoreHrSubmenu((prev) => !prev); // Toggle submenu
    };


    const handleNavigation = (path) => {
        navigate(path); // Navigate without interfering with Collapse
    };

    return (
        <Flex>
            {/* Sidebar */}
            <Box
                as="nav"
                position="fixed"
                top={0}
                left={0}
                w={isHovered || isOpen ? "280px" : "80px"} // Adjust width based on hover/collapse
                h="100vh"
                bg="#ebf0f9"
                color="black"
                transition="width 0.3s ease"
                onMouseEnter={() => setIsHovered(true)}  
                onMouseLeave={() => setIsHovered(false)} 
                zIndex={1000} // Keep sidebar above the content
            >
                {/* Toggle Button */}
                <Button
                    aria-label="Toggle Sidebar"
                    position="absolute"
                    top={3}
                    left={3}
                    zIndex="overlay"
                    onClick={toggleSidebar}
                >
                    <HamburgerIcon />
                </Button>

                <VStack p={5} spacing={5} align="stretch">
                    {/* Home Icon with Tooltip */}
                    <Tooltip label="Home" placement="right">
                        <Button leftIcon={<FaHome />} variant="ghost" justifyContent="center" onClick={() => navigate('/employee')}>
                            {isHovered && 'Home'}
                        </Button>
                    </Tooltip>

                    {/* Employee Management Icon */}
                    <Tooltip label="Employee" placement="right">
                        <Button
                            leftIcon={<FaUser />}
                            variant="ghost"
                            justifyContent="center"
                            onClick={handleEmployeeClick} // Toggle submenu
                        >
                            {isHovered && 'Employee'}
                        </Button>
                    </Tooltip>

             
                    {/* Submenu for Employee Management */}
                    <Collapse in={showEmployeeSubmenu} animateOpacity>
                        <Box pl={10}>
                            <Button variant="ghost" onClick={() => handleNavigation('/employee')}>Employee Management</Button>
                            <Button variant="ghost" onClick={() => handleNavigation('/employeeprofiles')}>Employee Profile</Button>
                            <Button variant="ghost" onClick={() => handleNavigation('/department')}>Department Head</Button>
                            <Button variant="ghost" onClick={() => handleNavigation('/designation')}>Designation</Button>
                            <Button variant="ghost" onClick={() => handleNavigation('/locationtransfer')}>Location Transfer</Button>
                        </Box>
                    </Collapse>

                    {/* Other icons */}
                    <Tooltip label="Attendance&Time" placement="right">
                        <Button leftIcon={<FaClock />} variant="ghost" justifyContent="center">
                            {isHovered && 'Attendance&Time'}
                        </Button>
                    </Tooltip>
                    <Tooltip label="Onboarding" placement="right">
                        <Button leftIcon={<FaSuitcase />} variant="ghost" justifyContent="center">
                            {isHovered && 'Onboarding'}
                        </Button>
                    </Tooltip>
                    <Tooltip label="Self Services" placement="right">
                        <Button leftIcon={<FaCogs />} variant="ghost" justifyContent="center">
                            {isHovered && 'Self Services'}
                        </Button>
                    </Tooltip>
                    <Tooltip label="Leave Management" placement="right">
                        <Button leftIcon={<FaBook />} variant="ghost" justifyContent="center">
                            {isHovered && 'Leave Management'}
                        </Button>
                    </Tooltip>
                    <Tooltip label="Payroll" placement="right">
                        <Button leftIcon={<FaDollarSign />} variant="ghost" justifyContent="center">
                            {isHovered && 'Payroll'}
                        </Button>
                    </Tooltip>
                    <Tooltip label="Asset Management" placement="right">
                        <Button leftIcon={<FaToolbox />} variant="ghost" justifyContent="center">
                            {isHovered && 'Asset Management'}
                        </Button>
                    </Tooltip>
                    <Tooltip label="Performance Management" placement="right">
                        <Button leftIcon={<FaChartLine />} variant="ghost" justifyContent="center">
                            {isHovered && 'Performance Management'}
                        </Button>
                    </Tooltip>
                      {/* Core Hr Management Icon */}
              <Tooltip label="CoreHr" placement="right">
                        <Button
                            leftIcon={<FaCogs />}
                            variant="ghost"
                            justifyContent="center"
                            onClick={handleCoreHrClick} // Toggle submenu
                        >
                            {isHovered && 'CoreHr'}
                        </Button>
                    </Tooltip>

                    <Collapse in={showCoreHrSubmenu} animateOpacity>
                        <Box pl={10}>
                            <Button variant="ghost" onClick={() => handleNavigation('/generateletter')}>Generate Offer Letter</Button>
                        </Box>
                    </Collapse>
                    {/* <Tooltip label="Core HR" placement="right">
                        <Button leftIcon={<FaCogs />} variant="ghost" justifyContent="center">
                            {isHovered && 'Core HR'}
                        </Button>
                    </Tooltip> */}
                    <Tooltip label="Expense Management" placement="right">
                        <Button leftIcon={<FaDollarSign />} variant="ghost" justifyContent="center">
                            {isHovered && 'Expense Management'}
                        </Button>
                    </Tooltip>
                    <Tooltip label="Services" placement="right">
                        <Button leftIcon={<FaToolbox />} variant="ghost" justifyContent="center">
                            {isHovered && 'Services'}
                        </Button>
                    </Tooltip>
                    <Tooltip label="Profile" placement="right">
                        <Button leftIcon={<FaUser />} variant="ghost" justifyContent="center">
                            {isHovered && 'Profile'}
                        </Button>
                    </Tooltip>
                    <Tooltip label="Logout" placement="right">
                        <Button leftIcon={<FaSignOutAlt />} variant="ghost" justifyContent="center">
                            {isHovered && 'Logout'}
                        </Button>
                    </Tooltip>
                </VStack>
            </Box>

            {/* Main Content */}
            <Box
                as="main"
                ml={isOpen || isHovered ? "280px" : "80px"} // Adjust margin-left based on sidebar state
                transition="margin-left 0.3s ease"
                w="100%"
                p={5} // Padding for table content
            >
                {/* Add your table or other content here */}
            </Box>
        </Flex>
    );
};

export default Sidebar;
