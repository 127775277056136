import React from "react";
import { useState, useEffect } from "react";
import Axios, {
  HrmAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Rnd } from "react-rnd";
import moment from "moment";

// Internal module import
import "./DraggableDepartmentHeadTable.css";

// Chakra UI modules
import { Badge } from "@chakra-ui/react";
import {
  useDisclosure,
  Button,
  Modal,
  Stack,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  IconButton,
  Tooltip,
  useToast,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";

// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";
import { CgClose } from "react-icons/cg";

const DraggableDepartmentTable = ({
  isLoading,
  columns,
  data,
  departmentData,
  getData,
  setDepartmentData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsDepartment,
  pageNumbers,
}) => {
  const toast = useToast();
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [cityData, setCityData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({});
  const [editedDepartment, setEditedDepartment] = useState({});
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  const [size, setSize] = useState("xl");
  const [formData, setFormData] = useState({
    PhoneWithCode: "",
    CountryData: "",
  });

  useEffect(() => {
    setEditedDepartment(selectedDepartment);
  }, [selectedDepartment]);

  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;
      setEditedDepartment((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (name === "Country") {
        setSelectedDepartment((prev) => ({
          ...prev,
          Country: value,
          City: "",
        }));
        const { data } = await MasterDataAPIInstance.get
          ("get_city");
      
        setCityData(data.data);
      } else if (name === "City") {
        setSelectedDepartment((prev) => ({
          ...prev,
          City: value,
        }));
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const handleCloseModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
    onClose(true);
  };

  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number) => number
      );
      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);

  const sortTable = (key) => {
    let newSortOrder = "asc";
    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }
    const sortedData = [...departmentData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
    setSortKey(key);
    setSortOrder(newSortOrder);
    if (typeof setDepartmentData === "function") {
      setDepartmentData(sortedData);
    }
  };

  const handleFullSizeClick = () => {
    setSize("xl");
    onOpen();
  };

  const style = {
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "scroll",
    overflowX: "hidden",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };

  return (
    <div>
      {!isLoading ? (
        tableColumnsDepartment?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {false ? (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={checkBoxAll}
                        onChange={() => {
                          handleCheckbox(data.map((item) => item.id));
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          setCheckBoxAll((prev) => {
                            if (prev === true) {
                              setCheckBoxSelected([]);
                            }
                            return !prev;
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={
                          checkBoxSelected.length === 0
                            ? false
                            : activePageCheckBoxSelect.includes(currentPage)
                        }
                        onChange={() => {
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          const idsToAdd = data.map((item) => item.id);
                          setCheckBoxSelected((prev) => {
                            const currentData = new Set(prev);
                            if (
                              activePageCheckBoxSelect.includes(currentPage)
                            ) {
                              idsToAdd.forEach((item) => {
                                currentData.delete(item);
                              });
                            } else {
                              idsToAdd.forEach((item) => {
                                currentData.add(item);
                              });
                            }
                            return Array.from(currentData);
                          });
                        }}
                      />
                    )}
                  </div>
                </th>
                <th className="border border-slate-300 whitespace-nowrap text-center">
                  Edit

                </th>

                {tableColumnsDepartment.map(
                  (column,index) =>
                    column.label !== "Id" &&
                    column.label !== "DialCode" &&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, column.key)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, column.key)}
                      >
                        <>
                          <span style={{ cursor: "all-scroll" }}>
                            {column.label}
                          </span>
                          <BiSort
                            style={{
                              marginLeft: "160px",
                              marginTop: "-22px",
                              cursor: "pointer",
                            }}
                            onClick={() => sortTable(column.key)}
                          />
                        </>
                      </th>
                    )
                )}
              </tr>
            </thead>
            <tbody>
              {data?.length !== 0 ? (
                data.map((row) => (
                  <tr
                    key={row.id}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                        {false ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected.includes(row.id)
                            }
                          />
                        )}
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        label="Edit Row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="right-start"
                      >
                        <IconButton
                          icon={<MdEdit />}
                          onClick={() => {
                            setSelectedDepartment(row);
                            handleFullSizeClick();
                          }}
                          boxSize={6}
                          size={"20px"}
                        />
                      </Tooltip>
                    </td>
                    {tableColumnsDepartment.map(
                      (column) =>
                        column.label !== "Id" &&
                        column.label !== "DialCode" &&
                        column.isVisible === true && (
                          <td
                            key={`${row.id}-${column.key}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {column.key === "Email" ? (
                              <a href={`mailto:${row[column.key]}`}>
                                {row[column.key]}
                              </a>
                            ) : column.key === "DeletionFlag" ? (
                              row[column.key] === 1 ? (
                                <Badge colorScheme="red">Deleted</Badge>
                              ) : (
                                <Badge colorScheme="green">Not Deleted</Badge>
                              )
                            ) : (
                              row[column.key]
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsDepartment.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}
      {isOpen && (
        <div className={`singleEditModal ${scrollBehavior}`}>
          <Rnd
            style={style}
            default={{
              x: 0,
              y: 0,
              height: 500,
            }}
            cancel="X"
            allowAnyClick={true}
          >
            <header
              style={{ float: "left", fontSize: "20px", fontWeight: "bold" }}
            >
              Edit Department Information
            </header>
            <div
              onClick={handleCloseModal}
              style={closeModal}
            >
              <CgClose />
            </div>
            <br />
            {selectedDepartment && Object.keys(selectedDepartment).length > 0 && (
              <form>
                <Badge variant="solid" colorScheme="red" fontSize="12pt">
                  Department: {selectedDepartment["Department"]}
                </Badge>
                <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                  <GridItem colSpan={2}>
                    <FormControl>
                      <FormLabel>
                        Department<span style={{ color: "red" }}>*</span>
                      </FormLabel>
                      <Input
                        type="text"
                        name="Department"
                        value={editedDepartment["Department"] || ""}
                        onChange={handleChange}
                        // disabled
                      />
                       <FormLabel>Reporting_Manager</FormLabel>
                      <Input
                        type="text"
                        name="Reporting_Manager"
                        value={editedDepartment["Reporting_Manager"] || ""}
                        onChange={handleChange}
                      />
                       <FormLabel>No_Of_Employee</FormLabel>
                      <Input
                        type="text"
                        name="No_Of_Employee"
                        value={editedDepartment["No_Of_Employee"] || ""}
                        onChange={handleChange}
                      />
                      <FormLabel>Department_Description</FormLabel>
                      <Input
                        type="text"
                        name="Department_Description"
                        value={editedDepartment["Department_Description"] || ""}
                        onChange={handleChange}
                      />
                       <FormLabel>Location</FormLabel>
                      <Input
                        type="text"
                        name="Location"
                        value={editedDepartment["Location"] || ""}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              </form>
            )}
            <Stack
              spacing={4}
              direction="row"
              align="center"
              float="right"
              marginTop={5}
            >
              <Button onClick={handleCloseModal}>Cancel</Button>
            </Stack>
          </Rnd>
        </div>
      )}
    </div>
  );
};

export default DraggableDepartmentTable;
