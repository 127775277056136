/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FileSaver } from "file-saver";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { read, utils, writeFile } from "xlsx";
import { color } from "framer-motion";
import moment, { utc } from "moment/moment";
import { Rnd } from "react-rnd";
// External module
import Axios, {
  HrmAPIInstance,
} from "../../api-instance";
import "../../assets/css/container.css";
import { DraggableEmployeedocumentTable,Header } from "../../components";

// Internal modules
import "./Generateletter.css";
// import Sidebar from "../HrmDashboard/sidebar.js";
import Selectallpages from "./Selectallpages";
import Dashboard from "../HrmDashboard/HrmDashboard.js";
 import { Checkvalidation } from "./helpers/Checkvalidation";
//   import { Massupload } from "./helpers/Massupload";
// Chakara ui modules
import { useDisclosure } from "@chakra-ui/react";
import { Avatar } from "@chakra-ui/react";

// import employeeJson from './employee.json';
import { Flex, Select, useToast, Box, Text, Tooltip } from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

import { FormControl, FormLabel, Input } from "@chakra-ui/react";

import { Radio, RadioGroup, Stack,Drawer } from "@chakra-ui/react";
// Icons import
import { MdDownloading } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import { LiaFileUploadSolid } from "react-icons/lia";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { GoMoveToEnd } from "react-icons/go";
import { FaFilter } from "react-icons/fa";
import { BsFiletypeExe } from "react-icons/bs";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle, IoMdFastforward } from "react-icons/io";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiArrowRightCircle } from "react-icons/fi";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import { IoMdPrint } from "react-icons/io";
import { MdSelectAll } from "react-icons/md";
import { CgClose } from "react-icons/cg";

const Generateletter = () => {
  // State Variables
  const navigate = useNavigate();
  const deleterecordshowhide = true;
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [size, setSize] = React.useState("xl");
  // const [showSidebar, setShowSidebar] = useState(false);
  const [photoUpload, setPhotoUpload] = useState(null);
  const [employeeNumber, setEmployeeNumber] = useState("");
  const handleOpenHrmDocs = () => {
    onOpen();
  };
  const handleCloseHrmDocs = () => {
    onClose();
  };
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );
  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const {
    isOpen: isOpenManagecolumn,
    onOpen: onOpenManagecolumn,
    onClose: onCloseManagecolumn,
  } = useDisclosure();
  const {
    isOpen: isOpenDownload,
    onOpen: onOpenDownload,
    onClose: onCloseDownload,
  } = useDisclosure();
  const {
    isOpen: isOpenMassupdate,
    onOpen: onOpenMassupdate,
    onClose: onCloseMassupdate,
  } = useDisclosure();
  const {
    isOpen: isOpenReportDelete,
    onOpen: onOpenReportDelete,
    onClose: onCloseReportDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenReportMassEmail,
    onOpen: onOpenReportMassEmail,
    onClose: onCloseReportMassEmail,
  } = useDisclosure();
  const {
    isOpen: isOpenReportMassupdate,
    onOpen: onOpenReportMassupdate,
    onClose: onCloseReportMassupdate,
  } = useDisclosure();

  const cancelRef = React.useRef();
  const searchRef = useRef(null);
  const editorRef = useRef(null);
  const [employeeno, setEmployeeno] = useState([]);
  const [movies, setMovies] = useState([]);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const [excelOpen, setExcelOpen] = useState(false);
  const toast = useToast();
  const [isActionOpen, setActionOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isManagecolumnOpen, setManagecolumnOpen] = useState(false);
  const [employeeDocData, setEmployeeDocData] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [handleCheckboxFilter, setHandleCheckboxFilter] = useState("OR");
  const [updatedConditions, setUpdatedConditions] = useState([]);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [prospectWarning, setProspectWarning] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState("");
  const [selectedOption, setSelectedOption] = useState("Select a field");
  const [updatedValue, setUpdatedValue] = useState(null);
  const [istoOpen, setIsToOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isdelete, setIsdelete] = useState(false);
  const [isconvert, setIsconvert] = useState(false);
  // Column Toggle
  const [columns, setColumns] = useState({
    id: true,
    employeeId: true,
    URL_Object: true,
    userId: true,
    EmployeeNumber: true,
    First_Name: true,
    Last_Name: true,
    Generate_Letter:true,
    Doc_Status:true,
    Remarks:true,
    CreatedAt: true,
    ChangedAt: true,
    ChangeBy: true,
    EmployeeOwner: true,
  });
  const [tableColumnsEmployeeDoc, settableColumnsEmployeeDoc] = useState([]);

  useEffect(() => {
    getData();
  }, [isdelete, isconvert]); // isdelete state changes

  const handleRecordDelete = () => {
    setIsdelete(!isdelete);
  };
  const handleRecordConvert = () => {
    setIsconvert(!isconvert);
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setHandleCheckboxFilter(value);
  };

  // Export data
  const handleExportOptionChange = (e) => {
    setSelectedExportOption(e.target.value);
    setDownloadComplete(false);
  };
  const handleExport = () => {
    setIsDownloading(true);
    setDownloadComplete(false);

    if (selectedExportOption === "Excel") {
      // Simulate the download process with a setTimeout
      setTimeout(() => {
        exportToExcel(exportData, "Employee");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000); // 2 seconds delay for demonstration purposes
    } else if (selectedExportOption === "CSV") {
      setTimeout(() => {
        exportToCSV(exportData, "Employee");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    } else if (selectedExportOption === "PDF") {
      setTimeout(() => {
        exportToPDF(exportData, "Employee");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    } else if (selectedExportOption === "Text") {
      setTimeout(() => {
        exportToText(exportData, "Employee");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    }
  };
  const exportToExcel = (data, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const dataArray = data.map((item) => [
      item.employeeId,
      item.userId,
      item.EmployeeNumber,
      item.First_Name,
      item.Last_Name,
      item.Generate_Letter,
      item.Doc_Status,
      item.Remarks,
      item.CreatedAt,
      item.ChangedAt,
      item.ChangeBy,
      item.EmployeeOwner,
      
    ]);
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "employeeId",
        "userId",
        "EmployeeNumber",
        "First_Name",
        "Last_Name",
        "Generate_Letter",
        "Doc_Status",
        "Remarks",
       "CreatedAt",
       "ChangedAt",
       "ChangeBy",
       "EmployeeOwner",

      ],
      ...dataArray,
    ]);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Employee");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;

    saveAs(dataBlob, finalFileName);
  };
  const exportToCSV = (data, fileName) => {
    const dataArray = data.map((item) => [
      item.employeeId,
      item.userId,
      item.EmployeeNumber,
      item.First_Name,
      item.Last_Name,
      item.Generate_Letter,
      item.Doc_Status,
      item.Remakrs,
      item.CreatedAt,
      item.ChangedAt,
      item.ChangeBy,
      item.EmployeeOwner,  
    ]);

    const csvContent = [
      [
         "employeeId",
        "userId",
        "EmployeeNumber",
        "First_Name",
        "Last_Name",
       "Generate_Letter",
       "Doc_Status",
       "Remarks",
       "CreatedAt",
       "ChangedAt",
       "ChangeBy",
       "EmployeeOwner",
      ],
      ...dataArray.map((row) => row.join(",")),
    ].join("\n");

    const fileType = "text/csv;charset=utf-8;";
    const fileExtension = ".csv";

    const csvDataBlob = new Blob([csvContent], {
      type: fileType,
    });

    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;

    saveAs(csvDataBlob, finalFileName);
  };
  // Make sure to import 'jsPDF' and 'jspdf-autotable' libraries before using this code

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    setPhotoUpload(file);
  };

  const exportToPDF = (data, fileName) => {
    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";

    const doc = new jsPDF(orientation, unit, size);
    const tableColumnsEmployeeDoc = [
      "employeeId",
      "userId",
      "EmployeeNumber",
      "First_Name",
      "Last_Name",
      "Generate_Letter",
      "Doc_Status",
      "Remarks", 
     "CreatedAt",
     "ChangedAt",
     "ChangeBy",
     "EmployeeOwner",
    ];

    const tableRows = data.map((item) => {
      return tableColumnsEmployeeDoc.map((column) => item[column]);
    });

    const marginLeft = 40;
    const marginTop = 40;
    const titleFontSize = 15;

    doc.setFontSize(titleFontSize);

    const title = "Employee Report";
    doc.text(title, marginLeft, marginTop);

    const tableOptions = {
      startY: marginTop + titleFontSize + 20,
      head: [tableColumnsEmployeeDoc],
      body: tableRows,
      didDrawCell: (data) => {
        doc.rect(
          data.cell.x,
          data.cell.y,
          data.cell.width,
          data.cell.height,
          "S"
        );
      },
    };

    doc.autoTable(tableOptions);

    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.pdf`;

    doc.save(finalFileName);
  };

  const exportToText = (data, fileName) => {
    const tableColumnsEmployeeDoc = [
      "employeeId",
        "userId",
        "EmployeeNumber",
        "First_Name",
        "Last_Name",
       "Generate_Letter",
       "Doc_Status",
       "Remarks",  
       "CreatedAt",
       "ChangedAt",
       "ChangeBy",
       "EmployeeOwner",
    ];

    const tableRows = data.map((item) => {
      return Object.values(item).join(",");
    });

    const textContent = [tableColumnsEmployeeDoc.join(",")]
      .concat(tableRows)
      .join("\n");

    const fileType = "text/plain;charset=utf-8;";
    const fileExtension = "txt";

    const textDataBlob = new Blob([textContent], {
      type: fileType,
    });
    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.${fileExtension}`;
    saveAs(textDataBlob, finalFileName);
  };

  const handleCheckboxColumn = (event, item) => {
    settableColumnsEmployeeDoc((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });

    const newData = tableColumnsEmployeeDoc.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );

    localStorage.setItem("tableColumnsEmployeeDoc", JSON.stringify(newData));
  };
  const handleCheckbox = (id) => {
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
  };

  //get timezone api
  // Timezone API
  //  const [timezoneData, setTimezoneData] = useState([]);
  //  useEffect(() => {
  //    const getData = async () => {
  //      try {
  //        const { data } = await Axios.get(`/masterdata/get_timezone`);
  //        setTimezoneData(data.data);
  //        console.log(data.data);
  //      } catch (error) {
  //        toast({
  //          title: error.message,
  //          position: "bottom-center",
  //          isClosable: true,
  //          status: "error",
  //          containerStyle: {
  //            width: "98.5vw",
  //            maxWidth: "98.5vw",
  //            alignContent: "center",
  //          },
  //        });
  //      }
  //    };
  //    getData();
  //  }, []);
  // Admin API
  const [userdata, setUserdata] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await HrmAPIInstance.get(
          `get_user?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );

        const userDataList = response?.data?.data || [];
        setUserdata(userDataList);
      } catch (error) {
        // Handle error
      }
    };

    getData();
  }, []);

  // Extracting the roles from user data
  const userRoles = userdata.map((user) => user.Role);

  // Check if the user has the 'Admin' role
  const isAdmin = userRoles.includes("Admin");
  // End Admin API
  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await HrmAPIInstance.get(
        `get_employeedoc?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&isDeleted=${isdelete}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      
      if (data.data?.length === 0) {
        setEmployeeDocData([]);
        return setColumns2([]);
      }
  
      const array1 = Object.keys(columns).filter((key) => columns[key]);
  
      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "First_Name"
              ? false
              : item === "Last_Name"
              ? false
              : item === "Generate_Letter"
              ? false
              : item === "Doc_Status"
              ? false
              : item === "Remarks"
              ? false
              
              : true,
        };
      });
  
      const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));
  
      setColumns2(filteredDataHeader);
  
      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};
  
        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            if ([ "DateOfJoining", "Confirmation_Date"].includes(key) && item[key]) {
              filteredItem[key] = new Date(item[key]).toLocaleDateString("en-US", {
                timeZone: "UTC", 
              });
            } else {
              filteredItem[key] = item[key];
            }
          }
        });
  
        return filteredItem;
      });
  
      setEmployeeDocData(filteredData);
      setExportData(data.data);
    } catch (error) {
      toast({
        title: error?.message || "An unexpected error occurred.",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  

  useEffect(() => {
    if (window.localStorage.getItem("employeefilter")?.length === 0) {
      return setEmployeeDocData([]);
    }

    if (window.localStorage.getItem("employeefilter")) {
      getFilterData();
    } else {
      getData();
    }
  }, []);

  const toggleActionDropdown = () => {
    setActionOpen(!isActionOpen);
  };
  const handleActionSelection = () => {
    setActionOpen(false);
    toggleActionDropdown();
  };
  // Filter Data
  const [filterRows, setFilterRows] = useState(() => {
    return JSON.parse(localStorage.getItem("employeefilterRows"))
      ? JSON.parse(localStorage.getItem("employeefilterRows"))
      : [0];
  });
  const [filterConditions, setFilterConditions] = useState(() => {
    const savedConditions = JSON.parse(
      localStorage.getItem("employeefilterConditions")
    );
    return savedConditions || {};
  });

  const [inputValues, setInputValues] = useState({ ...filterConditions });
  const [selectValues, setSelectValues] = useState({ ...filterConditions });
  const addFilterRow = () => {
    setFilterRows((prevFilterRows) => [
      ...prevFilterRows,
      prevFilterRows?.length,
    ]);
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [filterRows?.length]: {
        condition: "",
        value: "",
      },
    }));
  };

  useEffect(() => {
    localStorage.setItem("employeefilterRows", JSON.stringify(filterRows));
    localStorage.setItem(
      "employeefilterConditions",
      JSON.stringify(filterConditions)
    );
  }, [filterRows, filterConditions]);

  const removeFilterRow = (index) => {
    const newFilterRows = filterRows.filter((rowIndex) => rowIndex !== index);
    setFilterRows(newFilterRows);

    setFilterConditions((prevConditions) => {
      const { [index]: removedCondition, ...restConditions } = prevConditions;
      return restConditions;
    });
  };

  const handleSubmitFilter = async () => {
    localStorage.setItem(
      "employeefilterConditions",
      JSON.stringify(filterConditions)
    );

    const transformedConditions = {};

    filterRows.forEach((index) => {
      const column = document.getElementById(`column-select-${index}`).value;
      const condition = document.getElementById(`filter-type-${index}`).value;
      const value = document.getElementById(`value-input-${index}`).value;

      if (column && condition && value) {
        transformedConditions[column + index] = {
          condition,
          value,
        };
      }
    });

    const a = JSON.stringify(transformedConditions, null, 2);
    const sendData = JSON.parse(a);
    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };
    try {
      const { data } = await HrmAPIInstance.post(
        `massfilter_employee?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&filterType=${handleCheckboxFilter}&isDeleted=${isdelete}`,
        { sendData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data?.data?.length === 0) {
        setFilterOpen(false);
        localStorage.setItem("employeefilter", JSON.stringify([]));
        return setEmployeeDocData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "First_Name"
              ? false
              : item === "Last_Name"
              ? false
              : item === "Generate_Letter"
              ? false
              : item === "Doc_Status"
              ? false
              : item === "Remarks"
              ? false
              
              : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setEmployeeDocData(filteredData);

      localStorage.setItem("employeefilter", JSON.stringify(filteredData));
      setFilterOpen(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  const handleFilterConditionSelect = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        condition: selectedValue,
      },
    }));
  };
  const handleFilterValueChange = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        value: selectedValue,
      },
    }));
  };

  const getFilterData = async (sendData) => {
    try {
      const data = {
        data: JSON.parse(window.localStorage.getItem("employeefilter")),
      };

      if (data?.data?.length === 0) {
        setFilterOpen(false);
        return setEmployeeDocData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "First_Name"
              ? false
              : item === "Last_Name"
              ? false
              : item === "Generate_Letter"
              ? false
              : item === "Doc_Status"
              ? false
              : item === "Remarks"
              ? false
              : true,
        };
      });

      // // Filter the data based on the keys in filterKeys array
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setEmployeeDocData(filteredData);

      setFilterOpen(false);
    } catch (error) {
      //console.log(error.message);
    }
  };

  const handleClearFilters = () => {
    const inputs = document.querySelectorAll(".value-input");
    const newInputValues = { ...inputValues };
    inputs.forEach((input) => {
      const index = inputValues;
      newInputValues[index] = input.value;
      input.value = "";
    });

    const selects = document.querySelectorAll(".column-select, .filter-type");
    selects.forEach((select) => {
      select.value = "select";
    });

    setFilterConditions({});
    setFilterOpen(false);
    setFilterRows([0]);
    getData();
    setInputValues(newInputValues);
    localStorage.removeItem("employeefilterConditions");
    window.localStorage.removeItem("employeefilter");
    window.localStorage.removeItem("employeefilterRows");
    // localStorage.setItem("filterInputValues", JSON.stringify(newInputValues));
  };

  useEffect(() => {
    const array1 = columns && Object?.keys(columns);
    const array2 = employeeDocData[0] && Object?.keys(employeeDocData[0]);

    const savedInputValues = localStorage.getItem("filterInputValues");
    if (savedInputValues) {
      setInputValues(JSON.parse(savedInputValues));
    }

    if (window.localStorage.getItem("employeefilter")?.length === 0) {
      return setEmployeeDocData([]);
    }

    if (window.localStorage.getItem("employeefilter")) {
      getFilterData();
    } else {
      getData();
      getFilterData();
    }
  }, [columns]);

  // Mass Update Input Type

  const handleOptionChange = (newValue) => {
    setSelectedOption(newValue);
  };

  const handleSubmitUpdate = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);

      const { data } = await HrmAPIInstance.put(
        `update_employee?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? employeeDocData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          update: {
            field: selectedOption,
            value: updatedValue,
            // DialCode: formData.CountryData.dialCode, // Include dial code
            // Phone: formData.Phone, // Include updated phone number
            // FollowUpDate: formData.FollowUpDate,
          },
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Employee Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        setUpdatedValue(""); // Clear the input field
        handleOptionChange("Select a field"); // Reset the select option
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
    console.log("selectedOption:", selectedOption);
    console.log("updatedValue:", updatedValue);
  };
  const handleSubmitDelete = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);

      const { data } = await HrmAPIInstance.post(
        `delete_employee?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? employeeDocData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: `Employee Deleted Successfully!`,
          position: "bottom-right",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "1500px",
            maxWidth: "100%",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        onCloseReportDelete();
      } else {
        toast({
          title: data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "1500px",
            maxWidth: "100%",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        onCloseReportDelete();
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "1500px",
            maxWidth: "100%",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "1500px",
            maxWidth: "100%",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "1500px",
            maxWidth: "100%",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      onCloseReportDelete();
    }

    if (selectAllPages) {
      localStorage.removeItem("employeefilter");
      localStorage.removeItem("employeefilterConditions");
      localStorage.removeItem("employeefilterRows");
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      setFilterRows([0]);
      setFilterConditions({});
      return;
    }

    if (localStorage.getItem("employeefilter")) {
      const storedData = JSON.parse(
        localStorage.getItem("employeefilter") || []
      );

      //Define the ID of the item you want to remove
      const idToRemove = selectAllPages
        ? employeeDocData.map((item) => item.id)
        : checkBoxSelected; // Replace with the ID you want to remove
      const newArray = storedData.filter(
        (item) => !idToRemove.includes(item.id)
      );
      //Update the array in localStorage
      localStorage.setItem("employeefilter", JSON.stringify(newArray));
      if (window.localStorage.getItem("employeefilter")) {
        getFilterData();
      } else {
        getData();
        getFilterData();
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    } else {
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
  };
  const handleOpenEditor = (event) => {
    if (event) {
      event.preventDefault(); // Prevent the default link behavior
    }
    setIsEditorOpen(true);
  };

  const handleCloseEditor = () => {
    setIsEditorOpen(false);
  };

  const handleSubmitMassEmail = async () => {
    try {
      const selectedIds = checkBoxSelected;
      const recipients = employeeDocData
        .filter((item) => selectedIds.includes(item.id))
        .map((item) => item.Email);

      const emailData = {
        recipients,
        subject,
        body,
      };
      const response = await HrmAPIInstance.post(
        "send_email",
        emailData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      const { data } = response;
      if (data.success) {
        toast({
          title: "Email sent successfully!",
          position: " bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setBody("");
      setIsEditorOpen(false);
      setSubject("");
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
    setSelectAllPages(false);
    setCheckBoxAll(false);
    setCheckBoxSelected(false);
  };

  console.log("Employee data:", employeeDocData);
  console.log("set employee data:", setEmployeeDocData);

    const handleSearch = async (event) => {
      const queryData = event.target.value;

      const dataSet = {
        userId: JSON.parse(localStorage.getItem("user")).userId,
      };

      const { data } = await HrmAPIInstance.get(
        `search_employee?userId=${JSON.parse(localStorage.getItem("user")).userId
        }&query=${queryData}&isDeleted=${isdelete}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.data?.length === 0) {
        return setEmployeeDocData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
          item === "First_Name"
          ? false
          : item === "Last_Name"
            ? false
            : item === "Generate_Letter"
              ? false
              : item === "Doc_Status"
              ? false
              :item === "Remarks"
              ? false
                   : true,
        };
      });
      const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });

        return filteredItem;
      });

      setEmployeeDocData(filteredData);
    };
  // Pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };

  const pageNumbers = Math.ceil(employeeDocData?.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.

    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);
    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        ).map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };
  // filter Part Combinationget
  const [showOptions, setShowOptions] = useState(false);
  const [showPhoneOptions, setShowPhoneOptions] = useState(false);

  const handleFilterTypeSelect = (event, index) => {
    const selectedValue = event.target.value;
    setShowOptions(selectedValue !== "select");
    setShowPhoneOptions(
      selectedValue === "Phone" || selectedValue === "FollowUpDate"
    );

    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        column: selectedValue,
      },
    }));
  };

  useEffect(() => {
    if (localStorage.getItem("tableColumnsEmployeeDoc")) {
      settableColumnsEmployeeDoc(
        JSON.parse(localStorage.getItem("tableColumnsEmployeeDoc"))
      );
    } else {
      settableColumnsEmployeeDoc(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);

  //console.log(columns2);
  const handlePrint = async () => {
    const pdfDataResponse = await HrmAPIInstance.post(
      `http://localhost:5000/employeedoc/add_olcreation_and_pdf?userId=10001&employeeNumber=BHT00001`,
      {
        "Date_":"11/2/2022",
        "First_Name":"Moni",
        "Last_Name":"Sha",
        "Designation":"Developer",
        "Location":"Chennai",
        "DateOfJoining":"21/11/2023"
    },
      {
        headers: {
          "Content-Type": "application/json",
         
        },
        responseType: "blob", // Set the response type to 'blob' to receive binary data
      }
    )
      .then((response) => {
        // Create a Blob from the binary data
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });

        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Open the PDF in a new tab
        window.open(pdfUrl, "_blank");
      }).catch((error) => {
        console.error("Error fetching PDF:", error);
      });
  }
  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };
  // const handleRecordDelete = () => {
  //   setIsdelete(!isdelete);
  // };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsEmployeeDoc];

    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem(
      "tableColumnsEmployeeDoc",
      JSON.stringify(updatedColumns)
    );
    settableColumnsEmployeeDoc(updatedColumns);
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };
  const columnstyle = {
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "auto",
    scrollbarWidth: "thin", // Set the width of the scrollbar to thin
    scrollbarColor: "rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1)",
  };

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };

   //class declaration
    const checkValidation = new Checkvalidation(
      checkBoxSelected,
      toast,
      onOpenMassupdate,
      onOpenReportDelete,
      handleOpenEditor,

    );

  return (
    <>
      <Header/>

      <div className="wrapper">
   
      <div className="mx-auto  bg-blue-950 text-white flex flex-wrap dark:bg-indigo-900">
{/*   
      <Sidebar  
  // onClick={handleOpenHrmDocs}
  size={25}
  style={{
    position: "absolute",
    cursor: "pointer",
    marginTop: "15px",  // Ensure the unit is specified
    marginLeft: "10px"  // Ensure the unit is specified
  }}
/> */}
{/* <Drawer
  isOpen={isOpen}
  placement='left'
  onClose={handleCloseHrmDocs}
/> */}

      <div className="flex flex-wrap">
        <FaFilter
          size={25}
          onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
          style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }}
        />
        </div>
        &nbsp;&nbsp;
        <>
        
          <div className="pt-2 ml-10 relative mx-auto text-gray-600">
          
            <input
              className="border-2  border-gray-300 bg-white h-8 px-5 pr-10 rounded-lg text-sm focus:outline-none"
              ref={searchRef}
              type="search"
              name="search"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
        </>
        &nbsp;
        
        <TbAdjustmentsHorizontal
          onClick={
            isOpenManagecolumn ? onCloseManagecolumn : onOpenManagecolumn
          }
          size={25}
          style={{ cursor: "pointer", marginTop: "15", marginRight: "30" }}
        />
        &nbsp;
        <div className="relative inline-block">
          <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950">
            <Link to="/addemployeedoc">Create Employee Document </Link>
          </button>
        </div>
        &nbsp;&nbsp;
        <div className="relative inline-block">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
            onClick={toggleActionDropdown}
          >
            Actions
          </button>
          {/* <MenuList>
    <MenuItem>New File</MenuItem>
    <MenuItem>New Window</MenuItem>
    <MenuDivider />
    <MenuItem>Open...</MenuItem>
    <MenuItem>Save File</MenuItem>
  </MenuList>
</Menu>    */}
 &nbsp;&nbsp;
          {isActionOpen && (
            <div className="absolute right-0 mt-1 py-2 w-48 bg-white border rounded shadow-lg dark:bg-slate-700 z-[9999]">
              <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                <FiEdit size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={() => {
                    checkValidation.handleEditClickMassupdate();
                    handleActionSelection();
                  }}
                >
                  Mass Update
                </span>
              </a>
              <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  checkValidation.handleEditClickMassdelete();
                }}
              >
                <RiDeleteBinLine size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Mass Delete
                </span>
              </a>
              <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
              {/* <FiPrinter size={20} /> */}
              <span
                className="flex-grow cursor-pointer"
                onClick={() => {
                  handleActionSelection();
                  handlePrint();
                }}
              >
                View
              </span>
            </a>
              <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  checkValidation.handleEditClickMassmail();
                }}
              >
                <HiOutlineMail size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Mass Email
                </span>
              </a>
            
              <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                <LiaFileUploadSolid size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={() => {
                    onOpen();
                    toggleActionDropdown();
                    handleActionSelection();
                  }}
                >
                  Mass Upload
                </span>
              </a>
              {/* <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  onOpenDownload();
                  setOverlay(<OverlayOne />);
                  toggleActionDropdown();
                }}
              >
                <MdOutlineDownloadForOffline size={20} />

                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Export Data
                </span>
              </a> */}
              {isAdmin && (<a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  onOpenDownload();
                  setOverlay(<OverlayOne />);
                  toggleActionDropdown();
                }}
              >
                <MdOutlineDownloadForOffline size={20} />

                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Export Data
                </span>
              </a>)}
              <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  toggleActionDropdown();
                  {
                    navigate("/employeetemplate");
                  }
                }}
              >
                <BsFiletypeExe size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Export Employee Template
                </span>
              </a>
            </div>
          )}
        </div>
        {/* Mass filter */}
        <>
          {isOpenFilter && (
            <>
              <div style={backdropStyle}></div>
              <div className={`FilterModal ${scrollBehavior}`}>
                <Rnd
                  style={style}
                  default={{
                    x: 0,
                    y: 0,
                    width: 600,
                    // height: 200,
                  }}
                  cancel=".cancelDragAndDrop"
                  allowAnyClick={true}
                >
                  <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Criteria
                  </header>
                  <div
                    className="closemodule cancelDragAndDrop"
                    onClick={() => {
                      onCloseFilter(false);
                    }}
                    style={closeModal}
                  >
                    <CgClose />
                  </div>
                  <br />
                  <FormControl className="cancelDragAndDrop">
                    {filterRows.map((index) => (
                      <Flex key={index}>
                        <Select
                          id={`column-select-${index}`}
                          placeholder="Select"
                          onChange={(e) => handleFilterTypeSelect(e, index)}
                          value={filterConditions[index]?.column || "select"}
                          size={"md"}
                          isDisabled={false}
                          blurInputOnSelect={true}
                          isSearchable={false}
                          style={{ position: "relative" }}
                        >
                          <option value="employeeNumberSeries">
                            EmployeeNumberSeries </option>
                          <option value="employeeNo">employeeNo</option>
                          <option value="First_Name">First_Name</option>
                          <option value="dob">dob</option>
                          <option value="gender">gender</option>
                          <option value="mobileNumber">mobileNumber</option>
                          <option value="reportingManager">
                            reportingManager
                          </option>
                          <option value="DeletionFlag">Deletion Flag</option>
                          <option value="Employee_Type">Employee_Type</option>
                          <option value="Employee_Status">Employee_Status</option>
                          <option value="dateOfJoining">dateOfJoining</option>
                          <option value="probationPeriod">
                            probationPeriod
                          </option>
                          <option value="confirmationDate">
                            confirmationDate
                          </option>
                          <option value="emailId">emailId</option>
                          <option value="aadharNumber">aadharNumber</option>
                          <option value="emergencyContactName">
                            emergencyContactName
                          </option>
                          <option value="emergencyContactNumber">
                            emergencyContactNumber
                          </option>
                          <option value="referredBy">referredBy</option>
                          <option value="fathersName">fathersName</option>
                          <option value="spousesName">spousesName</option>
                          <option value="costCenter">costCenter</option>
                          <option value="designation">designation</option>
                          <option value="department">department</option>
                          <option value="reportinglocation">
                            reportinglocation
                          </option>
                          <option value="panNumber">panNumber</option>
                          <option value="uanNumber">uanNumber</option>
                          
                          <option value="pfExcessContribution">
                            pfExcessContribution
                          </option>
                        </Select>
                        &nbsp;&nbsp;
                        <Select
                          id={`filter-type-${index}`}
                          onChange={(e) =>
                            handleFilterConditionSelect(e, index)
                          }
                          value={filterConditions[index]?.condition || "select"}
                          placeholder="Select"
                        >
                          {filterConditions[index]?.column === "CreatedAt" ||
                          filterConditions[index]?.column === "ChangedAt" ||
                          filterConditions[index]?.column === "DeletionFlag"  ? (
                            <>
                              <option value="equalsDate">=</option>
                              <option value="not-equalsDate">!=</option>
                              <option value="greaterDate">&gt;</option>
                              <option value="lesserDate">&lt;</option>
                              <option value="greater-equalDate">&gt;=</option>
                              <option value="lesser-equalDate">&lt;=</option>
                            </>
                          ) : filterConditions[index]?.column ===
                            "employeeNumberSeries" ? (
                            <>
                              <option value="isemployeename">is</option>
                              <option value="containsemployeename">
                                contains
                              </option>
                              <option value="startsWithemployeename">
                                starts with
                              </option>
                              <option value="endsWithemployeename">
                                ends with
                              </option>
                            </>
                          ) : filterConditions[index]?.column === "Phone" ? (
                            <>
                              <option value="equals">=</option>
                              <option value="not-equals">!=</option>
                              <option value="greater">&gt;</option>
                              <option value="lesser">&lt;</option>
                              <option value="greater-equal">&gt;=</option>
                              <option value="lesser-equal">&lt;=</option>
                            </>
                          ) : (
                            <>
                              <option value="is">is</option>
                              <option value="contains">contains</option>
                              <option value="startsWith">starts with</option>
                              <option value="endsWith">ends with</option>
                            </>
                          )}
                        </Select>
                        &nbsp;&nbsp;
                        {/* <Input
                        type="text"
                        id={`value-input-${index}`}
                        value={filterConditions[index]?.value || ""}
                        onChange={(e) => handleFilterValueChange(e, index)}
                      /> */}
                        {/* Conditionally render the input field based on the selected column */}
                        {["CreatedAt"].includes(
                          filterConditions[index]?.column
                        ) ? (
                          <Input
                            type="date"
                            id={`value-input-${index}`}
                            value={filterConditions[index]?.value || ""}
                            onChange={(e) => handleFilterValueChange(e, index)}
                          />
                        ) : (
                          <Input
                            type="text"
                            id={`value-input-${index}`}
                            value={filterConditions[index]?.value || ""}
                            onChange={(e) => handleFilterValueChange(e, index)}
                          />
                        )}
                        &nbsp;&nbsp;{" "}
                        <IoMdAddCircle
                          className="cursor-pointer"
                          size={70}
                          style={{ marginTop: "-15px", color: "green" }}
                          onClick={() => addFilterRow()}
                        />
                        {filterRows?.length > 1 && index > 0 && (
                          <BiSolidMinusCircle
                            className="cursor-pointer"
                            size={70}
                            style={{ marginTop: "-15px", color: "red" }}
                            onClick={() => removeFilterRow(index)}
                          />
                        )}
                      </Flex>
                    ))}
                  </FormControl>
                  <div
                    className="cancelDragAndDrop"
                    style={{
                      float: "right",
                      display: "block",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      colorScheme="green"
                      onClick={() => {
                        handleSubmitFilter();
                        onCloseFilter();
                      }}
                      mr={3}
                    >
                      Apply
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        handleClearFilters();
                        onCloseFilter();
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                  <div
                    style={{
                      float: "left",
                      marginTop: "10px",
                      display: "block",
                    }}
                  >
                    <RadioGroup
                      className="cancelDragAndDrop"
                      style={{ flex: 1 }}
                      value={handleCheckboxFilter}
                    >
                      <Stack direction="row">
                        <Radio value="AND" onChange={handleCheckboxChange}>
                          All
                        </Radio>
                        <Radio value="OR" onChange={handleCheckboxChange}>
                          Any
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </div>
                </Rnd>
              </div>
            </>
          )}
        </>
        {/* Manage column */}
        <>
          {isOpenManagecolumn && (
            <>
              <div style={backdropStyle}></div>
              <div style={modalStyleUpdate}>
                <Rnd
                  style={columnstyle}
                  default={{
                    x: 0,
                    y: 0,
                    width: 400,
                    height: 500,
                  }}
                  cancel="X"
                  allowAnyClick={true}
                >
                  <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Manage Column
                  </header>
                    <div
                    onClick={() => {
                      onCloseManagecolumn(false);
                    }}
                    style={closeModal}
                  >
                    <CgClose />
                  </div>
                  <br />

                  {tableColumnsEmployeeDoc.map(
                    (label, index) =>
                      label.key !== "id" &&
                      label.key !== "DialCode" && (
                        <label
                          key={index}
                          className="flex items-center"
                          draggable
                          onDragStart={(event) => handleDragStart(event, index)}
                          onDragOver={handleDragOver}
                          onDrop={(event) => handleDrop(event, index)}
                        >
                          <input
                            type="checkbox"
                            // checked={label.label}
                            className="cursor-pointer"
                            disabled={
                              label.key === "employeeNumberseries"
                                ? true
                                : label.key === "UserId"
                                ? true 
                                : null
                            }
                            checked={label.isVisible}
                            onChange={(event) => {
                              handleCheckboxColumn(event, label);
                            }}
                          />
                          <span
                            className="ml-1"
                            style={{ cursor: "all-scroll" }}
                          >
                            {label.label}
                          </span>
                          {label.isDisabled && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </label>
                      )
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                      float: "right",
                    }}
                  >
                    <Button
                      onClick={() => {
                        onCloseManagecolumn(false);
                      }}
                      colorScheme="green"
                    >
                      OK
                    </Button>
                    <Button
                      onClick={() => {
                        onCloseManagecolumn(false);
                      }}
                      colorScheme="red"
                      ml={3}
                    >
                      Cancel
                    </Button>
                  </div>
                </Rnd>
              </div>
            </>
          )}
        </>
      </div>
      <div className="mx-auto  bg-blue-950 text-black flex flex-wrap dark:bg-indigo-900">
        <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
          {/* Next page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
            style={{ float: "right", marginRight: "5px" }}
          >
            <FiArrowRightCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToNextPage}
              disabled={currentPage === pageNumbers}
            />
          </div>

          {/* Previous page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
            style={{ float: "right", marginRight: "10px" }}
          >
            <FiArrowLeftCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            />
          </div>

          {/* Page numbers */}
          <p
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
            style={{ float: "right", marginRight: "20px" }}
          >
            <a className="relative inline-flex items-center ">
              {renderPageNumbers()}
            </a>
          </p>

          {/* Items per page dropdown */}
          <select
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
            style={{
              float: "right",
              marginRight: "20px",
              cursor: "pointer",
            }}
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            {availableItemsPerPage.map((option) => (
              <option key={option} value={option}>
                {option} Records Per Page
              </option>
            ))}
          </select>
          {/* Icons */}
          <Tooltip hasArrow label="Mass Update" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 update">
              <FiEdit
                size={20}
                onClick={() => {
                  checkValidation.handleEditClickMassupdate();
                }}
              />
            </div>
          </Tooltip>

          {/* <Tooltip
              hasArrow
              label="Hide converted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiShow size={24} onClick={handleRecordConvert} />
              </div>
            </Tooltip> */}
          <Tooltip hasArrow label="Delete" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
              <RiDeleteBinLine
                size={20}
                onClick={() => {
                checkValidation.handleEditClickMassdelete();
                }}
              />
            </div>
          </Tooltip>
          {isdelete ? (
            <Tooltip
              hasArrow
              label="Show deleted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiShow size={24} onClick={handleRecordDelete} />
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              hasArrow
              label="Hide deleted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiHide size={24} onClick={handleRecordDelete} />
              </div>
            </Tooltip>
          )}
        
          <Tooltip
            hasArrow
            label="Select all pages"
            bg="gray.300"
            color="black"
          >
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <MdSelectAll
                onClick={() => {
                  handleCheckbox(employeeDocData.map((item) => item.id));
                  setCheckBoxAll((prev) => {
                    if (prev === true) {
                      setCheckBoxSelected([]);
                    }
                    return !prev;
                  });
                }}
                size={24}
              />
            </div>
          </Tooltip>
          <Tooltip hasArrow label="Server Mail" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <HiOutlineMail
                onClick={() => {
                  checkValidation.handleEditClickMassmail();
                }}
                size={20}
              />
            </div>
          </Tooltip>

          {/* Record count */}
          <p className="py-1 px-2 my-2" style={{ float: "left" }}>
            <p className="text-sm text-gray-700 dark:text-white">
              Showing{" "}
              <span className="font-medium">
                {currentPage === 1 ? 1 : indexOfFirstItem + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(indexOfLastItem, employeeDocData?.length)}
              </span>{" "}
              of <span className="font-medium">{employeeDocData?.length}</span>{" "}
              records
            </p>
            <p style={{ float: "left", marginTop: "10px" }}>
              <Selectallpages
                selectAllPages={selectAllPages}
                setSelectAllPages={setSelectAllPages}
                checkBoxAll={checkBoxAll}
              />
            </p>
          </p>
        </div>
        {/* table */}
        <div className="table-container">
          <DraggableEmployeedocumentTable
            isLoading={isLoading}
            columns={columns2}
            employeeDocData={employeeDocData}
            getData={getData}
            data={employeeDocData.slice(indexOfFirstItem, indexOfLastItem)}
            setEmployeeDocData={setEmployeeDocData}
            handleCheckbox={handleCheckbox}
            checkBoxSelected={checkBoxSelected}
            setCheckBoxSelected={setCheckBoxSelected}
            setCheckBoxAll={setCheckBoxAll}
            checkBoxAll={checkBoxAll}
            handleDragOver={handleDragOver}
            handleDragStart={handleDragStart}
            handleDrop={handleDrop}
            tableColumnsEmployeeDoc={tableColumnsEmployeeDoc}
            currentPage={currentPage}
            pageNumbers={pageNumbers}
          />
        </div>
        {/* Mass update */}
        <>
          {
            <div>
              {isOpenMassupdate && (
                <>
                  <div style={backdropStyle}></div>
                  <div className={`MassUpdateModal ${scrollBehavior}`}>
                    <Rnd
                      style={style}
                      default={{
                        x: 0,
                        y: 0,
                        width: 500,
                        // height: 200
                      }}
                      cancel="X"
                      allowAnyClick={true}
                    >
                      <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                        Mass Update
                      </header>
                      <div
                        onClick={() => {
                          onCloseMassupdate(false);
                        }}
                        style={closeModal}
                      >
                        <CgClose />
                      </div>
                      <br />
                      <FormControl>
                        <Flex>
                          <Select
                            mt={7}
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            value={selectedOption}
                            onChange={(e) => handleOptionChange(e.target.value)}
                          >
                            <option value="Select a field">
                              Select a field
                            </option>
                            <option value="employeeNumberSeries">
                              EmployeeNumberSeries
                            </option>
                            <option value="employeeNo">employeeNo</option>
                            <option value="First_Name">name</option>
                            <option value="dob">dob</option>
                            <option value="gender">gender</option>
                            <option value="mobileNumber">mobileNumber</option>
                            <option value="reportingManager">
                              reportingManager
                            </option>
                            <option value="Employee_Type">Type</option>
                            <option value="Employee_Status">Status</option>
                            <option value="dateOfJoining">dateOfJoining</option>
                            <option value="probationPeriod">
                              probationPeriod
                            </option>
                            <option value="confirmationDate">
                              confirmationDate
                            </option>
                            <option value="emailId">emailId</option>
                            <option value="aadharNumber">aadharNumber</option>
                            <option value="emergencyContactName">
                              emergencyContactName
                            </option>
                            <option value="emergencyContactNumber">
                              emergencyContactNumber
                            </option>
                            <option value="referredBy">referredBy</option>
                            <option value="fathersName">fathersName</option>
                            <option value="spousesName">spousesName</option>
                            <option value="costCenter">costCenter</option>
                            <option value="designation">designation</option>
                            <option value="department">department</option>
                            <option value="reportinglocation">
                              reportinglocation
                            </option>
                            <option value="panNumber">panNumber</option>
                            <option value="uanNumber">uanNumber</option>
                            <option value="pfExcessContribution">
                              pfExcessContribution
                            </option>
                          </Select>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {
                            <Input
                              mt={7}
                              onChange={(e) => setUpdatedValue(e.target.value)}
                              value={updatedValue}
                              type="text"
                              name={selectedOption}
                              id="updateField"
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                              disabled={selectedOption === "Select a field"}
                            />
                          }
                        </Flex>
                      </FormControl>
                      <br />
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          onOpenReportMassupdate();
                          onCloseMassupdate();
                        }}
                        mr={3}
                      >
                        Submit
                      </Button>
                    </Rnd>
                  </div>
                </>
              )}
            </div>
          }
        </>
      </div>

      {/* Mass update confirmation*/}
      <>
        {isOpenReportMassupdate && (
          <>
            <div style={backdropStyle}></div>
            <div className={`MassUpdateModalConfirm ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 600,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Update Employees
                </header>
                <div
                  onClick={() => {
                    onCloseReportMassupdate(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <FormControl>
                  {" "}
                  Are you sure you want to Update the employees?
                </FormControl>
                <br />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleSubmitUpdate();
                    onCloseReportMassupdate();
                  }}
                >
                  Update
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onCloseReportMassupdate();
                  }}
                  ml={3}
                >
                  Cancel
                </Button>
              </Rnd>
            </div>
          </>
        )}
      </>
      {/* Server Mail */}
      <>
        {isEditorOpen && (
          <>
            <div style={backdropStyle}></div>
            <div className={`EditMailModule ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 600,
                  // height: 200
                }}
                // cancel="X"
                cancel="cancel"
                allowAnyClick={true}
              >
                {/* <Modal isOpen={isEditorOpen} onClose={handleCloseEditor} size="md">
          <ModalOverlay />
          <ModalContent maxW="600px">
            <ModalHeader>Compose Email</ModalHeader>
            <ModalBody> */}
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Compose Email
                </header>
                <div
                  onClick={() => {
                    handleCloseEditor(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                {/* Subject input */}
                <FormControl id="subject">
                  <FormLabel>Subject</FormLabel>
                  <Input
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </FormControl>
                {/* Editor */}
                <Editor
                  value={body}
                  onEditorChange={setBody}
                  init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help",
                  }}
                />
                <br />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleSubmitMassEmail();
                    handleCloseEditor();
                  }}
                >
                  Submit
                </Button>

                <Button colorScheme="red" ml={3} onClick={handleCloseEditor}>
                  Cancel
                </Button>
              </Rnd>
            </div>
          </>
        )}
      </>
      <>
        {isOpenReportDelete && (
          <div className={`MassDeleteModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Delete Employee
              </header>
              <div
                onClick={() => {
                  onCloseReportDelete(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                {" "}
                Are you sure you want to delete the Employee?
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitDelete();
                  onCloseReportDelete();
                }}
                mr={3}
              >
                Delete
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseReportDelete();
                }}
                mr={3}
              >
                Cancel
              </Button>
            </Rnd>
          </div>
        )}
      </>

      {/* Download */}
      <>
        {isOpenDownload && (
          <>
            <div style={backdropStyle}></div>
            <div className={`DownloadExcelModule ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 500,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Do you wanna to export data?
                </header>
                <div
                  onClick={() => {
                    onCloseDownload(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <FormControl>
                  <Flex alignItems="center ">
                    {" "}
                    {/* Use alignItems to vertically center the contents */}
                    <FormLabel marginLeft="100">Select</FormLabel>{" "}
                    {/* Push FormLabel to the left */}
                    <Select
                      w={60}
                      mt={-1}
                      placeholder="Select option"
                      size="md"
                      ref={initialRef}
                      value={selectedExportOption}
                      onChange={handleExportOptionChange}
                    >
                      <option value="Excel">Excel</option>
                      <option value="CSV">CSV</option>
                      <option value="PDF">PDF</option>
                      <option value="Text">Text</option>
                    </Select>
                  </Flex>
                </FormControl>
                <br />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleExport();
                  }}
                  disabled={isDownloading}
                  mr={3}
                >
                  {isDownloading ? "Downloading..." : "Export Data"}
                </Button>
                {downloadComplete && (
                  <Text mt={2} color="green">
                    Download completed!
                  </Text>
                )}
              </Rnd>
            </div>
          </>
        )}
      </>
      </div>
    </>
  );
};
export default Generateletter;
