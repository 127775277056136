import { React, useState, useEffect } from "react";
import "../../page/Addjob/Addjob.css";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import {
  Select,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
  Badge,
  Button,
  FormControl,
} from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import Axios, {
  JobAPIInstance,
  MasterDataAPIInstance,
  OperationDocumentAPIInstance,
} from "../../api-instance";
import {
  Header,
  OperationAddOps,
  OperationTransferData,
} from "../../components";
import { Rnd } from "react-rnd";
import { CgClose } from "react-icons/cg";
import AddUnallocatedJob from "../../page/Addjob/AddUnallocatedJob";
import UnallocatedEditJob from "./UnallocatedEditJob";
const EditJobPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  const [equipmentData, setEquipmentData] = useState([]);
  const [incotermData, setIncotermData] = useState([]);
  const [scopeData, setScopeData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [shipmentLineData, setShipmentLineData] = useState([]);
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [productData, setProductData] = useState([]);
  const [portData, setPortData] = useState([]);
  const [selectedJob, setSelectedJob] = useState([]);
  const [packageTypeData, setPackageTypeData] = useState([]);
  const [salesPersonData, setSalesPersonData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  // const [formData, setFormData] = useState({
  // });
  const [bookingNumbers, setBookingNumbers] = useState([]);
  const [jobTransferData, setJobTransferData] = useState([]);
  const [selectedRegionData, setSelectedRegionData] = useState([]);
  const [selectedJobTransfer, setSelectedJobTransfer] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedOpsUser, setSelectedOpsUser] = useState([]);
  const [selectedOpsDocumentData, setSelectedOpsDocumentData] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  // Popup
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [unallocatedBookingState, setUnallocatedBookingState] = useState([]);
  const [tableData, setTableData] = useState([]);

  const handleApply = () => {
    console.log("Selected Data:", selectedData);
  };

  const handleSelectedRegionData = (regionCodes) => {
    setSelectedRegionData(regionCodes);
    console.log("Selected OPS user:", regionCodes);
  };

  const handleSelectedJobTransfer = (selectedRows) => {
    setSelectedJobTransfer(selectedRows);
    console.log("Selected Booking Rows:", selectedRows);
  };

  const handleSelectedProduct = (product) => {
    setSelectedProduct(product);
    console.log("Selected Product:", product);
  };

  const handleSelectedOpsUserData = (userId) => {
    setSelectedOpsUser(userId);

    console.log("Selected OPS user:", userId);
  };

  const handleSelectedOpsDocumentData = (data) => {
    setSelectedOpsDocumentData(data);
  };

  const {
    isOpen: isOpenJobTransferData,
    onOpen: onOpenJobTransferData,
    onClose: onCloseJobTransferData,
  } = useDisclosure();

  const {
    isOpen: isOpenJobAdd,
    onOpen: onOpenJobAdd,
    onClose: onCloseJobAdd,
  } = useDisclosure();
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };

  const blurBackgroundStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.2)",
    backdropFilter: "blur(3px) contrast(0.9) brightness(0.9)",
    zIndex: 999,
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };

  const [formData, setFormData] = useState([
    {
      Product: "",
      ShipmentHandledBy: "",
      JobNumber: "",
      JobInfono: "",
      DateOfBusinessConfirmed: "",
      POL: "",
      POD: "",
      Job_Status: "",
      Shipper: "",
      Consignee: "",
      Client: "",
      Client_email_id: "",
      Agent: "",
      QuotationNumber: "",
      Scope: "",
      Region: "",
      Sales: "",
      Incoterm: "",
      Cargo: "",
      CargoNature: "",
      HsCode: "",
      ShippingLine: "",
      VesselNameVoyage: "",
      CyOpen: "",
      SICutOff: "",
      VGMCutOff: "",
      CYCutOff: "",
      CustomsCuttOff: "",
      OOGCuttOff: "",
      ETD: "",
      ETA: "",
      FreeDaysAtOrigin: "",
      FreeDaysAtDestination: "",
      Freight: "",
      MBLType: "",
      HBLType: "",
      LinerBookingNumber: "",
      MBLNumber: "",
      HBLNumber: "",
      CargoPickUpDate: "",
      EmptyPickUpDate: "",
      Stuffingdate: "",
      LadenGateIndate: "",
      VesselArrivalDate: "",
      OperationTranferDeadline: "",
      // Vendor: "",
      // Detail: "",
      // Amount: "",
      // ContainerType: "",
      // Quantity: "",
      // Weight: "",
      // Dimensions: "",
      ContainerNumber: "",
      SealNumber: "",
      No_Of_Pkge: "",
      Type_Of_Pkge: "",
      NtWeight: "",
      GrWeight: "",
      Volume: "",
      TareWeight: "",
      VGM: "",
      VGMMethod: "",
      Detention: "",
      Demurrage: "",
      Detention_By_Liner: "",
      Demurage_By_Liner: "",
      DetentionNo: "",
      DemurrageNo: "",
      Port_Storage: "",
      PortStorage: "",
      Port_Storage_Days: "",
      Current_Status: "",
      Notes: "",

      VendorContainers: "Vendor: , Detail: , Currency: , Amount: ;",
      Operations: [],
      // VendorOperations: [
      //   {
      //     Vendor: "",
      //     Detail: "",
      //     Amount: "",
      //   },
      // ],
    },
  ]);

  const [editJobTab, setEditJobTab] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const id =
          window.location.pathname.split("/")[
            window.location.pathname.split("/").length - 1
          ];
        const { data } = await JobAPIInstance.get(
          `edit_get_job?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }&jobId=${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        // setSelectedJob(data.data[0]);
        // setJobTransferData(data.data);
        // setEditJobTab(data.data);
        // setBookingNumbers(data.data.map((job) => job.BookingNumber));
        const filteredData = data.data.map(
          ({ DeletionFlag, ConversionFlag, ...rest }) => rest
        );

        // const containerData = createContainerArray(filteredData[0].Containers);
        // setTableData(containerData);

        setSelectedJob(filteredData[0]);
        setJobTransferData(filteredData);
        setEditJobTab(filteredData);
        setBookingNumbers(filteredData.map((job) => job.BookingNumber));

        // console.log("Selected Job Data", data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    setTableData(
      editJobTab.map((item) =>
        createContainerArray(item.Containers, item.Containers)
      )
    );
  }, [editJobTab]);
  // console.log("setTableData",tableData)

  // Product API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
        // console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Equipment API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        // console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Incoterm API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_incoterm");
        setIncotermData(data.data);
        // console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Scope API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
        // console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Region API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_region");
        setRegionData(data.data);
        // console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Shipment API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_shipment");
        setShipmentLineData(data.data);
        // console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Port API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
        // console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Package Type API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_packagetype");
        setPackageTypeData(data.data);
        // console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //Sales Person API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_salesperson");
        setSalesPersonData(data.data);
        // console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Customer API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setCustomerData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Currency Data
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_currency");
        setCurrencyData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  function handleContainerChange(name, value, str) {
    // Parse the string into an array of objects
    let containers = str.split(";").map((item) => {
      let obj = {};
      item
        .trim()
        .split(",")
        .forEach((prop) => {
          let [key, val] = prop.split(":").map((p) => p.trim());
          obj[key] =
            isNaN(val) || val === "NaN" || val === "" ? val : parseFloat(val);
        });
      return obj;
    });

    // Update the specified object
    let [propertyName, index] = name.split("-");
    index = parseInt(index);
    containers[index][propertyName] =
      isNaN(value) || value === "" ? value : parseFloat(value);

    // Convert the array back into a string
    let updatedStr = containers
      .map((obj) => {
        return Object.entries(obj)
          .map(([key, val]) => `${key}: ${val}`)
          .join(", ");
      })
      .join("; ");

    return updatedStr;
  }

  const handleInnerContainerChange = async (e) => {
    try {
      const { name, value } = e.target;
      // console.log(name, value);

      const ans = handleContainerChange(name, value, selectedJob.Containers);

      // Update editedJob state
      setSelectedJob((prev) => ({
        ...prev,
        Containers: ans,
      }));

      setEditJobTab((prevData) => {
        return prevData.map((item) =>
          item.BookingNumber === selectedJob.BookingNumber
            ? { ...item, Containers: ans }
            : item
        );
      });
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const handleInnerContainerOperationChange = async (e) => {
    try {
      const { name, value } = e.target;
      console.log(name, value, "Operation Conatiner Change");
      const ans = handleContainerChange(name, value, selectedJob.Containers);

      setSelectedJob((prev) => ({
        ...prev,
        Containers: ans,
      }));

      setEditJobTab((prevData) => {
        return prevData.map((item) =>
          item.BookingNumber === selectedJob.BookingNumber
            ? { ...item, Containers: ans }
            : item
        );
      });
    } catch (error) {
      console.log(error, "Operation Conatiner Change");
    }
  };

  const addVendorContainer = () => {
    setEditJobTab((prevData) => {
      const newData = [...prevData];
      console.log(newData[0]);
      const updatedVendorOperations = [newData[0]?.VendorOperations];
      // console.log(...newData[selectedJob]);
      // updatedVendorOperations?.push({
      //   // add_costid: "",
      //   Vendor: "",
      //   Detail: "",
      //   Amount: "",
      // });

      newData[0] = {
        ...newData[0],
        VendorContainers:
          newData[0].VendorContainers +
          ";Vendor: , Detail: , Currency: , Amount: ",
        //VendorOperations: updatedVendorOperations,
      };
      return newData;
    });
    setSelectedJob((prevData) => {
      return {
        ...prevData,
        VendorContainers:
          prevData.VendorContainers +
          ";Vendor: , Detail: , Currency: , Amount: ",
        // Operations: updatedOperations,
      };
    });
  };

  function removeRowByIndex(data, indexToRemove) {
    let rows = data?.split(";").filter((row) => row.trim() !== ""); // Split the data into rows
    const removedContainerIds = []; // Array to hold the removed container IDs

    if (indexToRemove >= 0 && indexToRemove < rows.length) {
      const removedRow = rows[indexToRemove]; // Get the row being removed
      const containerId = removedRow
        .split(", ")
        .find((part) => part.startsWith("Container_ID:"))
        ?.split(": ")[1]
        .trim(); // Extract Container_ID safely
      if (containerId) {
        removedContainerIds.push({ id: containerId, flag: 1 }); // Push the object into the array if Container_ID is found
      }
      rows.splice(indexToRemove, 1); // Remove the row at the specified index
    }

    return { updatedRows: rows.join("; "), removedContainerIds }; // Return updated rows and removed container IDs
  }

  // Function to remove a container and update the data
  const removeContainer = async (indexToRemove) => {
    setEditJobTab((prevData) => {
      const newData = [...prevData];
      const { updatedRows, removedContainerIds } = removeRowByIndex(
        newData[selectedTabIndex].Containers,
        indexToRemove
      );

      console.log("ConQ", newData[selectedTabIndex]);

      // Ensure DeletionFlag is initialized as an array if not present
      const currentDeletionFlag = Array.isArray(
        newData[selectedTabIndex].DeletionFlag
      )
        ? newData[selectedTabIndex].DeletionFlag
        : [];

      newData[selectedTabIndex] = {
        ...newData[selectedTabIndex],
        Containers: updatedRows,
        // Filter Operations safely if defined
        Operations:
          newData[selectedTabIndex]?.Operations?.filter(
            (_, index) => index !== indexToRemove
          ) || [],
        // Update the DeletionFlag with removed container IDs
        DeletionFlag: [...currentDeletionFlag, ...removedContainerIds],
      };

      return newData;
    });

    setSelectedJob((prevData) => {
      const containersArray = prevData.Containers.split(";");
      containersArray.splice(indexToRemove, 1);
      const updatedContainers = containersArray.join(";");
      return {
        ...prevData,
        Containers: updatedContainers,
      };
    });
  };

  function removeRowByIndexVendor(data, indexToRemove) {
    // Split the data into rows and filter out empty rows
    let rows = data?.split(";").filter((row) => row.trim() !== "");
    const removedVendorIds = []; // Array to store removed vendor IDs with flag

    if (indexToRemove >= 0 && indexToRemove < rows.length) {
      const removedRow = rows[indexToRemove]; // Get the specific row by index

      // Extract add_costid using a regular expression that accounts for spaces
      const vendorIdMatch = removedRow.match(/add_costid\s*:\s*(\S+)/);
      const vendorId = vendorIdMatch ? vendorIdMatch[1] : null;

      if (vendorId) {
        // Add vendor object with add_costid and flag to removedVendorIds array
        removedVendorIds.push({ add_costid: vendorId, flag: 1 });
      }

      // Remove the row at the specified index
      rows.splice(indexToRemove, 1);
    }

    // Join remaining rows back into a single string separated by ';'
    return { updatedRows: rows.join("; "), removedVendorIds };
  }

  const removeVendorContainer = (indexToRemove) => {
    console.log(indexToRemove);

    setEditJobTab((prevData) => {
      const newData = [...prevData];

      // Get updated VendorContainers and removed vendor IDs from removeRowByIndexVendor
      const { updatedRows: updatedVendorContainers, removedVendorIds } =
        removeRowByIndexVendor(
          newData[selectedTabIndex].VendorContainers,
          indexToRemove
        );

      // Ensure vendorDeletionFlag is initialized as an array if not present
      const currentVendorDeletionFlag = Array.isArray(
        newData[selectedTabIndex].vendorDeletionFlag
      )
        ? newData[selectedTabIndex].vendorDeletionFlag
        : [];

      newData[selectedTabIndex] = {
        ...newData[selectedTabIndex],
        VendorContainers: updatedVendorContainers,
        // Filter VendorOperations safely if defined
        VendorOperations:
          newData[selectedTabIndex]?.VendorOperations?.filter(
            (_, index) => index !== indexToRemove
          ) || [],
        // Update vendorDeletionFlag with removed vendor IDs
        vendorDeletionFlag: [...currentVendorDeletionFlag, ...removedVendorIds],
      };

      return newData;
    });

    // Update selected job's VendorContainers
    setSelectedJob((prevData) => {
      const containersArray = prevData.VendorContainers.split(";");
      containersArray.splice(indexToRemove, 1);
      const updatedVendorContainers = containersArray.join(";");
      return {
        ...prevData,
        VendorContainers: updatedVendorContainers,
      };
    });
  };

  function handleVendorContainerChange(name, value, str) {
    // Parse the string into an array of objects
    let vendorcontainers = str.split(";").map((item) => {
      let obj = {};
      item
        .trim()
        .split(",")
        .forEach((prop) => {
          let [key, val] = prop.split(":").map((p) => p.trim());
          obj[key] =
            isNaN(val) || val === "NaN" || val === "" ? val : parseFloat(val);
        });
      return obj;
    });

    // Update the specified object
    let [propertyName, index] = name.split("-");
    index = parseInt(index);
    vendorcontainers[index][propertyName] =
      isNaN(value) || value === "" ? value : parseFloat(value);

    // Convert the array back into a string
    let updatedStr = vendorcontainers
      .map((obj) => {
        return Object.entries(obj)
          .map(([key, val]) => `${key}: ${val}`)
          .join(", ");
      })
      .join("; ");

    return updatedStr;
  }

  const handleInnerVendorContainerChange = async (e) => {
    try {
      const { name, value } = e.target;
      console.log(name, value);

      const mnk = handleVendorContainerChange(
        name,
        value,
        selectedJob.VendorContainers
      );

      // Update editedJob state
      setSelectedJob((prev) => ({
        ...prev,
        VendorContainers: mnk,
      }));

      setEditJobTab((prevData) => {
        return prevData.map((item) =>
          item.BookingNumber === selectedJob.BookingNumber
            ? { ...item, VendorContainers: mnk }
            : item
        );
      });
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  // Single Update
  const handleSubmitUpdate = async () => {
    const updateData = [...editJobTab];

    // Update Operations for the selected tab
    if (selectedTabIndex !== null) {
      updateData[selectedTabIndex].Operations =
        tableData[selectedTabIndex] || [];
    }
    // const updateData = [...editJobTab];
    // console.log("Table data", tableData);
    try {
      const { data } = await JobAPIInstance.put(
        `singleupdate_job?userId=?${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          data: [...updateData],
          id: selectedJob.id,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data?.success) {
        toast({
          title: "Job Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        window.location.reload();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };
  // Client based show email id
  const filteredEmails = customerData.filter(
    (item) => item.Company === selectedJob?.Client
  );

  useEffect(() => {
    if (selectedJob.Client && filteredEmails.length > 0) {
      setSelectedJob((prevState) => ({
        ...prevState,
        Client_email_id: filteredEmails[0].Client_email_id,
      }));
    }
  }, [selectedJob?.Client, filteredEmails]);

  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;

      // Update selectedJob state
      const updatedJob = {
        ...selectedJob,
        [name]: value,
      };

      // Recalculate detention if relevant fields are changed
      if (name === "Laden_Gate_In_Date" || name === "Empty_Pickup_Date") {
        updatedJob.Detention = calculateDetention(updatedJob);
      }

      if (name === "Vessel_Arrival_Date" || name === "Laden_Gate_In_Date") {
        updatedJob.Demurrage = calculateDemurrage(updatedJob);
      }

      if (name === "Vessel_Arrival_Date" || name === "Laden_Gate_In_Date") {
        updatedJob.Port_Storage = calculatePortStorage(updatedJob);
      }

      if (name === "Vessel_Arrival_Date" || name === "Empty_Pickup_Date") {
        updatedJob.Combined_Detention_Demurrage =
          calculateCombainedDD(updatedJob);
      }

      if (name === "Empty_Return_Date" || name === "Port_Out_Date") {
        updatedJob.Destination_Detention =
          calculatedDestinationDetention(updatedJob);
      }

      if (name === "Port_Out_Date" || name === "Vessel_Arrival_Date") {
        updatedJob.Destination_Demurrage =
          calculatedDestinationDemurrage(updatedJob);
      }

      if (name === "Port_Out_Date" || name === "Vessel_Arrival_Date") {
        updatedJob.Destination_Port_Storage =
          calculatedDestinationPortStorage(updatedJob);
      }

      if (name === "Empty_Return_Date" || name === "Vessel_Arrival_Date") {
        updatedJob.Destination_Combined_Detention_Demurrage =
          calculatedDestinationCombinedDD(updatedJob);
      }

      // Calculate the number of detentions occurring
      if (name === "Detention" || name === "Detention_By_Liner") {
        const detention = name === "Detention" ? value : updatedJob.Detention;
        const detentionByLiner =
          name === "Detention_By_Liner" ? value : updatedJob.Detention_By_Liner;
        updatedJob.DetentionNo = calculateDetentionNo(
          detention,
          detentionByLiner
        );
      }

      if (name === "Demurrage" || name === "Demurrage_By_Liner") {
        const demurrage = name === "Demurrage" ? value : updatedJob.Demurrage;
        const demurrageByLiner =
          name === "Demurrage_By_Liner" ? value : updatedJob.Demurrage_By_Liner;
        updatedJob.DemurrageNo = calculateDemurrageNo(
          demurrage,
          demurrageByLiner
        );
      }

      if (name === "Port_Storage" || name === "PortStorage") {
        const portstorage =
          name === "Port_Storage" ? value : updatedJob.Port_Storage;
        const portStorage =
          name === "PortStorage" ? value : updatedJob.PortStorage;
        updatedJob.Port_Storage_Days = calculateNoOfDaysPortStorage(
          portstorage,
          portStorage
        );
      }

      if (
        name === "Destination_Port_Storage" ||
        name === "Destination_Port_Storage_Given_By_Port"
      ) {
        const destinationportstorage =
          name === "Destination_Port_Storage"
            ? value
            : updatedJob.Destination_Port_Storage;
        const destinationportstoragegivenbyport =
          name === "Destination_Port_Storage_Given_By_Port"
            ? value
            : updatedJob.Destination_Port_Storage_Given_By_Port;
        updatedJob.Destination_No_Of_Days_Port_Storage_Occuring =
          calculateDestinationPortStorageOccuring(
            destinationportstorage,
            destinationportstoragegivenbyport
          );
      }

      if (
        name === "Destination_Detention" ||
        name === "Destination_Detention_By_Liner"
      ) {
        const destinationdetention =
          name === "Destination_Detention"
            ? value
            : updatedJob.Destination_Detention;
        const destinationdetentionbyliner =
          name === "Destination_Detention_By_Liner"
            ? value
            : updatedJob.Destination_Detention_By_Liner;
        updatedJob.Destination_No_Of_Detention_Occuring =
          calculateDestinationNoOfDaysDetentionOccuring(
            destinationdetention,
            destinationdetentionbyliner
          );
      }

      if (
        name === "Destination_Detention" ||
        name === "Destination_Detention_By_Liner"
      ) {
        const destinationdetention =
          name === "Destination_Detention"
            ? value
            : updatedJob.Destination_Detention;
        const destinationdetentionbyliner =
          name === "Destination_Detention_By_Liner"
            ? value
            : updatedJob.Destination_Detention_By_Liner;
        updatedJob.Destination_No_Of_Detention_Occuring =
          calculateDestinationNoOfDaysDetentionOccuring(
            destinationdetention,
            destinationdetentionbyliner
          );
      }

      if (
        name === "Destination_Demurrage" ||
        name === "Destination_Demurrage_By_Liner"
      ) {
        const destinationdemurrage =
          name === "Destination_Demurrage"
            ? value
            : updatedJob.Destination_Demurrage;
        const destinationdemurragebyliner =
          name === "Destination_Demurrage_By_Liner"
            ? value
            : updatedJob.Destination_Demurrage_By_Liner;
        updatedJob.Destination_No_Of_Demurrage_Occuring =
          calculateDestinationNoOfDaysDemurrageOccuring(
            destinationdemurrage,
            destinationdemurragebyliner
          );
      }

      if (
        name === "Combined_Detention_Demurrage" ||
        name === "Combined_Detention_Demurrage_Liner"
      ) {
        const combinedDD =
          name === "Combined_Detention_Demurrage"
            ? value
            : updatedJob.Combined_Detention_Demurrage;
        const combinedDDLiner =
          name === "Combined_Detention_Demurrage_Liner"
            ? value
            : updatedJob.Combined_Detention_Demurrage_Liner;
        updatedJob.No_Of_Days_Detention_Demurrage_Occuring =
          calculateNoOfDaysDDOccuring(combinedDD, combinedDDLiner);
      }

      if (
        name === "Destination_Combined_Detention_Demurrage" ||
        name === "Destination_Combined_Detention_Demurrage_By_Liner"
      ) {
        const destinationCombinedDD =
          name === "Destination_Combined_Detention_Demurrage"
            ? value
            : updatedJob.Destination_Combined_Detention_Demurrage;
        const destinationCombinedDDbyliner =
          name === "Destination_Combined_Detention_Demurrage_By_Liner"
            ? value
            : updatedJob.Destination_Combined_Detention_Demurrage_By_Liner;
        updatedJob.Destination_No_Of_Days_Detention_Demurrage_Occuring =
          calculateDestinationCombinedDDOccuring(
            destinationCombinedDD,
            destinationCombinedDDbyliner
          );
      }

      if (name === "ETA") {
        updatedJob.Operation_Transfer_Deadline = calculateEtaMinus(value, 12);
      }

      setSelectedJob(updatedJob);

      setEditJobTab((prevData) =>
        prevData.map((item) =>
          item.BookingNumber === selectedJob.BookingNumber
            ? {
                ...item,
                [name]: value,
                Detention: updatedJob.Detention,
                DetentionNo: updatedJob.DetentionNo,
                Demurrage: updatedJob.Demurrage,
                DemurrageNo: updatedJob.DemurrageNo,
                Port_Storage_Days: updatedJob.Port_Storage_Days,
                No_Of_Days_Detention_Demurrage_Occuring:
                  updatedJob.No_Of_Days_Detention_Demurrage_Occuring,
                Destination_Detention_By_Liner:
                  updatedJob.Destination_Detention_By_Liner,
                Destination_No_Of_Demurrage_Occuring:
                  updatedJob.Destination_No_Of_Demurrage_Occuring,
                Destination_No_Of_Days_Detention_Demurrage_Occuring:
                  updatedJob.Destination_No_Of_Days_Detention_Demurrage_Occuring,
                Operation_Transfer_Deadline:
                  name === "ETA"
                    ? updatedJob.Operation_Transfer_Deadline
                    : item.Operation_Transfer_Deadline,
              }
            : item
        )
      );
    } catch (error) {
      // Handle errors if necessary
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  // const addContainer = () => {
  //   setEditJobTab((prevData) => {
  //     const newData = [...prevData];
  //     console.log(newData[0]);
  //     const updatedOperations = [newData[0]?.Operations];
  //     // console.log(...newData[selectedJob]);
  //     updatedOperations?.push({
  //       ContainerNumber: "",
  //       SealNumber: "",
  //       No_Of_Pkge: "",
  //       Type_Of_Pkge: "",
  //       NtWeight: "",
  //       GrWeight: "",
  //       Volume: "",
  //       TareWeight: "",
  //       VGM: "",
  //       VGMMethod: "",
  //     });

  //     newData[0] = {
  //       ...newData[0],
  //       Containers:
  //         newData[0].Containers +
  //         ";ContainerType: , Quantity: , Weight: , Dimensions: ",
  //       Operations: updatedOperations,
  //     };
  //     return newData;
  //   });
  //   setSelectedJob((prevData) => {
  //     return {
  //       ...prevData,
  //       Containers:
  //         prevData.Containers +
  //         ";ContainerType: , Quantity: , Weight: , Dimensions: ",
  //       // Operations: updatedOperations,
  //     };
  //   });
  // };

  // console.log(setSelectedJob);

  const addContainer = () => {
    setEditJobTab((prevData) => {
      const newData = [...prevData];
      const selectedJobData = newData[selectedTabIndex]; // Use the selected tab index
      if (!selectedJobData) return prevData; // Safety check in case index is invalid
  
      const updatedOperations = selectedJobData?.Operations
        ? [...selectedJobData.Operations]
        : [];
      
      updatedOperations.push({
        ContainerNumber: "",
        SealNumber: "",
        No_Of_Pkge: "",
        Type_Of_Pkge: "",
        NtWeight: "",
        GrWeight: "",
        Volume: "",
        TareWeight: "",
        VGM: "",
        VGMMethod: "",
      });
  
      newData[selectedTabIndex] = {
        ...selectedJobData,
        Containers:
          selectedJobData.Containers +
          ";ContainerType: , Quantity: , Weight: , Dimensions: ",
        Operations: updatedOperations,
      };
  
      return newData;
    });
  
    setSelectedJob((prevData) => {
      if (!prevData) return prevData; // Safety check
      return {
        ...prevData,
        Containers:
          prevData.Containers +
          ";ContainerType: , Quantity: , Weight: , Dimensions: ",
      };
    });
  };

  const renderContainerRows = () => {
    if (!selectedJob || !selectedJob.Containers) {
      return null;
    }

    return selectedJob?.Containers?.split(";")
      .map((container, index) => {
        const containerAttributes = container.split(", ");

        // Extract quantity from container attributes
        const quantityAttribute = containerAttributes.find((attr) =>
          attr.includes("Quantity")
        );
        const quantity = parseInt(quantityAttribute.split(": ")[1], 10);

        // Generate rows based on quantity
        const rows = [];
        for (let i = 0; i < quantity; i++) {
          rows.push(
            <tr key={`${index}-${i}`}>
              {/* Render table cells for each container attribute */}
              <td>
                <select
                  width="190px"
                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                  name={`ContainerType-${index}-${i}`}
                  value={
                    selectedJob[`ContainerType-${index}-${i}`] ||
                    containerAttributes[0]?.split(": ")[1]
                  }
                  onChange={(e) =>
                    handleInnerContainerChange(e, index, "ContainerType")
                  }
                  disabled
                >
                  <option>Select Equipment</option>
                  {equipmentData.map((item, idx) => (
                    <option key={idx} value={item.ContainerType}>
                      {item.ContainerType}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <Input
                  w="200px"
                  name={`ContainerNumber-${index}`}
                  type="text"
                  value={
                    selectedJob[`ContainerNumber-${index}`] ||
                    containerAttributes[4]?.split(": ")[1]
                  }
                  onChange={(e) =>
                    handleInnerContainerOperationChange(
                      e,
                      index,
                      "ContainerNumber"
                    )
                  }
                />
              </td>
              <td>
                <Input
                  w="200px"
                  name={`SealNumber-${index}`}
                  type="text"
                  value={
                    selectedJob[`SealNumber-${index}`] ||
                    containerAttributes[5]?.split(": ")[1]
                  }
                  onChange={(e) =>
                    handleInnerContainerOperationChange(e, index, "SealNumber")
                  }
                />
              </td>
              <td>
                <Input
                  w="200px"
                  name={`No_Of_Pkge-${index}`}
                  type="text"
                  value={
                    selectedJob[`No_Of_Pkge-${index}`] ||
                    containerAttributes[6]?.split(": ")[1]
                  }
                  onChange={(e) =>
                    handleInnerContainerOperationChange(e, index, "No_Of_Pkge")
                  }
                />
              </td>
              <td>
                <Input
                  w="200px"
                  name={`Type_Of_Pkge-${index}`}
                  type="text"
                  value={
                    selectedJob[`Type_Of_Pkge-${index}`] ||
                    containerAttributes[7]?.split(": ")[1]
                  }
                  onChange={(e) =>
                    handleInnerContainerOperationChange(
                      e,
                      index,
                      "Type_Of_Pkge"
                    )
                  }
                />
              </td>
              <td>
                <Input
                  w="200px"
                  name={`NtWeight-${index}`}
                  type="text"
                  value={
                    selectedJob[`NtWeight-${index}`] ||
                    containerAttributes[8]?.split(": ")[1]
                  }
                  onChange={(e) =>
                    handleInnerContainerOperationChange(e, index, "NtWeight")
                  }
                />
              </td>
              <td>
                <Input
                  w="200px"
                  name={`GrWeight-${index}`}
                  type="text"
                  value={
                    selectedJob[`GrWeight-${index}`] ||
                    containerAttributes[9]?.split(": ")[1]
                  }
                  onChange={(e) =>
                    handleInnerContainerOperationChange(e, index, "GrWeight")
                  }
                />
              </td>
              <td>
                <Input
                  w="200px"
                  name={`Volume-${index}`}
                  type="text"
                  value={
                    selectedJob[`Volume-${index}`] ||
                    containerAttributes[10]?.split(": ")[1]
                  }
                  onChange={(e) =>
                    handleInnerContainerOperationChange(e, index, "Volume")
                  }
                />
              </td>
              <td>
                <Input
                  w="200px"
                  name={`TareWeight-${index}`}
                  type="text"
                  value={
                    selectedJob[`TareWeight-${index}`] ||
                    containerAttributes[11]?.split(": ")[1]
                  }
                  onChange={(e) =>
                    handleInnerContainerOperationChange(e, index, "TareWeight")
                  }
                />
              </td>
              <td>
                <Input
                  w="200px"
                  name={`VGM-${index}`}
                  type="text"
                  value={
                    selectedJob[`VGM-${index}`] ||
                    containerAttributes[12]?.split(": ")[1]
                  }
                  onChange={(e) =>
                    handleInnerContainerOperationChange(e, index, "VGM")
                  }
                />
              </td>
              <td>
                {/* <Input
                              w="200px"
                              name={`VGMMethod-${index}`}
                              type="text"
                              value={selectedJob[`VGMMethod-${index}`] || containerAttributes[13].split(': ')[1]}
                              onChange={(e) => handleInnerContainerChange(e, index, "VGMMethod")}
                            /> */}
                <Select
                  width="190px"
                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                  name={`VGMMethod-${index}`}
                  value={
                    selectedJob[`VGMMethod-${index}`] ||
                    containerAttributes[13]?.split(": ")[1]
                  }
                  onChange={(e) =>
                    handleInnerContainerOperationChange(e, index, "VGMMethod")
                  }
                >
                  <option>Select option</option>
                  <option value="Method I">Method I</option>
                  <option value="Method II">Method II</option>
                </Select>
              </td>
              {/* Render other input fields similarly */}
              {/* Make sure to update value and onChange props to use dynamic keys */}
            </tr>
          );
        }

        return rows;
      })
      .flat(); // Flatten array of arrays into a single array of rows
  };

  const handleSubmitJobTransfer = async () => {
    try {
      const { data } = await JobAPIInstance.post(
        `job_transfer?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          userId: JSON.parse(localStorage.getItem("user")).userId,
          regionCodes: selectedRegionData,
          JobTransferData: jobTransferData,
          documentTransferData: selectedJobTransfer,
          productTransferData: selectedProduct,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  const handleSubmitJobAdd = async () => {
    try {
      const { data } = await JobAPIInstance.post(
        `job_add_ops?userId=${JSON.parse(localStorage.getItem("user")).userId}`,

        {
          userId: JSON.parse(localStorage.getItem("user")).userId,
          JobAddData: jobTransferData,
          OpsDocumentData: selectedOpsDocumentData,
          // documentTransferData: selectedJobTransfer,
          //productTransferData: selectedProduct,
          //regionCodes: selectedRegionData,
          regionCodeOpeartion: ["OP"],
          OpsuserId: selectedOpsUser,
        },

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };
  // Origin
  const [operationTransferDeadline, setOperationTransferDeadline] =
    useState("");

  useEffect(() => {
    if (selectedJob.ETA) {
      setOperationTransferDeadline(calculateEtaMinus(selectedJob.ETA, 12));
    }
  }, [selectedJob?.ETA]);

  const calculateEtaMinus = (eta, days) => {
    const date = new Date(eta);
    date.setDate(date.getDate() - days);
    return date.toISOString().split("T")[0];
  };

  const calculateDetention = (job) => {
    const ladenGateInDate = new Date(job?.Laden_Gate_In_Date);
    const emptyPickupDate = new Date(job?.Empty_Pickup_Date);

    if (
      !ladenGateInDate ||
      !emptyPickupDate ||
      isNaN(ladenGateInDate) ||
      isNaN(emptyPickupDate)
    ) {
      return 0;
    }

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      Math.abs((ladenGateInDate - emptyPickupDate) / oneDay)
    );
    return diffDays;
  };

  const calculateDetentionNo = (detention, detentionByLiner) => {
    return detention - detentionByLiner;
  };

  const calculateDemurrage = (job) => {
    const vesselArrivalSailingDate = new Date(job?.Vessel_Arrival_Date);
    const ladenGateInDate = new Date(job?.Laden_Gate_In_Date);

    if (
      !vesselArrivalSailingDate ||
      !ladenGateInDate ||
      isNaN(vesselArrivalSailingDate) ||
      isNaN(ladenGateInDate)
    ) {
      return 0;
    }

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      Math.abs((vesselArrivalSailingDate - ladenGateInDate) / oneDay)
    );
    return diffDays;
  };
  const calculateDemurrageNo = (demurrage, demurrageByLiner) => {
    return demurrage - demurrageByLiner;
  };
  const calculatePortStorage = (job) => {
    const vesselArrivalSailingDate = new Date(job?.Vessel_Arrival_Date);
    const ladenGateInDate = new Date(job?.Laden_Gate_In_Date);

    if (
      !vesselArrivalSailingDate ||
      !ladenGateInDate ||
      isNaN(vesselArrivalSailingDate) ||
      isNaN(ladenGateInDate)
    ) {
      return 0;
    }

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      Math.abs((vesselArrivalSailingDate - ladenGateInDate) / oneDay)
    );
    return diffDays;
  };
  const calculateNoOfDaysPortStorage = (portstorage, portStorage) => {
    return portstorage - portStorage;
  };

  const calculateCombainedDD = (job) => {
    const vesselArrivalSailingDate = new Date(job?.Vessel_Arrival_Date);
    const emptyPickupDate = new Date(job?.Empty_Pickup_Date);

    if (
      !vesselArrivalSailingDate ||
      !emptyPickupDate ||
      isNaN(vesselArrivalSailingDate) ||
      isNaN(emptyPickupDate)
    ) {
      return 0;
    }

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      Math.abs((vesselArrivalSailingDate - emptyPickupDate) / oneDay)
    );
    return diffDays;
  };

  const calculateNoOfDaysDDOccuring = (combinedDD, combinedDDLiner) => {
    return combinedDD - combinedDDLiner;
  };
  const calculatedDestinationDetention = (job) => {
    const emptyReturnDate = new Date(job?.Empty_Return_Date);
    const portOutDate = new Date(job?.Port_Out_Date);

    if (
      !emptyReturnDate ||
      !portOutDate ||
      isNaN(emptyReturnDate) ||
      isNaN(portOutDate)
    ) {
      return 0;
    }

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      Math.abs((emptyReturnDate - portOutDate) / oneDay)
    );
    return diffDays;
  };

  const calculatedDestinationDemurrage = (job) => {
    const portOutDate = new Date(job?.Port_Out_Date);
    const vesselarrivalDate = new Date(job?.Vessel_Arrival_Date);

    if (
      !portOutDate ||
      !vesselarrivalDate ||
      isNaN(portOutDate) ||
      isNaN(vesselarrivalDate)
    ) {
      return 0;
    }

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      Math.abs((portOutDate - vesselarrivalDate) / oneDay)
    );
    return diffDays;
  };

  const calculateDestinationNoOfDaysDetentionOccuring = (
    destinationdetention,
    destinationdetentionbyliner
  ) => {
    return destinationdetention - destinationdetentionbyliner;
  };

  const calculateDestinationNoOfDaysDemurrageOccuring = (
    destinationdemurrage,
    destinationdemurragebyliner
  ) => {
    return destinationdemurrage - destinationdemurragebyliner;
  };

  const calculatedDestinationPortStorage = (job) => {
    const portOutDate = new Date(job?.Port_Out_Date);
    const vesselarrivalDate = new Date(job?.Vessel_Arrival_Date);

    if (
      !portOutDate ||
      !vesselarrivalDate ||
      isNaN(portOutDate) ||
      isNaN(vesselarrivalDate)
    ) {
      return 0;
    }

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      Math.abs((portOutDate - vesselarrivalDate) / oneDay)
    );
    return diffDays;
  };

  const calculateDestinationPortStorageOccuring = (
    destinationportstorage,
    destinationportstoragegivenbyport
  ) => {
    return destinationportstorage - destinationportstoragegivenbyport;
  };

  const calculatedDestinationCombinedDD = (job) => {
    const emptyReturnDate = new Date(job?.Empty_Return_Date);
    const vesselarrivalDate = new Date(job?.Vessel_Arrival_Date);

    if (
      !emptyReturnDate ||
      !vesselarrivalDate ||
      isNaN(emptyReturnDate) ||
      isNaN(vesselarrivalDate)
    ) {
      return 0;
    }

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      Math.abs((emptyReturnDate - vesselarrivalDate) / oneDay)
    );
    return diffDays;
  };

  const calculateDestinationCombinedDDOccuring = (
    destinationCombinedDD,
    destinationCombinedDDbyliner
  ) => {
    return destinationCombinedDD - destinationCombinedDDbyliner;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  function createContainerArray(containerString) {
    if (!containerString || typeof containerString !== "string") {
      // console.error("Invalid container string");
      return [];
    }

    // console.log("Input string:", containerString); // Debugging console.log

    // Split the string by ; to separate container entries
    const containers = containerString
      .split(";")
      .map((line) => line.trim())
      .filter((line) => line); // Filter out empty lines

    // Create a map to avoid duplicate ContainerType
    const containerMap = new Map();

    containers.forEach((container) => {
      const details = {};

      // Parse the key-value pairs from the container string
      container.split(",").forEach((detail) => {
        const [key, value] = detail.split(":").map((item) => item.trim());
        details[key] = value || null; // Use null for empty values
      });

      const containerType = details.ContainerType;
      const quantity = parseInt(details.Quantity, 10) || 0;

      if (!containerMap.has(containerType)) {
        containerMap.set(containerType, []);
      }

      // Add objects to the array for the respective ContainerType
      for (let i = 0; i < quantity; i++) {
        containerMap.get(containerType).push({ ...details });
      }
    });

    // Convert the map values into an array
    return Array.from(containerMap.values());
  }

  // useEffect(() => {
  //   setTableData(
  //     createContainerArray(setEditJobTab?.Containers));
  //       // "ContainerType: 20 GP, Quantity: 2, Weight: 900, Dimensions: 9x10, ContainerNumber: Auto1, SealNumber: 9087, No_Of_Pkge: 0, Type_Of_Pkge: , NtWeight: , GrWeight: , Volume: b, TareWeight: 0, VGM: 0, VGMMethod: , Container_ID: JCID00000049; ContainerType: 40 HC, Quantity: 2, Weight: 500, Dimensions: 9x10, ContainerNumber: Auto1, SealNumber: 9344, No_Of_Pkge: 0, Type_Of_Pkge: , NtWeight: , GrWeight: , Volume: X, TareWeight: 0, VGM: 0, VGMMethod: , Container_ID: JCID00000051"

  // }, []);

  // useEffect(() => {
  //   if (selectedJob?.Containers) {
  //     const containerData = createContainerArray(selectedJob.Containers);
  //     setTableData(containerData);
  //   }
  // }, [selectedJob]);

  // Handle changes in any input field
  const handleTableChange = (e, outerIndex, innerIndex, key) => {
    const { value } = e.target;

    setTableData((prevData) => {
      const updatedData = [...prevData]; // Create a shallow copy of the entire tableData

      // Clone the selected tab's data
      const tabData = [...updatedData[selectedTabIndex]];

      // Clone the specific outer array (row)
      const outerRow = [...tabData[outerIndex]];

      // Update the specific cell (inner object)
      outerRow[innerIndex] = {
        ...outerRow[innerIndex],
        [key]: value,
      };

      // Update the outer array in the tabData
      tabData[outerIndex] = outerRow;

      // Update the selectedTabIndex in updatedData
      updatedData[selectedTabIndex] = tabData;

      return updatedData; // Return the updated tableData
    });
  };

  // console.log("Table Data", tableData);
  //console.log("setSelectedTabIndex",selectedTabIndex)
  return (
    <div>
      <Header />
      <div onKeyDown={handleKeyDown} w={100} size="md">
        <div
          className="top-bar"
          style={{
            position: "sticky",
            top: "0",
            zIndex: "100",
          }}
        >
          {/* <OperationSidebar selectedJob={selectedJob} size={20} /> */}

          <p className="float-left text-neutral-950	 py-1 px-2 rounded my-2">
            Edit Job Information
          </p>
          <p className="float-left text-neutral-950	 py-1 px-2 rounded my-2"></p>

          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-green-700 text-white   text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={() => {
              handleSubmitUpdate();
              // navigate("/operation_job");
            }}
          >
            Save Edit
          </button>

          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-red-700  text-white text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/operation_job");
            }}
            disabled={!submitAllowed}
          >
            Cancel
          </button>
          <UnallocatedEditJob
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            selectedData={editJobTab}
            setSelectedData={setEditJobTab}
            onApply={handleApply}
            setUnallocatedBookingState={setUnallocatedBookingState}
            setFormData={setFormData}
            formData={formData}
          />
        </div>
        &nbsp;
        {/* <Tabs variant='soft-rounded' style={{ backgroundColor: "bg-indigo-900" }} > */}
        <Badge variant="solid" float="left" colorScheme="red" fontSize="12pt">
          <a>Title :</a>&nbsp;
          {selectedJob?.JobNumber}
        </Badge>
        <Tabs w="100%" variant="enclosed">
          <Tabs>
            <TabList>
              {editJobTab.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    onClick={() => {
                      setSelectedJob(item);
                      setSelectedTabIndex(index);
                    }}
                  >
                    {item.BookingNumber}
                  </Tab>
                );
              })}
            </TabList>
          </Tabs>
          &nbsp;
          <TabList>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Job Information
            </Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Cargo Details
            </Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Liner Details
            </Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Operation Details
            </Tab>
            {/* <Tab _selected={{ color: 'white', bg: '#172554' }}>Additional Charges</Tab> */}
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Detention & Demurrage
            </Tab>
            {/* <Tab _selected={{ color: "white", bg: "#172554" }}>
              Current Status & Notes
            </Tab> */}
            &nbsp;&nbsp;&nbsp;
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Ops & Document
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    navigate("/opertion_docs", {
                      state: { selectedJob: selectedJob },
                    });
                  }}
                >
                  Document
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/opertion_vendor", {
                      state: { selectedJob: selectedJob },
                    });
                  }}
                >
                  Vendor
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/addchecklist", {
                      state: { selectedJob: selectedJob },
                    });
                  }}
                >
                  Checklist
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/opertion_activitylog", {
                      state: { selectedJob: selectedJob },
                    });
                  }}
                >
                  Activity Log
                </MenuItem>
              </MenuList>
            </Menu>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Actions
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    onOpenJobTransferData();
                  }}
                >
                  Job Tranfer
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onOpenJobAdd();
                  }}
                >
                  Add OVS Operation
                </MenuItem>
              </MenuList>
            </Menu>
            {/* <Tab _selected={{ color: 'white', bg: '#172554' }}>Notes</Tab> */}
          </TabList>
          <TabPanels>
            <TabPanel>
              {/* Shippment  */}
              <div className="content">
                <div className="form-user">
                  <div className="user-job">
                    <div className="input-box">
                      <span className="job">
                        Product<span style={{ color: "red" }}>*</span>
                      </span>
                      <select
                        name="Product"
                        value={selectedJob["Product"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select Product</option>
                        {productData.map((data) => (
                          <option value={data.Product}>{data.Product}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">Place of Receipt</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="PlaceOfReceipt"
                        value={selectedJob["PlaceOfReceipt"]} //[formDataIndex]
                        onChange={handleChange}
                        placeholder="Enter your place of receipt"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Place Of Delivery</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="PlaceOfDelivery"
                        value={selectedJob["PlaceOfDelivery"]}
                        onChange={handleChange}
                        placeholder="Enter your place of delivery"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Shipment Handled By</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        name="HandleBy"
                        value={selectedJob["HandleBy"]}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Job Status</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Job_Status"
                        value={selectedJob["Job_Status"] || ""}
                        onChange={handleChange}
                      >
                        <option value="Inprogress">Inprogress</option>
                        <option value="Completed">Completed</option>
                        <option value="Hold">Hold</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">Date Of Business Confirmed</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="DOBC"
                        Value={(selectedJob["DOBC"] || "").split("T")[0] || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        POL<span style={{ color: "red" }}>*</span>
                      </span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="POL"
                        value={selectedJob["POL"] || ""}
                        onChange={handleChange}
                      /> */}
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="POL"
                        value={selectedJob["POL"] || ""}
                        onChange={handleChange}
                        placeholder="Enter your pol"
                      >
                        <option>Select Pol</option>
                        {portData.map((item) => (
                          <option value={item.Port}>{item.Port}</option>
                        ))}
                      </select>
                    </div>
                    <div className="input-box">
                      <span className="job">
                        Pod<span style={{ color: "red" }}>*</span>
                      </span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="POD"
                        value={selectedJob["POD"] || ""}
                        onChange={handleChange}
                      /> */}
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="POD"
                        value={selectedJob["POD"] || ""}
                        onChange={handleChange}
                        placeholder="Enter your pod"
                      >
                        <option>Select Pod</option>
                        {portData.map((item) => (
                          <option value={item.Port}>{item.Port}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">Shipper</span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Shipper"
                        value={selectedJob["Shipper"] || ""}
                        onChange={handleChange}
                      /> */}
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Shipper"
                        value={selectedJob["Shipper"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select Shipper</option>
                        {customerData.map((item) => (
                          <option value={item.Company}>{item.Company}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">Consignee</span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Consignee"
                        value={selectedJob["Consignee"] || ""}
                        onChange={handleChange}
                      /> */}
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Consignee"
                        value={selectedJob["Consignee"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select Consignee</option>
                        {customerData.map((item) => (
                          <option value={item.Company}>{item.Company}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">
                        Client<span style={{ color: "red" }}>*</span>
                      </span>

                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Client"
                        value={selectedJob["Client"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select Client</option>
                        {customerData.map((item) => (
                          <option value={item.Company}>{item.Company}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">Agent</span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Agent"
                        value={selectedJob["Agent"] || ""}
                        onChange={handleChange}
                      /> */}
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Agent"
                        value={selectedJob["Agent"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select Agent</option>
                        {customerData.map((item) => (
                          <option value={item.Company}>{item.Company}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">Client E-Mail Id</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Client_email_id"
                        value={selectedJob["Client_email_id"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select Client E-Mail Id</option>
                        {filteredEmails.map((item) => (
                          <option
                            key={item.Client_email_id}
                            value={item.Client_email_id}
                          >
                            {item.Client_email_id}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">Quote Number</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="QuotationNumber"
                        value={selectedJob["QuotationNumber"] || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Update E-Mail Id</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Client_email_id"
                        value={selectedJob["Client_email_id"] || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Scope</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Scope"
                        value={selectedJob["Scope"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select Scope</option>
                        {scopeData.map((item) => (
                          <option value={item.Scope}>{item.Scope}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">Region</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Region"
                        value={selectedJob["Region"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select Internal Region</option>
                        {regionData.map((item) => (
                          <option key={item.Region} value={item.Region}>
                            {item.Region}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="input-box">
                      <span className="job">Sales</span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Sales"
                        value={selectedJob["Sales"] || ""}
                        onChange={handleChange}
                      /> */}
                      <select
                        name="Sales"
                        value={selectedJob["Sales"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select Sales Person</option>
                        {salesPersonData.map((data) => (
                          <option value={data.fullname}>{data.fullname}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">Incoterm</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Incoterm"
                        value={selectedJob["Incoterm"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select Incoterm</option>
                        {incotermData.map((item) => (
                          <option value={item.Incoterm}>{item.Incoterm}</option>
                        ))}
                      </select>
                    </div>
                    <div className="input-box">
                      <span className="job">Notes</span>
                      <textarea
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Notes"
                        value={selectedJob["Notes"]}
                        onChange={handleChange}
                        placeholder="Enter your notes"
                      />
                    </div>
                    <div className="input-box">
                      <span className="job">Current Status</span>
                      <textarea
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Current_Status"
                        value={selectedJob["Current_Status"]}
                        onChange={handleChange}
                        placeholder="Enter your current status"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End Shippment */}
            </TabPanel>
            <TabPanel>
              {/* Cargo */}
              <div className="content">
                <div className="form-user">
                  <div className="user-job">
                    <div className="input-box">
                      <span className="job">
                        Cargo<span style={{ color: "red" }}>*</span>
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Cargo"
                        value={selectedJob["Cargo"] || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Cargo Nature</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Cargo_Nature"
                        value={selectedJob["Cargo_Nature"] || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">HS Code</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="HS_Code"
                        value={selectedJob["HS_Code"] || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End Cargo */}
              {/* Table Conatiner */}
              <div className="table-container">
                <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                  <thead className="flex-nowrap text-center bg-blue-950 text-white">
                    <tr>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Container Type
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        QTY
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Weight Per container(KG)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Dimension(LXWXH IN M)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedJob?.Containers?.split(";")?.map(
                      (container, index) => {
                        const containerAttributes = container.split(", ");

                        // console.log(selectedJob);

                        return (
                          <tr key={index}>
                            <td>
                              <Select
                                w="190px"
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                type="text"
                                name={`ContainerType-${index}`}
                                value={
                                  selectedJob[`ContainerType-${index}`] ||
                                  containerAttributes[0].split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(
                                    e,
                                    index,
                                    "ContainerType"
                                  )
                                }
                              >
                                <option>Select Equipment</option>
                                {equipmentData.map((item, idx) => (
                                  <option key={idx} value={item.ContainerType}>
                                    {item.ContainerType}
                                  </option>
                                ))}
                              </Select>
                            </td>
                            <td>
                              <Input
                                w="100px"
                                name={`Quantity-${index}`}
                                type="text"
                                value={
                                  selectedJob[`Quantity-${index}`] ||
                                  containerAttributes[1]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(
                                    e,
                                    index,
                                    "Quantity"
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                w="120px"
                                name={`Weight-${index}`}
                                type="text"
                                value={
                                  selectedJob[`Weight-${index}`] ||
                                  containerAttributes[2]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(e, index, "Weight")
                                }
                              />
                            </td>
                            <td>
                              <Input
                                w="200px"
                                name={`Dimensions-${index}`}
                                type="text"
                                value={
                                  selectedJob[`Dimensions-${index}`] ||
                                  containerAttributes[3]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(
                                    e,
                                    index,
                                    "Dimensions"
                                  )
                                }
                              />
                            </td>
                            <td>
                              {selectedJob?.Containers?.split(";").length >
                                1 && (
                                <BiSolidMinusCircle
                                  className="cursor-pointer"
                                  size={30}
                                  style={{ marginLeft: "5px", color: "red" }}
                                  onClick={() => removeContainer(index)}
                                />
                              )}

                              <IoMdAddCircle
                                className="cursor-pointer"
                                size={30}
                                style={{ marginLeft: "5px", color: "green" }}
                                onClick={addContainer}
                              />
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
              <br />
            </TabPanel>

            <TabPanel>
              {/* Liner start */}
              <div className="content">
                <div className="form-user">
                  <div className="user-job">
                    <div className="input-box">
                      <span className="job">Shipping Line / NVOCC / LCL</span>
                      {/* <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Shipping_Line"
                        value={selectedJob["Shipping_Line"] || ""}
                        onChange={handleChange}
                      /> */}

                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Shipping_Line"
                        value={selectedJob["Shipping_Line"] || ""}
                        onChange={handleChange}
                        placeholder="Enter your Shipping Line"
                      >
                        <option>Select Shipping Line</option>
                        {shipmentLineData.map((item) => (
                          <option value={item.ShippingLine}>
                            {item.ShippingLine}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">Vessel Name & Voy</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="VesselNameVoyage"
                        value={selectedJob["VesselNameVoyage"] || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">CY Open</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="CY_Open"
                        Value={
                          (selectedJob["CY_Open"] || "").split("T")[0] || ""
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">SI Cutt Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="SI_Cutoff"
                        Value={
                          (selectedJob["SI_Cutoff"] || "").split("T")[0] || ""
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">VGM Cut Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="VGM_Cutoff"
                        Value={
                          (selectedJob["VGM_Cutoff"] || "").split("T")[0] || ""
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">CY Cut Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="CY_Cutoff"
                        Value={
                          (selectedJob["CY_Cutoff"] || "").split("T")[0] || ""
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Customs Cut Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="Customs_Cutoff"
                        Value={
                          (selectedJob["Customs_Cutoff"] || "").split("T")[0] ||
                          ""
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">OOG Cut Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="OOG_Cutoff"
                        Value={
                          (selectedJob["OOG_Cutoff"] || "").split("T")[0] || ""
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">ETD</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ETD"
                        Value={(selectedJob["ETD"] || "").split("T")[0] || ""}
                        min={new Date().toISOString().split("T")[0]}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">ETA</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ETA"
                        value={(selectedJob.ETA || "").split("T")[0]}
                        min={new Date().toISOString().split("T")[0]}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Free Days At Origin</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="days_at_origin"
                        value={selectedJob["days_at_origin"] || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Free Days At Destination</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="days_at_destiantion"
                        value={selectedJob["days_at_destiantion"] || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Freight</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Freight"
                        value={selectedJob["Freight"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select Freight Type</option>
                        <option value="Prepaid">Prepaid</option>
                        <option value="Collect">Collect</option>
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">MBL Type</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="MBL_Type"
                        value={selectedJob["MBL_Type"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select MBL Type</option>
                        <option value="Original">Original</option>
                        <option value="Surrender">Surrender</option>
                        <option value="Seaway">Seaway</option>
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="job">HBL Type</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="HBL_Type"
                        value={selectedJob["HBL_Type"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select HBL Type</option>
                        <option value="Not Involved">Not Involved</option>
                        <option value="Original">Original</option>
                        <option value="Surrender">Surrender</option>
                        <option value="Seaway">Seaway</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Liner */}
              <br />
            </TabPanel>

            <TabPanel>
              {/* Operation start */}
              <div className="content">
                <div className="form-user">
                  <div className="user-job">
                    <div className="input-box">
                      <span className="job">Liner Booking Number</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Liner_Bookingno"
                        value={selectedJob["Liner_Bookingno"] || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">MBL Number</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="MBLNo"
                        value={selectedJob["MBLNo"] || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">HBL Number</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="HBLNo"
                        value={selectedJob["BookingNumber"] || ""}
                        onChange={handleChange}
                        disabled
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Cargo PickUp Date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="Cargo_Pickup_Date"
                        Value={
                          (selectedJob["Cargo_Pickup_Date"] || "").split(
                            "T"
                          )[0] || ""
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Empty PickUp date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="Empty_Pickup_Date"
                        Value={
                          (selectedJob["Empty_Pickup_Date"] || "").split(
                            "T"
                          )[0] || ""
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Stuffing Date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="Stuffing_Date"
                        Value={
                          (selectedJob["Stuffing_Date"] || "").split("T")[0] ||
                          ""
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Laden Gate In Date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="Laden_Gate_In_Date"
                        Value={
                          (selectedJob["Laden_Gate_In_Date"] || "").split(
                            "T"
                          )[0] || ""
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Opertion Tranfer Deadline</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="Operation_Transfer_Deadline"
                        value={
                          (
                            selectedJob["Operation_Transfer_Deadline"] || ""
                          ).split("T")[0] || operationTransferDeadline
                        }
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <TabPanel>
                {/* Additional Charges */}

                <div className="table-container">
                  <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                    <thead className="flex-nowrap text-center bg-blue-950 text-white">
                      <tr>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Vendor
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Additional Charges Details
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Currency / Amount
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedJob?.VendorContainers?.split(";")?.map(
                        (container, index) => {
                          const vendorAttributes = container.split(", ");

                          //console.log(selectedJob);

                          return (
                            <tr key={index}>
                              <td>
                                <Input
                                  type="text"
                                  name={`Vendor-${index}`}
                                  value={vendorAttributes[0]?.split(": ")[1]}
                                  onChange={(e) =>
                                    handleInnerVendorContainerChange(
                                      e,
                                      index,
                                      "Vendor"
                                    )
                                  }
                                  placeholder="Enter your vendor"
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  name={`Detail-${index}`}
                                  value={
                                    vendorAttributes[1]?.split(": ")[1] || ""
                                  }
                                  onChange={(e) =>
                                    handleInnerVendorContainerChange(
                                      e,
                                      index,
                                      "Detail"
                                    )
                                  }
                                  placeholder="Enter your charges"
                                />
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Select
                                    width="300px"
                                    name={`Currency-${index}`}
                                    value={
                                      vendorAttributes[2]?.split(": ")[1] || ""
                                    }
                                    onChange={(e) =>
                                      handleInnerVendorContainerChange(
                                        e,
                                        index,
                                        "Currency"
                                      )
                                    }
                                  >
                                    <option>Select Currency</option>
                                    {currencyData.map((item) => (
                                      <option
                                        key={`${item.Currency}`}
                                        value={`${item.Currency}`}
                                      >
                                        {item.Currency}
                                      </option>
                                    ))}
                                  </Select>

                                  <Input
                                    width="150px"
                                    type="text"
                                    name={`Amount-${index}`}
                                    value={
                                      vendorAttributes[3]?.split(": ")[1] || ""
                                    }
                                    onChange={(e) =>
                                      handleInnerVendorContainerChange(
                                        e,
                                        index,
                                        "Amount"
                                      )
                                    }
                                    placeholder="Enter your amount"
                                    style={{ marginLeft: "10px" }}
                                  />
                                </div>
                              </td>
                              {/* <td>
                                <Input
                                  type="text"
                                  name={`Amount-${index}`}
                                  value={
                                    vendorAttributes[2]?.split(": ")[1] || ""
                                  }
                                  onChange={(e) =>
                                    handleInnerVendorContainerChange(
                                      e,
                                      index,
                                      "Amount"
                                    )
                                  }
                                  placeholder="Enter your amount"
                                />
                              </td> */}
                              <td>
                                {index ===
                                  selectedJob.VendorContainers.split(";")
                                    .length -
                                    1 && (
                                  <IoMdAddCircle
                                    className="cursor-pointer"
                                    size={30}
                                    style={{
                                      marginLeft: "5px",
                                      color: "green",
                                    }}
                                    onClick={addVendorContainer}
                                  />
                                )}
                                {selectedJob.VendorContainers.split(";")
                                  .length !== 1 && (
                                  <BiSolidMinusCircle
                                    className="cursor-pointer"
                                    size={30}
                                    style={{ marginLeft: "5px", color: "red" }}
                                    onClick={() => removeVendorContainer(index)}
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>

                    {/* <tbody>
                      <tr>
                        <td>
                          <Input
                            type="text"
                            name="Vendor"
                            value={selectedJob["Vendor"] || ""}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            name="Detail"
                            value={selectedJob["Detail"] || ""}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            name="Amount"
                            value={selectedJob["Amount"] || ""}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody> */}
                  </table>
                </div>
                <br />
              </TabPanel>

              {/* Table Container */}
              <div className="table-container">
                <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                  <thead className="flex-nowrap text-center bg-blue-950 text-white">
                    <tr>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Container Type
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Container Number
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Seal Number
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Number of Packages
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Type Of Package
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Net Weight (kgs)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Gross Weight (kgs)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Volume (m3)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Tare Weight (kgs)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        VGM Weight (kgs)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        VGM Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* string Convert array */}
                    {/* {tableData[selectedTabIndex]?.map(
                      (outerArray, outerIndex) =>
                        outerArray.map((container, innerIndex) => (
                          <>
                            <tr key={`${outerIndex}-${innerIndex}`}>
                              <td>
                                <Input
                                  type="text"
                                  value={container.ContainerType || ""}
                                  onChange={(e) =>
                                    handleTableChange(
                                      e,
                                      outerIndex,
                                      innerIndex,
                                      "ContainerType"
                                    )
                                  }
                                />
                              </td>

                              <td>
                                <Input
                                  type="text"
                                  value={container.ContainerNumber || ""}
                                  onChange={(e) =>
                                    handleTableChange(
                                      e,
                                      outerIndex,
                                      innerIndex,
                                      "ContainerNumber"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  value={container.SealNumber || ""}
                                  onChange={(e) =>
                                    handleTableChange(
                                      e,
                                      outerIndex,
                                      innerIndex,
                                      "SealNumber"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  value={container.No_Of_Pkge || ""}
                                  onChange={(e) =>
                                    handleTableChange(
                                      e,
                                      outerIndex,
                                      innerIndex,
                                      "No_Of_Pkge"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Select
                                  value={container.Type_Of_Pkge || ""}
                                  onChange={(e) =>
                                    handleTableChange(
                                      e,
                                      outerIndex,
                                      innerIndex,
                                      "Type_Of_Pkge"
                                    )
                                  }
                                >
                                  <option>Select Package Type</option>
                                  {packageTypeData.map((item, idx) => (
                                    <option key={idx} value={item.packingType}>
                                      {item.packingType}
                                    </option>
                                  ))}
                                </Select>
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  value={container.NtWeight || ""}
                                  onChange={(e) =>
                                    handleTableChange(
                                      e,
                                      outerIndex,
                                      innerIndex,
                                      "NtWeight"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  value={container.GrWeight || ""}
                                  onChange={(e) =>
                                    handleTableChange(
                                      e,
                                      outerIndex,
                                      innerIndex,
                                      "GrWeight"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  value={container.Volume || ""}
                                  onChange={(e) =>
                                    handleTableChange(
                                      e,
                                      outerIndex,
                                      innerIndex,
                                      "Volume"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  value={container.TareWeight || ""}
                                  onChange={(e) =>
                                    handleTableChange(
                                      e,
                                      outerIndex,
                                      innerIndex,
                                      "TareWeight"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  value={container.VGM || ""}
                                  onChange={(e) =>
                                    handleTableChange(
                                      e,
                                      outerIndex,
                                      innerIndex,
                                      "VGM"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Select
                                  value={container.VGMMethod || ""}
                                  onChange={(e) =>
                                    handleTableChange(
                                      e,
                                      outerIndex,
                                      innerIndex,
                                      "VGMMethod"
                                    )
                                  }
                                >
                                  <option value="">Select Method</option>
                                  <option value="Method I">Method I</option>
                                  <option value="Method II">Method II</option>
                                </Select>
                              </td>
                            </tr>
                          </>
                        ))
                    )} */}
                    {/* String Data */}
                    {selectedJob?.Containers?.split(";")?.map(
                      (container, index) => {
                        const containerAttributes = container.split(", ");

                        console.log(selectedJob);

                        return (
                          <tr key={index}>
                            <td>
                              <Select
                                w="190px"
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                type="text"
                                name="ContainerType"
                                value={
                                  selectedJob[`ContainerType-${index}`] ||
                                  containerAttributes[0]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(
                                    e,
                                    index,
                                    "ContainerType"
                                  )
                                }
                              >
                                <option>Select Equipment</option>
                                {equipmentData.map((item, idx) => (
                                  <option key={idx} value={item.ContainerType}>
                                    {item.ContainerType}
                                  </option>
                                ))}
                              </Select>
                            </td>

                            <td>
                              <Input
                                w="200px"
                                name={`ContainerNumber-${index}`}
                                type="text"
                                value={
                                  selectedJob[`ContainerNumber-${index}`] ||
                                  containerAttributes[4]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(
                                    e,
                                    index,
                                    "ContainerNumber"
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                w="200px"
                                name={`SealNumber-${index}`}
                                type="text"
                                value={
                                  selectedJob[`SealNumber-${index}`] ||
                                  containerAttributes[5]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(
                                    e,
                                    index,
                                    "SealNumber"
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                w="200px"
                                name={`No_Of_Pkge-${index}`}
                                type="text"
                                value={
                                  selectedJob[`No_Of_Pkge-${index}`] ||
                                  containerAttributes[6]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(
                                    e,
                                    index,
                                    "No_Of_Pkge"
                                  )
                                }
                              />
                            </td>
                            <td>
                              {/* <Input
                                w="200px"
                                name={`Type_Of_Pkge-${index}`}
                                type="text"
                                value={
                                  selectedJob[`Type_Of_Pkge-${index}`] ||
                                  containerAttributes[7]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(
                                    e,
                                    index,
                                    "Type_Of_Pkge"
                                  )
                                }
                              /> */}
                              <Select
                                width="190px"
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                name={`Type_Of_Pkge-${index}`}
                                value={
                                  selectedJob[`Type_Of_Pkge-${index}`] ||
                                  containerAttributes[7]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(
                                    e,
                                    index,
                                    "Type_Of_Pkge"
                                  )
                                }
                              >
                                <option>Select Package Type</option>
                                {packageTypeData.map((item, idx) => (
                                  <option key={idx} value={item.packingType}>
                                    {item.packingType}
                                  </option>
                                ))}
                              </Select>
                            </td>
                            <td>
                              <Input
                                w="200px"
                                name={`NtWeight-${index}`}
                                type="text"
                                value={
                                  selectedJob[`NtWeight-${index}`] ||
                                  containerAttributes[8]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(
                                    e,
                                    index,
                                    "NtWeight"
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                w="200px"
                                name={`GrWeight-${index}`}
                                type="text"
                                value={
                                  selectedJob[`GrWeight-${index}`] ||
                                  containerAttributes[9]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(
                                    e,
                                    index,
                                    "GrWeight"
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                w="200px"
                                name={`Volume-${index}`}
                                type="text"
                                value={
                                  selectedJob[`Volume-${index}`] ||
                                  containerAttributes[10]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(e, index, "Volume")
                                }
                              />
                            </td>
                            <td>
                              <Input
                                w="200px"
                                name={`TareWeight-${index}`}
                                type="text"
                                value={
                                  selectedJob[`TareWeight-${index}`] ||
                                  containerAttributes[11]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(
                                    e,
                                    index,
                                    "TareWeight"
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                w="200px"
                                name={`VGM-${index}`}
                                type="text"
                                value={
                                  selectedJob[`VGM-${index}`] ||
                                  containerAttributes[12]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(e, index, "VGM")
                                }
                              />
                            </td>
                            <td>
                              <Select
                                width="190px"
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                name={`VGMMethod-${index}`}
                                value={
                                  selectedJob[`VGMMethod-${index}`] ||
                                  containerAttributes[13]?.split(": ")[1]
                                }
                                onChange={(e) =>
                                  handleInnerContainerChange(
                                    e,
                                    index,
                                    "VGMMethod"
                                  )
                                }
                              >
                                <option>Select option</option>
                                <option value="Method I">Method I</option>
                                <option value="Method II">Method II</option>
                              </Select>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>

              <br />
              {/* End operation */}
            </TabPanel>

            <TabPanel>
              {/* <TabPanel>
                <Badge
                  float="left"
                  fontSize="10px"
                  fontFamily="sans-serif"
                  colorScheme="red"
                >
                  Origin
                </Badge>
              </TabPanel> */}
              <h2
                style={{
                  float: "left",
                  color: "#fff",
                  fontSize: "20px",
                  backgroundColor: "#172554",
                  borderRadius: "10px",
                  fontFamily: "cursive",
                }}
              >
                Origin
              </h2>
              <br />

              {/* Detention and Demurrage */}
              <div className="content">
                <div className="form-user">
                  <div className="user-job">
                    <div className="input-box">
                      <span className="job">Empty PickUp date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="Empty_Pickup_Date"
                        Value={
                          (selectedJob["Empty_Pickup_Date"] || "").split(
                            "T"
                          )[0] || ""
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Laden Gate In Date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="Laden_Gate_In_Date"
                        Value={
                          (selectedJob["Laden_Gate_In_Date"] || "").split(
                            "T"
                          )[0] || ""
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job whitespace-nowrap">
                        Vessel Arrival Date / Vessel Sailing Date
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="Vessel_Arrival_Date"
                        Value={
                          (selectedJob["Vessel_Arrival_Date"] || "").split(
                            "T"
                          )[0] || ""
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Detention</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Detention"
                        value={
                          selectedJob.Detention ||
                          calculateDetention(selectedJob)
                        }
                        readOnly
                        placeholder="Enter your detention"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Liner Free Time / Days</span>
                      {/* Detention By Liner */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Detention_By_Liner"
                        value={selectedJob["Detention_By_Liner"] || ""}
                        onChange={handleChange}
                        placeholder="Enter your detention by liner"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Detention Days</span>
                      {/* No Of Detention Occuring */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="DetentionNo"
                        value={selectedJob["DetentionNo"] || ""}
                        readOnly
                        placeholder="Enter your detention occuring"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Demurrage</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Demurrage"
                        value={
                          selectedJob.Demurrage ||
                          calculateDemurrage(selectedJob)
                        }
                        readOnly
                        onChange={handleChange}
                        placeholder="Enter your demurrage"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Port Demurrage</span>
                      {/* Demurrage By Liner */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Demurrage_By_Liner"
                        value={selectedJob["Demurrage_By_Liner"] || ""}
                        onChange={handleChange}
                        placeholder="Enter your demurrage by liner"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Demurrage Days</span>
                      {/* No Of Demurrage Occuring */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="DemurrageNo"
                        value={selectedJob["DemurrageNo"] || "0"}
                        readOnly
                        placeholder="Enter your demurrage occuring"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Port Storage</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Port_Storage"
                        value={selectedJob["Port_Storage"] || ""}
                        readOnly
                        placeholder="Enter your port storage"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Port Storage Free Time</span>
                      {/* Port Storage Given By Port */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="PortStorage"
                        value={selectedJob["PortStorage"] || ""}
                        onChange={handleChange}
                        placeholder="Enter your storage"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        No Of Days Port Storage Occuring
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Port_Storage_Days"
                        value={selectedJob["Port_Storage_Days"] || "0"}
                        readOnly
                        placeholder="Enter your no of days port storage occuring"
                      />
                    </div>
                    <div className="input-box">
                      <span className="job">
                        Combined Detention & Demurrage
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Combined_Detention_Demurrage"
                        value={
                          selectedJob["Combined_Detention_Demurrage"] || ""
                        }
                        readOnly
                        placeholder="Enter your combined detention demurrage"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        Combined Detention & Demurrage By Liner
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Combined_Detention_Demurrage_Liner"
                        value={
                          selectedJob["Combined_Detention_Demurrage_Liner"] ||
                          ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your combined detention demurrage liner"
                      />
                    </div>
                    <div className="input-box">
                      <span className="job">
                        No Of Days Detention & Demurrage Occuring
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="No_Of_Days_Detention_Demurrage_Occuring"
                        value={
                          selectedJob[
                            "No_Of_Days_Detention_Demurrage_Occuring"
                          ] || ""
                        }
                        readOnly
                        placeholder="Enter your no of days detention demurrage occuring"
                      />
                    </div>
                  </div>
                </div>
                <h2
                  style={{
                    float: "left",
                    color: "#fff",
                    fontSize: "20px",
                    backgroundColor: "#172554",
                    borderRadius: "10px",
                    fontFamily: "cursive",
                  }}
                >
                  Destination
                </h2>
                <br />
                <div className="form-user">
                  <div className="user-job">
                    <div className="input-box">
                      <span className="job">Discharge Date</span>
                      {/* Container Discharge Date */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="Container_Discharge_Date"
                        value={
                          (selectedJob.Container_Discharge_Date || "").split(
                            "T"
                          )[0]
                        }
                        onChange={handleChange}
                        placeholder="Enter your container discharge date"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Port Out Date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="Port_Out_Date"
                        value={(selectedJob.Port_Out_Date || "").split("T")[0]}
                        onChange={handleChange}
                        placeholder="Enter your port out date"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Empty Return Date</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="Empty_Return_Date"
                        value={
                          (selectedJob.Empty_Return_Date || "").split("T")[0]
                        }
                        onChange={handleChange}
                        placeholder="Enter your empty return date"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Detention</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Destination_Detention"
                        value={selectedJob["Destination_Detention"] || ""}
                        readOnly
                        placeholder="Enter your detention"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Demurrage</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Destination_Demurrage"
                        value={selectedJob["Destination_Demurrage"] || ""}
                        onChange={handleChange}
                        placeholder="Enter your demurrage"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Liner Free Time / Days</span>
                      {/* Detention By Liner */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Destination_Detention_By_Liner"
                        value={
                          selectedJob["Destination_Detention_By_Liner"] || ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your detention by liner"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Demurrage By Liner</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Destination_Demurrage_By_Liner"
                        value={
                          selectedJob["Destination_Demurrage_By_Liner"] || ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your demurrage by liner"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Detention Days</span>
                      {/* No Of Detention Occuring */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Destination_No_Of_Detention_Occuring"
                        value={
                          selectedJob["Destination_No_Of_Detention_Occuring"] ||
                          ""
                        }
                        readOnly
                        placeholder="Enter your no of detention occuring"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Demurrage Days</span>
                      {/* No Of Demurrage Occuring */}
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Destination_No_Of_Demurrage_Occuring"
                        value={
                          selectedJob["Destination_No_Of_Demurrage_Occuring"] ||
                          ""
                        }
                        readOnly
                        placeholder="Enter your no of demurrage occuring"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Port Storage</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Destination_Port_Storage"
                        value={selectedJob["Destination_Port_Storage"] || ""}
                        onChange={handleChange}
                        placeholder="Enter your port storage destination"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">Port Storage Given By Port</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Destination_Port_Storage_Given_By_Port"
                        value={
                          selectedJob[
                            "Destination_Port_Storage_Given_By_Port"
                          ] || ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your port storage given by port"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        No Of Days Port Storage Occuring
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Destination_No_Of_Days_Port_Storage_Occuring"
                        value={
                          selectedJob[
                            "Destination_No_Of_Days_Port_Storage_Occuring"
                          ] || ""
                        }
                        readOnly
                        placeholder="Enter your no of days port storage occuring"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        Combined Detention & Demurrage
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Destination_Combined_Detention_Demurrage"
                        value={
                          selectedJob[
                            "Destination_Combined_Detention_Demurrage"
                          ] || ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your combined detention & demurrage"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        Combined Detention & Demurrage By Liner
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Destination_Combined_Detention_Demurrage_By_Liner"
                        value={
                          selectedJob[
                            "Destination_Combined_Detention_Demurrage_By_Liner"
                          ] || ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your combined detention & demurrage by liner"
                      />
                    </div>

                    <div className="input-box">
                      <span className="job">
                        No Of days Detention & Demurrage Occuring
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="Number"
                        name="Destination_No_Of_Days_Detention_Demurrage_Occuring"
                        value={
                          selectedJob[
                            "Destination_No_Of_Days_Detention_Demurrage_Occuring"
                          ] || ""
                        }
                        readOnly
                        placeholder="No of days detention & demurrage occurring"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End De & D */}
            </TabPanel>

            <TabPanel></TabPanel>
          </TabPanels>
        </Tabs>
      </div>
      <>
        {isOpenJobTransferData && (
          <div style={blurBackgroundStyle}>
            <div className={`MassUpdateModalConfirm ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 600,
                  // height: 200
                }}
                //cancel="X"
                cancel="cancel"
                allowAnyClick={true}
              >
                <header
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    float: "left",
                    height: "1px",
                  }}
                >
                  Job tranfer - Job / Booking Number : {selectedJob?.JobNumber}{" "}
                  /{bookingNumbers.join(", ")}
                </header>
                <div
                  onClick={() => {
                    onCloseJobTransferData(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <FormControl></FormControl>
                <br />
                <br />

                <OperationTransferData
                  jobNumber={selectedJob?.JobNumber}
                  onSelect={handleSelectedRegionData}
                  onSelectJobTransfer={handleSelectedJobTransfer}
                  onSelectProduct={handleSelectedProduct}
                />
                <br />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleSubmitJobTransfer();
                    onCloseJobTransferData();
                  }}
                >
                  Send
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onCloseJobTransferData();
                  }}
                  ml={3}
                >
                  Cancel
                </Button>
              </Rnd>
            </div>
          </div>
        )}
      </>

      <>
        {isOpenJobAdd && (
          <div style={blurBackgroundStyle}>
            <div className={`MassUpdateModalConfirm ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 600,
                  // height: 200
                }}
                //cancel="X"
                cancel="cancel"
                allowAnyClick={true}
              >
                <header
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    float: "left",
                    height: "1px",
                  }}
                >
                  Add Job - Job / Booking Number : {selectedJob?.JobNumber} /
                  {bookingNumbers.join(", ")}
                </header>
                <div
                  onClick={() => {
                    onCloseJobAdd(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <FormControl></FormControl>
                <br />
                <br />

                <OperationAddOps
                  jobNumber={selectedJob?.JobNumber}
                  onSelectOps={handleSelectedOpsUserData}
                  onSelectOpsDocument={handleSelectedOpsDocumentData}
                />
                <br />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleSubmitJobAdd();
                    onCloseJobAdd();
                  }}
                >
                  Send
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onCloseJobAdd();
                  }}
                  ml={3}
                >
                  Cancel
                </Button>
              </Rnd>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default EditJobPage;
