import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios, {
  HrmAPIInstance,
} from "../../api-instance";
import Bruhat_Logo from "../../assets/img/Bruhat_Logo.png";
import {
  Card,
  Stack,
  Switch,
  Collapse,
  Input,
  Heading,
  Text,
  CardBody,
  Box,
  Button,
  useToast,
} from "@chakra-ui/react";

// Importing the CSS file
import "./AddUploadEmployee.css";

const AddUploadEmployeeDoc = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const [NDAUpload, setNDAUpload] = useState(false);
  const [BondUpload, setBondUpload] = useState(false);
  const [FormUpload, setFormUpload] = useState(false);
  const [OfferUpload, setOfferUpload] = useState(false);
  const [fileError, setFileError] = useState("");
  const toast = useToast();
  const { token } = useParams();
 
  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await HrmAPIInstance.get(
          `employee_validate_token?token=${token}`
        );
        if (!response.data.success) {
          navigate("/*");
        }
      } catch (err) {
        navigate("/*");
      }
    };

    validateToken();
    const intervalId = setInterval(() => {
      validateToken();
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [token, navigate]);

  //Size
  const validateFileSize = (file) => {
    const maxOfferSize = 247 * 1024; // 247 KB in bytes
    const maxSignatureSize = 100 * 1024; // 100 KB in bytes

    if (file.size > maxOfferSize) {
      setFileError("The Offer Letter file size should not exceed 247 KB.");
      return false;
    }
    if (
      file.name.toLowerCase().includes("signature") &&
      file.size > maxSignatureSize
    ) {
      setFileError("The Digital Signature file size should not exceed 100 KB.");
      return false;
    }

    setFileError(""); // Reset error if validation passes
    return true;
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (validateFileSize(file)) {
        toast({
          title: "File uploaded successfully.",
          status: "success",
          
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "File upload failed.",
          description: fileError,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <div className="logo-container">
        <img src={Bruhat_Logo} className="mr-3 h-6 sm:h-9" alt="Bruhat" />
      </div>
      <Box className="card-container">
        <Card className="card">
          <CardBody>
            <Stack spacing={4} textAlign="left">
              <Heading className="card-heading">
                Document Upload Guidelines
                <hr />
              </Heading>
              <Box
                as="ol"
                className="card-description-list"
              >
                <Text as="li">
                  All the documents are to be submitted online within 3 days of
                  acceptance.
                </Text>
                <Text as="li">The file size should not exceed 5 MB.</Text>
                <Text as="li">
                  After uploading each document, save it and move on to the next
                  document upload
                </Text>
                <Text as="li">Digital Signature should be no more than 100 KB </Text>
              </Box>
            </Stack>
          </CardBody>
        </Card>

        <Card className="card">
          <CardBody>
            <Stack spacing={4}>
              <Box className="toggle-switch-container">
                <Heading className="toggle-switch-container heading">Offer Letter</Heading>
                <Stack direction="row" alignItems="center">
                  <Text >Upload</Text>
                  <Switch
                    isChecked={OfferUpload}
                    onChange={() => setOfferUpload(!OfferUpload)}
                  />
                </Stack>
              </Box>
              <Text className="upload-description">
                 Upload the Offer Letter document
              </Text>
              <Collapse in={OfferUpload}>
                <Stack spacing={4} className="upload-input-container">
                  <Input className="file-input" type="file" accept=".pdf,.docx,.png" variant="filled" onChange={handleFileChange} />
                  <Button colorScheme="blue" alignSelf="start" size="sm">
                    Upload Offer Letter
                  </Button>
                  {fileError && (
                    
                <Text color="red.500" fontSize="sm">
                  {fileError}
                </Text>
              )}
                </Stack>
              </Collapse>
            </Stack>
          </CardBody>
          <Box className="save-button-container">
            <Button colorScheme="blue">Save</Button>
          </Box>

          
        </Card>

        <Card className="card">
          <CardBody>
            <Stack spacing={4}>
              <Box className="toggle-switch-container">
                <Heading className="toggle-switch-container heading">Bond Document</Heading>
                <Stack direction="row" alignItems="center">
                  <Text>Upload</Text>
                  <Switch
                    isChecked={BondUpload}
                    onChange={() => setBondUpload(!BondUpload)}
                  />
                </Stack>
              </Box>
              <Text className="upload-description">
                 Upload the Bond document
              </Text>
              <Collapse in={BondUpload}>
                <Stack spacing={4} className="upload-input-container">
                  <Input type="file" accept=".pdf,.docx,.png" />
                  
                  <Button colorScheme="blue" alignSelf="start">
                    Upload Bond
                  </Button>
                 
                </Stack>
              </Collapse>
            </Stack>
          </CardBody>
          <Box className="save-button-container">
            <Button colorScheme="blue">Save</Button>
          </Box>
         
        </Card>

        <Card className="card">
          <CardBody>
            <Stack spacing={4}>
              <Box className="toggle-switch-container">
                <Heading className="toggle-switch-container heading">NDA Document</Heading>
                <Stack direction="row" alignItems="center">
                  <Text>Upload</Text>
                  <Switch
                    isChecked={NDAUpload}
                    onChange={() => setNDAUpload(!NDAUpload)}
                  />
                </Stack>
              </Box>
              <Text className="upload-description">
                Upload the NDA document
              </Text>
              <Collapse in={NDAUpload}>
                <Stack spacing={4} className="upload-input-container">
                  <Input type="file" accept=".pdf,.docx,.png" />
                  <Button colorScheme="blue" alignSelf="start">
                    Upload NDA
                  </Button>
                </Stack>
              </Collapse>
            </Stack>
          </CardBody>
          <Box className="save-button-container">
            <Button colorScheme="blue">Save</Button>
          </Box>
        </Card>

        <Card className="card">
          <CardBody>
            <Stack spacing={4}>
              <Box className="toggle-switch-container">
                <Heading className="toggle-switch-container heading">Form 11 Document</Heading>
                <Stack direction="row" alignItems="center">
                  <Text>Upload</Text>
                  <Switch
                    isChecked={FormUpload}
                    onChange={() => setFormUpload(!FormUpload)}
                  />
                </Stack>
              </Box>
              <Text className="upload-description">
                Upload the Form 11 document
              </Text>
              <Collapse in={FormUpload}>
                <Stack spacing={4} className="upload-input-container">
                  <Input type="file" accept=".pdf,.docx,.png" />
                  <Button colorScheme="blue" alignSelf="start">
                    Form11 Upload
                  </Button>
                </Stack>
              </Collapse>
            </Stack>
          </CardBody>
          <Box className="save-button-container">
            <Button colorScheme="blue">Save</Button>
          </Box>
        </Card>

        <Box className="bottom-buttons-container">
          <Stack direction="row" spacing={4}>
            <Button colorScheme="blue">Save</Button>
            <Button colorScheme="blue">Submit</Button>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default AddUploadEmployeeDoc;
