import React, { useState, useCallback, useEffect } from "react";
import { Header } from "../../components";
import Axios, {
  HrmAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance";
import { useNavigate } from "react-router-dom";
import { Card,Stack, Heading, CardBody, CardFooter } from '@chakra-ui/react';
// import Sidebar from "../HrmDashboard/sidebar.js";
//import "../AddEmployee/uploads/avatar.jpg"
import "./AddEmployee.css";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Image,
  Select,
  Step,
  StepIndicator,
  StepSeparator,
  StepTitle,
  Stepper,
  Text,
  useToast,
  Checkbox,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
const STEPS = [
  {
    label: "Basic Info",
    fields: [
      // "employeeNumberSeries",
      // "employeeNo",
      "First_Name",
      "Last_Name",
      "DateOfBirth",
      "PlaceOfBirth",
      "AadhaarNumber",
      "Reporting_Manager",
      "Bloodgroup",
      "Gender",
      "Nationality",
      "Religion",
      "Employee_Type",
      "Employee_Status",
      "DateOfJoining",
      "ReferredBy",
      "ProbationPeriod",
      "ConfirmationDate",
      "Email",
      "Mobile_Number",
    ],
  },
  { 
    label: "Employee Position", 
    fields: ["Cost_Center", "Designation", "Department", "Location"],
    dropdownOptions: {

      Location:[
           "Chennai",
           "Mumbai",
           "Bengalore"
      ]
      
    }
  },
  { 
    label: "Statutory Info", 
    fields: ["Pan_Number", "Include_Pf", "Include_Esi", "Include_Wl","Pf_Number","Uan_Number", "Pf_Excess_Contribution"] 
  },
  { 
    label: "Payment Mode", 
    fields: ["Payment_Type", "Bank_Name", "Bank_Branch", "Account_Number"] 
  },
  { 
    label: "PhotoUpload", 
    fields: ["photoupload"] 
  },

];
    
const initialFormValues = {
  // employeeNumberSeries: "",
  // employeeNo: "",
  First_Name: "",
  Last_Name: "",
  DateOfBirth: "",
  PlaceOfBirth: "",
  AadhaarNumber: "",
  Bloodgroup: "",
  Gender: "",
  Nationality: "",
  Religion: "",
  // probationPeriodConfirmation: "", 
  Reporting_Manager: "",
  Employee_Type: "",
  Employee_Status:"",
  DateOfJoining: "",
  ReferredBy: "",
  ProbationPeriod: "",
  ConfirmationDate: "",
  Email: "",
  Mobile_Number:"",
  Designation: "",
  Department: "",
  Location: "",
  Cost_Center: "",
  Pan_Number: "",
  Include_Pf: false,
  Include_Esi: false,
  Include_Wl:false,
  Pf_Number:"",
  Uan_Number: "",
  Pf_Excess_Contribution: "",
  Payment_Type:"",
  Bank_Name:"",
  Bank_Branch:"",
  Account_Number:"",
  photoupload:"",
//  EmployeeOwner: JSON.parse(localStorage.getItem("user")).userId,


};

const validateAadharNumber = (Aadhaar_Number) => {
  const regex = /^[0-9]{4}\s[0-9]{4}\s[0-9]{4}$/;
  return regex.test(Aadhaar_Number);
};

const validatePANNumber = (Pan_Number) => {
  const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/; // Updated to match exact PAN format
  return regex.test(Pan_Number);
};

const AddEmployee = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [photoUpload, setPhotoUpload] = useState(null);
  const toast = useToast();
  const [reportingManagerData, setReportingManagerData] = useState([]);
  const [costCenterData, setCostCenterData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const[departmentData,setDepartmentData] =useState([]);
  const[file,setFile] =useState([]);
  const[selectedEmployee,setselectedEmployee] =useState([]);
  const[UrlObject,setUrlObject]=useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const validateFields = useCallback(
    (fields) => {
      const newErrors = {};
      let isValid = true;
  
      fields.forEach((field) => {
        const value = formValues[field] || "";
  
        // Ensure value is a string before using trim
        const valueAsString = typeof value === 'string' ? value : "";
  
        if (field === 'AadhaarNumber') {
          if (!validateAadharNumber(valueAsString)) {
            newErrors[field] = 'Invalid Aadhaar number format';
            isValid = false;
          } else {
            newErrors[field] = false;
          }
        } else if (field === 'Pan_Number') {
          if (!validatePANNumber(valueAsString)) {
            newErrors[field] = 'Invalid PAN number format';
            isValid = false;
          } else {
            newErrors[field] = false;
          }
        } else if (field !== "photoUpload" && valueAsString.trim() === "" && field !== "includePF" && field !== "includeESI") {
          newErrors[field] = 'This field is required';
          isValid = false;
        } else {
          newErrors[field] = false;
        }
      });
  
      // Return the new errors object and validity status
      return { newErrors, isValid };
    },
    [formValues]
  );
  
  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    setPhotoUpload(file);
    console.log("Selected file:", file); // Check the file here
};

  const handleValidation = () => {
    const fields = STEPS[currentStep].fields;
    return validateFields(fields);
  };
  //upload file
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        const url = URL.createObjectURL(file);
        setUrlObject(url); // Update your state with the URL
    }
};

  
// Helper function to format file size
const formatFileSize=(size)=>{
  let fileSizeInKB = size / 1024;
  if (fileSizeInKB < 1024) {
    return `${fileSizeInKB.toFixed(2)} KB`;
  } else {
    return `${(fileSizeInKB / 1024).toFixed(2)} MB`;
  }
}
  const handleChange = (e) => {
    const { id, value, files, checked, type } = e.target;
    setFormValues((prevValues) => {
      const newValues = {
        ...prevValues,
        [id]: type === "checkbox" ? checked : files ? files[0] : value,
      };

      // If employeeNumberSeries is changed, update employeeNo
      // if (id === "employeeNumberSeries") {
      //   newValues.employeeNo = generateEmployeeNumber(value);
      // }

      return newValues;
    });
  };
//Reporting Manager
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_reportingmanager");
        setReportingManagerData(data.data); // Assuming 'data.data' holds the required manager list
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  
  //Cost center
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_costcenter");
        setCostCenterData(data.data); // Assuming 'data.data' holds the required manager list
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //Designation 
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_designation");
        setDesignationData(data.data); // Assuming 'data.data' holds the required manager list
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

//Department
useEffect(() => {
  const getData = async () => {
    try {
      const { data } = await MasterDataAPIInstance.get("get_department");
      setDepartmentData(data.data); // Assuming 'data.data' holds the required manager list
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  getData();
}, []);

  const handleNext = () => {
    console.log("NEst")
    if (handleValidation()) {
      setCurrentStep((prev) => Math.min(prev + 1, STEPS.length - 1));
    } else {
      toast({
        title: "Incomplete Information",
        description: "Please fill out all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleBack = () => setCurrentStep((prev) => Math.max(prev - 1, 0));
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formValues, "form");

    try {
        // Submit the employee data
        const { data } = await HrmAPIInstance.post(
            `add_employee?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
            formValues,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: JSON.parse(localStorage.getItem("token")),
                },
            }
        );

        if (data.success) {
            const employeeNumber = data.data; // Assuming the response contains the employee number

            // Prepare FormData for photo upload
            const formData = new FormData();
            if (photoUpload) {
                // If a photo is uploaded, append the file to FormData
                formData.append("file", photoUpload);
            } else {
                 // If no photo is uploaded, add a default dummy photo
                 const dummyPhotoPath = '../AddEmployee/uploads/avatar.jpg'; // Adjust the path to your default image
                 const dummyPhoto = new File([dummyPhotoPath], "avatar.jpg", {
                     type: "image/jpeg",
                 });
                 formData.append("file", dummyPhoto);
            }

            formData.append("EmployeeNumber", employeeNumber);
            formData.append("EmployeeOwner", JSON.parse(localStorage.getItem("user")).userId);
            console.log("FormData", formData);

            // Upload the photo
            try {
                const uploadResponse = await HrmAPIInstance.post(
                    `single_upload_employeephoto?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: JSON.parse(localStorage.getItem("token")),
                        },
                    }
                );

                const uploadData = uploadResponse.data;
                toast({
                    title: uploadData.message,
                    position: "bottom-center",
                    isClosable: true,
                    status: uploadData.message === "Employee Photo Upload successfully." ? "success" : "error",
                    containerStyle: {
                        width: "98.5vw",
                        maxWidth: "98.5vw",
                        alignContent: "center",
                    },
                });
            } catch (uploadError) {
                // Handle upload error
                toast({
                    title: uploadError.message,
                    position: "bottom-center",
                    isClosable: true,
                    status: "error",
                    containerStyle: {
                        width: "98.5vw",
                        maxWidth: "98.5vw",
                        alignContent: "center",
                    },
                });
            }

            // Navigate to the employee list page after both submissions
            navigate("/employee");
        } else {
            // Handle the case where employee data submission failed
            toast({
                title: data.message,
                position: "bottom-center",
                isClosable: true,
                status: "error",
                containerStyle: {
                    width: "98.5vw",
                    maxWidth: "98.5vw",
                    alignContent: "center",
                },
            });
        }
    } catch (error) {
        // Handle the case where employee data submission throws an error
        toast({
            title: error.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
                width: "98.5vw",
                maxWidth: "98.5vw",
                alignContent: "center",
            },
        });
    }
};


  const getInputType = (field) => {
    if (["DateOfBirth", "DateOfJoining", "ConfirmationDate"].includes(field)) {
      return "date";
    }
    if (field === "Email") {
      return "email";
    }
    if (
      [
        "Mobile_Number", 
        "Aadhaar_Number",
        "Account_Number",
        "Pan_Number",
        "Uan_Number",
        "Pf_Excess_Contribution",
      ].includes(field)
    ) {
      return "text";
    }
  };

  const splitFieldsIntoColumns = (fields) => {
    const half = Math.ceil(fields.length / 2);
    return [fields.slice(0, half), fields.slice(half)];
  };

  const renderFields = (fields) => {
    const dropdownOptions = STEPS[currentStep].dropdownOptions || {};
    return fields.map((field, index) => (
      <div key={field} className="input-box">
        <FormControl isInvalid={errors[field]}>
          {["Include_Pf", "Include_Esi", "Include_Wl"].includes(field) ? (
            <div className="checkbox-group" style={{ display: 'flex', flexDirection: 'column' }}>
              <Checkbox
                id={field}
                isChecked={formValues[field]}
                onChange={handleChange}
                style={{ marginRight: '8px' }}
              >
                {field === "Include_Pf"
                  ? "Include PF"
                  : field === "Include_Esi"
                  ? "Include ESI"
                  : "Include WL"}
              </Checkbox>
            </div>
          ) : (
            <>
              <FormLabel className="employee" htmlFor={field}>
                {field.replace(/([A-Z])/g, " $1").toUpperCase()}
                {errors[field] && <Text as="span" color="red.500">*</Text>}
              </FormLabel>
              {field === "Mobile_Number" ? (
                <>
                  <Input
                    className="text-black bg-white light:bg-gray-950 dark:text-white"
                    id={field}
                    type={getInputType(field)}
                    placeholder={`Enter your ${field.replace(/([A-Z])/g, " $1")}`}
                    value={formValues[field]}
                    onChange={handleChange}
                  />
                  
                  <Checkbox
                    id="fillInfoCheckbox"
                    isChecked={formValues.fillInfoCheckbox}
                    onChange={(e) => setFormValues(prev => ({
                      ...prev,
                      fillInfoCheckbox: e.target.checked
                    }))}
                    style={{ marginTop: '8px' }}
                  >
                    Allow the employee to fill in their information 
                  </Checkbox>
                </>
              ) : (
                <>
                  {field === "Gender" ? (
                    <Select
                      id={field}
                      placeholder="Select the Gender"
                      value={formValues[field]}
                      onChange={handleChange}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </Select>
                  ) : field === "Reporting_Manager" ? (
                    <Select
                      id={field}
                      placeholder="Enter Reporting Manager"
                      value={formValues[field]}
                      onChange={handleChange}
                    >
                      <option>Select Reporting Manager</option>
                      {reportingManagerData.map((item) => (
                        <option key={item.reportingmanagerId} value={item.reportingmanagerId}>
                          {item.reportingmanagerId}
                        </option>
                      ))}
                    </Select>
                  )  : field === "Employee_Type" ? (
                    <Select
                      id={field}
                      placeholder="Select the Employee_Type"
                      value={formValues[field]}
                      onChange={handleChange}
                    >
                      <option value="Permanent">Permanent</option>
                      <option value="Contract">Contract</option>
                      <option value="Intern">Intern</option>
                      <option value="Probation">Probation</option>
                      
                    </Select>
                  ) : field === "Employee_Status" ? (
                    <Select
                      id={field}
                      placeholder="Select the Employee_Status"
                      value={formValues[field]}
                      onChange={handleChange}
                    >
                      <option value="Active">Active</option>
                      <option value="Terminated">Terminated</option>
                      <option value="Deceased">Deceased</option>
                      <option value="Resigned">Resigned</option>
                      
                    </Select>
                  ) 
                  : field === "Cost_Center" ? (
                    <Select
                      id={field}
                      value={formValues[field]}
                      onChange={handleChange}
                    >
                      <option>Select Cost Center</option>
                      {costCenterData.map((item) => (
                        <option key={item.costcenterId} value={item.costcenterId}>
                          {item.costcenterId}
                        </option>
                      ))}
                    </Select>
                  ) : field === "Designation" ? (
                    <Select
                      id={field}
                      value={formValues[field]}
                      onChange={handleChange}
                    >
                      <option>Select Designation</option>
                      {designationData.map((item) => (
                        <option key={item.designationId} value={item.designationId}>
                          {item.designationId}
                        </option>
                      ))}
                    </Select>
                  ) : field === "Department" ? (
                    <Select
                      id={field}
                      value={formValues[field]}
                      onChange={handleChange}
                    >
                      <option>Select Department</option>
                      {departmentData.map((item) => (
                        <option key={item.departmentId} value={item.departmentId}>
                          {item.departmentId}
                        </option>
                      ))}
                    </Select>
                  ) : field === "Location" ? (
                    <Select
                      id={field}
                      placeholder="Select Location"
                      value={formValues[field]}
                      onChange={handleChange}
                    >
                      {dropdownOptions.Location?.map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  ) : field === "Pf_Excess_Contribution" ? (
                    <RadioGroup
                      id={field}
                      value={formValues[field]}
                      onChange={(value) =>
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          [field]: value,
                        }))
                      }
                    >
                      <FormControl>
                        <Radio value="Employee & Employer Contribution -12% with in wage ceiling (Max Rs.1800)">
                          Employee & Employer Contribution -12% with in wage ceiling (Max Rs.1800)
                        </Radio>
                        <Radio value="Employee contribution-12% over and above wage ceiling (In Excess to Rs.1800)">
                          Employee contribution-12% over and above wage ceiling (In Excess to Rs.1800)
                        </Radio>
                      </FormControl>
                    </RadioGroup>
                  ) 
                  : (
                    <Input
                      className="text-black bg-white light:bg-gray-950 dark:text-white"
                      id={field}
                      type={getInputType(field)}
                      placeholder={`Enter your ${field.replace(/([A-Z])/g, " $1")}`}
                      value={formValues[field]}
                      onChange={handleChange}
                      accept={field === "photoUpload" ? "image/*" : undefined}
                    />
                  )
                  }
                </>
              )}
            </>
          )}
        </FormControl>
      </div>
    ));
  };
  const calculateConfirmationDate = useCallback(() => {
    const { DateOfJoining, ProbationPeriod } = formValues;
    if (DateOfJoining && ProbationPeriod) {
      const doj = new Date(DateOfJoining);
      const confirmationDate = new Date(doj);

      const daysToAdd = parseInt(ProbationPeriod, 10);
      confirmationDate.setDate(doj.getDate() + daysToAdd);

      setFormValues((prevValues) => ({
        ...prevValues,
        confirmationDate: confirmationDate.toISOString().split("T")[0], // Format to YYYY-MM-DD
      }));
    }
  }, [formValues]); 

  useEffect(() => {
    calculateConfirmationDate();
  }, [formValues.DateOfJoining, formValues.ProbationPeriod, calculateConfirmationDate]);

  const [firstColumnFields, secondColumnFields] = splitFieldsIntoColumns(STEPS[currentStep].fields);

  return (
    <>
      <Header />
      <div className="top-bar bg-blue-950 text-white dark:bg-indigo-900">
  
        <p className="float-left py-1 px-2 rounded my-2 mb-6">Create Employee Management</p>
      </div>
      <div className="content pb-4">
        &nbsp;&nbsp;
        <Stepper activeStep={currentStep} colorScheme="teal" size="lg">
          {STEPS.map((step, index) => (
            <Step key={index}>
              <StepIndicator
                bg={currentStep === index ? "teal.500" : "gray.300"}
                color={currentStep === index ? "white" : "gray.500"}
                borderRadius="full"
              />
              <StepTitle
                fontWeight={currentStep === index ? "bold" : "normal"}
                color={currentStep === index ? "teal.500" : "gray.600"}
              >
                {step.label}
              </StepTitle>
              <StepSeparator bg={currentStep === index ? "teal.500" : "gray.200"} height="2px" />
            </Step>
          ))}
        </Stepper>

        &nbsp;&nbsp;
      
        <div className="form-user-emp">
          <div className="user-employee">
            <div className="input-box">
              {renderFields(firstColumnFields)}
            </div>
            <div className="input-box">{renderFields(secondColumnFields)}</div>
          </div> 
        </div>
        {currentStep === 4 && (
 <Box mt={4}>
 <Box display="flex" flexDirection="row">
     <Box flex="1" ml={5}>
         <Input
             type="file"
             accept="image/*"
             onChange={handlePhotoUpload} // Ensure this is set
             mb={4}
         />
         {photoUpload && (
             <Card
                 direction={{ base: 'column', sm: 'row' }}
                 overflow="hidden"
                 variant="outline"
             >
                 <Image
                     objectFit="cover"
                     maxW={{ base: '100%', sm: '200px' }}
                     src={URL.createObjectURL(photoUpload)}
                     alt="Employee Photo"
                     boxSize="200px"
                     borderRadius="md"
                 />
                 <Stack>
                     <CardBody>
                         <Heading size="md">Employee Photo</Heading>
                         <Text py="2" fontWeight="bold">
                             Photo Size: {formatFileSize(photoUpload.size)}
                         </Text>
                     </CardBody>
                     <CardFooter>
                         <Button variant="solid" colorScheme="blue">
                             Confirm Photo
                         </Button>
                     </CardFooter>
                 </Stack>
             </Card>
         )}
     </Box>
 </Box>
</Box>
)}


  <Button
    variant="outline"
    mr={4}
    onClick={handleBack}
    isDisabled={currentStep === 0}
  >
    Previous
  </Button>
  <Button
  colorScheme="teal"
  onClick={currentStep === STEPS.length - 1 ? handleSubmit : handleNext}
>
  {currentStep === STEPS.length - 1 ? "Submit" : "Next"}
</Button>
      </div>
    </>

  );
};

export default AddEmployee;


