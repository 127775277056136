/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FileSaver } from "file-saver";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { FiPrinter } from "react-icons/fi";
import { read, utils, writeFile } from "xlsx";
import { color } from "framer-motion";
import moment, { utc } from "moment/moment";
import { Rnd } from "react-rnd";
// External module
import Axios, {
  ProfomaInvoiceAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance.js";
import "../../assets/css/container.css";
import {
  DraggableCnTable,
  Header,
  OperationSidebar,
  UnAuthorizedModal,
} from "../../components";
// Internal modules
//import "./CreditNote.css";
import Selectallpages from "./Selectallpages";
import { Checkvalidation } from "./helpers/Checkvalidation.js";
// Chakara ui modules
import { useDisclosure } from "@chakra-ui/react";
import { Flex, Select, useToast, Text, Tooltip } from "@chakra-ui/react";
import { ModalOverlay, Button } from "@chakra-ui/react";

import { FormControl, FormLabel, Input } from "@chakra-ui/react";

import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
// Icons import

import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";

import { MdOutlineDownloadForOffline } from "react-icons/md";

import { FaFilter } from "react-icons/fa";
import { TbAdjustmentsHorizontal, TbUserEdit } from "react-icons/tb";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle, IoMdFastforward } from "react-icons/io";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiArrowRightCircle } from "react-icons/fi";
import { SiMicrosoftoutlook } from "react-icons/si";
import { MdSelectAll } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";

const CreditNote = () => {
  // State Variables
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const navigate = useNavigate();
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const {
    isOpen: isOpenManagecolumn,
    onOpen: onOpenManagecolumn,
    onClose: onCloseManagecolumn,
  } = useDisclosure();
  const {
    isOpen: isOpenReportDelete,
    onOpen: onOpenReportDelete,
    onClose: onCloseReportDelete,
  } = useDisclosure();
  const [overlay, setOverlay] = useState(<OverlayOne />);
  const cancelRef = useRef();
  const dropdownRef = useRef(null);
  const searchRef = useRef(null);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const [excelOpen, setExcelOpen] = useState(false);
  const toast = useToast();
  const [isActionOpen, setActionOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isManagecolumnOpen, setManagecolumnOpen] = useState(false);
  const [crnData, setCrnData] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [handleCheckboxFilter, setHandleCheckboxFilter] = useState("OR");
  const [updatedConditions, setUpdatedConditions] = useState([]);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [prospectWarning, setProspectWarning] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState("");
  const [selectedOption, setSelectedOption] = useState("Select a field");
  const [updatedValue, setUpdatedValue] = useState(null);
  const [istoOpen, setIsToOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [bookingWarning, setBookingWarning] = useState(false);
  const [selectedCnData, setSelectedCnData] = useState({});
  const [loading, setLoading] = useState(false);

  // Unallcoted
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [jobforPiState, setJobforPiState] = useState([]);

  const [taxNumber, setTaxNumber] = useState("");
  const [irnStatus, setIrnStatus] = useState("");
  const [tiCountry, setTiCountry] = useState("");

  const handleApply = () => {
    console.log("Selected Data:", selectedData);
  };

  // Column Toggle
  const [columns, setColumns] = useState({
    id: true,
    status: true,
    message: true,
    Irn: true,
    AckDt: true,
    AckNo: true,
    InvoiceStatus: true,
    SignedQrCodeImgUrl: true,
    InvoicePdfUrl: true,
    IrnStatus: true,
    PiNumber: true,
    TaxNumber: true,
    TiRegion: true,
    CrnNumber: true,
    Bill_To: true,
    JobNumber:true,
  });
  const [tableColumnsCrn, settableColumnsCrn] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setHandleCheckboxFilter(value);
  };

  const handleCheckboxColumn = (event, item) => {
    settableColumnsCrn((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });

    const newData = tableColumnsCrn.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );

    localStorage.setItem("tableColumnsCrn", JSON.stringify(newData));
  };
  const handleCheckbox = (id, TaxNumber, IrnStatus, TiRegion) => {
    console.log(id, TaxNumber);
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
    setTaxNumber(TaxNumber);
    setIrnStatus(IrnStatus);
    setTiCountry(TiRegion);
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await ProfomaInvoiceAPIInstance.get(
        `/get_creditnote?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.data?.length === 0) {
        setCrnData([]);
        return setColumns2([]);
      }
      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "status"
              ? false
              : item === "message"
              ? false
              : item === "distance"
              ? false
              : item === "SignedQrCode"
              ? false
              : item === "SignedInvoice"
              ? false
              : item === "SignedQrCodeImgUrl"
              ? false
              : item === "InvoicePdfUrl"
              ? false
              : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });

        return filteredItem;
      });
      setCrnData(filteredData);
      setExportData(data.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        // If 401 Unauthorized, show the Unauthorized Modal
        setIsUnauthorized(true);
      } else if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("crnfilter")?.length === 0) {
      return setCrnData([]);
    }

    if (window.localStorage.getItem("crnfilter")) {
      getFilterData();
    } else {
      getData();
    }
  }, []);

  const toggleActionDropdown = () => {
    setActionOpen(!isActionOpen);
  };
  const handleActionSelection = () => {
    setActionOpen(false);
    toggleActionDropdown();
  };
  //print pdf
  // const handlePrint = async () => {
  //   console.log(taxNumber);
  //   const userId = JSON.parse(localStorage.getItem("user")).userId;
  //   const token = JSON.parse(localStorage.getItem("token"));

  //   try {
  //     const crnTidata = await ProfomaInvoiceAPIInstance.get(
  //       `/get_note_pdfdata?userId=${userId}&type=CRN&id=${taxNumber}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: token,
  //         },
  //       }
  //     );

  //     // Assuming PITaxData is obtained from the API response
  //     const PITaxData = crnTidata.data.data[0];
  //     if (PITaxData && PITaxData.Containers && PITaxData.Containers !== "") {
  //       const operations = JSON.parse(PITaxData.Containers).map((container) => {
  //         const containerObj = {};
  //         Object.keys(container).forEach((key) => {
  //           containerObj[key] = container[key] === "" ? null : container[key];
  //         });
  //         return containerObj;
  //       });
  //       PITaxData.Operations = operations;
  //     }
  //     console.log("seo", PITaxData);

  //    // Determine API endpoint based on PiRegion and PiState
  //    const pdfConfigResponse = await ProfomaInvoiceAPIInstance.get(
  //     `get_pdf?userId=${userId}&country=${tiCountry}`,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: token,
  //       },
  //     }
  //   );

  //   const pdfConfigData = pdfConfigResponse.data.data[0];
  //   console.log("PDF Config Data:", pdfConfigData);

  //   if (pdfConfigData && pdfConfigData.proformaApi) {
  //     const proformaApiEndpoint = `/${pdfConfigData.taxApi}`;

  //     // Step 3: POST PITaxData to the dynamically formed proformaApi endpoint
  //     const postResponse = await ProfomaInvoiceAPIInstance.post(
  //       proformaApiEndpoint,
  //       PITaxData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: token,
  //         },
  //         responseType: "blob",
  //       }
  //     );

  //     const pdfBlob = new Blob([postResponse.data], {
  //       type: "application/pdf",
  //     });

  //     const pdfUrl = URL.createObjectURL(pdfBlob);
  //     window.open(pdfUrl, "_blank");
  //   }
  // } catch (error) {
  //   console.error("Error in handlePrint:", error);
  // }
  // };
  const handlePrint = async () => {
    console.log(taxNumber);
    const userId = JSON.parse(localStorage.getItem("user")).userId;
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      const taxPidata = await ProfomaInvoiceAPIInstance.get(
        `/get_note_pdfdata?userId=${userId}&type=CRN&id=${taxNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      // Assuming PITaxData is obtained from the API response
      const PITaxData = taxPidata.data.data[0];
      if (PITaxData && PITaxData.Containers && PITaxData.Containers !== "") {
        const operations = JSON.parse(PITaxData.Containers).map((container) => {
          const containerObj = {};
          Object.keys(container).forEach((key) => {
            containerObj[key] = container[key] === "" ? null : container[key];
          });
          return containerObj;
        });
        PITaxData.Operations = operations;
      }
      console.log("seo", PITaxData);

      // Extract currency and region information
      let currency = "";
      let TiCurrency = PITaxData.NoteCurrency; // Assign TiCurrency from PITaxData
      if (PITaxData.Operations && Array.isArray(PITaxData.Operations)) {
        const matchingOperation = PITaxData.Operations.find(
          (operation) => parseFloat(operation.Ex_Rate) === 1
        );
        if (matchingOperation) {
          currency = matchingOperation.Currency;
        }
        console.log("Currency with Ex_Rate = 1:", currency);
      }
      console.log("TICurrency:", TiCurrency);

      // Check if currency matches TiCurrency
      if (currency === TiCurrency) {
        await processPDF(TiCurrency, PITaxData); // Pass TiCurrency and PITaxData to processPDF
      } else {
        // Show popup for user to select between TiCurrency and Currency
        setShowPopup(true);
        setCurrencyOptions({ TiCurrency, currency, PITaxData });
      }
    } catch (error) {
      console.error("Error in handlePrint:", error);
    }
  };
  const processPDF = async (type, PITaxData) => {
    try {
      const userId = JSON.parse(localStorage.getItem("user")).userId;
      const token = JSON.parse(localStorage.getItem("token"));

      // Fetch PDF configuration based on the country of the tax invoice
      const pdfConfigResponse = await ProfomaInvoiceAPIInstance.get(
        `get_pdf?userId=${userId}&country=${tiCountry}`, // Adjust 'tiCountry' if needed
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      const pdfConfigData = pdfConfigResponse.data.data[0];
      console.log("PDF Config Data:", pdfConfigData);

      if (pdfConfigData && pdfConfigData.taxApi) {
        const taxApiEndpoint = `/${pdfConfigData.taxApi}?currtype=${type}`;

        // Step 3: POST PITaxData to the dynamically formed taxApi endpoint
        const postResponse = await ProfomaInvoiceAPIInstance.post(
          taxApiEndpoint,
          PITaxData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Make sure the response is in Blob format for PDF
          }
        );

        // Create a PDF Blob from the response data
        const pdfBlob = new Blob([postResponse.data], {
          type: "application/pdf",
        });

        // Create a URL for the Blob and open it in a new tab
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      }
    } catch (error) {
      console.error("Error in processPDF:", error);
    }
  };

  // Modal for user selection
  const [showPopup, setShowPopup] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState({});

  const handlePopupSelection = (type) => {
    setShowPopup(false); // Close the popup
    processPDF(type, currencyOptions.PITaxData); // Proceed with the selected type
    setCheckBoxSelected([]);
  };

  //cancel tax invoice
  //   const handleCancel = async () => {
  //     console.log(taxNumber, tiCountry, irnStatus);
  //     const apikey = "de3a3a01-273a-4a81-8b75-13fe37f14dc6";
  //     const userId = JSON.parse(localStorage.getItem("user")).userId;
  //     const token = JSON.parse(localStorage.getItem("token"));
  //     if (irnStatus === "CAN") {
  //       toast({
  //         title: "IRN already cancelled",
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //       return; // Early exit if already cancelled
  //     }
  //     try {
  //       const crnTiResponse = await ProfomaInvoiceAPIInstance.get(
  //         `/get_crndatacnl?userId=${userId}&id=${taxNumber}`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: token,
  //           },
  //         }
  //       );
  // if(crnTiResponse.data.success){
  //       const PITaxData = crnTiResponse.data.data.data;
  //       if (tiCountry === "India") {
  //         const gstzenResponse = await axios.post(
  //           "https://my.gstzen.in/~gstzen/a/post-einvoice-data/einvoice-json/cancel/",
  //           JSON.stringify(PITaxData),
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //               Token: apikey,
  //             },
  //           }
  //         );

  //         const gstzenStatus = gstzenResponse.data.status;
  //         const gstzenMessage = gstzenResponse.data.message;

  //         toast({
  //           title: gstzenMessage,
  //           position: "bottom-center",
  //           isClosable: true,
  //           status: gstzenStatus === 1 ? "success" : "error",
  //           containerStyle: {
  //             width: "98.5vw",
  //             maxWidth: "98.5vw",
  //             alignContent: "center",
  //           },
  //         });

  //         if (gstzenStatus === 1) {
  //           console.log("GSTZen Response:", gstzenResponse.data);
  //           console.log("Data Sent to GSTZen:", PITaxData);

  //           const taxInvoiceData = {
  //             ...gstzenResponse.data,
  //             docNumber: PITaxData.DocDtls.No,
  //           };
  //           console.log(taxInvoiceData);
  //           const cancelResponse = await ProfomaInvoiceAPIInstance.put(
  //             `/cancel_crndata?userId=${userId}`,
  //             JSON.stringify(taxInvoiceData),
  //             {
  //               headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: token,
  //               },
  //             }
  //           );

  //           handleCancelResponse(cancelResponse);
  //         }
  //       } else {
  //         const cancelResponse = await ProfomaInvoiceAPIInstance.put(
  //           `/cancel_crndata?userId=${userId}`,
  //           JSON.stringify(PITaxData),
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: token,
  //             },
  //           }
  //         );

  //         handleCancelResponse(cancelResponse);
  //       }
  //       setSelectAllPages(false);
  //       setCheckBoxAll(false);
  //       setCheckBoxSelected(false);
  //       getData();
  //     }
  //     else{
  //       toast({
  //         title:crnTiResponse.data.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       })
  //     }

  //     } catch (error) {
  //       console.error(error);
  //       toast({
  //         title: error?.response?.data?.message || "An error occurred",
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     }
  //   };
  //   const handleCancelResponse = (response) => {
  //     if (response?.data?.success) {
  //       toast({
  //         title: "Tax Invoice updated to cancel",
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "success",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     } else {
  //       toast({
  //         title: "Error updating the Tax invoice info",
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     }
  //   };
  const handleCancel = async (event) => {
    try {
      const { data } = await ProfomaInvoiceAPIInstance.put(
        `cancel_fullcrndata?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&id=${taxNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      console.log("====================================");
      console.log(data);
      console.log("====================================");
      if (data.data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        getData();
        //navigate("/proforma_invoice");
      } else {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      // Handling any errors that occur during the API request
      console.error("Error during the form submission:", error);

      toast({
        title: "An error occurred. Please try again later.",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  // Filter Data
  const [filterRows, setFilterRows] = useState(() => {
    return JSON.parse(localStorage.getItem("crnfilterRows"))
      ? JSON.parse(localStorage.getItem("crnfilterRows"))
      : [0];
  });
  const [filterConditions, setFilterConditions] = useState(() => {
    const savedConditions = JSON.parse(
      localStorage.getItem("crnfilterConditions")
    );
    return savedConditions || {};
  });

  const [inputValues, setInputValues] = useState({ ...filterConditions });
  const [selectValues, setSelectValues] = useState({ ...filterConditions });
  const addFilterRow = () => {
    setFilterRows((prevFilterRows) => [
      ...prevFilterRows,
      prevFilterRows?.length,
    ]);
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [filterRows?.length]: {
        condition: "",
        value: "",
      },
    }));
  };

  useEffect(() => {
    localStorage.setItem("crnfilterRows", JSON.stringify(filterRows));
    localStorage.setItem(
      "crnfilterConditions",
      JSON.stringify(filterConditions)
    );
  }, [filterRows, filterConditions]);

  const removeFilterRow = (index) => {
    const newFilterRows = filterRows.filter((rowIndex) => rowIndex !== index);
    setFilterRows(newFilterRows);

    setFilterConditions((prevConditions) => {
      const { [index]: removedCondition, ...restConditions } = prevConditions;
      return restConditions;
    });
  };

  const handleSubmitFilter = async () => {
    localStorage.setItem(
      "crnfilterConditions",
      JSON.stringify(filterConditions)
    );

    const transformedConditions = {};

    filterRows.forEach((index) => {
      const column = document.getElementById(`column-select-${index}`).value;
      const condition = document.getElementById(`filter-type-${index}`).value;
      const value = document.getElementById(`value-input-${index}`).value;

      if (column && condition && value) {
        transformedConditions[column + index] = {
          condition,
          value,
        };
      }
    });

    const a = JSON.stringify(transformedConditions, null, 2);
    const sendData = JSON.parse(a);
    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };
    try {
      const { data } = await ProfomaInvoiceAPIInstance.post(
        `/massfilter_cn?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&filterType=${handleCheckboxFilter}`,
        { sendData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data?.data?.length === 0) {
        setFilterOpen(false);
        localStorage.setItem("crnfilter", JSON.stringify([]));
        return setCrnData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "status"
              ? false
              : item === "message"
              ? false
              : item === "distance"
              ? false
              : item === "SignedQrCode"
              ? false
              : item === "SignedInvoice"
              ? false
              : item === "SignedQrCodeImgUrl"
              ? false
              : item === "InvoicePdfUrl"
              ? false
              : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setCrnData(filteredData);

      localStorage.setItem("crnfilter", JSON.stringify(filteredData));
      setFilterOpen(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  const handleFilterConditionSelect = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        condition: selectedValue,
      },
    }));
  };
  const handleFilterValueChange = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        value: selectedValue,
      },
    }));
  };

  const getFilterData = async (sendData) => {
    try {
      const data = {
        data: JSON.parse(window.localStorage.getItem("crnfilter")),
      };

      if (data?.data?.length === 0) {
        setFilterOpen(false);
        return setCrnData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "status"
              ? false
              : item === "message"
              ? false
              : item === "distance"
              ? false
              : item === "SignedQrCode"
              ? false
              : item === "SignedInvoice"
              ? false
              : item === "SignedQrCodeImgUrl"
              ? false
              : item === "InvoicePdfUrl"
              ? false
              : true,
        };
      });

      // // Filter the data based on the keys in filterKeys array
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setCrnData(filteredData);

      setFilterOpen(false);
    } catch (error) {
      //console.log(error.message);
    }
  };

  const handleClearFilters = () => {
    const inputs = document.querySelectorAll(".value-input");
    const newInputValues = { ...inputValues };
    inputs.forEach((input) => {
      const index = inputValues;
      newInputValues[index] = input.value;
      input.value = "";
    });

    const selects = document.querySelectorAll(".column-select, .filter-type");
    selects.forEach((select) => {
      select.value = "select";
    });

    setFilterConditions({});
    setFilterOpen(false);
    setFilterRows([0]);
    getData();
    setInputValues(newInputValues);
    localStorage.removeItem("crnfilterConditions");
    window.localStorage.removeItem("crnfilter");
    window.localStorage.removeItem("crnfilterRows");
    // localStorage.setItem("filterInputValues", JSON.stringify(newInputValues));
  };

  useEffect(() => {
    const array1 = columns && Object?.keys(columns);
    const array2 = crnData[0] && Object?.keys(crnData[0]);

    const savedInputValues = localStorage.getItem("filterInputValues");
    if (savedInputValues) {
      setInputValues(JSON.parse(savedInputValues));
    }

    if (window.localStorage.getItem("crnfilter")?.length === 0) {
      return setCrnData([]);
    }

    if (window.localStorage.getItem("crnfilter")) {
      getFilterData();
    } else {
      getData();
      getFilterData();
    }
  }, [columns]);

  // Mass Update Input Type

  const handleOptionChange = (newValue) => {
    setSelectedOption(newValue);
  };

  const handleSubmitUpdate = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);

      const { data } = await ProfomaInvoiceAPIInstance.put(
        `/update_pi?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? crnData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          update: {
            field: selectedOption,
            value: updatedValue,
          },
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "TaxInvoice Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        setUpdatedValue(""); // Clear the input field
        handleOptionChange("Select a field"); // Reset the select option
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
    console.log("selectedOption:", selectedOption);
    console.log("updatedValue:", updatedValue);
  };

  const handleSearch = async (event) => {
    const queryData = event.target.value;

    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };

    const { data } = await ProfomaInvoiceAPIInstance.get(
      `/search_cn?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }&query=${queryData}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    if (data?.data?.length === 0) {
      return setCrnData([]);
    }

    const array1 = Object.keys(columns).filter((key) => columns[key]);

    const header = Object.keys(data?.data[0])?.map((item) => {
      return {
        key: item,
        label: item.charAt(0).toUpperCase() + item.slice(1),
        isVisible:
          item === "status"
            ? false
            : item === "message"
            ? false
            : item === "distance"
            ? false
            : item === "SignedQrCode"
            ? false
            : item === "SignedInvoice"
            ? false
            : item === "SignedQrCodeImgUrl"
            ? false
            : item === "InvoicePdfUrl"
            ? false
            : true,
      };
    });
    const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));

    setColumns2(filteredDataHeader);

    const filteredData = data?.data?.map((item) => {
      const filteredItem = {};

      Object.keys(item).forEach((key) => {
        if (array1.includes(key)) {
          filteredItem[key] = item[key];
        }
      });

      return filteredItem;
    });
    setCurrentPage(1);
    setCrnData(filteredData);
  };
  // Pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };

  const pageNumbers = Math.ceil(crnData?.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.

    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);
    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        )?.map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };

  // filter Part Combinationget
  const [showOptions, setShowOptions] = useState(false);
  const [showPhoneOptions, setShowPhoneOptions] = useState(false);

  const handleFilterTypeSelect = (event, index) => {
    const selectedValue = event.target.value;
    setShowOptions(selectedValue !== "select");
    setShowPhoneOptions(
      selectedValue === "Phone" || selectedValue === "FollowUpDate"
    );

    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        column: selectedValue,
      },
    }));
  };

  useEffect(() => {
    if (localStorage.getItem("tableColumnsCrn")) {
      settableColumnsCrn(JSON.parse(localStorage.getItem("tableColumnsCrn")));
    } else {
      settableColumnsCrn(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);

  //console.log(columns2);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsCrn];

    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem("tableColumnsCrn", JSON.stringify(updatedColumns));
    settableColumnsCrn(updatedColumns);
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };

  const Managecolumn = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "30px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
    position: "relative",
    // overflowY: "auto",
  };
  const ManagecolumnmodalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "15%",
    zIndex: "200",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimming effect
    zIndex: "100",
  };
  const handleAddCreditClick = () => {
    navigate("/addcreditnote");
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActionOpen(false);
    }
  };

  useEffect(() => {
    if (isActionOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActionOpen]);

  //class declaration
  const checkValidation = new Checkvalidation(
    checkBoxSelected,
    toast,
    handlePrint,
    handleCancel
  );

  return (
    <div>
      <Header />
      {!isUnauthorized ? (
        <>
          <div className="mx-auto  bg-blue-950 text-white flex flex-wrap dark:bg-indigo-900">
            {/* <OperationSidebar
          style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }}
          size={20}
        /> */}
            <FaFilter
              size={25}
              onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
              style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }}
            />
            &nbsp;&nbsp;
            <>
              <div className="pt-2 ml-10 relative mx-auto text-gray-600">
                <input
                  className="border-2  border-gray-300 bg-white h-8 px-5 pr-10 rounded-lg text-sm focus:outline-none"
                  ref={searchRef}
                  type="search"
                  name="search"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>
            </>
            &nbsp;
            <TbAdjustmentsHorizontal
              onClick={
                isOpenManagecolumn ? onCloseManagecolumn : onOpenManagecolumn
              }
              size={25}
              style={{ cursor: "pointer", marginTop: "15", marginRight: "30" }}
            />
            &nbsp; &nbsp;&nbsp;
            <div className="relative inline-block">
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950 mr-4"
                onClick={handleAddCreditClick}
              >
                Add Credit Note
              </button>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
                onClick={toggleActionDropdown}
              >
                Actions
              </button>
              &nbsp;&nbsp;
              {isActionOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute right-0 mt-1 py-2 w-48 bg-white border rounded shadow-lg dark:bg-slate-700 z-[9999]"
                >
                  <a
                    className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                    onClick={() => {
                      checkValidation.handlePrintClick();
                    }}
                  >
                    <FiPrinter size={20} />
                    <span
                      className="flex-grow cursor-pointer"
                      onClick={handleActionSelection}
                    >
                      View
                    </span>
                  </a>
                  <a
                    className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                    onClick={() => {
                      checkValidation.handleCancelClick();
                    }}
                  >
                    <RiDeleteBinLine size={20} />
                    <span
                      className="flex-grow cursor-pointer"
                      onClick={handleActionSelection}
                    >
                      Cancel
                    </span>
                  </a>
                </div>
              )}
            </div>
            <>
              {isOpenFilter && (
                <>
                  <div style={backdropStyle}></div>
                  <div className={`FilterModal`}>
                    {/* {${scrollBehavior}} */}
                    <Rnd
                      style={style}
                      default={{
                        x: 0,
                        y: 0,
                        width: 600,
                        // height: 200,
                      }}
                      cancel="X"
                      allowAnyClick={true}
                    >
                      <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                        Criteria
                      </header>
                      <div
                        className="closemodule"
                        onClick={() => {
                          onCloseFilter(false);
                        }}
                        style={closeModal}
                      >
                        <CgClose />
                      </div>
                      <br />

                      <FormControl>
                        {filterRows?.map((index) => (
                          <Flex key={index}>
                            <Select
                              id={`column-select-${index}`}
                              placeholder="Select"
                              onChange={(e) => handleFilterTypeSelect(e, index)}
                              value={
                                filterConditions[index]?.column || "select"
                              }
                              size={"md"}
                              isDisabled={false}
                              blurInputOnSelect={true}
                              isSearchable={false}
                              style={{ position: "relative" }}
                            >
                              {/* <option value="status">status</option> */}
                              <option value="message">message</option>
                              <option value="PiNumber">PiNumber</option>
                              <option value="JobNumber">JobNumber</option>
                              <option value="TaxNumber">Tax Number</option>
                              <option value="CrnNumber">CrnNumber</option>
                              <option value="AckNo">AckNo</option>
                              <option value="AckDt">AckDt</option>
                              <option value="Irn">Irn</option>
                              <option value="InvoiceStatus">
                                InvoiceStatus
                              </option>
                              <option value="IrnStatus">IrnStatus</option>
                              <option value="Bill_To">Bill_To</option>
                            </Select>
                            &nbsp;&nbsp;
                            <Select
                              id={`filter-type-${index}`}
                              onChange={(e) =>
                                handleFilterConditionSelect(e, index)
                              }
                              value={
                                filterConditions[index]?.condition || "select"
                              }
                              placeholder="Select"
                            >
                              {filterConditions[index]?.column === "AckDt" ? (
                                // ||
                                // filterConditions[index]?.column === "status"
                                <>
                                  <option value="equals">=</option>
                                  <option value="not-equals">!=</option>
                                  <option value="greater">&gt;</option>
                                  <option value="lesser">&lt;</option>
                                  <option value="greater-equal">&gt;=</option>
                                  <option value="lesser-equal">&lt;=</option>
                                </>
                              ) : filterConditions[index]?.column ===
                                "Bill_To" ||
                                filterConditions[index]?.column ===
                                "JobNumber" ? (
                                <>
                                  <option value="com-is">is</option>
                                  <option value="com-contains">contains</option>
                                  <option value="com-startsWith">
                                    starts with
                                  </option>
                                  <option value="com-endsWith">
                                    ends with
                                  </option>
                                </>
                              ) : (
                                <>
                                  <option value="is">is</option>
                                  <option value="contains">contains</option>
                                  <option value="startsWith">
                                    starts with
                                  </option>
                                  <option value="endsWith">ends with</option>
                                </>
                              )}
                            </Select>
                            &nbsp;&nbsp;
                            {/* Conditionally render the input field based on the selected column */}
                            {["AckDt"].includes(
                              filterConditions[index]?.column
                            ) ? (
                              <Input
                                type="date"
                                id={`value-input-${index}`}
                                value={filterConditions[index]?.value || ""}
                                onChange={(e) =>
                                  handleFilterValueChange(e, index)
                                }
                              />
                            ) : (
                              <Input
                                type="text"
                                id={`value-input-${index}`}
                                value={filterConditions[index]?.value || ""}
                                onChange={(e) =>
                                  handleFilterValueChange(e, index)
                                }
                              />
                            )}
                            &nbsp;&nbsp;{" "}
                            <IoMdAddCircle
                              className="cursor-pointer"
                              size={70}
                              style={{ marginTop: "-15px", color: "green" }}
                              onClick={() => addFilterRow()}
                            />
                            {filterRows?.length > 1 && index > 0 && (
                              <BiSolidMinusCircle
                                className="cursor-pointer"
                                size={70}
                                style={{ marginTop: "-15px", color: "red" }}
                                onClick={() => removeFilterRow(index)}
                              />
                            )}
                          </Flex>
                        ))}
                      </FormControl>
                      <div
                        style={{
                          float: "right",
                          display: "block",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          colorScheme="green"
                          onClick={() => {
                            handleSubmitFilter();
                            onCloseFilter();
                          }}
                          mr={3}
                        >
                          Apply
                        </Button>
                        <Button
                          colorScheme="red"
                          onClick={() => {
                            handleClearFilters();
                            onCloseFilter();
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                      <div
                        style={{
                          float: "left",
                          marginTop: "10px",
                          display: "block",
                        }}
                      >
                        <RadioGroup
                          style={{ flex: 1 }}
                          value={handleCheckboxFilter}
                        >
                          <Stack direction="row">
                            <Radio value="AND" onChange={handleCheckboxChange}>
                              All
                            </Radio>
                            <Radio value="OR" onChange={handleCheckboxChange}>
                              Any
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      </div>
                    </Rnd>
                  </div>
                </>
              )}
            </>
            <>
              {isOpenManagecolumn && (
                <>
                  <div style={backdropStyle}></div>
                  <div style={ManagecolumnmodalStyleUpdate}>
                    <div></div>
                    <Rnd
                      style={Managecolumn}
                      default={{
                        x: 0,
                        y: 0,
                        width: 320,
                        // height: 610
                      }}
                      cancel="X"
                      allowAnyClick={true}
                    >
                      <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                        Manage Column
                      </header>
                      <div
                        onClick={() => {
                          onCloseManagecolumn(false);
                        }}
                        style={closeModal}
                      >
                        <CgClose />
                      </div>
                      <br />
                      {/* <div className="grid gap-x-35 gap-y-5 grid-cols-5"> */}
                      {tableColumnsCrn.map(
                        (label, index) =>
                          label.key !== "id" && (
                            <label
                              key={index}
                              className="flex items-center"
                              draggable
                              onDragStart={(event) =>
                                handleDragStart(event, index)
                              }
                              onDragOver={handleDragOver}
                              onDrop={(event) => handleDrop(event, index)}
                            >
                              <input
                                type="checkbox"
                                // checked={label.label}
                                className="cursor-pointer"
                                disabled={
                                  label.key === "PiNumber"
                                    ? true
                                    : label.key === "AckDt"
                                    ? true
                                    : label.key === "AckNo"
                                    ? true
                                    : label.key === "IrnStatus"
                                    ? true
                                    : label.key === "CrnNumber"
                                    ? true
                                    : null
                                }
                                checked={label.isVisible}
                                onChange={(event) => {
                                  handleCheckboxColumn(event, label);
                                }}
                              />
                              <span
                                className="ml-2"
                                style={{ cursor: "all-scroll" }}
                              >
                                {label.label}
                              </span>
                              {label.isDisabled && (
                                <span style={{ color: "red" }}> *</span>
                              )}
                            </label>
                          )
                      )}
                      {/* </div> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                          float: "right",
                        }}
                      >
                        <Button
                          onClick={() => {
                            onCloseManagecolumn(false);
                          }}
                          colorScheme="green"
                        >
                          OK
                        </Button>
                        <Button
                          onClick={() => {
                            onCloseManagecolumn(false);
                          }}
                          colorScheme="red"
                          ml={3}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Rnd>
                  </div>
                </>
              )}
            </>
          </div>
          <div className="mx-auto  bg-blue-950 text-black flex flex-wrap dark:bg-indigo-900">
            <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
              {/* Next page button */}
              <div
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
                style={{ float: "right", marginRight: "5px" }}
              >
                <FiArrowRightCircle
                  className="cursor-pointer w-6 h-6"
                  onClick={goToNextPage}
                  disabled={currentPage === pageNumbers}
                />
              </div>

              {/* Previous page button */}
              <div
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
                style={{ float: "right", marginRight: "10px" }}
              >
                <FiArrowLeftCircle
                  className="cursor-pointer w-6 h-6"
                  onClick={goToPreviousPage}
                  disabled={currentPage === 1}
                />
              </div>

              {/* Page numbers */}
              <p
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
                style={{ float: "right", marginRight: "20px" }}
              >
                <a className="relative inline-flex items-center ">
                  {renderPageNumbers()}
                </a>
              </p>

              {/* Items per page dropdown */}
              <select
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
                style={{
                  float: "right",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {availableItemsPerPage?.map((option) => (
                  <option key={option} value={option}>
                    {option} Records Per Page
                  </option>
                ))}
              </select>
              {/* Icons */}
              <Tooltip
                hasArrow
                label="Select all pages"
                bg="gray.300"
                color="black"
              >
                <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
                  <MdSelectAll
                    onClick={() => {
                      handleCheckbox(crnData?.map((item) => item.id));
                      setCheckBoxAll((prev) => {
                        if (prev === true) {
                          setCheckBoxSelected([]);
                        }
                        return !prev;
                      });
                    }}
                    size={24}
                  />
                </div>
              </Tooltip>
              {/* Record count */}
              <p className="py-1 px-2 my-2" style={{ float: "left" }}>
                <p className="text-sm text-gray-700 dark:text-white">
                  Showing{" "}
                  <span className="font-medium">
                    {currentPage === 1 ? 1 : indexOfFirstItem + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {Math.min(indexOfLastItem, crnData?.length)}
                  </span>{" "}
                  of <span className="font-medium">{crnData?.length}</span>{" "}
                  records
                </p>
                <p style={{ float: "left", marginTop: "10px" }}>
                  <Selectallpages
                    selectAllPages={selectAllPages}
                    setSelectAllPages={setSelectAllPages}
                    checkBoxAll={checkBoxAll}
                  />
                </p>
              </p>
            </div>

            <div className="table-container">
              <DraggableCnTable
                isLoading={isLoading}
                columns={columns2}
                crnData={crnData}
                getData={getData}
                data={crnData.slice(indexOfFirstItem, indexOfLastItem)}
                setCrnData={setCrnData}
                handleCheckbox={handleCheckbox}
                checkBoxSelected={checkBoxSelected}
                setCheckBoxSelected={setCheckBoxSelected}
                setCheckBoxAll={setCheckBoxAll}
                checkBoxAll={checkBoxAll}
                handleDragOver={handleDragOver}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
                tableColumnsCrn={tableColumnsCrn}
                currentPage={currentPage}
                pageNumbers={pageNumbers}
              />
            </div>
          </div>

          <>
            {showPopup && (
              <>
                <div style={backdropStyle}></div>
                <div className={`MassDeleteModule ${scrollBehavior}`}>
                  <Rnd
                    style={style}
                    default={{
                      x: 0,
                      y: 0,
                      width: 600,
                      // height: 200
                    }}
                    cancel="X"
                    allowAnyClick={true}
                  >
                    <header>Show the Credit Invoice in</header>
                    <div
                      onClick={() => {
                        setShowPopup(false);
                        setCheckBoxSelected([]);
                      }}
                      style={closeModal}
                    >
                      <CgClose />
                    </div>
                    <br />
                    <Button
                      colorScheme="blue"
                      onClick={() =>
                        handlePopupSelection(currencyOptions.TiCurrency)
                      }
                    >
                      {currencyOptions.TiCurrency}
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      colorScheme="blue"
                      onClick={() =>
                        handlePopupSelection(currencyOptions.currency)
                      }
                    >
                      {currencyOptions.currency}
                    </Button>
                  </Rnd>
                </div>
              </>
            )}
            {/* The rest of your component */}
          </>
        </>
      ) : (
        <div className="relative w-full flex-1 flex flex-col gap-4 p-10 overflow-x-hidden overflow-y-auto mt-52">
          <UnAuthorizedModal
            heading={"UnAuthorized"}
            text={"Contact the administrator for more details"}
          />
        </div>
      )}
    </div>
  );
};

export default CreditNote;
