import React, { useState,useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import  {Header}  from "../../components";
import { HrmAPIInstance } from "../../api-instance.js";
import { 
  Avatar, Box, Flex, Text, IconButton, 
  Input, Button, Tabs, TabList, TabPanels, Tab, 
  TabPanel, Table, Tbody, Tr, Td,useDisclosure,Modal, ModalOverlay,ModalContent,ModalHeader, ModalCloseButton, ModalBody, ModalFooter,Thead, Th,FormLabel,
  FormControl ,useToast,Spacer,AvatarBadge
} from '@chakra-ui/react';
import { FaFilter } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { Rnd } from "react-rnd";
import { CgClose } from "react-icons/cg";
import { LiaEditSolid } from "react-icons/lia";
import Select from "react-select";
import { AiOutlineUser,AiOutlineUpload } from 'react-icons/ai'; 



const EmployeeProfile = () => {
  const navigate = useNavigate();
  
  const [isOpenUpdateStatus, setIsOpenUpdateStatus] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select a field");
  const [updatedValue, setUpdatedValue] = useState("");
  const [dateValue, setDateValue] = useState('');
  const [remarks, setRemarks] = useState('');
  const [exportData, setExportData] = useState([]);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [columns2, setColumns2] = useState([]);
  const [employeeprofileData, setEmployeeProfileData] = useState([]);
  const [employeeStatus, setEmployeeStatus] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isdelete, setIsdelete] = useState(false);
  const [isconvert, setIsconvert] = useState(false);
  const [editEmployeeProfile, setEditEmployeeProfile] = useState([])
  const [employeeData, setEmployeeData] = useState(null);
  const [error,setError]=useState([]);
  const[Loading,setLoading]=useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedEmployee, setEditedEmployee] = useState(selectedEmployee);
  
  
  const [columns, setColumns] = useState({
    EmployeeNumber: true,
    First_Name: true,
   
  });
console.log("editing",isEditing)
  const getEmployeeNumberFromUrl = () => {
    const pathParts = window.location.pathname.split("/");
    return pathParts[pathParts.length - 1] || ""; // Get the last part or fallback to an empty string
  };

  // Fetch initial employee data on component load or employeeNumber change
  useEffect(() => {
    const getData = async () => {
      try {
        const id = employeeNumber || getEmployeeNumberFromUrl(); // Use employeeNumber from state or URL
        if (!id) return; // Exit if no ID is found

        const { data } = await HrmAPIInstance.put(
          `/employeeprofile/edit_employeeprofile?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }&employeeNumber=${id}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );

        setSelectedEmployee(data.data[0]);
        console.log("Selected EmployeeProfile Data", data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };

    getData(); // Fetch data
  }, [employeeNumber]);
console.log("ss", selectedEmployee);
const handleUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    // You can process the file here
    // For example, upload the file to the server and update selectedEmployee.URL_Object
    console.log("File uploaded:", file);
    // You might also update the state to show the uploaded image
  }
};


const [options, setOptions] = useState([]);
//Search Api 
const handleSearch = async () => {
  const isdelete = false; // Assume 'isdelete' value is provided as needed

  const { data } = await HrmAPIInstance.get(
    `/employeeprofile/search_employeeprofile?userId=${JSON.parse(localStorage.getItem("user")).userId}&isDeleted=${isdelete}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(localStorage.getItem("token")),
      },
    }
  );

  if (data?.data?.length === 0) {
    setEmployeeData([]);
    setOptions([]); // Clear dropdown options if no data
    return;
  }

  const array1 = Object.keys(columns).filter((key) => columns[key]);

  const header = Object.keys(data?.data[0])?.map((item) => ({
    key: item,
    label: item.charAt(0).toUpperCase() + item.slice(1),
  }));

  const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));
  setColumns2(filteredDataHeader);

  const filteredData = data?.data?.map((item) => {
    const filteredItem = {};
    Object.keys(item).forEach((key) => {
      if (array1.includes(key)) {
        filteredItem[key] = item[key];
      }
    });
    return filteredItem;
  });

  setEmployeeData(filteredData);

  // Prepare options for react-select
  const newOptions = data?.data?.map((item) => ({
    value: item.EmployeeNumber,
    label: `${item.First_Name} ${item.Last_Name} (${item.EmployeeNumber})`,
  }));

  setOptions(newOptions);
};

//SingleUpdate 
const handleSingleupdate = async (event) => {
  try {
    console.log("Edited Employee:", editedEmployee);
    const response = await HrmAPIInstance.put(
      `/employeeprofile/singleupdate_employeeprofile?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
      { employee: editedEmployee }, // Pass the updated employee data
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );
    
   // Update the employee data after successful save
   if(response.data.success){
   setSelectedEmployee(editedEmployee);
   setIsEditing(false); // Exit edit mode
   
   // Show success toast message
   toast({
     title: "Employee updated",
     description: "Employee Profile has been Updated successfully",
     status: "success",
     duration: 3000,
     isClosable: true,
     position:"bottom-center",
    containerStyle:{
      width:"98.5vw",
      maxWidth:"98.5vw",
      alignContent:"center",
    },
   });
  }
 } catch (error) {
   console.error("Error saving data:", error);

   // Show error toast message
   toast({
     title: "Error saving data.",
     description: "There was a problem saving the employee data. Please try again.",
     status: "error",
     duration: 3000,
     position:"botton-center",
     isClosable: true,
    containerStyle:{
      width:"98.5vw",
      maxWidth:"98.5vw",
      alignContent:"center",
    },
   });
 }
};

useEffect(() => {
  // Call handleSearch once to fetch initial data
  handleSearch();
}, []);

// useEffect(() => {
//   // Call handleUpdate once to fetch initial data
//   handleSingleUpdate();
// }, []);
const handleAddEmployee = () => {
  navigate("/addemployee"); // Navigate to the Add Employee page
};

const handleEditClick = () => {
  setIsEditing(true);
  setEditedEmployee(selectedEmployee); // Set the data to the currently selected employee's data
};

const handleCancel = () => {
  setIsEditing(false); // Revert to view mode without saving
  setEditedEmployee(selectedEmployee); // Reset to original data
};


const handleChange = (e) => {
  const { name, value } = e.target;
  setEmployeeData(prevData => ({
    ...prevData,
    [name]: value,
  }));
};
console.log("ABC",setEmployeeData);

  // Function to handle form submission
  const onSubmitUpdateStatus = () => {
    // Handle form submission logic here
    console.log('Date:', dateValue);
    console.log('EmployeeStatus:', employeeStatus);
    console.log('Remarks:', remarks);
    // Add any further submission logic here
  };

  const style = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    border: "1px solid #ddd",
    background: "#fff",
    zIndex: 1000,
    padding: "16px",
    borderRadius: "8px"
  };

  const closeModal = {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  };

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 999,
  };

  return (
    <>
      <Header />
      <Flex align="center" bg="blue.900" p={1} color="white">
        <FaFilter size={25} style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }} />
        <div className="pt-2 ml-10 relative mx-auto text-gray-600">
      <Select
        options={options}
        isClearable
        onChange={(selectedOption) => {
          if (selectedOption) {
            setEmployeeNumber(selectedOption.value); // Set selected employee number
          } else {
            setEmployeeNumber("");
          }
        }}
        placeholder="Select an Employee"
        className="w-72"
        styles={{
          control: (base) => ({
            ...base,
            border: "2px solid #d1d5db",
            boxShadow: "none",
            "&:hover": { borderColor: "#d1d5db" },
          }),
        }}
      />
    </div>
    <Button colorScheme="blue" ml={4} onClick={handleAddEmployee}>Add Employee</Button>
    
        <Button colorScheme="blue" ml={4}>Save</Button>&nbsp;&nbsp;
        <Button colorScheme="blue" ml={2}>Cancel</Button>
      </Flex>
      <Flex justify="left" mt={10} mb={10}>
  {selectedEmployee.URL_Object ? (
    <Avatar
      size="2xl"
      name={selectedEmployee.First_Name || "Default Name"}
      src={selectedEmployee.URL_Object}
    >
      <AvatarBadge boxSize='1.25em' bg='green.500' />
    </Avatar>
  ) : (
    <Box position="relative">
      <Avatar
        size="2xl"
        bg="red.500"
        icon={<AiOutlineUser fontSize="1.5rem" />}
        name="Default Name" // Fallback name
      >
        <AvatarBadge borderColor='papayawhip' bg='tomato' boxSize='1.25em' />
      </Avatar>
      <Box
        position="absolute"
        bottom={0}
        right={0}
        transform="translate(50%, 50%)"
        bg="white"
        borderRadius="full"
        p={1}
        cursor="pointer"
      >
        <label htmlFor="upload-avatar">
          <AiOutlineUpload fontSize="1.5rem" />
        </label>
        <input
          id="upload-avatar"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleUpload}
        />
      </Box>
    </Box>
  )}

  <Box ml={5}>
    <Text fontSize="2xl">{selectedEmployee.First_Name || "Default Name"}</Text>
    <Text fontSize="sm">Account</Text>
    <Text fontSize="md">{selectedEmployee.Email || "default@example.com"}</Text>
  </Box>
</Flex>




    
   
      <Tabs isFitted variant="enclosed" >
        <TabList mb="1em">
          <Tab>Basic Info</Tab>
          <Tab>Job</Tab>
          <Tab>Leave</Tab>
          <Tab>Performance</Tab>
          <Tab>Permission</Tab>
          <Tab>Assets</Tab>
          <Tab>Document</Tab>
          <Tab>Payslip</Tab>
          <Tab>Payroll</Tab>
          <Tab>Attendance</Tab>
          <Tab>PF/ESI</Tab>  
        </TabList>
        <TabPanels>
          <TabPanel>
          <Box p={5} shadow="md" borderWidth="1px">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th colSpan={4} textAlign="left" fontSize="lg" fontWeight="bold">
              <Flex align="center" justify="space-between" width="100%">
                <span>Basic Info</span>
                <LiaEditSolid onClick={handleEditClick} style={{ marginLeft: "2px", cursor: "pointer" }} />
              </Flex>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
          <Td fontWeight="bold">First Name</Td>
<Td>
  <input
    type="text"
    name="First_Name"
    value={editedEmployee?.First_Name|| selectedEmployee?.First_Name || ''}
    onChange={(e) => {
      setEditedEmployee({ ...editedEmployee, First_Name: e.target.value });
    }}
    disabled={!isEditing} // Disable input when not editing
  />
</Td>

      <Td fontWeight="bold">Last Name</Td>
      <Td>
        <input
          type="text"
          name="Last_Name"
          value={editedEmployee?.Last_Name || selectedEmployee?.Last_Name || ''}
          onChange={(e) => setEditedEmployee({ ...editedEmployee, Last_Name: e.target.value })}
          disabled={!isEditing}
        />
      </Td>
    </Tr>

          <Tr>
            <Td fontWeight="bold">Mobile_Number </Td>
            <Td><input type="text" name="Mobile_Number" value={editedEmployee?.Mobile_Number || selectedEmployee?.Mobile_Number || ''}
             onChange={(e) => setEditedEmployee({ ...editedEmployee, Mobile_Number: e.target.value })}
             disabled={!isEditing}
            /></Td>
            <Td fontWeight="bold">Gender</Td>
            <Td><input type="text" name ="Gender" value={editedEmployee?.Gender || selectedEmployee?.Gender || ''}
            onChange={(e) => setEditedEmployee({ ...editedEmployee, Gender: e.target.value })}
            disabled={!isEditing}
            /></Td>
            </Tr>
            <Tr>
            <Td fontWeight="bold">Email Id</Td>
            <Td><input type ="text" name ="Email" value={editedEmployee?.Email || selectedEmployee?.Email || ''}
            onChange={(e) => setEditedEmployee({ ...editedEmployee, Email: e.target.value })}
            disabled={!isEditing}
            /></Td>  
          </Tr>
        </Tbody>
      </Table>
    </Box>
            &nbsp;&nbsp;
            <Box p={5} shadow="md" borderWidth="1px">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th colSpan={6} textAlign="left" fontSize="md" fontWeight="bold">
                      <Flex align="left" justify="space-between" width="100%">
                        <span>Personal Information</span> <Spacer />  <LiaEditSolid onClick={handleEditClick} style={{ marginLeft: "2px", cursor: "pointer" }} />
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td fontWeight="bold">DOB</Td>
                    <Td><input type ="text" name ="DateOfBirth" value={editedEmployee?.DateOfBirth || selectedEmployee?.DateOfBirth || ''}
                      onChange={(e) => setEditedEmployee({ ...editedEmployee, DateOfBirth: e.target.value })}
                      disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Father's Name</Td>
                    <Td><input type ="text" name ="Father_Name" value={editedEmployee?.Father_Name || selectedEmployee?.Father_Name || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Father_Name: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Blood Group</Td>
                    <Td><input type ="text" name ="Bloodgroup" value={editedEmployee?.Bloodgroup || selectedEmployee?.Bloodgroup || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Bloodgroup: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Marital Status</Td>
                    <Td><input type ="text" name ="Marital_Status" value={editedEmployee?.Marital_Status || selectedEmployee?.Marital_Status || ''}
                     onChange={(e) => setEditedEmployee({ ...editedEmployee, Marital_Status: e.target.value })}
                     disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Spouse's Name</Td>
                    <Td><input type ="text" name ="Spouse_Name" value={editedEmployee?.Spouse_Name || selectedEmployee?.Spouse_Name || ''}
                     onChange={(e) => setEditedEmployee({ ...editedEmployee, Spouse_Name: e.target.value })}
                     disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Nationality</Td>
                    <Td><input type ="text" name="Nationality" value={editedEmployee?.Nationality || selectedEmployee?.Nationality || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Nationality: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Place of Birth</Td>
                    <Td><input type ="text" name ="PlaceOfBirth" value={editedEmployee?.PlaceOfBirth || selectedEmployee?.PlaceOfBirth || ''}
                     onChange={(e) => setEditedEmployee({ ...editedEmployee, PlaceOfBirth: e.target.value })}
                     disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Country of Origin</Td>
                    <Td><input type ="text" name ="CountryOfOrigin"  value={editedEmployee?.CountryOfOrigin || selectedEmployee?.CountryOfOrigin || ''}
                     onChange={(e) => setEditedEmployee({ ...editedEmployee, CountryOfOrigin: e.target.value })}
                     disabled={!isEditing}/></Td>  

                    <Td fontWeight="bold">Religion</Td>
                    <Td><input type ="text" name ="Religion" value={editedEmployee?.Religion || selectedEmployee?.Religion || ''}
                     onChange={(e) => setEditedEmployee({ ...editedEmployee, Religion: e.target.value })}
                     disabled={!isEditing}
                    /></Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">International Employee</Td>
                    <Td><input type="text" name ="International_Employee" value={editedEmployee?.International_Employee || selectedEmployee?.International_Employee || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, International_Employee: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Physically Challenged</Td>
                    <Td><input type ="text" name ="Physically_Challenged" value={editedEmployee?.Physically_Challenged || selectedEmployee?.Physically_Challenged || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Physically_Challenged: e.target.value })}
                    disabled={!isEditing}
                    /></Td> 
                  </Tr>  
                </Tbody>
              </Table>
            </Box>
            &nbsp;&nbsp;
            
            <Box p={5} shadow="md" borderWidth="1px">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th colSpan={6} textAlign="left" fontSize="md" fontWeight="bold">
                      <Flex align="center" justify="space-between" width="100%">
                        <span>Joining Details</span> <LiaEditSolid onClick={handleEditClick} style={{ marginLeft: "2px", cursor: "pointer" }} />
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td fontWeight="bold">Joined Date</Td>
                    <Td><input type ="text" name ="DateOfJoining" value={editedEmployee?.DateOfJoining || selectedEmployee?.DateOfJoining || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, DateOfJoining: e.target.value })}
                    disabled={!isEditing}
                    /></Td> 
                    <Td fontWeight="bold">Confirmation Date</Td>
                    <Td><input type ="text" name ="Confirmation_Date" value={editedEmployee?.Confirmation_Date || selectedEmployee?.Confirmation_Date || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Confirmation_Date: e.target.value })}
                    disabled={!isEditing}
                    /></Td> 
                     <Td fontWeight="bold">Employee_Type	</Td>
                    <Td><input type ="text" name ="Employee_Type	" value={editedEmployee?.Employee_Type	 || selectedEmployee?.Employee_Type	 || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Employee_Type	: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Employee_Status	</Td>
                    <Td><input type ="text" name ="Employee_Status	" value={editedEmployee?.Employee_Status	 || selectedEmployee?.Employee_Status	 || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Employee_Status	: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Probation Period</Td>
                    <Td><input type ="text" name ="Probation_Period"  value={editedEmployee?.Probation_Period || selectedEmployee?.Probation_Period || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Probation_Period: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Notice Period</Td>
                    <Td><input type ="text" name ="Notice_Period"  value={editedEmployee?.Notice_Period || selectedEmployee?.Notice_Period || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Notice_Period: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Current Company Experience</Td>
                    <Td><input type ="text" name ="Current_Companyexperience"  value={editedEmployee?.Current_Companyexperience || selectedEmployee?.Current_Companyexperience || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Current_Companyexperience: e.target.value })}
                    disabled={!isEditing}
                    /></Td>

                  </Tr>
                  <Tr>
                   <Td fontWeight="bold">Previous Experience</Td>
                   <Td><input type ="text" name ="Previous_Experience"   value={editedEmployee?.Previous_Experience || selectedEmployee?.Previous_Experience || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Previous_Experience: e.target.value })}
                    disabled={!isEditing}
                   /></Td>
                   <Td fontWeight="bold">Total Experience</Td>
                   <Td><input type ="text" name ="Total_Experience" value={editedEmployee?.Total_Experience || selectedEmployee?.Total_Experience || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Total_Experience: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                   <Td fontWeight="bold">Referred By</Td>
                   <Td><input type ="text" name ="ReferredBy"  value={editedEmployee?.ReferredBy || selectedEmployee?.ReferredBy || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, ReferredBy: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
            &nbsp;&nbsp;

            <Box p={5} shadow="md" borderWidth="1px">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th colSpan={6} textAlign="left" fontSize="md" fontWeight="bold">
                      <Flex align="center" justify="space-between" width="100%">
                        <span>Education</span> <LiaEditSolid onClick={handleEditClick} style={{ marginLeft: "2px", cursor: "pointer" }} /> 
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td fontWeight="bold">Certificate_Level</Td>
                    <Td><input type ="text" name ="Certificate_Level"  value={editedEmployee?.Certificate_Level || selectedEmployee?.Certificate_Level || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Certificate_Level: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Institute</Td>
                    <Td><input type="text" name ="Institute" value={editedEmployee?.Institute || selectedEmployee?.Institute || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Institute: e.target.value })}
                    disabled={!isEditing}
                    /></Td>                   
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Year_Of_Passing</Td>
                    <Td><input type="text" name ="Year_Of_Passing" value={editedEmployee?.Year_Of_Passing || selectedEmployee?.Year_Of_Passing || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Year_Of_Passing: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Grade</Td>
                    <Td><input type ="text" name ="Grade" value={editedEmployee?.Grade || selectedEmployee?.Grade || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Grade: e.target.value })}
                    disabled={!isEditing}
                    /></Td>

                  </Tr>
                 
                </Tbody>
              </Table>
            </Box>

            &nbsp;&nbsp;
            <Box p={5} shadow="md" borderWidth="1px">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th colSpan={6} textAlign="left" fontSize="md" fontWeight="bold">
                      <Flex align="center" justify="space-between" width="100%">
                        <span> Present Address</span> <LiaEditSolid onClick={handleEditClick} style={{ marginLeft: "2px", cursor: "pointer" }} /> 
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                   
                    <Td fontWeight="bold">Present_Address_Doorno</Td>
                    <Td><input type ="text" name ="Present_Address_Doorno" value={editedEmployee?.Present_Address_Doorno || selectedEmployee?.Present_Address_Doorno || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Present_Address_Doorno: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Present_City</Td>
                    <Td><input type="text" name ="Present_City"  value={editedEmployee?.Present_City || selectedEmployee?.Present_City || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Present_City: e.target.value })}
                    disabled={!isEditing}
                    /> </Td> 
                    <Td fontWeight="bold">Present_State</Td>
                    <Td><input type ="text" name ="Present_State"  value={editedEmployee?.Present_State || selectedEmployee?.Present_State || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Present_State: e.target.value })}
                    disabled={!isEditing}/></Td> 
                    
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Present_Country</Td>
                    <Td><input type ="text" name ="Present_Country" value={editedEmployee?.Present_Country || selectedEmployee?.Present_Country || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Present_Country: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    
                    <Td fontWeight="bold">Present_Pincode</Td>
                    <Td><input type ="text" name ="Present_Pincode"  value={editedEmployee?.Present_Pincode || selectedEmployee?.Present_Pincode || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Present_Pincode: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>

            &nbsp;&nbsp;
            <Box p={5} shadow="md" borderWidth="1px">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th colSpan={6} textAlign="left" fontSize="md" fontWeight="bold">
                      <Flex align="center" justify="space-between" width="100%">
                        <span> Permanent Address</span> <LiaEditSolid onClick={handleEditClick} style={{ marginLeft: "2px", cursor: "pointer" }} /> 
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    
                    <Td fontWeight="bold">Permanent_Address</Td>
                    <Td><input type="text" name="Permanent_Address" value={editedEmployee?.Permanent_Address || selectedEmployee?.Permanent_Address || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Permanent_Address: e.target.value })}
                    disabled={!isEditing}
                    /> </Td>
                    <Td fontWeight="bold">Permanent_City</Td>
                    <Td><input type="text" name ="Permanent_City" value={editedEmployee?.Permanent_City || selectedEmployee?.Permanent_City || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Permanent_City: e.target.value })}
                    disabled={!isEditing}
                    /> </Td> 
                    <Td fontWeight="bold">Permanent_State</Td>
                    <Td><input type="text" name ="Permanent_State" value={editedEmployee?.Permanent_State || selectedEmployee?.Permanent_State || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Permanent_State: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Permanent_Country</Td>
                    <Td><input type ="text" name ="Permanent_Country" value={editedEmployee?.Permanent_Country || selectedEmployee?.Permanent_Country || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Permanent_Country: e.target.value })}
                    disabled={!isEditing}/></Td>
                    
                    <Td fontWeight="bold">Permanent_Pincode</Td>
                    <Td><input type ="text" name ="Permanent_Pincode"  value={editedEmployee?.Permanent_Pincode || selectedEmployee?.Permanent_Pincode || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Permanent_Pincode: e.target.value })}
                    disabled={!isEditing}/></Td>
                  </Tr>
        
                 
                </Tbody>
              </Table>
            </Box>
            &nbsp;&nbsp;
            <Box p={5} shadow="md" borderWidth="1px">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th colSpan={6} textAlign="left" fontSize="md" fontWeight="bold">
                      <Flex align="center" justify="space-between" width="100%">
                        <span> Emergency Contact</span> <LiaEditSolid onClick={handleEditClick} style={{ marginLeft: "2px", cursor: "pointer" }} />
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td fontWeight="bold">Emergency_Contactname</Td>
                    <Td><input type="text" name ="Emergency_Contactname" value={editedEmployee?.Emergency_Contactname || selectedEmployee?.Emergency_Contactname || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Emergency_Contactname: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Emergency_Contactnumber</Td>
                    <Td><input type ="text" name ="Emergency_Contactnumber"  value={editedEmployee?.Emergency_Contactnumber || selectedEmployee?.Emergency_Contactnumber || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Emergency_Contactnumber: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Emergency_Address</Td>
                    <Td><input type ="text" name="Emergency_Address"  value={editedEmployee?.Emergency_Address || selectedEmployee?.Emergency_Address || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Emergency_Address: e.target.value })}
                    disabled={!isEditing}
                    /> </Td> 
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Emergency_City</Td>
                    <Td><input type ="text" name ="Emergency_City" value={editedEmployee?.Emergency_City || selectedEmployee?.Emergency_City || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Emergency_City: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Emergency_Country</Td>
                    <Td><Input type ="text" name ="Emergency_Country" value={editedEmployee?.Emergency_Country || selectedEmployee?.Emergency_Country || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Emergency_Country: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                     <Td fontWeight="bold">Emergency_State</Td>
                    <Td><input type ="text" name ="Emergency_State" value={editedEmployee?.Emergency_State || selectedEmployee?.Emergency_State || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Emergency_State: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                  </Tr>
                  <Tr> 
                    <Td fontWeight="bold">Emergency_Pincode</Td>
                    <Td><input type ="text" name ="Emergency_Pincode" value={editedEmployee?.Emergency_Pincode || selectedEmployee?.Emergency_Pincode || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Emergency_Pincode: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Emergency_Relationship</Td>
                    <Td><input type ="text" name ="Emergency_Relationship" value={editedEmployee?.Emergency_Relationship || selectedEmployee?.Emergency_Relationship || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Emergency_Relationship: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    <Td fontWeight="bold">Remarks</Td>
                    <Td><input type ="text" name ="Remarks"  value={editedEmployee?.Remarks || selectedEmployee?.Remarks || ''}
                    onChange={(e) => setEditedEmployee({ ...editedEmployee, Remarks: e.target.value })}
                    disabled={!isEditing}
                    /></Td>
                    </Tr>
                 
                </Tbody>
              </Table>
            </Box>
            {isEditing && (
  <Flex justifyContent="flex-end" mt={3}>
    <Button colorScheme="blue" ml={4} onClick={(e) => handleSingleupdate(e)}>
      Save
    </Button>
    <Button colorScheme="blue" ml={4} onClick={handleCancel}>
      Cancel
    </Button>
  </Flex>
)}
          </TabPanel>
          <TabPanel>

            &nbsp;&nbsp;

            <Box p={5} shadow="md" borderWidth="1px">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th colSpan={2} textAlign="left" fontSize="lg" fontWeight="bold">
                      Employee Job Status
                    </Th>
                    <Th colSpan={2} textAlign="right">
                      <Button colorScheme="blue" size="sm" onClick={() => setIsOpenUpdateStatus(true)}>
                        Action Button
                      </Button>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td fontWeight="bold">Designation</Td>
                    <Td>Business Analysts</Td>
                    <Td fontWeight="bold">CostCenter</Td>
                    <Td>Common</Td>
                    <Td fontWeight="bold">Department</Td>
                    <Td>IT</Td>
                  </Tr>
                  <Tr>
                  <Td fontWeight="bold">Location</Td>
                  <Td>Location</Td>
                  <Td fontWeight="bold">Reporting To</Td>
                  <Td>Reporting To</Td>
                  </Tr>
                
                </Tbody>
              </Table>
            </Box>
            {/* Modal Implementation */}
            {isOpenUpdateStatus && (
  <>
    <div style={backdropStyle}></div>
    <div className="UpdateStatusModal">
      <Rnd
        default={{
          x: window.innerWidth / 4,
          y: window.innerHeight / 4,
          width: 500,
          height: "auto",
        }}
        style={style}
      >
        <CgClose onClick={() => setIsOpenUpdateStatus(false)} style={closeModal} />
        <h3>Update Status</h3>
        <FormControl style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>Date</label>
          <Input
            type="date"
            value={dateValue}
            onChange={(e) => setDateValue(e.target.value)}
            style={{ flex: 1 }}
          />
        </FormControl>
        <FormControl style={{display:'flex' ,alignItems:'center',marginBottom:'10px'}}>
          <label style ={{marginRight:'15px'}}>Employee Status :</label>
          <Select
            value={employeeStatus}
            onChange={(e) => setEmployeeStatus(e.target.value)}
          >
            <option value="">Select an option</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
            <option value="Suspended">Suspended</option>
            {/* Add more options as needed */}
          </Select>
        </FormControl>
        <FormControl>
          <label>Remarks</label>
          <Input
            type="text"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </FormControl>
        <Button colorScheme="blue" onClick={onSubmitUpdateStatus}>
          Submit
        </Button>
      </Rnd>
    </div>
  </>
)}

          </TabPanel>
          {/* Additional TabPanels go here */}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default EmployeeProfile;
